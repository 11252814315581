import { findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import resolveConfig from 'tailwindcss/resolveConfig'

import twConfig from '../tailwind.config.js'
import { getChainPageRoute, getNavigationRoutes } from '../utils/routes'

import ChainSelector from './ChainSelector.js'
import Footer from './Footer.js'
import IconContainer from './icons/IconContainer.js'
import PlatformValues from './PlatformValues.js'
import VesperBanner from './VesperBanner'
import Wallet from './Wallet.js'

const tailwindConfig = resolveConfig(twConfig)

const NavigationMenu = function ({
  mobileNavbarOpen,
  onItemClick,
  setMobileNavbarOpen,
  showRibbon
}) {
  const { t } = useTranslation()
  const { asPath } = useRouter()
  const routes = getNavigationRoutes()
  const chainId = useChainId()

  return (
    <>
      <div
        className={`relative z-40 flex cursor-pointer items-center justify-between p-4 md:hidden md:pt-6 ${
          showRibbon ? 'mt-10' : ''
        }`}
      >
        <IconContainer
          className="xs:w-auto w-full"
          height="32"
          name="metronome"
        />
        <div className="flex space-x-2">
          <div
            className="flex h-8 w-8 items-center justify-center rounded-lg bg-white"
            onClick={() => setMobileNavbarOpen(!mobileNavbarOpen)}
          >
            <IconContainer
              fill={mobileNavbarOpen ? tailwindConfig.theme.colors.main : ''}
              name={mobileNavbarOpen ? 'close' : 'hamburger'}
              stroke={!mobileNavbarOpen ? tailwindConfig.theme.colors.main : ''}
            />
          </div>
        </div>
      </div>
      <div
        className={`absolute h-fit md:static ${
          mobileNavbarOpen
            ? 'bg-main inset-0 z-30 w-full px-4 py-9 pt-16 md:w-auto md:bg-inherit md:py-0'
            : 'p-6'
        }`}
      >
        {mobileNavbarOpen && (
          <h2 className="mb-8 mt-4 text-2xl font-semibold leading-7 text-white md:hidden">
            {t('menu')}
          </h2>
        )}
        <div className="md:flex md:items-center md:justify-between">
          <IconContainer
            className="xs:w-auto hidden w-full md:block"
            height="32"
            name="metronome"
          />
          <ul
            className={`ml-6 flex-col font-medium md:ml-0 md:flex-row md:space-x-6 ${
              !mobileNavbarOpen ? 'hidden' : 'flex'
            } md:flex`}
          >
            {routes.map(function ({ key, route, supportedChainIds }) {
              const selected =
                asPath.split('?')[0] ===
                `/${findByChainId(chainId).shortName}${
                  route === '/' ? '' : route
                }`
              return (
                <li className="mb-14 md:mb-0" key={key}>
                  <div>
                    <Link
                      className={`block whitespace-nowrap hover:text-white ${
                        selected ? 'text-white' : 'text-gray-primary'
                      }`}
                      data-sentry="navigation-item"
                      href={getChainPageRoute({ chainId, route }).href}
                      onClick={onItemClick(supportedChainIds, key)}
                    >
                      <span className="inline-block">
                        {t(`navigation.${key}`)}
                        {selected && <div className="selected-nav-bg mt-1" />}
                      </span>
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
          <div className="hidden space-x-2 md:flex">
            <ChainSelector compact={true} mode="desktop" />
            <Wallet />
          </div>
        </div>
        {mobileNavbarOpen && (
          <>
            <PlatformValues />
            <VesperBanner className="!mb-8 mt-16" />
            <Footer />
          </>
        )}
      </div>
    </>
  )
}

export default NavigationMenu
