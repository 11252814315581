import ExternalLink from './ExternalLink'
import IconContainer from './icons/IconContainer'

const SocialLinks = () => (
  <>
    <ExternalLink href="https://twitter.com/MetronomeDAO">
      <IconContainer name="twitter" />
    </ExternalLink>
    <ExternalLink href="https://t.me/metronometoken">
      <IconContainer name="telegram" />
    </ExternalLink>
    <ExternalLink href="https://discord.gg/metronome">
      <IconContainer name="discord" />
    </ExternalLink>
    <ExternalLink href="https://metronomedao.medium.com">
      <IconContainer name="medium" />
    </ExternalLink>
    <ExternalLink href="https://github.com/autonomoussoftware">
      <IconContainer name="github" />
    </ExternalLink>
    <ExternalLink href="https://docs.metronome.io/">
      <IconContainer name="gitbook" />
    </ExternalLink>
  </>
)

export default SocialLinks
