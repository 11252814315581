import { useCallback, useState } from 'react'

export const useClientStorage = function (key, initialValue = '', type) {
  let storage
  if (typeof window !== 'undefined') {
    storage = type === 'session' ? window.sessionStorage : window.localStorage
  }
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(function () {
    if (!storage) {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      console.warn(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    function (value) {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        if (storage) {
          storage.setItem(key, JSON.stringify(valueToStore))
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        // eslint-disable-next-line no-console
        console.warn(error)
      }
    },
    [key, storage, storedValue]
  )
  const clearValue = useCallback(
    function () {
      try {
        // Remove from local storage
        storage.removeItem(key)
      } catch (error) {
        // A more advanced implementation would handle the error case
        // eslint-disable-next-line no-console
        console.warn(error)
      }
    },
    [key, storage]
  )
  return [storedValue, setValue, clearValue]
}
