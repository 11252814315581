import { AppState } from './AppContext'

const buildInitialState = (): AppState => ({
  balances: {
    collateral: {},
    debt: {},
    deposit: {},
    synthetic: {}
  },
  userPosition: {
    claimable: '0',
    deposited: '0',
    generated: '0',
    issuableLimitInUsd: '0'
  },
  wallet: {
    balancesLoaded: false,
    connected: false
  }
})

export default buildInitialState
