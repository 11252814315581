const MsbtcIcon = ({ id = 'paint0_linear_5171_347754', ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <g clipPath="url(#clip0_5171_347754)">
        <g>
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
            fill={`url(#${id})`}
          />
          <path
            d="M17.3912 10.515C17.6267 8.943 16.4289 8.09775 14.7924 7.53375L15.3234 5.40375L14.0274 5.08125L13.5099 7.155C13.1694 7.0695 12.8199 6.99 12.4712 6.9105L12.9924 4.82325L11.6964 4.5L11.1654 6.62925C10.8834 6.56475 10.6059 6.50175 10.3374 6.43425L10.3389 6.4275L8.55094 5.98125L8.20594 7.36575C8.20594 7.36575 9.16819 7.58625 9.14794 7.59975C9.67294 7.731 9.76744 8.07825 9.75169 8.35425L9.14719 10.7805C9.18319 10.7895 9.22969 10.803 9.28219 10.8232L9.14494 10.7895L8.29744 14.1885C8.23294 14.3475 8.07019 14.5867 7.70269 14.496C7.71619 14.5147 6.76069 14.2612 6.76069 14.2612L6.11719 15.7448L7.80469 16.1655C8.11819 16.2443 8.42569 16.3267 8.72794 16.404L8.19169 18.558L9.48694 18.8805L10.0179 16.7505C10.3719 16.8457 10.7154 16.9342 11.0514 17.0182L10.5219 19.1392L11.8179 19.4618L12.3542 17.3123C14.5652 17.7308 16.2272 17.562 16.9269 15.5625C17.4909 13.953 16.8992 13.0238 15.7359 12.4185C16.5834 12.2235 17.2209 11.6663 17.3912 10.515ZM14.4287 14.6685C14.0289 16.2788 11.3177 15.408 10.4387 15.1898L11.1512 12.336C12.0302 12.5557 14.8479 12.99 14.4287 14.6685ZM14.8299 10.4918C14.4647 11.9565 12.2087 11.2118 11.4774 11.0295L12.1224 8.442C12.8537 8.62425 15.2109 8.964 14.8299 10.4918Z"
            fill="white"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id={id}
        x1="0"
        x2="24"
        y1="0"
        y2="24"
      >
        <stop stopColor="#596AEC" />
        <stop offset="1" stopColor="#60E2E9" />
      </linearGradient>
      <clipPath id="clip0_5171_347754">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default MsbtcIcon
