const ViIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1515_119024)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#E94B35"
      />
      <path
        clipRule="evenodd"
        d="M11.9993 14.52L8.4726 16.8541L9.60265 12.7787L6.29297 10.1459L10.5181 9.96128L11.9993 6L13.4805 9.96128L17.7056 10.1459L14.396 12.7787L15.526 16.8541L11.9993 14.52Z"
        fill="#F2C500"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1515_119024">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default ViIcon
