import Big from 'big.js'
import { findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import { createIsNativeToken, fromUnit, toUnit } from 'metronome-utils'
import useSWR from 'swr'

/**
 * Get usd Rate for given token. Keep refreshing the rate on intervals
 * @param token The token to convert and get the rate for
 * @param amount Amount to use as reference for conversion to get the rate
 * @param refreshInterval
 * @param isCollateral
 * @returns {(*|boolean)[]}
 */
const useRefreshedUsdRate = function ({
  token,
  amount = 1,
  refreshInterval = 10 * 1000,
  metronome
}) {
  const chainId = useChainId()
  const isNativeToken = createIsNativeToken(chainId)
  const { wrappedToken } = findByChainId(chainId)

  const { data: usdRate, error } = useSWR(
    `usdrate-${token?.symbol || ''}-${toUnit(amount, token?.decimals)}`,
    function () {
      const _amountToConvert = toUnit(amount, token.decimals)
      let promise
      promise = metronome.convertToUsd(
        isNativeToken(token.symbol) ? wrappedToken.address : token.address,
        _amountToConvert
      )
      if (Big(amount).eq(0)) {
        return Big(0)
      }
      return promise
        .then(_amountOut => Big(fromUnit(_amountOut)).div(amount))
        .catch(console.log)
    },
    { refreshInterval }
  )

  return [usdRate, !error && !usdRate]
}

export default useRefreshedUsdRate
