const CurveIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" fill="#FFFEFE" r="11.4286" />
    <g clipPath="url(#clip0_1782_49644)">
      <mask
        height="16"
        id="mask0_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1782_49644)">
        <path
          d="M10.9992 13.4675L9.93069 13.7041L9.79544 13.2037L10.9349 12.9975L10.9992 13.4675Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask1_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1782_49644)">
        <path
          d="M9.93069 13.7041L8.99747 14.0186L8.79797 13.4979L9.79544 13.2037L9.93069 13.7041Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask2_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_1782_49644)">
        <path
          d="M10.9315 12.9975L9.79544 13.2037L9.67709 12.6188L10.8741 12.4429L10.9315 12.9975Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask3_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_1782_49644)">
        <path
          d="M9.79544 13.2037L8.79797 13.4979L8.61539 12.8893L9.67709 12.6188L9.79544 13.2037Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask4_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_1782_49644)">
        <path
          d="M11.0702 13.8495L10.0727 14.1099L9.92731 13.7041L10.9992 13.4675L11.0702 13.8495Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask5_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_1782_49644)">
        <path
          d="M10.0727 14.1099L9.21387 14.4413L8.99747 14.0186L9.93069 13.7041L10.0727 14.1099Z"
          fill="#0000DA"
        />
      </g>
      <mask
        height="16"
        id="mask6_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_1782_49644)">
        <path
          d="M10.8741 12.4429L9.67711 12.6188L9.5723 11.9662L10.8234 11.8242L10.8741 12.4429Z"
          fill="#0058FF"
        />
      </g>
      <mask
        height="16"
        id="mask7_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_1782_49644)">
        <path
          d="M9.67711 12.6188L8.61541 12.8893L8.45987 12.2063L9.5723 11.9662L9.67711 12.6188Z"
          fill="#0048FF"
        />
      </g>
      <mask
        height="16"
        id="mask8_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask8_1782_49644)">
        <path
          d="M12.1691 13.3153L10.9992 13.4675L10.9349 12.9975L12.1657 12.8724L12.1691 13.3153Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask9_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask9_1782_49644)">
        <path
          d="M11.1479 14.1302L10.2215 14.4074L10.0727 14.1099L11.0702 13.8495L11.1479 14.1302Z"
          fill="#0000DA"
        />
      </g>
      <mask
        height="16"
        id="mask10_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask10_1782_49644)">
        <path
          d="M8.99746 14.0186L8.23669 14.4108L7.96619 13.8732L8.79797 13.4979L8.99746 14.0186Z"
          fill="#0000E8"
        />
      </g>
      <mask
        height="16"
        id="mask11_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask11_1782_49644)">
        <path
          d="M10.2215 14.4074L9.44041 14.7557L9.21387 14.4413L10.0727 14.1099L10.2215 14.4074Z"
          fill="#0000C4"
        />
      </g>
      <mask
        height="16"
        id="mask12_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask12_1782_49644)">
        <path
          d="M12.1657 12.8724L10.9349 12.9975L10.8741 12.4429L12.1623 12.355L12.1657 12.8724Z"
          fill="#003CFF"
        />
      </g>
      <mask
        height="16"
        id="mask13_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask13_1782_49644)">
        <path
          d="M8.79799 13.4979L7.96958 13.8732L7.72614 13.2443L8.6154 12.8893L8.79799 13.4979Z"
          fill="#0008FF"
        />
      </g>
      <mask
        height="16"
        id="mask14_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask14_1782_49644)">
        <path
          d="M12.1758 13.667L11.0702 13.8495L10.9992 13.4675L12.1691 13.3153L12.1758 13.667Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask15_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask15_1782_49644)">
        <path
          d="M9.21387 14.4413L8.5241 14.8436L8.23669 14.4108L8.99747 14.0186L9.21387 14.4413Z"
          fill="#0000C8"
        />
      </g>
      <mask
        height="16"
        id="mask16_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask16_1782_49644)">
        <path
          d="M10.8233 11.8242L9.57229 11.9662L9.48776 11.2595L10.7828 11.1547L10.8233 11.8242Z"
          fill="#0090FF"
        />
      </g>
      <mask
        height="16"
        id="mask17_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask17_1782_49644)">
        <path
          d="M9.57227 11.9662L8.45985 12.2063L8.33136 11.4658L9.48774 11.2595L9.57227 11.9662Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask18_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask18_1782_49644)">
        <path
          d="M12.1623 12.355L10.8741 12.443L10.8233 11.8242L12.1589 11.7768L12.1623 12.355Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask19_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask19_1782_49644)">
        <path
          d="M8.61538 12.8893L7.72612 13.2443L7.51648 12.541L8.45984 12.2063L8.61538 12.8893Z"
          fill="#003CFF"
        />
      </g>
      <mask
        height="16"
        id="mask20_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask20_1782_49644)">
        <path
          d="M12.1826 13.9273L11.1479 14.1302L11.0702 13.8495L12.1758 13.667L12.1826 13.9273Z"
          fill="#0000F6"
        />
      </g>
      <mask
        height="16"
        id="mask21_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask21_1782_49644)">
        <path
          d="M11.2223 14.3026L10.3736 14.5934L10.2215 14.4074L11.1479 14.1302L11.2223 14.3026Z"
          fill="#0000D1"
        />
      </g>
      <mask
        height="16"
        id="mask22_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask22_1782_49644)">
        <path
          d="M9.44042 14.7557L8.82504 15.1648L8.52411 14.8436L9.21388 14.4413L9.44042 14.7557Z"
          fill="#0000AD"
        />
      </g>
      <mask
        height="16"
        id="mask23_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask23_1782_49644)">
        <path
          d="M10.3737 14.5934L9.67035 14.9484L9.44043 14.7557L10.2215 14.4074L10.3737 14.5934Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask24_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask24_1782_49644)">
        <path
          d="M12.1589 11.7768L10.8234 11.8242L10.7828 11.1547L12.1589 11.1479V11.7768Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask25_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask25_1782_49644)">
        <path
          d="M8.45986 12.2063L7.5165 12.541L7.34406 11.7768L8.33138 11.4658L8.45986 12.2063Z"
          fill="#0078FF"
        />
      </g>
      <mask
        height="16"
        id="mask26_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask26_1782_49644)">
        <path
          d="M12.1894 14.0828L11.2223 14.3026L11.1479 14.1302L12.1826 13.9273L12.1894 14.0828Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask27_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask27_1782_49644)">
        <path
          d="M10.7828 11.1547L9.48777 11.2595L9.42352 10.5156L10.7523 10.448L10.7828 11.1547Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask28_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask28_1782_49644)">
        <path
          d="M13.3863 13.2342L12.1691 13.3153L12.1657 12.8724L13.437 12.8284L13.3863 13.2342Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask29_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask29_1782_49644)">
        <path
          d="M9.48774 11.2595L8.32798 11.4658L8.22992 10.6881L9.4235 10.5156L9.48774 11.2595Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask30_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask30_1782_49644)">
        <path
          d="M13.3322 13.5588L12.1758 13.667L12.1691 13.3153L13.3863 13.2342L13.3322 13.5588Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask31_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask31_1782_49644)">
        <path
          d="M13.437 12.8284L12.1657 12.8724L12.1623 12.3584L13.4844 12.3517L13.437 12.8284Z"
          fill="#0050FF"
        />
      </g>
      <mask
        height="16"
        id="mask32_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask32_1782_49644)">
        <path
          d="M8.23669 14.4108L7.65512 14.8673L7.32376 14.3229L7.9662 13.8732L8.23669 14.4108Z"
          fill="#0000DA"
        />
      </g>
      <mask
        height="16"
        id="mask33_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask33_1782_49644)">
        <path
          d="M7.9662 13.8732L7.32376 14.3229L7.02621 13.6805L7.72613 13.2443L7.9662 13.8732Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask34_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask34_1782_49644)">
        <path
          d="M9.67035 14.9484L9.12935 15.361L8.82504 15.1648L9.44043 14.7557L9.67035 14.9484Z"
          fill="#0000A4"
        />
      </g>
      <mask
        height="16"
        id="mask35_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask35_1782_49644)">
        <path
          d="M8.5241 14.8436L8.00677 15.3069L7.65512 14.8673L8.23669 14.4108L8.5241 14.8436Z"
          fill="#0000B6"
        />
      </g>
      <mask
        height="16"
        id="mask36_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask36_1782_49644)">
        <path
          d="M11.3001 14.3635L10.5258 14.661L10.3736 14.5934L11.2223 14.3026L11.3001 14.3635Z"
          fill="#0000D6"
        />
      </g>
      <mask
        height="16"
        id="mask37_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask37_1782_49644)">
        <path
          d="M13.2781 13.7921L12.1826 13.9273L12.1758 13.667L13.3322 13.5588L13.2781 13.7921Z"
          fill="#0004FF"
        />
      </g>
      <mask
        height="16"
        id="mask38_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask38_1782_49644)">
        <path
          d="M13.4844 12.3517L12.1623 12.355L12.1589 11.7768L13.525 11.814L13.4844 12.3517Z"
          fill="#0078FF"
        />
      </g>
      <mask
        height="16"
        id="mask39_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask39_1782_49644)">
        <path
          d="M10.5258 14.661L9.90027 15.0194L9.67035 14.9484L10.3736 14.5934L10.5258 14.661Z"
          fill="#0000BF"
        />
      </g>
      <mask
        height="16"
        id="mask40_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask40_1782_49644)">
        <path
          d="M12.1589 11.1479L10.7828 11.1547L10.7523 10.448L12.1589 10.4818V11.1479Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask41_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask41_1782_49644)">
        <path
          d="M7.72614 13.2443L7.02622 13.6805L6.76587 12.9637L7.5165 12.541L7.72614 13.2443Z"
          fill="#0034FF"
        />
      </g>
      <mask
        height="16"
        id="mask42_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask42_1782_49644)">
        <path
          d="M12.1961 14.1336L11.3001 14.3635L11.2223 14.306L12.1894 14.0828L12.1961 14.1336Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask43_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask43_1782_49644)">
        <path
          d="M8.82503 15.1648L8.37871 15.6281L8.00677 15.3069L8.5241 14.8436L8.82503 15.1648Z"
          fill="#00009F"
        />
      </g>
      <mask
        height="16"
        id="mask44_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask44_1782_49644)">
        <path
          d="M8.328 11.4658L7.34406 11.7768L7.21219 10.9721L8.22994 10.6881L8.328 11.4658Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask45_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask45_1782_49644)">
        <path
          d="M13.224 13.9273L12.1893 14.0828L12.1826 13.9273L13.2781 13.7921L13.224 13.9273Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask46_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask46_1782_49644)">
        <path
          d="M10.7523 10.448L9.42351 10.5156L9.38293 9.75148L10.732 9.72105L10.7523 10.448Z"
          fill="#23FFD4"
        />
      </g>
      <mask
        height="16"
        id="mask47_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask47_1782_49644)">
        <path
          d="M13.525 11.814L12.1589 11.7768V11.1479L13.5621 11.2291L13.525 11.814Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask48_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask48_1782_49644)">
        <path
          d="M9.42351 10.5156L8.23331 10.6881L8.16907 9.89012L9.38293 9.75149L9.42351 10.5156Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask49_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask49_1782_49644)">
        <path
          d="M9.90025 15.0194L9.43364 15.432L9.12933 15.361L9.67033 14.9484L9.90025 15.0194Z"
          fill="#0000AD"
        />
      </g>
      <mask
        height="16"
        id="mask50_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask50_1782_49644)">
        <path
          d="M7.51651 12.541L6.76587 12.9637L6.55286 12.1792L7.34407 11.7768L7.51651 12.541Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask51_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask51_1782_49644)">
        <path
          d="M11.3745 14.3094L10.6712 14.6103L10.5258 14.661L11.3001 14.3635L11.3745 14.3094Z"
          fill="#0000E8"
        />
      </g>
      <mask
        height="16"
        id="mask52_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask52_1782_49644)">
        <path
          d="M9.12936 15.361L8.75404 15.8208L8.37872 15.6281L8.82505 15.1648L9.12936 15.361Z"
          fill="#000096"
        />
      </g>
      <mask
        height="16"
        id="mask53_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask53_1782_49644)">
        <path
          d="M12.1589 10.4818L10.7523 10.448L10.7321 9.72105L12.1623 9.79882L12.1589 10.4818Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask54_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask54_1782_49644)">
        <path
          d="M13.1699 13.9645L12.1961 14.1302L12.1894 14.0828L13.224 13.9273L13.1699 13.9645Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask55_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask55_1782_49644)">
        <path
          d="M14.59 13.224L13.3863 13.2342L13.437 12.8284L14.6881 12.8588L14.59 13.224Z"
          fill="#0044FF"
        />
      </g>
      <mask
        height="16"
        id="mask56_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask56_1782_49644)">
        <path
          d="M14.4852 13.5148L13.3322 13.5588L13.3863 13.2342L14.59 13.224L14.4852 13.5148Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask57_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask57_1782_49644)">
        <path
          d="M10.6746 14.6103L10.12 14.9654L9.90027 15.0195L10.5258 14.661L10.6746 14.6103Z"
          fill="#0000D1"
        />
      </g>
      <mask
        height="16"
        id="mask58_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask58_1782_49644)">
        <path
          d="M13.5621 11.2291L12.1589 11.1479V10.4818L13.5892 10.6103L13.5621 11.2291Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask59_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask59_1782_49644)">
        <path
          d="M12.2029 14.0727L11.3745 14.3094L11.3001 14.3635L12.1961 14.1336L12.2029 14.0727Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask60_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask60_1782_49644)">
        <path
          d="M14.6881 12.8588L13.437 12.8284L13.4844 12.3517L14.776 12.4227L14.6881 12.8588Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask61_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask61_1782_49644)">
        <path
          d="M8.23331 10.6881L7.21218 10.9721L7.12427 10.1437L8.16907 9.89011L8.23331 10.6881Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask62_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask62_1782_49644)">
        <path
          d="M14.3804 13.7211L13.2781 13.7921L13.3322 13.5588L14.4852 13.5148L14.3804 13.7211Z"
          fill="#001CFF"
        />
      </g>
      <mask
        height="16"
        id="mask63_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask63_1782_49644)">
        <path
          d="M7.65514 14.8673L7.24939 15.3745L6.86731 14.8301L7.32378 14.3229L7.65514 14.8673Z"
          fill="#0000D1"
        />
      </g>
      <mask
        height="16"
        id="mask64_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask64_1782_49644)">
        <path
          d="M8.00679 15.3069L7.6619 15.8107L7.24939 15.3745L7.65514 14.8673L8.00679 15.3069Z"
          fill="#0000A8"
        />
      </g>
      <mask
        height="16"
        id="mask65_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask65_1782_49644)">
        <path
          d="M10.732 9.72106L9.38293 9.75149L9.36603 8.98395L10.7253 8.99409L10.732 9.72106Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask66_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask66_1782_49644)">
        <path
          d="M14.776 12.4227L13.4844 12.3517L13.525 11.814L14.8538 11.929L14.776 12.4227Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask67_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask67_1782_49644)">
        <path
          d="M7.32378 14.3229L6.86393 14.8301L6.51904 14.1843L7.02623 13.6805L7.32378 14.3229Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask68_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask68_1782_49644)">
        <path
          d="M7.34404 11.7768L6.55283 12.1826L6.39053 11.3542L7.21217 10.9721L7.34404 11.7768Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask69_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask69_1782_49644)">
        <path
          d="M9.38294 9.75149L8.16907 9.89012L8.13864 9.09215L9.36603 8.98395L9.38294 9.75149Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask70_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask70_1782_49644)">
        <path
          d="M14.2722 13.836L13.224 13.9273L13.2781 13.7921L14.3804 13.7211L14.2722 13.836Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask71_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask71_1782_49644)">
        <path
          d="M10.12 14.9653L9.7312 15.3711L9.43365 15.432L9.90026 15.0194L10.12 14.9653Z"
          fill="#0000BF"
        />
      </g>
      <mask
        height="16"
        id="mask72_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask72_1782_49644)">
        <path
          d="M9.43366 15.432L9.12935 15.885L8.75742 15.8208L9.12935 15.3643L9.43366 15.432Z"
          fill="#00009B"
        />
      </g>
      <mask
        height="16"
        id="mask73_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask73_1782_49644)">
        <path
          d="M8.37872 15.6281L8.0947 16.1251L7.66528 15.8107L8.00679 15.3069L8.37872 15.6281Z"
          fill="#000092"
        />
      </g>
      <mask
        height="16"
        id="mask74_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask74_1782_49644)">
        <path
          d="M13.1158 13.9003L12.2029 14.0727L12.1961 14.1336L13.1699 13.9645L13.1158 13.9003Z"
          fill="#000CFF"
        />
      </g>
      <mask
        height="16"
        id="mask75_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask75_1782_49644)">
        <path
          d="M7.02622 13.6805L6.51903 14.1843L6.21472 13.4607L6.76586 12.9637L7.02622 13.6805Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask76_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask76_1782_49644)">
        <path
          d="M12.1623 9.79882L10.732 9.72105L10.7253 8.99409L12.1657 9.11581L12.1623 9.79882Z"
          fill="#5DFF9A"
        />
      </g>
      <mask
        height="16"
        id="mask77_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask77_1782_49644)">
        <path
          d="M13.5892 10.6103L12.1589 10.4818L12.1623 9.79882L13.6095 9.97126L13.5892 10.6103Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask78_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask78_1782_49644)">
        <path
          d="M14.8538 11.929L13.5249 11.814L13.5621 11.2291L14.9214 11.388L14.8538 11.929Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask79_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask79_1782_49644)">
        <path
          d="M11.4455 14.1403L10.8132 14.4345L10.6746 14.6103L11.3745 14.3094L11.4455 14.1403Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask80_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask80_1782_49644)">
        <path
          d="M12.213 13.907L11.4455 14.1403L11.3745 14.3094L12.2029 14.0727L12.213 13.907Z"
          fill="#000CFF"
        />
      </g>
      <mask
        height="16"
        id="mask81_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask81_1782_49644)">
        <path
          d="M14.164 13.8597L13.1699 13.9645L13.224 13.9273L14.2722 13.836L14.164 13.8597Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask82_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask82_1782_49644)">
        <path
          d="M10.8132 14.4345L10.3297 14.7828L10.1201 14.9653L10.6746 14.6103L10.8132 14.4345Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask83_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask83_1782_49644)">
        <path
          d="M8.16906 9.89012L7.12426 10.1437L7.08707 9.31531L8.13863 9.09215L8.16906 9.89012Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask84_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask84_1782_49644)">
        <path
          d="M8.75742 15.8208L8.53426 16.3111L8.0947 16.1251L8.37872 15.6281L8.75742 15.8208Z"
          fill="#000089"
        />
      </g>
      <mask
        height="16"
        id="mask85_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask85_1782_49644)">
        <path
          d="M6.76587 12.9637L6.21473 13.4607L5.9679 12.6695L6.55285 12.1792L6.76587 12.9637Z"
          fill="#006CFF"
        />
      </g>
      <mask
        height="16"
        id="mask86_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask86_1782_49644)">
        <path
          d="M7.21219 10.9721L6.39055 11.3542L6.28235 10.5055L7.12428 10.1437L7.21219 10.9721Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask87_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask87_1782_49644)">
        <path
          d="M14.9214 11.388L13.5621 11.2291L13.5892 10.6103L14.9721 10.8166L14.9214 11.388Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask88_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask88_1782_49644)">
        <path
          d="M10.7253 8.99409L9.36603 8.98395L9.37279 8.24007L10.7287 8.28741L10.7253 8.99409Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask89_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask89_1782_49644)">
        <path
          d="M13.0685 13.738L12.213 13.907L12.2029 14.0727L13.1158 13.9003L13.0685 13.738Z"
          fill="#0024FF"
        />
      </g>
      <mask
        height="16"
        id="mask90_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask90_1782_49644)">
        <path
          d="M9.73119 15.3711L9.49451 15.814L9.12933 15.885L9.43364 15.432L9.73119 15.3711Z"
          fill="#0000AD"
        />
      </g>
      <mask
        height="16"
        id="mask91_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask91_1782_49644)">
        <path
          d="M15.5706 13.5317L14.4852 13.5148L14.59 13.224L15.7126 13.2747L15.5706 13.5317Z"
          fill="#0044FF"
        />
      </g>
      <mask
        height="16"
        id="mask92_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask92_1782_49644)">
        <path
          d="M15.7126 13.2747L14.59 13.224L14.6881 12.8588L15.8478 12.9468L15.7126 13.2747Z"
          fill="#005CFF"
        />
      </g>
      <mask
        height="16"
        id="mask93_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask93_1782_49644)">
        <path
          d="M13.6095 9.97127L12.1623 9.79882L12.1657 9.11581L13.623 9.33221L13.6095 9.97127Z"
          fill="#5DFF9A"
        />
      </g>
      <mask
        height="16"
        id="mask94_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask94_1782_49644)">
        <path
          d="M9.36603 8.98395L8.13864 9.09214L8.14878 8.31108L9.37279 8.24007L9.36603 8.98395Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask95_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask95_1782_49644)">
        <path
          d="M14.0626 13.7887L13.1158 13.9003L13.1699 13.9645L14.164 13.8597L14.0626 13.7887Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask96_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask96_1782_49644)">
        <path
          d="M10.3297 14.7828L10.0085 15.1784L9.7312 15.3711L10.12 14.9653L10.3297 14.7828Z"
          fill="#0000DF"
        />
      </g>
      <mask
        height="16"
        id="mask97_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask97_1782_49644)">
        <path
          d="M15.4218 13.7109L14.3804 13.7211L14.4852 13.5148L15.5706 13.5317L15.4218 13.7109Z"
          fill="#0038FF"
        />
      </g>
      <mask
        height="16"
        id="mask98_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask98_1782_49644)">
        <path
          d="M15.8478 12.9468L14.6881 12.8588L14.776 12.4227L15.9696 12.5511L15.8478 12.9468Z"
          fill="#0078FF"
        />
      </g>
      <mask
        height="16"
        id="mask99_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask99_1782_49644)">
        <path
          d="M12.1657 9.11582L10.7253 8.99409L10.7287 8.28741L12.1691 8.44633L12.1657 9.11582Z"
          fill="#90FF66"
        />
      </g>
      <mask
        height="16"
        id="mask100_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask100_1782_49644)">
        <path
          d="M9.12934 15.885L8.97042 16.3618L8.53424 16.3111L8.7574 15.8208L9.12934 15.885Z"
          fill="#00008D"
        />
      </g>
      <mask
        height="16"
        id="mask101_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask101_1782_49644)">
        <path
          d="M11.5131 13.8597L10.9383 14.1437L10.8132 14.4345L11.4455 14.1403L11.5131 13.8597Z"
          fill="#001CFF"
        />
      </g>
      <mask
        height="16"
        id="mask102_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask102_1782_49644)">
        <path
          d="M15.2697 13.8056L14.2722 13.836L14.3804 13.7211L15.4184 13.7109L15.2697 13.8056Z"
          fill="#0034FF"
        />
      </g>
      <mask
        height="16"
        id="mask103_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask103_1782_49644)">
        <path
          d="M12.2198 13.6365L11.5131 13.8597L11.4455 14.1403L12.213 13.907L12.2198 13.6365Z"
          fill="#0030FF"
        />
      </g>
      <mask
        height="16"
        id="mask104_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask104_1782_49644)">
        <path
          d="M15.9696 12.5511L14.776 12.4227L14.8538 11.929L16.0778 12.1048L15.9696 12.5511Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask105_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask105_1782_49644)">
        <path
          d="M14.9721 10.8166L13.5892 10.6103L13.6095 9.97127L15.0093 10.2249L14.9721 10.8166Z"
          fill="#33FFC4"
        />
      </g>
      <mask
        height="16"
        id="mask106_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask106_1782_49644)">
        <path
          d="M6.55284 12.1792L5.96788 12.6695L5.77853 11.8309L6.39054 11.3542L6.55284 12.1792Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask107_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask107_1782_49644)">
        <path
          d="M7.24939 15.3745L7.0127 15.9155L6.58328 15.3779L6.86392 14.8301L7.24939 15.3745Z"
          fill="#0000C8"
        />
      </g>
      <mask
        height="16"
        id="mask108_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask108_1782_49644)">
        <path
          d="M7.66527 15.8107L7.47254 16.3415L7.0127 15.9155L7.24938 15.3745L7.66527 15.8107Z"
          fill="#0000A4"
        />
      </g>
      <mask
        height="16"
        id="mask109_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask109_1782_49644)">
        <path
          d="M10.9383 14.1437L10.5224 14.4818L10.3297 14.7828L10.8132 14.4345L10.9383 14.1437Z"
          fill="#0008FF"
        />
      </g>
      <mask
        height="16"
        id="mask110_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask110_1782_49644)">
        <path
          d="M6.8673 14.8301L6.58328 15.3779L6.19781 14.7388L6.51903 14.1843L6.8673 14.8301Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask111_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask111_1782_49644)">
        <path
          d="M8.13863 9.09215L7.08707 9.31531L7.09721 8.50719L8.14877 8.31108L8.13863 9.09215Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask112_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask112_1782_49644)">
        <path
          d="M15.1209 13.8157L14.164 13.8597L14.2722 13.836L15.2697 13.8056L15.1209 13.8157Z"
          fill="#0034FF"
        />
      </g>
      <mask
        height="16"
        id="mask113_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask113_1782_49644)">
        <path
          d="M13.9679 13.6264L13.0685 13.738L13.1158 13.9003L14.0626 13.7887L13.9679 13.6264Z"
          fill="#003CFF"
        />
      </g>
      <mask
        height="16"
        id="mask114_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask114_1782_49644)">
        <path
          d="M7.12428 10.1437L6.28235 10.5055L6.23502 9.65343L7.08709 9.31531L7.12428 10.1437Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask115_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask115_1782_49644)">
        <path
          d="M8.09468 16.1251L7.95267 16.6424L7.47253 16.3415L7.66526 15.8107L8.09468 16.1251Z"
          fill="#000089"
        />
      </g>
      <mask
        height="16"
        id="mask116_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask116_1782_49644)">
        <path
          d="M13.0279 13.4742L12.2198 13.6365L12.213 13.907L13.0685 13.738L13.0279 13.4742Z"
          fill="#0044FF"
        />
      </g>
      <mask
        height="16"
        id="mask117_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask117_1782_49644)">
        <path
          d="M16.0778 12.1048L14.8538 11.929L14.9214 11.388L16.1691 11.6112L16.0778 12.1048Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask118_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask118_1782_49644)">
        <path
          d="M13.623 9.33221L12.1657 9.11581L12.1691 8.44633L13.623 8.70668V9.33221Z"
          fill="#90FF66"
        />
      </g>
      <mask
        height="16"
        id="mask119_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask119_1782_49644)">
        <path
          d="M10.7287 8.28741L9.3728 8.24007L9.40323 7.53001L10.749 7.61454L10.7287 8.28741Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask120_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask120_1782_49644)">
        <path
          d="M10.0085 15.1784L9.83939 15.6078L9.49451 15.814L9.73119 15.3711L10.0085 15.1784Z"
          fill="#0000CD"
        />
      </g>
      <mask
        height="16"
        id="mask121_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask121_1782_49644)">
        <path
          d="M6.51902 14.1843L6.1978 14.7388L5.85968 14.0152L6.21471 13.4607L6.51902 14.1843Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask122_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask122_1782_49644)">
        <path
          d="M9.37278 8.24007L8.14877 8.31108L8.19273 7.57059L9.40321 7.53001L9.37278 8.24007Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask123_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask123_1782_49644)">
        <path
          d="M12.1691 8.44633L10.7287 8.28741L10.749 7.61455L12.1725 7.81066L12.1691 8.44633Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask124_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask124_1782_49644)">
        <path
          d="M9.49452 15.814L9.39646 16.2773L8.97043 16.3618L9.13273 15.885L9.49452 15.814Z"
          fill="#00009F"
        />
      </g>
      <mask
        height="16"
        id="mask125_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask125_1782_49644)">
        <path
          d="M15.0093 10.2249L13.6095 9.97127L13.623 9.33221L15.0296 9.62976L15.0093 10.2249Z"
          fill="#60FF97"
        />
      </g>
      <mask
        height="16"
        id="mask126_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask126_1782_49644)">
        <path
          d="M14.9823 13.738L14.0626 13.7887L14.164 13.8597L15.1209 13.8157L14.9823 13.738Z"
          fill="#0040FF"
        />
      </g>
      <mask
        height="16"
        id="mask127_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask127_1782_49644)">
        <path
          d="M10.5224 14.4785L10.2654 14.8605L10.0085 15.1784L10.3297 14.7828L10.5224 14.4785Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask128_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask128_1782_49644)">
        <path
          d="M8.53424 16.3111L8.44295 16.8115L7.95267 16.6424L8.09468 16.1251L8.53424 16.3111Z"
          fill="#000080"
        />
      </g>
      <mask
        height="16"
        id="mask129_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask129_1782_49644)">
        <path
          d="M6.39055 11.3542L5.77855 11.8309L5.65344 10.9653L6.28235 10.5055L6.39055 11.3542Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask130_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask130_1782_49644)">
        <path
          d="M16.1691 11.6112L14.9214 11.388L14.9721 10.8166L16.2401 11.0871L16.1691 11.6112Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask131_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask131_1782_49644)">
        <path
          d="M12.2266 13.268L11.5706 13.4742L11.5131 13.8597L12.2198 13.6365L12.2266 13.268Z"
          fill="#0058FF"
        />
      </g>
      <mask
        height="16"
        id="mask132_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask132_1782_49644)">
        <path
          d="M11.5706 13.4742L11.0533 13.7413L10.9383 14.1437L11.5131 13.8597L11.5706 13.4742Z"
          fill="#0048FF"
        />
      </g>
      <mask
        height="16"
        id="mask133_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask133_1782_49644)">
        <path
          d="M13.8833 13.3728L13.0279 13.4742L13.0685 13.738L13.9679 13.6264L13.8833 13.3728Z"
          fill="#0058FF"
        />
      </g>
      <mask
        height="16"
        id="mask134_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask134_1782_49644)">
        <path
          d="M6.21472 13.4607L5.85969 14.0152L5.58243 13.2206L5.96789 12.6695L6.21472 13.4607Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask135_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask135_1782_49644)">
        <path
          d="M16.5173 13.5993L15.5706 13.5317L15.7126 13.2747L16.6898 13.3762L16.5173 13.5993Z"
          fill="#0060FF"
        />
      </g>
      <mask
        height="16"
        id="mask136_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask136_1782_49644)">
        <path
          d="M16.3381 13.7515L15.4218 13.7109L15.5706 13.5317L16.5173 13.5993L16.3381 13.7515Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask137_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask137_1782_49644)">
        <path
          d="M16.6898 13.3762L15.7126 13.2747L15.8479 12.9468L16.8521 13.082L16.6898 13.3762Z"
          fill="#0074FF"
        />
      </g>
      <mask
        height="16"
        id="mask138_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask138_1782_49644)">
        <path
          d="M12.9907 13.1226L12.2265 13.268L12.2198 13.6365L13.0279 13.4742L12.9907 13.1226Z"
          fill="#006CFF"
        />
      </g>
      <mask
        height="16"
        id="mask139_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask139_1782_49644)">
        <path
          d="M11.0533 13.7413L10.6915 14.0592L10.5224 14.4784L10.9383 14.1437L11.0533 13.7413Z"
          fill="#0038FF"
        />
      </g>
      <mask
        height="16"
        id="mask140_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask140_1782_49644)">
        <path
          d="M14.847 13.5723L13.9679 13.6264L14.0626 13.7887L14.9789 13.738L14.847 13.5723Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask141_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask141_1782_49644)">
        <path
          d="M13.623 8.70668L12.1691 8.44633L12.1724 7.81065L13.6196 8.1082L13.623 8.70668Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask142_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask142_1782_49644)">
        <path
          d="M8.97044 16.3618L8.92648 16.8487L8.44296 16.8149L8.53426 16.3111L8.97044 16.3618Z"
          fill="#000084"
        />
      </g>
      <mask
        height="16"
        id="mask143_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask143_1782_49644)">
        <path
          d="M7.08709 9.31531L6.23502 9.65343L6.24854 8.81827L7.09723 8.50719L7.08709 9.31531Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask144_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask144_1782_49644)">
        <path
          d="M16.1555 13.8225L15.2697 13.8056L15.4218 13.7109L16.3381 13.7515L16.1555 13.8225Z"
          fill="#004CFF"
        />
      </g>
      <mask
        height="16"
        id="mask145_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask145_1782_49644)">
        <path
          d="M8.14879 8.31108L7.09723 8.50719L7.15471 7.73627L8.19275 7.57059L8.14879 8.31108Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask146_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask146_1782_49644)">
        <path
          d="M16.8521 13.082L15.8478 12.9468L15.9696 12.5511L17.0008 12.7304L16.8521 13.082Z"
          fill="#0090FF"
        />
      </g>
      <mask
        height="16"
        id="mask147_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask147_1782_49644)">
        <path
          d="M16.2401 11.0871L14.9721 10.8166L15.0093 10.2249L16.2874 10.5427L16.2401 11.0871Z"
          fill="#36FFC1"
        />
      </g>
      <mask
        height="16"
        id="mask148_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask148_1782_49644)">
        <path
          d="M15.0296 9.62976L13.623 9.33221V8.70668L15.0296 9.04818V9.62976Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask149_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask149_1782_49644)">
        <path
          d="M10.749 7.61455L9.40323 7.53002L9.45733 6.87744L10.776 6.9924L10.749 7.61455Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask150_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask150_1782_49644)">
        <path
          d="M10.2655 14.8605L10.1539 15.2764L9.83942 15.6078L10.0085 15.1784L10.2655 14.8605Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask151_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask151_1782_49644)">
        <path
          d="M15.9763 13.8191L15.1209 13.8157L15.2697 13.8056L16.1555 13.8225L15.9763 13.8191Z"
          fill="#0050FF"
        />
      </g>
      <mask
        height="16"
        id="mask152_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask152_1782_49644)">
        <path
          d="M9.8394 15.6078L9.79544 16.0575L9.39645 16.2773L9.49451 15.814L9.8394 15.6078Z"
          fill="#0000C4"
        />
      </g>
      <mask
        height="16"
        id="mask153_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask153_1782_49644)">
        <path
          d="M17.0009 12.7304L15.9696 12.5512L16.0778 12.1048L17.1327 12.3246L17.0009 12.7304Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask154_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask154_1782_49644)">
        <path
          d="M12.1725 7.81066L10.749 7.61455L10.776 6.9924L12.1792 7.22232L12.1725 7.81066Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask155_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask155_1782_49644)">
        <path
          d="M13.809 13.038L12.9907 13.1226L13.0279 13.4742L13.8834 13.3728L13.809 13.038Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask156_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask156_1782_49644)">
        <path
          d="M9.40323 7.53002L8.19275 7.57059L8.2739 6.88759L9.45733 6.87744L9.40323 7.53002Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask157_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask157_1782_49644)">
        <path
          d="M6.28234 10.5055L5.65343 10.9653L5.59595 10.0964L6.235 9.65343L6.28234 10.5055Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask158_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask158_1782_49644)">
        <path
          d="M5.9679 12.6695L5.58244 13.2206L5.3728 12.3787L5.77855 11.8309L5.9679 12.6695Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask159_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask159_1782_49644)">
        <path
          d="M10.6915 14.0592L10.4886 14.421L10.2654 14.8605L10.5224 14.4784L10.6915 14.0592Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask160_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask160_1782_49644)">
        <path
          d="M14.7287 13.3288L13.8834 13.3728L13.9679 13.6264L14.847 13.5723L14.7287 13.3288Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask161_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask161_1782_49644)">
        <path
          d="M7.47252 16.3415L7.43195 16.8555L6.95181 16.4362L7.01267 15.9155L7.47252 16.3415Z"
          fill="#00009F"
        />
      </g>
      <mask
        height="16"
        id="mask162_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask162_1782_49644)">
        <path
          d="M7.01268 15.9155L6.95182 16.4362L6.50549 15.9053L6.58326 15.3779L7.01268 15.9155Z"
          fill="#0000C8"
        />
      </g>
      <mask
        height="16"
        id="mask163_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask163_1782_49644)">
        <path
          d="M12.2367 12.8081L11.6213 12.9941L11.5706 13.4742L12.2265 13.268L12.2367 12.8081Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask164_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask164_1782_49644)">
        <path
          d="M15.8073 13.7346L14.9789 13.738L15.1209 13.8157L15.9763 13.8191L15.8073 13.7346Z"
          fill="#005CFF"
        />
      </g>
      <mask
        height="16"
        id="mask165_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask165_1782_49644)">
        <path
          d="M11.6213 12.9941L11.1479 13.2342L11.0533 13.7413L11.5706 13.4742L11.6213 12.9941Z"
          fill="#007CFF"
        />
      </g>
      <mask
        height="16"
        id="mask166_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask166_1782_49644)">
        <path
          d="M17.1327 12.3246L16.0778 12.1048L16.1691 11.6112L17.2409 11.8783L17.1327 12.3246Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask167_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask167_1782_49644)">
        <path
          d="M7.95269 16.6424L7.9324 17.1462L7.43198 16.8555L7.47255 16.3415L7.95269 16.6424Z"
          fill="#000089"
        />
      </g>
      <mask
        height="16"
        id="mask168_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask168_1782_49644)">
        <path
          d="M9.39647 16.2773L9.39986 16.7439L8.92648 16.8487L8.97044 16.3618L9.39647 16.2773Z"
          fill="#00009B"
        />
      </g>
      <mask
        height="16"
        id="mask169_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask169_1782_49644)">
        <path
          d="M16.2874 10.5427L15.0093 10.2249L15.0296 9.62976L16.3145 9.99493L16.2874 10.5427Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask170_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask170_1782_49644)">
        <path
          d="M6.58328 15.3779L6.50552 15.9053L6.10315 15.2697L6.19782 14.7388L6.58328 15.3779Z"
          fill="#0000FA"
        />
      </g>
      <mask
        height="16"
        id="mask171_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask171_1782_49644)">
        <path
          d="M12.9603 12.6864L12.2367 12.8081L12.2299 13.268L12.9907 13.1226L12.9603 12.6864Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask172_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask172_1782_49644)">
        <path
          d="M13.6196 8.1082L12.1725 7.81066L12.1792 7.22232L13.6027 7.55706L13.6196 8.1082Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask173_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask173_1782_49644)">
        <path
          d="M15.0296 9.04819L13.623 8.7033L13.6196 8.10821L15.0161 8.49029L15.0296 9.04819Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask174_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask174_1782_49644)">
        <path
          d="M11.1479 13.2342L10.8335 13.5317L10.6915 14.0592L11.0533 13.7413L11.1479 13.2342Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask175_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask175_1782_49644)">
        <path
          d="M8.44294 16.8149V17.3085L7.93237 17.1462L7.95266 16.6424L8.44294 16.8149Z"
          fill="#000080"
        />
      </g>
      <mask
        height="16"
        id="mask176_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask176_1782_49644)">
        <path
          d="M15.645 13.5723H14.847L14.9823 13.738L15.8073 13.7346L15.645 13.5723Z"
          fill="#006CFF"
        />
      </g>
      <mask
        height="16"
        id="mask177_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask177_1782_49644)">
        <path
          d="M7.09722 8.50719L6.24854 8.82164L6.31954 8.02367L7.15471 7.73627L7.09722 8.50719Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask178_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask178_1782_49644)">
        <path
          d="M17.2409 11.8783L16.1691 11.6112L16.2401 11.0871L17.3221 11.4015L17.2409 11.8783Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask179_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask179_1782_49644)">
        <path
          d="M8.19273 7.57058L7.15469 7.73626L7.26289 7.0262L8.27388 6.88757L8.19273 7.57058Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask180_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask180_1782_49644)">
        <path
          d="M6.19782 14.7388L6.10314 15.2697L5.7515 14.5495L5.85969 14.0152L6.19782 14.7388Z"
          fill="#0024FF"
        />
      </g>
      <mask
        height="16"
        id="mask181_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask181_1782_49644)">
        <path
          d="M14.6272 13.011L13.809 13.038L13.8833 13.3728L14.7287 13.3288L14.6272 13.011Z"
          fill="#0094FF"
        />
      </g>
      <mask
        height="16"
        id="mask182_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask182_1782_49644)">
        <path
          d="M13.7481 12.6255L12.9603 12.6864L12.9907 13.1226L13.809 13.038L13.7481 12.6255Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask183_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask183_1782_49644)">
        <path
          d="M10.776 6.99239L9.45734 6.87743L9.5351 6.29924L10.82 6.44125L10.776 6.99239Z"
          fill="#FFB200"
        />
      </g>
      <mask
        height="16"
        id="mask184_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask184_1782_49644)">
        <path
          d="M5.77854 11.8309L5.37279 12.3787L5.23416 11.5063L5.65343 10.9653L5.77854 11.8309Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask185_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask185_1782_49644)">
        <path
          d="M10.1539 15.2764L10.1606 15.7058L9.79544 16.0575L9.8394 15.6078L10.1539 15.2764Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask186_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask186_1782_49644)">
        <path
          d="M12.1792 7.22232L10.776 6.99239L10.8166 6.44125L12.186 6.70161L12.1792 7.22232Z"
          fill="#FFBD00"
        />
      </g>
      <mask
        height="16"
        id="mask187_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask187_1782_49644)">
        <path
          d="M10.4886 14.421L10.4311 14.8166L10.1539 15.2764L10.2655 14.8605L10.4886 14.421Z"
          fill="#0020FF"
        />
      </g>
      <mask
        height="16"
        id="mask188_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask188_1782_49644)">
        <path
          d="M16.3145 9.99155L15.0296 9.62976V9.04819L16.3145 9.45394V9.99155Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask189_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask189_1782_49644)">
        <path
          d="M17.0685 13.8293L16.3381 13.7515L16.5173 13.5993L17.268 13.7041L17.0685 13.8293Z"
          fill="#006CFF"
        />
      </g>
      <mask
        height="16"
        id="mask190_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask190_1782_49644)">
        <path
          d="M17.268 13.7041L16.5173 13.5993L16.6898 13.3762L17.4607 13.5114L17.268 13.7041Z"
          fill="#0078FF"
        />
      </g>
      <mask
        height="16"
        id="mask191_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask191_1782_49644)">
        <path
          d="M6.235 9.65343L5.59595 10.0964L5.61285 9.2443L6.24853 8.81827L6.235 9.65343Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask192_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask192_1782_49644)">
        <path
          d="M16.8656 13.8834L16.1555 13.8225L16.3381 13.7515L17.0685 13.8293L16.8656 13.8834Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask193_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask193_1782_49644)">
        <path
          d="M17.4607 13.5114L16.6898 13.3762L16.8521 13.082L17.6433 13.2544L17.4607 13.5114Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask194_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask194_1782_49644)">
        <path
          d="M9.45733 6.87744L8.2739 6.88758L8.38886 6.27896L9.5351 6.29925L9.45733 6.87744Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask195_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask195_1782_49644)">
        <path
          d="M12.2435 12.2705L11.6585 12.426L11.6213 12.9907L12.2367 12.8081L12.2435 12.2705Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask196_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask196_1782_49644)">
        <path
          d="M8.92648 16.8487L8.94677 17.3356L8.44296 17.3085V16.8149L8.92648 16.8487Z"
          fill="#000084"
        />
      </g>
      <mask
        height="16"
        id="mask197_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask197_1782_49644)">
        <path
          d="M9.79544 16.0575L9.84278 16.5072L9.39984 16.7439L9.39645 16.2773L9.79544 16.0575Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask198_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask198_1782_49644)">
        <path
          d="M15.503 13.3356L14.7287 13.3288L14.847 13.5723H15.645L15.503 13.3356Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask199_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask199_1782_49644)">
        <path
          d="M10.8335 13.5317L10.6779 13.8732L10.4886 14.4244L10.6915 14.0592L10.8335 13.5317Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask200_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask200_1782_49644)">
        <path
          d="M17.3221 11.4015L16.2401 11.0871L16.2874 10.5427L17.3796 10.9045L17.3221 11.4015Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask201_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask201_1782_49644)">
        <path
          d="M12.94 12.1792L12.2435 12.2705L12.2367 12.8081L12.9603 12.6864L12.94 12.1792Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask202_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask202_1782_49644)">
        <path
          d="M5.85969 14.0152L5.75149 14.5495L5.46408 13.7549L5.58242 13.2206L5.85969 14.0152Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask203_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask203_1782_49644)">
        <path
          d="M16.6695 13.8664L15.9763 13.8191L16.1556 13.8225L16.8656 13.8834L16.6695 13.8664Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask204_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask204_1782_49644)">
        <path
          d="M11.6585 12.426L11.2223 12.6391L11.1479 13.2342L11.6213 12.9941L11.6585 12.426Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask205_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask205_1782_49644)">
        <path
          d="M17.6433 13.2544L16.8521 13.082L17.0009 12.7304L17.809 12.9434L17.6433 13.2544Z"
          fill="#00A4FF"
        />
      </g>
      <mask
        height="16"
        id="mask206_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask206_1782_49644)">
        <path
          d="M15.0161 8.49029L13.6196 8.10821L13.6027 7.55707L14.9823 7.97296L15.0161 8.49029Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask207_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask207_1782_49644)">
        <path
          d="M13.6027 7.55707L12.1792 7.22232L12.186 6.70161L13.5791 7.06341L13.6027 7.55707Z"
          fill="#FFC800"
        />
      </g>
      <mask
        height="16"
        id="mask208_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask208_1782_49644)">
        <path
          d="M14.5427 12.6222L13.7481 12.6255L13.809 13.038L14.6272 13.011L14.5427 12.6222Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask209_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask209_1782_49644)">
        <path
          d="M16.4768 13.7752L15.8073 13.7346L15.9763 13.8191L16.6695 13.8665L16.4768 13.7752Z"
          fill="#0074FF"
        />
      </g>
      <mask
        height="16"
        id="mask210_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask210_1782_49644)">
        <path
          d="M17.809 12.9434L17.0009 12.7304L17.1293 12.3246L17.951 12.5816L17.809 12.9434Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask211_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask211_1782_49644)">
        <path
          d="M13.7042 12.1488L12.94 12.1792L12.9603 12.6864L13.7481 12.6255L13.7042 12.1488Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask212_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask212_1782_49644)">
        <path
          d="M11.2223 12.6391L10.9451 12.9096L10.8335 13.5317L11.148 13.2342L11.2223 12.6391Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask213_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask213_1782_49644)">
        <path
          d="M16.3145 9.45394L15.0296 9.04819L15.0127 8.49029L16.2908 8.93661L16.3145 9.45394Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask214_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask214_1782_49644)">
        <path
          d="M15.3779 13.0313L14.6272 13.011L14.7287 13.3288L15.503 13.3356L15.3779 13.0313Z"
          fill="#00A4FF"
        />
      </g>
      <mask
        height="16"
        id="mask215_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask215_1782_49644)">
        <path
          d="M17.3796 10.9045L16.2874 10.5427L16.3145 9.99493L17.41 10.4007L17.3796 10.9045Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask216_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask216_1782_49644)">
        <path
          d="M9.39986 16.7439L9.43705 17.2206L8.94677 17.3356L8.92648 16.8487L9.39986 16.7439Z"
          fill="#000096"
        />
      </g>
      <mask
        height="16"
        id="mask217_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask217_1782_49644)">
        <path
          d="M5.65344 10.9654L5.23417 11.5063L5.16992 10.6272L5.59596 10.0964L5.65344 10.9654Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask218_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask218_1782_49644)">
        <path
          d="M7.15471 7.73627L6.31955 8.02368L6.4548 7.28995L7.26291 7.02621L7.15471 7.73627Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask219_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask219_1782_49644)">
        <path
          d="M10.8166 6.44125L9.5351 6.29924L9.63653 5.80896L10.8707 5.97464L10.8166 6.44125Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask220_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask220_1782_49644)">
        <path
          d="M8.2739 6.88758L7.26291 7.02621L7.41845 6.39392L8.38886 6.27896L8.2739 6.88758Z"
          fill="#FF9C00"
        />
      </g>
      <mask
        height="16"
        id="mask221_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask221_1782_49644)">
        <path
          d="M12.186 6.70161L10.8199 6.44125L10.8707 5.97464L12.1927 6.25866L12.186 6.70161Z"
          fill="#FF8900"
        />
      </g>
      <mask
        height="16"
        id="mask222_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask222_1782_49644)">
        <path
          d="M16.3009 13.6129L15.645 13.5723L15.8073 13.7346L16.4768 13.7752L16.3009 13.6129Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask223_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask223_1782_49644)">
        <path
          d="M7.43195 16.8555L7.48943 17.3221L7.01267 16.9062L6.95181 16.4362L7.43195 16.8555Z"
          fill="#00009F"
        />
      </g>
      <mask
        height="16"
        id="mask224_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask224_1782_49644)">
        <path
          d="M17.951 12.5816L17.1327 12.3246L17.2409 11.8783L18.0727 12.1758L17.951 12.5816Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask225_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask225_1782_49644)">
        <path
          d="M6.95182 16.4362L7.01268 16.9062L6.56636 16.3753L6.50549 15.9053L6.95182 16.4362Z"
          fill="#0000C8"
        />
      </g>
      <mask
        height="16"
        id="mask226_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask226_1782_49644)">
        <path
          d="M10.4311 14.8166L10.4852 15.2291L10.1606 15.7058L10.1539 15.2764L10.4311 14.8166Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask227_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask227_1782_49644)">
        <path
          d="M5.58242 13.2206L5.46407 13.7583L5.24429 12.9129L5.37278 12.3787L5.58242 13.2206Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask228_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask228_1782_49644)">
        <path
          d="M7.9324 17.1462L7.98988 17.6162L7.48946 17.3221L7.43198 16.8555L7.9324 17.1462Z"
          fill="#000089"
        />
      </g>
      <mask
        height="16"
        id="mask229_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask229_1782_49644)">
        <path
          d="M10.678 13.8732L10.661 14.2485L10.4311 14.8166L10.4886 14.4243L10.678 13.8732Z"
          fill="#005CFF"
        />
      </g>
      <mask
        height="16"
        id="mask230_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask230_1782_49644)">
        <path
          d="M12.2468 11.6653L11.6856 11.787L11.6585 12.426L12.2435 12.2705L12.2468 11.6653Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask231_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask231_1782_49644)">
        <path
          d="M10.1606 15.7058L10.2519 16.1353L9.84278 16.5072L9.79544 16.0575L10.1606 15.7058Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask232_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask232_1782_49644)">
        <path
          d="M14.9823 7.97295L13.6027 7.55706L13.579 7.0634L14.9315 7.50972L14.9823 7.97295Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask233_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask233_1782_49644)">
        <path
          d="M12.9265 11.6112L12.2469 11.6653L12.2435 12.2705L12.94 12.1792L12.9265 11.6112Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask234_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask234_1782_49644)">
        <path
          d="M6.24853 8.81826L5.61285 9.2443L5.69738 8.42604L6.31953 8.02367L6.24853 8.81826Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask235_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask235_1782_49644)">
        <path
          d="M6.50552 15.9053L6.56638 16.3753L6.16401 15.743L6.10315 15.2697L6.50552 15.9053Z"
          fill="#0000FA"
        />
      </g>
      <mask
        height="16"
        id="mask236_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask236_1782_49644)">
        <path
          d="M14.4785 12.1758L13.7042 12.1488L13.7481 12.6255L14.5427 12.6222L14.4785 12.1758Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask237_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask237_1782_49644)">
        <path
          d="M9.53509 6.29924L8.38885 6.27895L8.53763 5.76839L9.63653 5.80896L9.53509 6.29924Z"
          fill="#FF6F00"
        />
      </g>
      <mask
        height="16"
        id="mask238_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask238_1782_49644)">
        <path
          d="M13.579 7.0634L12.186 6.70161L12.1927 6.25867L13.5486 6.64413L13.579 7.0634Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask239_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask239_1782_49644)">
        <path
          d="M11.6855 11.787L11.273 11.9662L11.2223 12.6391L11.6585 12.426L11.6855 11.787Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask240_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask240_1782_49644)">
        <path
          d="M16.1386 13.3863L15.503 13.3356L15.645 13.5723L16.2976 13.6129L16.1386 13.3863Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask241_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask241_1782_49644)">
        <path
          d="M10.9451 12.9096L10.8233 13.2274L10.6779 13.8732L10.8335 13.5317L10.9451 12.9096Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask242_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask242_1782_49644)">
        <path
          d="M15.273 12.6661L14.5427 12.6222L14.6272 13.011L15.3779 13.0313L15.273 12.6661Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask243_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask243_1782_49644)">
        <path
          d="M18.0727 12.1758L17.2409 11.8783L17.3221 11.4015L18.164 11.743L18.0727 12.1758Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask244_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask244_1782_49644)">
        <path
          d="M8.43956 17.3085L8.49704 17.7785L7.98985 17.6162L7.93237 17.1462L8.43956 17.3085Z"
          fill="#000080"
        />
      </g>
      <mask
        height="16"
        id="mask245_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask245_1782_49644)">
        <path
          d="M17.41 10.4007L16.3145 9.99493V9.45393L17.41 9.90364V10.4007Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask246_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask246_1782_49644)">
        <path
          d="M16.2908 8.93661L15.0161 8.49029L14.9823 7.97296L16.2435 8.4531L16.2908 8.93661Z"
          fill="#E1FF16"
        />
      </g>
      <mask
        height="16"
        id="mask247_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask247_1782_49644)">
        <path
          d="M13.6771 11.6179L12.9265 11.6112L12.94 12.1792L13.7042 12.1488L13.6771 11.6179Z"
          fill="#23FFD4"
        />
      </g>
      <mask
        height="16"
        id="mask248_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask248_1782_49644)">
        <path
          d="M6.10314 15.2697L6.16401 15.743L5.81236 15.0228L5.7515 14.5495L6.10314 15.2697Z"
          fill="#0024FF"
        />
      </g>
      <mask
        height="16"
        id="mask249_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask249_1782_49644)">
        <path
          d="M17.5655 13.9442L17.0685 13.8293L17.268 13.7041L17.7752 13.8428L17.5655 13.9442Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask250_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask250_1782_49644)">
        <path
          d="M9.84278 16.5072L9.90026 16.9738L9.43703 17.2206L9.39984 16.7439L9.84278 16.5072Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask251_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask251_1782_49644)">
        <path
          d="M17.3525 13.978L16.8656 13.8834L17.0685 13.8293L17.5655 13.9442L17.3525 13.978Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask252_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask252_1782_49644)">
        <path
          d="M17.7752 13.8428L17.268 13.7041L17.4607 13.5114L17.9814 13.6771L17.7752 13.8428Z"
          fill="#0090FF"
        />
      </g>
      <mask
        height="16"
        id="mask253_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask253_1782_49644)">
        <path
          d="M17.1429 13.951L16.6695 13.8664L16.8656 13.8834L17.3525 13.978L17.1429 13.951Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask254_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask254_1782_49644)">
        <path
          d="M17.9814 13.6771L17.4607 13.5114L17.6433 13.2544L18.1708 13.4539L17.9814 13.6771Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask255_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask255_1782_49644)">
        <path
          d="M11.273 11.9662L11.0195 12.2063L10.9451 12.9096L11.2223 12.6391L11.273 11.9662Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask256_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask256_1782_49644)">
        <path
          d="M5.37279 12.3787L5.2443 12.9129L5.09891 12.0406L5.23416 11.5063L5.37279 12.3787Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask257_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask257_1782_49644)">
        <path
          d="M16 13.0989L15.3779 13.0313L15.503 13.3356L16.1386 13.3863L16 13.0989Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask258_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask258_1782_49644)">
        <path
          d="M12.1927 6.25867L10.8707 5.97465L10.9315 5.60609L12.2029 5.90702L12.1927 6.25867Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask259_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask259_1782_49644)">
        <path
          d="M18.164 11.743L17.3221 11.4015L17.3796 10.9045L18.2283 11.2899L18.164 11.743Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask260_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask260_1782_49644)">
        <path
          d="M5.59596 10.0964L5.16992 10.6272L5.18683 9.75825L5.61286 9.2443L5.59596 10.0964Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask261_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask261_1782_49644)">
        <path
          d="M8.94677 17.3356L9.00425 17.8056L8.49706 17.7785L8.44296 17.3085L8.94677 17.3356Z"
          fill="#000084"
        />
      </g>
      <mask
        height="16"
        id="mask262_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask262_1782_49644)">
        <path
          d="M10.8707 5.97464L9.63654 5.80896L9.7515 5.42012L10.9315 5.60609L10.8707 5.97464Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask263_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask263_1782_49644)">
        <path
          d="M14.9315 7.50973L13.579 7.06341L13.5486 6.64413L14.8673 7.11413L14.9315 7.50973Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask264_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask264_1782_49644)">
        <path
          d="M16.94 13.8529L16.4768 13.7752L16.6695 13.8664L17.1429 13.951L16.94 13.8529Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask265_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask265_1782_49644)">
        <path
          d="M14.4379 11.6822L13.6771 11.6179L13.7041 12.1488L14.4784 12.1758L14.4379 11.6822Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask266_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask266_1782_49644)">
        <path
          d="M18.1708 13.4539L17.6433 13.2544L17.809 12.9434L18.3466 13.1767L18.1708 13.4539Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask267_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask267_1782_49644)">
        <path
          d="M7.26289 7.02621L6.45477 7.28995L6.64412 6.63399L7.41842 6.39392L7.26289 7.02621Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask268_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask268_1782_49644)">
        <path
          d="M8.38884 6.27895L7.41843 6.39392L7.61454 5.8563L8.53761 5.76839L8.38884 6.27895Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask269_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask269_1782_49644)">
        <path
          d="M15.1987 12.2502L14.4785 12.1758L14.5427 12.6221L15.273 12.6661L15.1987 12.2502Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask270_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask270_1782_49644)">
        <path
          d="M12.9231 10.9958L12.2536 11.0127L12.2468 11.6653L12.9265 11.6112L12.9231 10.9958Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask271_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask271_1782_49644)">
        <path
          d="M12.2536 11.0127L11.7025 11.0905L11.6855 11.787L12.2468 11.6653L12.2536 11.0127Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask272_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask272_1782_49644)">
        <path
          d="M17.41 9.90364L16.3145 9.45394L16.2908 8.93661L17.3796 9.42351L17.41 9.90364Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask273_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask273_1782_49644)">
        <path
          d="M10.661 14.2485L10.7557 14.6408L10.4852 15.2291L10.4311 14.8166L10.661 14.2485Z"
          fill="#0058FF"
        />
      </g>
      <mask
        height="16"
        id="mask274_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask274_1782_49644)">
        <path
          d="M13.5486 6.64414L12.1927 6.25868L12.2029 5.90703L13.5114 6.31278L13.5486 6.64414Z"
          fill="#FF6F00"
        />
      </g>
      <mask
        height="16"
        id="mask275_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask275_1782_49644)">
        <path
          d="M5.75149 14.5495L5.81235 15.0228L5.52494 14.235L5.46408 13.7549L5.75149 14.5495Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask276_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask276_1782_49644)">
        <path
          d="M16.2435 8.45308L14.9823 7.97295L14.9315 7.50972L16.1725 8.02029L16.2435 8.45308Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask277_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask277_1782_49644)">
        <path
          d="M10.4852 15.2291L10.6103 15.6416L10.2519 16.1353L10.1606 15.7058L10.4852 15.2291Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask278_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask278_1782_49644)">
        <path
          d="M10.8233 13.2274L10.8436 13.579L10.661 14.2485L10.6779 13.8732L10.8233 13.2274Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask279_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask279_1782_49644)">
        <path
          d="M16.754 13.694L16.2975 13.6162L16.4768 13.7752L16.94 13.8529L16.754 13.694Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask280_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask280_1782_49644)">
        <path
          d="M13.667 11.0465L12.9231 10.9992L12.9265 11.6112L13.6771 11.6179L13.667 11.0465Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask281_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask281_1782_49644)">
        <path
          d="M18.3466 13.1767L17.809 12.9434L17.951 12.5816L18.4954 12.8521L18.3466 13.1767Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask282_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask282_1782_49644)">
        <path
          d="M9.63653 5.80896L8.53763 5.76839L8.71683 5.36264L9.75149 5.42012L9.63653 5.80896Z"
          fill="#FF3F00"
        />
      </g>
      <mask
        height="16"
        id="mask283_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask283_1782_49644)">
        <path
          d="M6.31953 8.02368L5.69739 8.42604L5.85292 7.67203L6.45478 7.28995L6.31953 8.02368Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask284_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask284_1782_49644)">
        <path
          d="M11.7025 11.0905L11.3001 11.2358L11.273 11.9662L11.6856 11.787L11.7025 11.0905Z"
          fill="#49FFAD"
        />
      </g>
      <mask
        height="16"
        id="mask285_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask285_1782_49644)">
        <path
          d="M15.885 12.7574L15.273 12.6661L15.3779 13.0313L16 13.0989L15.885 12.7574Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask286_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask286_1782_49644)">
        <path
          d="M18.2282 11.2899L17.3795 10.9045L17.41 10.4007L18.2587 10.8301L18.2282 11.2899Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask287_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask287_1782_49644)">
        <path
          d="M11.0194 12.2063L10.9248 12.497L10.8233 13.2274L10.9451 12.9096L11.0194 12.2063Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask288_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask288_1782_49644)">
        <path
          d="M9.43703 17.224L9.49113 17.694L9.00423 17.8056L8.94675 17.3356L9.43703 17.224Z"
          fill="#00009B"
        />
      </g>
      <mask
        height="16"
        id="mask289_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask289_1782_49644)">
        <path
          d="M10.2519 16.1353L10.3263 16.5951L9.90025 16.9738L9.84277 16.5072L10.2519 16.1353Z"
          fill="#0000E8"
        />
      </g>
      <mask
        height="16"
        id="mask290_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask290_1782_49644)">
        <path
          d="M16.5816 13.4742L16.1386 13.3863L16.3009 13.6129L16.754 13.694L16.5816 13.4742Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask291_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask291_1782_49644)">
        <path
          d="M18.4954 12.8521L17.951 12.5816L18.0727 12.1758L18.6239 12.4903L18.4954 12.8521Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask292_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask292_1782_49644)">
        <path
          d="M15.1479 11.7937L14.4379 11.6822L14.4784 12.1758L15.1986 12.2502L15.1479 11.7937Z"
          fill="#33FFC4"
        />
      </g>
      <mask
        height="16"
        id="mask293_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask293_1782_49644)">
        <path
          d="M14.421 11.1513L13.6636 11.0465L13.6771 11.6179L14.4379 11.6822L14.421 11.1513Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask294_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask294_1782_49644)">
        <path
          d="M12.2029 5.90702L10.9315 5.60609L10.9992 5.34573L12.2097 5.66019L12.2029 5.90702Z"
          fill="#FF3B00"
        />
      </g>
      <mask
        height="16"
        id="mask295_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask295_1782_49644)">
        <path
          d="M7.48945 17.3221L7.58074 17.7447L7.10737 17.3322L7.0127 16.9062L7.48945 17.3221Z"
          fill="#0000A8"
        />
      </g>
      <mask
        height="16"
        id="mask296_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask296_1782_49644)">
        <path
          d="M17.3796 9.42351L16.2908 8.93661L16.2435 8.45309L17.3221 8.97719L17.3796 9.42351Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask297_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask297_1782_49644)">
        <path
          d="M14.8673 7.11413L13.5486 6.64414L13.5114 6.31277L14.7895 6.79967L14.8673 7.11413Z"
          fill="#FF8600"
        />
      </g>
      <mask
        height="16"
        id="mask298_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask298_1782_49644)">
        <path
          d="M7.0127 16.9062L7.10737 17.3322L6.66443 16.8047L6.56638 16.3753L7.0127 16.9062Z"
          fill="#0000CD"
        />
      </g>
      <mask
        height="16"
        id="mask299_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask299_1782_49644)">
        <path
          d="M5.23416 11.5063L5.09891 12.0406L5.03467 11.1547L5.16992 10.6272L5.23416 11.5063Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask300_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask300_1782_49644)">
        <path
          d="M11.3001 11.2358L11.06 11.4421L11.0194 12.2063L11.273 11.9662L11.3001 11.2358Z"
          fill="#46FFB1"
        />
      </g>
      <mask
        height="16"
        id="mask301_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask301_1782_49644)">
        <path
          d="M10.9315 5.60609L9.7515 5.42013L9.88674 5.14963L10.9992 5.34574L10.9315 5.60609Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask302_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask302_1782_49644)">
        <path
          d="M5.46407 13.7549L5.52494 14.235L5.30516 13.3931L5.24429 12.9129L5.46407 13.7549Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask303_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask303_1782_49644)">
        <path
          d="M12.9298 10.3533L12.257 10.3229L12.2536 11.0127L12.9231 10.9992L12.9298 10.3533Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask304_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask304_1782_49644)">
        <path
          d="M7.98986 17.6162L8.07439 18.0355L7.58073 17.7447L7.48944 17.3221L7.98986 17.6162Z"
          fill="#000092"
        />
      </g>
      <mask
        height="16"
        id="mask305_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask305_1782_49644)">
        <path
          d="M16.1725 8.02029L14.9316 7.50972L14.8673 7.11412L16.0812 7.64836L16.1725 8.02029Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask306_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask306_1782_49644)">
        <path
          d="M12.257 10.3229L11.7025 10.3601V11.0905L12.2536 11.0127L12.257 10.3229Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask307_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask307_1782_49644)">
        <path
          d="M15.8005 12.3686L15.1987 12.2502L15.2731 12.6695L15.8851 12.7574L15.8005 12.3686Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask308_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask308_1782_49644)">
        <path
          d="M13.5114 6.31277L12.2029 5.90702L12.2096 5.66019L13.4641 6.0727L13.5114 6.31277Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask309_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask309_1782_49644)">
        <path
          d="M18.2587 10.8301L17.41 10.4007V9.90364L18.2587 10.3736V10.8301Z"
          fill="#8AFF6D"
        />
      </g>
      <mask
        height="16"
        id="mask310_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask310_1782_49644)">
        <path
          d="M5.61286 9.2443L5.18683 9.75825L5.28488 8.92984L5.6974 8.42604L5.61286 9.2443Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask311_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask311_1782_49644)">
        <path
          d="M6.56637 16.3753L6.66442 16.8047L6.26544 16.1792L6.164 15.743L6.56637 16.3753Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask312_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask312_1782_49644)">
        <path
          d="M16.4362 13.2003L16 13.0989L16.1386 13.3863L16.5816 13.4742L16.4362 13.2003Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask313_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask313_1782_49644)">
        <path
          d="M13.6737 10.448L12.9299 10.3533L12.9231 10.9958L13.6636 11.0465L13.6737 10.448Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask314_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask314_1782_49644)">
        <path
          d="M18.6238 12.4903L18.0727 12.1758L18.164 11.743L18.7219 12.1014L18.6238 12.4903Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask315_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask315_1782_49644)">
        <path
          d="M8.53764 5.76839L7.61456 5.8563L7.85125 5.43365L8.71346 5.36264L8.53764 5.76839Z"
          fill="#FF3000"
        />
      </g>
      <mask
        height="16"
        id="mask316_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask316_1782_49644)">
        <path
          d="M7.41844 6.39392L6.64413 6.63399L6.88758 6.07609L7.61455 5.85631L7.41844 6.39392Z"
          fill="#FF5900"
        />
      </g>
      <mask
        height="16"
        id="mask317_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask317_1782_49644)">
        <path
          d="M17.5757 14.1031L17.3525 13.978L17.5655 13.9442L17.7921 14.0828L17.5757 14.1031Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask318_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask318_1782_49644)">
        <path
          d="M17.7921 14.0828L17.5655 13.9442L17.7752 13.8428L18.0051 14.0017L17.7921 14.0828Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask319_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask319_1782_49644)">
        <path
          d="M8.49705 17.7785L8.5782 18.1944L8.0744 18.0355L7.98987 17.6162L8.49705 17.7785Z"
          fill="#000084"
        />
      </g>
      <mask
        height="16"
        id="mask320_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask320_1782_49644)">
        <path
          d="M17.3593 14.0626L17.1429 13.951L17.3525 13.978L17.5757 14.1031L17.3593 14.0626Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask321_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask321_1782_49644)">
        <path
          d="M11.7025 10.3601L11.3001 10.4649V11.2358L11.7025 11.0905V10.3601Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask322_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask322_1782_49644)">
        <path
          d="M18.0051 14.0017L17.7751 13.8428L17.9814 13.6771L18.2113 13.8631L18.0051 14.0017Z"
          fill="#00A4FF"
        />
      </g>
      <mask
        height="16"
        id="mask323_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask323_1782_49644)">
        <path
          d="M10.8436 13.579L10.9654 13.951L10.7557 14.6407L10.661 14.2485L10.8436 13.579Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask324_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask324_1782_49644)">
        <path
          d="M9.90027 16.9738L9.95437 17.4472L9.49452 17.694L9.43704 17.224L9.90027 16.9738Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask325_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask325_1782_49644)">
        <path
          d="M10.7557 14.6407L10.9113 15.0364L10.6103 15.6416L10.4852 15.2291L10.7557 14.6407Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask326_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask326_1782_49644)">
        <path
          d="M9.75148 5.42013L8.71344 5.36265L8.91631 5.07524L9.88673 5.14963L9.75148 5.42013Z"
          fill="#FF1600"
        />
      </g>
      <mask
        height="16"
        id="mask327_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask327_1782_49644)">
        <path
          d="M15.1276 11.3035L14.421 11.1513L14.4379 11.6822L15.1479 11.7937L15.1276 11.3035Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask328_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask328_1782_49644)">
        <path
          d="M17.1564 13.9611L16.94 13.8529L17.1429 13.951L17.3593 14.0626L17.1564 13.9611Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask329_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask329_1782_49644)">
        <path
          d="M6.164 15.743L6.26543 16.1792L5.92055 15.4692L5.81235 15.0228L6.164 15.743Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask330_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask330_1782_49644)">
        <path
          d="M10.9248 12.497L10.9687 12.825L10.8436 13.579L10.8233 13.2274L10.9248 12.497Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask331_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask331_1782_49644)">
        <path
          d="M17.3221 8.97718L16.2435 8.45309L16.1725 8.02029L17.2342 8.57144L17.3221 8.97718Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask332_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask332_1782_49644)">
        <path
          d="M14.4311 10.6002L13.6737 10.448L13.6636 11.0465L14.421 11.1513L14.4311 10.6002Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask333_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask333_1782_49644)">
        <path
          d="M18.2113 13.8631L17.9814 13.6771L18.1708 13.4539L18.4075 13.667L18.2113 13.8631Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask334_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask334_1782_49644)">
        <path
          d="M16.3145 12.8825L15.885 12.7574L16 13.0989L16.4362 13.2003L16.3145 12.8825Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask335_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask335_1782_49644)">
        <path
          d="M14.7895 6.79967L13.5114 6.31278L13.4641 6.07271L14.6982 6.56975L14.7895 6.79967Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask336_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask336_1782_49644)">
        <path
          d="M6.4548 7.28995L5.85294 7.67203L6.07272 6.9924L6.64414 6.63399L6.4548 7.28995Z"
          fill="#FF9100"
        />
      </g>
      <mask
        height="16"
        id="mask337_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask337_1782_49644)">
        <path
          d="M18.7185 12.1014L18.164 11.743L18.2282 11.2899L18.7861 11.6889L18.7185 12.1014Z"
          fill="#46FFB1"
        />
      </g>
      <mask
        height="16"
        id="mask338_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask338_1782_49644)">
        <path
          d="M15.743 11.9459L15.1479 11.7937L15.1987 12.2502L15.8005 12.3686L15.743 11.9459Z"
          fill="#39FFBE"
        />
      </g>
      <mask
        height="16"
        id="mask339_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask339_1782_49644)">
        <path
          d="M12.2097 5.66019L10.9992 5.34573L11.0736 5.20372L12.2198 5.52156L12.2097 5.66019Z"
          fill="#FF2200"
        />
      </g>
      <mask
        height="16"
        id="mask340_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask340_1782_49644)">
        <path
          d="M18.2587 10.3736L17.41 9.90026L17.3795 9.42351L18.2249 9.93069L18.2587 10.3736Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask341_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask341_1782_49644)">
        <path
          d="M11.06 11.4421L10.9755 11.7025L10.9248 12.497L11.0194 12.2063L11.06 11.4421Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask342_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask342_1782_49644)">
        <path
          d="M16.9637 13.8056L16.754 13.694L16.94 13.8529L17.1564 13.9611L16.9637 13.8056Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask343_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask343_1782_49644)">
        <path
          d="M16.0812 7.64836L14.8673 7.11412L14.7895 6.79967L15.973 7.34743L16.0812 7.64836Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask344_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask344_1782_49644)">
        <path
          d="M10.6103 15.6416L10.6982 16.0913L10.3263 16.5951L10.2519 16.1353L10.6103 15.6416Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask345_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask345_1782_49644)">
        <path
          d="M12.9434 9.69738L12.2604 9.61961L12.257 10.3229L12.9299 10.3533L12.9434 9.69738Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask346_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask346_1782_49644)">
        <path
          d="M18.4075 13.667L18.1708 13.4539L18.3466 13.1767L18.5833 13.4235L18.4075 13.667Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask347_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask347_1782_49644)">
        <path
          d="M9.00423 17.8056L9.07861 18.2215L8.57819 18.1944L8.49704 17.7785L9.00423 17.8056Z"
          fill="#00008D"
        />
      </g>
      <mask
        height="16"
        id="mask348_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask348_1782_49644)">
        <path
          d="M13.4641 6.0727L12.2097 5.66019L12.2198 5.52156L13.4134 5.93407L13.4641 6.0727Z"
          fill="#FF3800"
        />
      </g>
      <mask
        height="16"
        id="mask349_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask349_1782_49644)">
        <path
          d="M5.2443 12.9129L5.30516 13.3931L5.16315 12.5241L5.09891 12.0406L5.2443 12.9129Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask350_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask350_1782_49644)">
        <path
          d="M13.6974 9.84278L12.9434 9.69739L12.9298 10.3533L13.6737 10.448L13.6974 9.84278Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask351_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask351_1782_49644)">
        <path
          d="M12.2604 9.61962L11.6923 9.60947L11.7025 10.3601L12.257 10.3229L12.2604 9.61962Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask352_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask352_1782_49644)">
        <path
          d="M10.9992 5.34574L9.88675 5.14963L10.0321 5.00085L11.0736 5.20373L10.9992 5.34574Z"
          fill="#F60B00"
        />
      </g>
      <mask
        height="16"
        id="mask353_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask353_1782_49644)">
        <path
          d="M11.3001 10.4649L11.06 10.634V11.4421L11.3001 11.2358V10.4649Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask354_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask354_1782_49644)">
        <path
          d="M5.16992 10.6272L5.03467 11.1547L5.05157 10.2823L5.18682 9.76163L5.16992 10.6272Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask355_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask355_1782_49644)">
        <path
          d="M16.7912 13.5926L16.5816 13.4742L16.754 13.694L16.9637 13.8056L16.7912 13.5926Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask356_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask356_1782_49644)">
        <path
          d="M5.81234 15.0228L5.92054 15.4692L5.63651 14.6881L5.52493 14.235L5.81234 15.0228Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask357_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask357_1782_49644)">
        <path
          d="M16.2232 12.5207L15.8005 12.3686L15.885 12.7574L16.3145 12.8825L16.2232 12.5207Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask358_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask358_1782_49644)">
        <path
          d="M18.5833 13.4235L18.3432 13.1767L18.4954 12.8521L18.7354 13.1361L18.5833 13.4235Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask359_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask359_1782_49644)">
        <path
          d="M15.1378 10.7997L14.4311 10.6002L14.421 11.1513L15.1276 11.3035L15.1378 10.7997Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask360_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask360_1782_49644)">
        <path
          d="M18.7861 11.6889L18.2282 11.2899L18.2587 10.8301L18.8166 11.2663L18.7861 11.6889Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask361_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask361_1782_49644)">
        <path
          d="M17.2341 8.57144L16.1724 8.0203L16.0811 7.64836L17.1226 8.22317L17.2341 8.57144Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask362_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask362_1782_49644)">
        <path
          d="M14.4649 10.0423L13.6974 9.84278L13.6737 10.448L14.4311 10.6002L14.4649 10.0423Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask363_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask363_1782_49644)">
        <path
          d="M11.6923 9.60947L11.2764 9.67372L11.3001 10.4649L11.7025 10.3601L11.6923 9.60947Z"
          fill="#BAFF3C"
        />
      </g>
      <mask
        height="16"
        id="mask364_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask364_1782_49644)">
        <path
          d="M15.7194 11.4962L15.1277 11.3035L15.1479 11.7938L15.743 11.9459L15.7194 11.4962Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask365_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask365_1782_49644)">
        <path
          d="M14.6982 6.56974L13.4641 6.0727L13.4134 5.93407L14.6002 6.43111L14.6982 6.56974Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask366_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask366_1782_49644)">
        <path
          d="M5.69739 8.42604L5.28488 8.92985L5.45732 8.15554L5.85293 7.67203L5.69739 8.42604Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask367_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask367_1782_49644)">
        <path
          d="M18.2249 9.93069L17.3796 9.4235L17.3221 8.97718L18.1572 9.51818L18.2249 9.93069Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask368_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask368_1782_49644)">
        <path
          d="M8.71682 5.36265L7.85123 5.43365L8.11834 5.13272L8.91632 5.07524L8.71682 5.36265Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask369_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask369_1782_49644)">
        <path
          d="M16.6424 13.3322L16.4362 13.2003L16.5816 13.4742L16.7912 13.5926L16.6424 13.3322Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask370_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask370_1782_49644)">
        <path
          d="M10.3263 16.5951L10.377 17.0685L9.95437 17.4472L9.90027 16.9738L10.3263 16.5951Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask371_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask371_1782_49644)">
        <path
          d="M15.9696 7.34743L14.7895 6.79967L14.6982 6.56975L15.8445 7.12765L15.9696 7.34743Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask372_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask372_1782_49644)">
        <path
          d="M9.88673 5.14963L8.91632 5.07525L9.1361 4.91971L10.0321 5.00086L9.88673 5.14963Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask373_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask373_1782_49644)">
        <path
          d="M12.9671 9.04481L12.2604 8.91632V9.61962L12.9434 9.69739L12.9671 9.04481Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask374_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask374_1782_49644)">
        <path
          d="M12.2198 5.52156L11.0735 5.20373L11.1513 5.18344L12.2265 5.49451L12.2198 5.52156Z"
          fill="#FA0F00"
        />
      </g>
      <mask
        height="16"
        id="mask375_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask375_1782_49644)">
        <path
          d="M7.61454 5.85631L6.88757 6.07609L7.17498 5.63315L7.85123 5.43365L7.61454 5.85631Z"
          fill="#FF2500"
        />
      </g>
      <mask
        height="16"
        id="mask376_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask376_1782_49644)">
        <path
          d="M18.7354 13.1361L18.4954 12.8521L18.6238 12.4903L18.8639 12.8081L18.7354 13.1361Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask377_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask377_1782_49644)">
        <path
          d="M9.49452 17.694L9.56215 18.1133L9.07863 18.2215L9.00424 17.8056L9.49452 17.694Z"
          fill="#00009F"
        />
      </g>
      <mask
        height="16"
        id="mask378_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask378_1782_49644)">
        <path
          d="M13.4134 5.93407L12.2198 5.52156L12.2265 5.49451L13.3593 5.90364L13.4134 5.93407Z"
          fill="#FF2500"
        />
      </g>
      <mask
        height="16"
        id="mask379_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask379_1782_49644)">
        <path
          d="M13.7413 9.24092L12.967 9.04481L12.9434 9.69739L13.6974 9.84278L13.7413 9.24092Z"
          fill="#DBFF1C"
        />
      </g>
      <mask
        height="16"
        id="mask380_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask380_1782_49644)">
        <path
          d="M12.2604 8.91632L11.6686 8.86222L11.6923 9.60947L12.2604 9.61962V8.91632Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask381_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask381_1782_49644)">
        <path
          d="M10.9687 12.825L11.1107 13.1767L10.9654 13.951L10.8436 13.579L10.9687 12.825Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask382_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask382_1782_49644)">
        <path
          d="M16.1657 12.1285L15.743 11.9459L15.8005 12.3686L16.2232 12.5207L16.1657 12.1285Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask383_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask383_1782_49644)">
        <path
          d="M10.9653 13.951L11.1479 14.3297L10.9112 15.0364L10.7557 14.6408L10.9653 13.951Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask384_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask384_1782_49644)">
        <path
          d="M10.9755 11.7025L11.0296 12.0068L10.9687 12.825L10.9248 12.497L10.9755 11.7025Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask385_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask385_1782_49644)">
        <path
          d="M11.0736 5.20372L10.0321 5.00085L10.1877 4.98056L11.1513 5.18343L11.0736 5.20372Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask386_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask386_1782_49644)">
        <path
          d="M7.58073 17.7447L7.66865 18.0964L7.20542 17.6906L7.10736 17.3322L7.58073 17.7447Z"
          fill="#0000B2"
        />
      </g>
      <mask
        height="16"
        id="mask387_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask387_1782_49644)">
        <path
          d="M18.8166 11.2663L18.2587 10.8301V10.3736L18.8166 10.8504V11.2663Z"
          fill="#8AFF6D"
        />
      </g>
      <mask
        height="16"
        id="mask388_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask388_1782_49644)">
        <path
          d="M7.10737 17.3322L7.20204 17.6906L6.77263 17.1767L6.66443 16.8047L7.10737 17.3322Z"
          fill="#0000DA"
        />
      </g>
      <mask
        height="16"
        id="mask389_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask389_1782_49644)">
        <path
          d="M15.175 10.2891L14.4616 10.0423L14.4311 10.6002L15.1378 10.7997L15.175 10.2891Z"
          fill="#A7FF50"
        />
      </g>
      <mask
        height="16"
        id="mask390_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask390_1782_49644)">
        <path
          d="M11.06 10.634L10.9755 10.8639V11.7025L11.06 11.4421V10.634Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask391_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask391_1782_49644)">
        <path
          d="M16.5173 13.0313L16.3145 12.8825L16.4362 13.2003L16.6424 13.3322L16.5173 13.0313Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask392_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask392_1782_49644)">
        <path
          d="M17.5114 14.2485L17.5723 14.1031L17.7921 14.0828L17.7278 14.2418L17.5114 14.2485Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask393_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask393_1782_49644)">
        <path
          d="M17.1226 8.22317L16.0812 7.64836L15.9696 7.34743L16.9907 7.93576L17.1226 8.22317Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask394_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask394_1782_49644)">
        <path
          d="M5.52496 14.235L5.63654 14.6881L5.42014 13.8597L5.30518 13.3931L5.52496 14.235Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask395_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask395_1782_49644)">
        <path
          d="M14.519 9.49113L13.7413 9.24092L13.6974 9.84278L14.4615 10.0423L14.519 9.49113Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask396_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask396_1782_49644)">
        <path
          d="M17.3018 14.1978L17.3593 14.0626L17.5723 14.1031L17.5114 14.2485L17.3018 14.1978Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask397_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask397_1782_49644)">
        <path
          d="M6.64412 6.63398L6.07269 6.99239L6.35672 6.4142L6.88757 6.07608L6.64412 6.63398Z"
          fill="#FF5500"
        />
      </g>
      <mask
        height="16"
        id="mask398_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask398_1782_49644)">
        <path
          d="M5.09891 12.0406L5.16315 12.5241L5.09891 11.6416L5.03467 11.1547L5.09891 12.0406Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask399_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask399_1782_49644)">
        <path
          d="M8.07441 18.0355L8.15218 18.3804L7.66866 18.0964L7.58075 17.7447L8.07441 18.0355Z"
          fill="#00009B"
        />
      </g>
      <mask
        height="16"
        id="mask400_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask400_1782_49644)">
        <path
          d="M14.6002 6.43111L13.4134 5.93407L13.3593 5.90364L14.492 6.39392L14.6002 6.43111Z"
          fill="#FF3F00"
        />
      </g>
      <mask
        height="16"
        id="mask401_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask401_1782_49644)">
        <path
          d="M17.7278 14.2418L17.7921 14.0828L18.0051 14.0017L17.9375 14.1775L17.7278 14.2418Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask402_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask402_1782_49644)">
        <path
          d="M11.2764 9.67371L11.0194 9.80896L11.06 10.634L11.3001 10.4649L11.2764 9.67371Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask403_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask403_1782_49644)">
        <path
          d="M10.9113 15.0364L11.0127 15.4759L10.6982 16.0913L10.6103 15.6416L10.9113 15.0364Z"
          fill="#0050FF"
        />
      </g>
      <mask
        height="16"
        id="mask404_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask404_1782_49644)">
        <path
          d="M15.7295 11.0364L15.1378 10.7997L15.1277 11.3035L15.7194 11.4962L15.7295 11.0364Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask405_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask405_1782_49644)">
        <path
          d="M18.8639 12.8081L18.6238 12.4903L18.7185 12.1014L18.962 12.4565L18.8639 12.8081Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask406_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask406_1782_49644)">
        <path
          d="M18.1572 9.51818L17.3221 8.97718L17.2342 8.57143L18.0626 9.14286L18.1572 9.51818Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask407_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask407_1782_49644)">
        <path
          d="M17.0989 14.093L17.1564 13.9611L17.3593 14.0626L17.3018 14.1978L17.0989 14.093Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask408_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask408_1782_49644)">
        <path
          d="M6.66443 16.8047L6.76925 17.1767L6.3804 16.5613L6.26544 16.1792L6.66443 16.8047Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask409_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask409_1782_49644)">
        <path
          d="M15.8445 7.12764L14.6982 6.56974L14.6002 6.43111L15.7059 6.98901L15.8445 7.12764Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask410_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask410_1782_49644)">
        <path
          d="M17.9408 14.1775L18.0051 14.0017L18.2113 13.8631L18.1437 14.0592L17.9408 14.1775Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask411_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask411_1782_49644)">
        <path
          d="M12.9975 8.4159L12.2604 8.24007V8.91632L12.9671 9.04481L12.9975 8.4159Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask412_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask412_1782_49644)">
        <path
          d="M11.6686 8.86222L11.2257 8.88589L11.273 9.67372L11.6923 9.60947L11.6686 8.86222Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask413_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask413_1782_49644)">
        <path
          d="M12.2265 5.49451L11.1513 5.18344L11.2325 5.28488L12.2333 5.58581L12.2265 5.49451Z"
          fill="#ED0400"
        />
      </g>
      <mask
        height="16"
        id="mask414_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask414_1782_49644)">
        <path
          d="M13.3593 5.90364L12.2266 5.49451L12.2333 5.5858L13.3018 5.9814L13.3593 5.90364Z"
          fill="#FF1E00"
        />
      </g>
      <mask
        height="16"
        id="mask415_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask415_1782_49644)">
        <path
          d="M13.7988 8.66273L12.9975 8.4159L12.967 9.04481L13.7413 9.24092L13.7988 8.66273Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask416_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask416_1782_49644)">
        <path
          d="M16.9096 13.9375L16.9637 13.8056L17.1564 13.9611L17.0989 14.0964L16.9096 13.9375Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask417_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask417_1782_49644)">
        <path
          d="M5.18682 9.75825L5.05157 10.2823L5.15301 9.44718L5.28488 8.92985L5.18682 9.75825Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask418_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask418_1782_49644)">
        <path
          d="M8.57821 18.1944L8.64583 18.5393L8.15217 18.3804L8.0744 18.0355L8.57821 18.1944Z"
          fill="#000092"
        />
      </g>
      <mask
        height="16"
        id="mask419_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask419_1782_49644)">
        <path
          d="M12.2604 8.24007L11.6348 8.13863L11.6686 8.86222L12.2604 8.91632V8.24007Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask420_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask420_1782_49644)">
        <path
          d="M18.1437 14.0592L18.2113 13.8631L18.4041 13.667L18.3331 13.8901L18.1437 14.0592Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask421_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask421_1782_49644)">
        <path
          d="M16.1386 11.716L15.7194 11.4962L15.743 11.9459L16.1657 12.1285L16.1386 11.716Z"
          fill="#5DFF9A"
        />
      </g>
      <mask
        height="16"
        id="mask422_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask422_1782_49644)">
        <path
          d="M16.426 12.6965L16.2232 12.5207L16.3145 12.8825L16.5207 13.0313L16.426 12.6965Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask423_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask423_1782_49644)">
        <path
          d="M18.8166 10.8504L18.2587 10.3736L18.2249 9.93069L18.7794 10.4413L18.8166 10.8504Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask424_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask424_1782_49644)">
        <path
          d="M10.0321 5.00085L9.13611 4.9197L9.36941 4.89941L10.1877 4.98056L10.0321 5.00085Z"
          fill="#C80000"
        />
      </g>
      <mask
        height="16"
        id="mask425_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask425_1782_49644)">
        <path
          d="M9.95438 17.4472L10.0186 17.8698L9.56216 18.1133L9.49115 17.694L9.95438 17.4472Z"
          fill="#0000C4"
        />
      </g>
      <mask
        height="16"
        id="mask426_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask426_1782_49644)">
        <path
          d="M16.7371 13.7346L16.7912 13.5926L16.9637 13.8056L16.9096 13.9375L16.7371 13.7346Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask427_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask427_1782_49644)">
        <path
          d="M8.91632 5.07525L8.11835 5.13273L8.40913 4.96705L9.1361 4.91971L8.91632 5.07525Z"
          fill="#CD0000"
        />
      </g>
      <mask
        height="16"
        id="mask428_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask428_1782_49644)">
        <path
          d="M15.246 9.78868L14.519 9.49113L14.4615 10.0423L15.175 10.2891L15.246 9.78868Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask429_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask429_1782_49644)">
        <path
          d="M14.492 6.39392L13.3593 5.90364L13.3052 5.98141L14.3804 6.45478L14.492 6.39392Z"
          fill="#FF3800"
        />
      </g>
      <mask
        height="16"
        id="mask430_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask430_1782_49644)">
        <path
          d="M14.6002 8.96366L13.7988 8.66273L13.7413 9.24092L14.519 9.49113L14.6002 8.96366Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask431_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask431_1782_49644)">
        <path
          d="M18.962 12.4565L18.7219 12.1014L18.7861 11.6889L19.0262 12.0812L18.962 12.4565Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask432_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask432_1782_49644)">
        <path
          d="M6.26545 16.1792L6.38041 16.5613L6.04229 15.8648L5.92056 15.4692L6.26545 16.1792Z"
          fill="#0030FF"
        />
      </g>
      <mask
        height="16"
        id="mask433_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask433_1782_49644)">
        <path
          d="M11.1513 5.18343L10.1877 4.98056L10.3466 5.09214L11.2325 5.28487L11.1513 5.18343Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask434_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask434_1782_49644)">
        <path
          d="M16.9907 7.93577L15.973 7.34744L15.8445 7.12766L16.8352 7.72275L16.9907 7.93577Z"
          fill="#FF9100"
        />
      </g>
      <mask
        height="16"
        id="mask435_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask435_1782_49644)">
        <path
          d="M10.6982 16.0913L10.7523 16.5681L10.3804 17.0685L10.3263 16.5951L10.6982 16.0913Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask436_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask436_1782_49644)">
        <path
          d="M13.0381 7.82756L12.2604 7.60101V8.24007L12.9975 8.41589L13.0381 7.82756Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask437_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask437_1782_49644)">
        <path
          d="M18.3365 13.8901L18.4075 13.667L18.5833 13.4235L18.5089 13.6771L18.3365 13.8901Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask438_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask438_1782_49644)">
        <path
          d="M15.7735 10.5697L15.175 10.2891L15.1378 10.7997L15.7295 11.0364L15.7735 10.5697Z"
          fill="#A4FF53"
        />
      </g>
      <mask
        height="16"
        id="mask439_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask439_1782_49644)">
        <path
          d="M15.7058 6.98902L14.6002 6.43111L14.492 6.39392L15.5571 6.94506L15.7058 6.98902Z"
          fill="#FF5500"
        />
      </g>
      <mask
        height="16"
        id="mask440_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask440_1782_49644)">
        <path
          d="M18.0626 9.14286L17.2342 8.57143L17.1226 8.22317L17.9375 8.81488L18.0626 9.14286Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask441_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask441_1782_49644)">
        <path
          d="M13.3052 5.9814L12.2333 5.5858L12.2401 5.79206L13.2477 6.16399L13.3052 5.9814Z"
          fill="#FF1E00"
        />
      </g>
      <mask
        height="16"
        id="mask442_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask442_1782_49644)">
        <path
          d="M5.85294 7.67203L5.45734 8.15555L5.70417 7.46239L6.07272 6.9924L5.85294 7.67203Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask443_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask443_1782_49644)">
        <path
          d="M13.8732 8.12173L13.038 7.82756L12.9975 8.4159L13.7988 8.66273L13.8732 8.12173Z"
          fill="#FFB600"
        />
      </g>
      <mask
        height="16"
        id="mask444_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask444_1782_49644)">
        <path
          d="M12.2333 5.58581L11.2325 5.28488L11.3136 5.51142L12.2401 5.79207L12.2333 5.58581Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask445_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask445_1782_49644)">
        <path
          d="M7.85123 5.43365L7.17499 5.63314L7.50635 5.31531L8.11835 5.13272L7.85123 5.43365Z"
          fill="#E40000"
        />
      </g>
      <mask
        height="16"
        id="mask446_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask446_1782_49644)">
        <path
          d="M16.5917 13.4844L16.6425 13.3322L16.7912 13.5926L16.7371 13.7346L16.5917 13.4844Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask447_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask447_1782_49644)">
        <path
          d="M11.6348 8.13863L11.1513 8.11835L11.2257 8.88251L11.6686 8.86222L11.6348 8.13863Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask448_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask448_1782_49644)">
        <path
          d="M12.2604 7.60102L11.5875 7.45563L11.6348 8.13864L12.2604 8.24007V7.60102Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask449_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask449_1782_49644)">
        <path
          d="M9.07864 18.2215L9.13612 18.5664L8.64584 18.5393L8.57822 18.1944L9.07864 18.2215Z"
          fill="#000096"
        />
      </g>
      <mask
        height="16"
        id="mask450_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask450_1782_49644)">
        <path
          d="M11.2257 8.88251L10.9417 8.98056L11.0194 9.80897L11.2764 9.67372L11.2257 8.88251Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask451_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask451_1782_49644)">
        <path
          d="M16.3652 12.3347L16.1657 12.1285L16.2232 12.5207L16.426 12.6965L16.3652 12.3347Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask452_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask452_1782_49644)">
        <path
          d="M5.30516 13.3931L5.42012 13.8597L5.27811 13.0008L5.16315 12.5241L5.30516 13.3931Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask453_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask453_1782_49644)">
        <path
          d="M14.3804 6.45479L13.3018 5.98141L13.2443 6.164L14.2688 6.61709L14.3804 6.45479Z"
          fill="#FF3800"
        />
      </g>
      <mask
        height="16"
        id="mask454_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask454_1782_49644)">
        <path
          d="M11.0194 9.80897L10.9248 10.0085L10.9755 10.8639L11.06 10.634L11.0194 9.80897Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask455_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask455_1782_49644)">
        <path
          d="M16.1488 11.2967L15.7295 11.0364L15.7194 11.4962L16.1386 11.716L16.1488 11.2967Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask456_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask456_1782_49644)">
        <path
          d="M13.082 7.29333L12.257 7.02283L12.2604 7.60102L13.0381 7.82756L13.082 7.29333Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask457_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask457_1782_49644)">
        <path
          d="M18.5089 13.6771L18.5833 13.4235L18.7354 13.1361L18.661 13.4167L18.5089 13.6771Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask458_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask458_1782_49644)">
        <path
          d="M10.9755 10.8673L11.0296 11.1446V12.0068L10.9755 11.7025V10.8673Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask459_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask459_1782_49644)">
        <path
          d="M14.7016 8.47338L13.8732 8.12173L13.7988 8.66272L14.6002 8.96365L14.7016 8.47338Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask460_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask460_1782_49644)">
        <path
          d="M13.2477 6.16399L12.2401 5.79205L12.2468 6.10651L13.1902 6.45139L13.2477 6.16399Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask461_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask461_1782_49644)">
        <path
          d="M11.0296 12.0068L11.1851 12.3415L11.1107 13.1801L10.9687 12.825L11.0296 12.0068Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask462_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask462_1782_49644)">
        <path
          d="M13.9578 7.63484L13.0854 7.29333L13.0381 7.82757L13.8732 8.12174L13.9578 7.63484Z"
          fill="#FF9100"
        />
      </g>
      <mask
        height="16"
        id="mask463_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask463_1782_49644)">
        <path
          d="M18.7794 10.4413L18.2249 9.93069L18.1572 9.51818L18.7084 10.0592L18.7794 10.4413Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask464_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask464_1782_49644)">
        <path
          d="M19.0262 12.0812L18.7861 11.6889L18.8166 11.2697L19.06 11.6957L19.0262 12.0812Z"
          fill="#6AFF8D"
        />
      </g>
      <mask
        height="16"
        id="mask465_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask465_1782_49644)">
        <path
          d="M15.3407 9.31193L14.6002 8.96366L14.519 9.49113L15.246 9.78868L15.3407 9.31193Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask466_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask466_1782_49644)">
        <path
          d="M11.1108 13.1801L11.3069 13.5418L11.1479 14.3297L10.9654 13.951L11.1108 13.1801Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask467_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask467_1782_49644)">
        <path
          d="M16.8352 7.72274L15.8445 7.12764L15.7058 6.98901L16.6695 7.58749L16.8352 7.72274Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask468_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask468_1782_49644)">
        <path
          d="M15.5571 6.94506L14.492 6.39392L14.3804 6.45478L15.4015 6.9924L15.5571 6.94506Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask469_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask469_1782_49644)">
        <path
          d="M12.2401 5.79206L11.3136 5.51141L11.3914 5.84954L12.2469 6.10651L12.2401 5.79206Z"
          fill="#FF1300"
        />
      </g>
      <mask
        height="16"
        id="mask470_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask470_1782_49644)">
        <path
          d="M11.2325 5.28488L10.3466 5.09215L10.5055 5.33222L11.3136 5.51142L11.2325 5.28488Z"
          fill="#D60000"
        />
      </g>
      <mask
        height="16"
        id="mask471_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask471_1782_49644)">
        <path
          d="M13.1327 6.8301L12.2536 6.51903L12.257 7.02283L13.082 7.29333L13.1327 6.8301Z"
          fill="#FF5900"
        />
      </g>
      <mask
        height="16"
        id="mask472_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask472_1782_49644)">
        <path
          d="M5.03467 11.1547L5.09891 11.6416L5.11582 10.776L5.05157 10.2823L5.03467 11.1547Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask473_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask473_1782_49644)">
        <path
          d="M13.1902 6.45141L12.2468 6.10652L12.2536 6.51903L13.1361 6.83011L13.1902 6.45141Z"
          fill="#FF3B00"
        />
      </g>
      <mask
        height="16"
        id="mask474_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask474_1782_49644)">
        <path
          d="M5.92056 15.4692L6.04228 15.8648L5.76164 15.104L5.63654 14.6881L5.92056 15.4692Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask475_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask475_1782_49644)">
        <path
          d="M16.47 13.2003L16.5173 13.0313L16.6424 13.3322L16.5917 13.4877L16.47 13.2003Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask476_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask476_1782_49644)">
        <path
          d="M14.2688 6.61709L13.2443 6.164L13.1902 6.45141L14.1606 6.87068L14.2688 6.61709Z"
          fill="#FF4300"
        />
      </g>
      <mask
        height="16"
        id="mask477_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask477_1782_49644)">
        <path
          d="M12.257 7.02284L11.53 6.83687L11.5875 7.45563L12.2604 7.60103L12.257 7.02284Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask478_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask478_1782_49644)">
        <path
          d="M14.0558 7.21217L13.1361 6.83009L13.0854 7.29332L13.9577 7.63483L14.0558 7.21217Z"
          fill="#FF6F00"
        />
      </g>
      <mask
        height="16"
        id="mask479_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask479_1782_49644)">
        <path
          d="M15.8479 10.1167L15.246 9.78868L15.175 10.2891L15.7735 10.5697L15.8479 10.1167Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask480_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask480_1782_49644)">
        <path
          d="M6.88757 6.07609L6.35672 6.41421L6.69484 5.95098L7.17498 5.63315L6.88757 6.07609Z"
          fill="#FF1E00"
        />
      </g>
      <mask
        height="16"
        id="mask481_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask481_1782_49644)">
        <path
          d="M10.1877 4.98056L9.36942 4.89941L9.60948 5.01438L10.3466 5.09214L10.1877 4.98056Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask482_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask482_1782_49644)">
        <path
          d="M17.9375 8.81488L17.1226 8.22317L16.9907 7.93576L17.7853 8.54438L17.9375 8.81488Z"
          fill="#FFBD00"
        />
      </g>
      <mask
        height="16"
        id="mask483_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask483_1782_49644)">
        <path
          d="M14.1606 6.87067L13.1902 6.4514L13.1361 6.8301L14.0558 7.21218L14.1606 6.87067Z"
          fill="#FF5500"
        />
      </g>
      <mask
        height="16"
        id="mask484_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask484_1782_49644)">
        <path
          d="M11.1479 14.3297L11.2561 14.7625L11.0127 15.4759L10.9113 15.0364L11.1479 14.3297Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask485_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask485_1782_49644)">
        <path
          d="M12.2468 6.10652L11.3914 5.84955L11.4624 6.29587L12.2536 6.51903L12.2468 6.10652Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask486_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask486_1782_49644)">
        <path
          d="M12.2536 6.51903L11.4624 6.29587L11.53 6.83687L12.257 7.02283L12.2536 6.51903Z"
          fill="#FF4700"
        />
      </g>
      <mask
        height="16"
        id="mask487_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask487_1782_49644)">
        <path
          d="M14.8199 8.03381L13.9577 7.63483L13.8732 8.12172L14.7016 8.47337L14.8199 8.03381Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask488_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask488_1782_49644)">
        <path
          d="M11.5875 7.45562L11.0533 7.39476L11.1513 8.11834L11.6348 8.13863L11.5875 7.45562Z"
          fill="#FF8900"
        />
      </g>
      <mask
        height="16"
        id="mask489_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask489_1782_49644)">
        <path
          d="M18.661 13.4167L18.7354 13.1361L18.8639 12.8081L18.7861 13.1226L18.661 13.4167Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask490_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask490_1782_49644)">
        <path
          d="M15.4015 6.9924L14.3804 6.45479L14.2688 6.61709L15.2494 7.12765L15.4015 6.9924Z"
          fill="#FF5200"
        />
      </g>
      <mask
        height="16"
        id="mask491_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask491_1782_49644)">
        <path
          d="M16.3415 11.9527L16.1386 11.716L16.1657 12.1285L16.3652 12.3314L16.3415 11.9527Z"
          fill="#60FF97"
        />
      </g>
      <mask
        height="16"
        id="mask492_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask492_1782_49644)">
        <path
          d="M16.967 14.3567L17.3018 14.1978L17.5114 14.2485L17.1699 14.4142L16.967 14.3567Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask493_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask493_1782_49644)">
        <path
          d="M9.13611 4.9197L8.40915 4.96704L8.72022 4.93999L9.36942 4.89941L9.13611 4.9197Z"
          fill="#B20000"
        />
      </g>
      <mask
        height="16"
        id="mask494_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask494_1782_49644)">
        <path
          d="M17.1699 14.4142L17.5114 14.2485L17.7278 14.2418L17.3796 14.4176L17.1699 14.4142Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask495_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask495_1782_49644)">
        <path
          d="M10.3804 17.0685L10.4379 17.4979L10.0186 17.8698L9.95438 17.4472L10.3804 17.0685Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask496_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask496_1782_49644)">
        <path
          d="M16.7675 14.2485L17.0989 14.093L17.3018 14.1978L16.967 14.3567L16.7675 14.2485Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask497_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask497_1782_49644)">
        <path
          d="M15.459 8.86899L14.7016 8.47338L14.6002 8.96366L15.3407 9.31193L15.459 8.86899Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask498_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask498_1782_49644)">
        <path
          d="M16.1961 10.8741L15.7735 10.5697L15.7295 11.0364L16.1488 11.2933L16.1961 10.8741Z"
          fill="#A0FF56"
        />
      </g>
      <mask
        height="16"
        id="mask499_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask499_1782_49644)">
        <path
          d="M16.6695 7.5875L15.7058 6.98902L15.5571 6.94507L16.4903 7.5334L16.6695 7.5875Z"
          fill="#FF6F00"
        />
      </g>
      <mask
        height="16"
        id="mask500_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask500_1782_49644)">
        <path
          d="M11.3136 5.51142L10.5055 5.33221L10.6576 5.69401L11.3914 5.84954L11.3136 5.51142Z"
          fill="#E40000"
        />
      </g>
      <mask
        height="16"
        id="mask501_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask501_1782_49644)">
        <path
          d="M14.9518 7.65512L14.0558 7.21556L13.9577 7.63483L14.8199 8.03382L14.9518 7.65512Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask502_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask502_1782_49644)">
        <path
          d="M5.28488 8.92984L5.15302 9.44717L5.33222 8.66611L5.45733 8.15554L5.28488 8.92984Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask503_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask503_1782_49644)">
        <path
          d="M9.56213 18.1133L9.61285 18.4615L9.13609 18.5664L9.07861 18.2215L9.56213 18.1133Z"
          fill="#0000A8"
        />
      </g>
      <mask
        height="16"
        id="mask504_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask504_1782_49644)">
        <path
          d="M11.1513 8.11835L10.8301 8.17921L10.9417 8.98056L11.2257 8.88251L11.1513 8.11835Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask505_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask505_1782_49644)">
        <path
          d="M16.3753 12.8859L16.4261 12.6965L16.5173 13.0313L16.47 13.2003L16.3753 12.8859Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask506_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask506_1782_49644)">
        <path
          d="M17.3795 14.4176L17.7278 14.2418L17.9375 14.1775L17.5824 14.3703L17.3795 14.4176Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask507_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask507_1782_49644)">
        <path
          d="M19.06 11.6957L18.8166 11.2697V10.8504L19.0567 11.3102L19.06 11.6957Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask508_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask508_1782_49644)">
        <path
          d="M15.2494 7.12765L14.2688 6.61708L14.1606 6.87067L15.0972 7.35081L15.2494 7.12765Z"
          fill="#FF5900"
        />
      </g>
      <mask
        height="16"
        id="mask509_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask509_1782_49644)">
        <path
          d="M18.7084 10.0592L18.1572 9.51818L18.0626 9.14286L18.6036 9.70753L18.7084 10.0592Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask510_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask510_1782_49644)">
        <path
          d="M16.585 14.093L16.9096 13.9375L17.0989 14.0964L16.7675 14.2485L16.585 14.093Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask511_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask511_1782_49644)">
        <path
          d="M15.0972 7.3508L14.1606 6.87067L14.0558 7.21555L14.9518 7.65511L15.0972 7.3508Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask512_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask512_1782_49644)">
        <path
          d="M11.53 6.83686L10.9383 6.7388L11.0533 7.39476L11.5875 7.45562L11.53 6.83686Z"
          fill="#FF5D00"
        />
      </g>
      <mask
        height="16"
        id="mask513_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask513_1782_49644)">
        <path
          d="M7.66864 18.0964L7.72951 18.3635L7.27642 17.9713L7.20541 17.6906L7.66864 18.0964Z"
          fill="#0000C4"
        />
      </g>
      <mask
        height="16"
        id="mask514_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask514_1782_49644)">
        <path
          d="M15.9527 9.68724L15.3407 9.31193L15.246 9.78868L15.8479 10.1167L15.9527 9.68724Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask515_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask515_1782_49644)">
        <path
          d="M17.5824 14.3702L17.9375 14.1775L18.1437 14.0592L17.7819 14.2722L17.5824 14.3702Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask516_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask516_1782_49644)">
        <path
          d="M17.7853 8.54439L16.9907 7.93577L16.8352 7.72275L17.6162 8.33813L17.7853 8.54439Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask517_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask517_1782_49644)">
        <path
          d="M11.3914 5.84954L10.6577 5.694L10.803 6.16737L11.4624 6.29586L11.3914 5.84954Z"
          fill="#FF1300"
        />
      </g>
      <mask
        height="16"
        id="mask518_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask518_1782_49644)">
        <path
          d="M7.20541 17.6906L7.27642 17.9713L6.85714 17.4708L6.77261 17.1767L7.20541 17.6906Z"
          fill="#0000E8"
        />
      </g>
      <mask
        height="16"
        id="mask519_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask519_1782_49644)">
        <path
          d="M18.7861 13.1226L18.8639 12.8081L18.962 12.4565L18.8808 12.8014L18.7861 13.1226Z"
          fill="#36FFC1"
        />
      </g>
      <mask
        height="16"
        id="mask520_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask520_1782_49644)">
        <path
          d="M16.4193 13.8935L16.7371 13.7346L16.9096 13.9375L16.585 14.093L16.4193 13.8935Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask521_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask521_1782_49644)">
        <path
          d="M11.4624 6.29586L10.803 6.16737L10.9383 6.7388L11.53 6.83686L11.4624 6.29586Z"
          fill="#FF3400"
        />
      </g>
      <mask
        height="16"
        id="mask522_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask522_1782_49644)">
        <path
          d="M10.9417 8.98056L10.8199 9.14962L10.9248 10.0085L11.0194 9.80896L10.9417 8.98056Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask523_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask523_1782_49644)">
        <path
          d="M11.0127 15.4759L11.0668 15.956L10.7523 16.5681L10.6982 16.0913L11.0127 15.4759Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask524_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask524_1782_49644)">
        <path
          d="M8.11835 5.13272L7.50635 5.31531L7.86814 5.1361L8.40914 4.96704L8.11835 5.13272Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask525_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask525_1782_49644)">
        <path
          d="M10.3466 5.09214L9.60947 5.01437L9.84953 5.26459L10.5055 5.33221L10.3466 5.09214Z"
          fill="#BF0000"
        />
      </g>
      <mask
        height="16"
        id="mask526_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask526_1782_49644)">
        <path
          d="M15.601 8.47676L14.82 8.03382L14.7016 8.47338L15.459 8.86899L15.601 8.47676Z"
          fill="#FFB600"
        />
      </g>
      <mask
        height="16"
        id="mask527_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask527_1782_49644)">
        <path
          d="M16.4869 7.5334L15.5571 6.94507L15.4015 6.99241L16.3043 7.56383L16.4869 7.5334Z"
          fill="#FF6800"
        />
      </g>
      <mask
        height="16"
        id="mask528_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask528_1782_49644)">
        <path
          d="M8.15216 18.3804L8.19949 18.6408L7.7295 18.3635L7.66864 18.0964L8.15216 18.3804Z"
          fill="#0000AD"
        />
      </g>
      <mask
        height="16"
        id="mask529_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask529_1782_49644)">
        <path
          d="M5.63653 14.6881L5.76164 15.104L5.552 14.2891L5.42014 13.8597L5.63653 14.6881Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask530_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask530_1782_49644)">
        <path
          d="M5.16315 12.5241L5.27811 13.0009L5.21387 12.1319L5.09891 11.6416L5.16315 12.5241Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask531_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask531_1782_49644)">
        <path
          d="M16.3483 11.5672L16.1488 11.2967L16.1386 11.716L16.3415 11.9527L16.3483 11.5672Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask532_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask532_1782_49644)">
        <path
          d="M6.07272 6.99239L5.70416 7.46238L6.022 6.8639L6.35674 6.4142L6.07272 6.99239Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask533_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask533_1782_49644)">
        <path
          d="M17.7819 14.2722L18.1437 14.0592L18.3331 13.8935L17.9679 14.1234L17.7819 14.2722Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask534_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask534_1782_49644)">
        <path
          d="M6.77262 17.1767L6.85716 17.4708L6.47846 16.8724L6.3804 16.5647L6.77262 17.1767Z"
          fill="#0004FF"
        />
      </g>
      <mask
        height="16"
        id="mask535_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask535_1782_49644)">
        <path
          d="M16.3178 12.5478L16.3652 12.3347L16.426 12.6965L16.3753 12.8859L16.3178 12.5478Z"
          fill="#49FFAD"
        />
      </g>
      <mask
        height="16"
        id="mask536_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask536_1782_49644)">
        <path
          d="M10.9248 10.0085L10.9687 10.2621L11.033 11.1446L10.9755 10.8673L10.9248 10.0085Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask537_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask537_1782_49644)">
        <path
          d="M16.2739 13.6568L16.5917 13.4877L16.7371 13.7346L16.4193 13.8935L16.2739 13.6568Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask538_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask538_1782_49644)">
        <path
          d="M16.2739 10.4649L15.8479 10.1167L15.7735 10.5697L16.1927 10.8741L16.2739 10.4649Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask539_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask539_1782_49644)">
        <path
          d="M15.7599 8.13864L14.9518 7.65512L14.8199 8.03382L15.601 8.47676L15.7599 8.13864Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask540_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask540_1782_49644)">
        <path
          d="M11.0533 7.39476L10.6813 7.42519L10.8301 8.17921L11.1513 8.11834L11.0533 7.39476Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask541_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask541_1782_49644)">
        <path
          d="M16.3043 7.56383L15.4015 6.9924L15.2494 7.12765L16.1184 7.67879L16.3043 7.56383Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask542_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask542_1782_49644)">
        <path
          d="M19.0566 11.3102L18.8166 10.8504L18.7794 10.4413L19.0161 10.9349L19.0566 11.3102Z"
          fill="#A7FF50"
        />
      </g>
      <mask
        height="16"
        id="mask543_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask543_1782_49644)">
        <path
          d="M18.6036 9.70753L18.0626 9.14286L17.9375 8.81488L18.4717 9.39983L18.6036 9.70753Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask544_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask544_1782_49644)">
        <path
          d="M16.0845 9.29163L15.459 8.86898L15.3407 9.31192L15.9527 9.68724L16.0845 9.29163Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask545_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask545_1782_49644)">
        <path
          d="M17.6162 8.33813L16.8352 7.72274L16.6695 7.58749L17.4269 8.20288L17.6162 8.33813Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask546_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask546_1782_49644)">
        <path
          d="M11.033 11.1446L11.1851 11.4624V12.3415L11.0296 12.0068L11.033 11.1446Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask547_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask547_1782_49644)">
        <path
          d="M15.9358 7.87152L15.0972 7.35081L14.9518 7.65513L15.76 8.13864L15.9358 7.87152Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask548_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask548_1782_49644)">
        <path
          d="M16.1184 7.6788L15.2494 7.12766L15.0972 7.35082L15.9358 7.87153L16.1184 7.6788Z"
          fill="#FF7300"
        />
      </g>
      <mask
        height="16"
        id="mask549_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask549_1782_49644)">
        <path
          d="M8.64582 18.5393L8.67963 18.7963L8.1995 18.6407L8.15216 18.3804L8.64582 18.5393Z"
          fill="#0000A4"
        />
      </g>
      <mask
        height="16"
        id="mask550_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask550_1782_49644)">
        <path
          d="M9.36941 4.89941L8.72021 4.93999L9.04143 5.05495L9.60948 5.01438L9.36941 4.89941Z"
          fill="#A40000"
        />
      </g>
      <mask
        height="16"
        id="mask551_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask551_1782_49644)">
        <path
          d="M18.8808 12.8014L18.962 12.4565L19.0262 12.0812L18.9451 12.4599L18.8808 12.8014Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask552_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask552_1782_49644)">
        <path
          d="M17.9679 14.1234L18.3331 13.8935L18.5089 13.6771L18.137 13.9307L17.9679 14.1234Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask553_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask553_1782_49644)">
        <path
          d="M10.5055 5.33221L9.84955 5.26459L10.0829 5.64329L10.6577 5.694L10.5055 5.33221Z"
          fill="#CD0000"
        />
      </g>
      <mask
        height="16"
        id="mask554_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask554_1782_49644)">
        <path
          d="M11.1851 12.3415L11.3914 12.6932L11.3102 13.5419L11.1107 13.1767L11.1851 12.3415Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask555_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask555_1782_49644)">
        <path
          d="M16.1555 13.3829L16.47 13.2003L16.5917 13.4878L16.2739 13.6568L16.1555 13.3829Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask556_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask556_1782_49644)">
        <path
          d="M5.05157 10.2823L5.11582 10.776L5.21726 9.94083L5.15301 9.44717L5.05157 10.2823Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask557_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask557_1782_49644)">
        <path
          d="M7.17499 5.63314L6.69485 5.95436L7.08032 5.61962L7.50635 5.31531L7.17499 5.63314Z"
          fill="#DA0000"
        />
      </g>
      <mask
        height="16"
        id="mask558_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask558_1782_49644)">
        <path
          d="M10.0186 17.8698L10.0626 18.2249L9.61285 18.4615L9.56213 18.1133L10.0186 17.8698Z"
          fill="#0000CD"
        />
      </g>
      <mask
        height="16"
        id="mask559_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask559_1782_49644)">
        <path
          d="M6.38039 16.5647L6.47845 16.8724L6.14709 16.1961L6.04227 15.8681L6.38039 16.5647Z"
          fill="#003CFF"
        />
      </g>
      <mask
        height="16"
        id="mask560_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask560_1782_49644)">
        <path
          d="M10.9383 6.7388H10.5055L10.6813 7.42519L11.0533 7.39814L10.9383 6.7388Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask561_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask561_1782_49644)">
        <path
          d="M16.3922 11.1851L16.1927 10.8741L16.1488 11.2967L16.3483 11.5672L16.3922 11.1851Z"
          fill="#9DFF5A"
        />
      </g>
      <mask
        height="16"
        id="mask562_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask562_1782_49644)">
        <path
          d="M16.2908 12.1961L16.3415 11.9527L16.3652 12.3347L16.3179 12.5478L16.2908 12.1961Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask563_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask563_1782_49644)">
        <path
          d="M10.6577 5.694L10.0829 5.64328L10.3026 6.13694L10.8031 6.16737L10.6577 5.694Z"
          fill="#E80000"
        />
      </g>
      <mask
        height="16"
        id="mask564_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask564_1782_49644)">
        <path
          d="M16.2435 8.93999L15.601 8.47676L15.459 8.86898L16.0845 9.29164L16.2435 8.93999Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask565_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask565_1782_49644)">
        <path
          d="M10.8301 8.17921L10.6712 8.32123L10.8199 9.14963L10.9417 8.98057L10.8301 8.17921Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask566_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask566_1782_49644)">
        <path
          d="M17.4269 8.20289L16.6695 7.5875L16.4903 7.5334L17.2274 8.13864L17.4269 8.20289Z"
          fill="#FF8600"
        />
      </g>
      <mask
        height="16"
        id="mask567_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask567_1782_49644)">
        <path
          d="M16.3821 10.0761L15.9527 9.68724L15.8478 10.1167L16.2739 10.4649L16.3821 10.0761Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask568_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask568_1782_49644)">
        <path
          d="M11.3069 13.5418L11.4252 13.9713L11.2561 14.7625L11.1479 14.3297L11.3069 13.5418Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask569_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask569_1782_49644)">
        <path
          d="M10.8031 6.16738L10.3026 6.13695L10.5055 6.73881H10.9383L10.8031 6.16738Z"
          fill="#FF2200"
        />
      </g>
      <mask
        height="16"
        id="mask570_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask570_1782_49644)">
        <path
          d="M10.7523 16.5681L10.8098 17.0042L10.4379 17.4979L10.3804 17.0685L10.7523 16.5681Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask571_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask571_1782_49644)">
        <path
          d="M18.4717 9.39984L17.9375 8.81489L17.7853 8.54439L18.3128 9.14287L18.4717 9.39984Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask572_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask572_1782_49644)">
        <path
          d="M18.137 13.9307L18.5089 13.6771L18.6611 13.4167L18.2824 13.6974L18.137 13.9307Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask573_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask573_1782_49644)">
        <path
          d="M19.0161 10.9349L18.7794 10.4413L18.7084 10.0592L18.9417 10.5799L19.0161 10.9349Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask574_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask574_1782_49644)">
        <path
          d="M16.0643 13.0854L16.3753 12.8859L16.47 13.2003L16.1556 13.3829L16.0643 13.0854Z"
          fill="#33FFC4"
        />
      </g>
      <mask
        height="16"
        id="mask575_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask575_1782_49644)">
        <path
          d="M9.13609 18.5664L9.15638 18.8233L8.67963 18.7963L8.64581 18.5393L9.13609 18.5664Z"
          fill="#0000A8"
        />
      </g>
      <mask
        height="16"
        id="mask576_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask576_1782_49644)">
        <path
          d="M18.9451 12.4599L19.0262 12.0812L19.06 11.6957L18.9755 12.1048L18.9451 12.4599Z"
          fill="#6AFF8D"
        />
      </g>
      <mask
        height="16"
        id="mask577_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask577_1782_49644)">
        <path
          d="M8.40913 4.96704L7.86813 5.1361L8.25021 5.10229L8.7202 4.93999L8.40913 4.96704Z"
          fill="#9F0000"
        />
      </g>
      <mask
        height="16"
        id="mask578_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask578_1782_49644)">
        <path
          d="M16.3719 14.5359L16.967 14.3567L17.1699 14.4142L16.5681 14.6002L16.3719 14.5359Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask579_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask579_1782_49644)">
        <path
          d="M16.4193 8.64582L15.7599 8.13863L15.601 8.47676L16.2434 8.93999L16.4193 8.64582Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask580_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask580_1782_49644)">
        <path
          d="M16.186 14.4244L16.7675 14.2485L16.967 14.3567L16.3719 14.5359L16.186 14.4244Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask581_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask581_1782_49644)">
        <path
          d="M17.2274 8.13864L16.4869 7.5334L16.3043 7.56383L17.0211 8.15555L17.2274 8.13864Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask582_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask582_1782_49644)">
        <path
          d="M5.42012 13.8597L5.55199 14.2891L5.41336 13.4472L5.27811 13.0009L5.42012 13.8597Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask583_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask583_1782_49644)">
        <path
          d="M5.45732 8.15554L5.33221 8.66611L5.59257 7.96281L5.70415 7.46239L5.45732 8.15554Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask584_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask584_1782_49644)">
        <path
          d="M16.5681 14.6002L17.1699 14.4142L17.3796 14.4176L16.7675 14.6137L16.5681 14.6002Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask585_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask585_1782_49644)">
        <path
          d="M16.0068 14.2688L16.585 14.093L16.7676 14.2485L16.1826 14.4243L16.0068 14.2688Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask586_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask586_1782_49644)">
        <path
          d="M9.60949 5.01437L9.04144 5.05495L9.36266 5.30854L9.84956 5.26459L9.60949 5.01437Z"
          fill="#A80000"
        />
      </g>
      <mask
        height="16"
        id="mask587_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask587_1782_49644)">
        <path
          d="M10.8199 9.14963L10.8402 9.38631L10.9687 10.2621L10.9248 10.0085L10.8199 9.14963Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask588_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask588_1782_49644)">
        <path
          d="M16.612 8.40914L15.9358 7.87152L15.7599 8.13864L16.4193 8.64582L16.612 8.40914Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask589_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask589_1782_49644)">
        <path
          d="M17.0211 8.15554L16.3043 7.56383L16.1183 7.67879L16.8149 8.24684L17.0211 8.15554Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask590_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask590_1782_49644)">
        <path
          d="M16.2976 11.8411L16.3483 11.5672L16.3415 11.9527L16.2908 12.1961L16.2976 11.8411Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask591_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask591_1782_49644)">
        <path
          d="M16.4734 10.8132L16.2739 10.4649L16.1961 10.8741L16.3956 11.1851L16.4734 10.8132Z"
          fill="#BAFF3C"
        />
      </g>
      <mask
        height="16"
        id="mask592_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask592_1782_49644)">
        <path
          d="M16.5207 9.72444L16.0845 9.29164L15.9527 9.68725L16.3821 10.0761L16.5207 9.72444Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask593_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask593_1782_49644)">
        <path
          d="M16.7675 14.6137L17.3796 14.4176L17.5824 14.3703L16.967 14.5765L16.7675 14.6137Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask594_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask594_1782_49644)">
        <path
          d="M18.2823 13.6974L18.661 13.4167L18.7862 13.1226L18.4041 13.4303L18.2823 13.6974Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask595_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask595_1782_49644)">
        <path
          d="M15.8478 14.0693L16.4193 13.8935L16.5849 14.093L16.0068 14.2688L15.8478 14.0693Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask596_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask596_1782_49644)">
        <path
          d="M6.04227 15.8648L6.14709 16.1961L5.87659 15.4556L5.76163 15.104L6.04227 15.8648Z"
          fill="#0078FF"
        />
      </g>
      <mask
        height="16"
        id="mask597_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask597_1782_49644)">
        <path
          d="M16.8149 8.24683L16.1183 7.67879L15.9358 7.87152L16.612 8.41252L16.8149 8.24683Z"
          fill="#FF8900"
        />
      </g>
      <mask
        height="16"
        id="mask598_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask598_1782_49644)">
        <path
          d="M18.3128 9.14286L17.7853 8.54438L17.6162 8.33813L18.1302 8.94337L18.3128 9.14286Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask599_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask599_1782_49644)">
        <path
          d="M11.2561 14.7625L11.3102 15.2494L11.0668 15.956L11.0127 15.4759L11.2561 14.7625Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask600_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask600_1782_49644)">
        <path
          d="M5.09891 11.6416L5.21387 12.1319L5.23416 11.273L5.11581 10.776L5.09891 11.6416Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask601_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask601_1782_49644)">
        <path
          d="M10.6813 7.4252L10.4717 7.54016L10.6712 8.32123L10.8301 8.17922L10.6813 7.4252Z"
          fill="#FF7300"
        />
      </g>
      <mask
        height="16"
        id="mask602_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask602_1782_49644)">
        <path
          d="M16.0068 12.7675L16.3178 12.5478L16.3753 12.8859L16.0643 13.0854L16.0068 12.7675Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask603_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask603_1782_49644)">
        <path
          d="M18.9417 10.5799L18.7084 10.0592L18.6035 9.70753L18.8369 10.2519L18.9417 10.5799Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask604_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask604_1782_49644)">
        <path
          d="M6.35675 6.4142L6.022 6.86391L6.4007 6.38377L6.69487 5.95097L6.35675 6.4142Z"
          fill="#FF1A00"
        />
      </g>
      <mask
        height="16"
        id="mask605_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask605_1782_49644)">
        <path
          d="M16.967 14.5765L17.5824 14.3702L17.7819 14.2722L17.1564 14.492L16.967 14.5765Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask606_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask606_1782_49644)">
        <path
          d="M15.7092 13.836L16.2739 13.6568L16.4193 13.8935L15.8479 14.0693L15.7092 13.836Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask607_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask607_1782_49644)">
        <path
          d="M18.9755 12.1048L19.06 11.6957L19.0566 11.3102L18.9721 11.7498L18.9755 12.1048Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask608_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask608_1782_49644)">
        <path
          d="M10.9687 10.2621L11.1107 10.563L11.1851 11.4624L11.033 11.1446L10.9687 10.2621Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask609_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask609_1782_49644)">
        <path
          d="M9.84954 5.26459L9.36264 5.30854L9.67371 5.69739L10.0828 5.64329L9.84954 5.26459Z"
          fill="#B60000"
        />
      </g>
      <mask
        height="16"
        id="mask610_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask610_1782_49644)">
        <path
          d="M16.6864 9.41336L16.2435 8.93999L16.0845 9.29164L16.5207 9.72443L16.6864 9.41336Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask611_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask611_1782_49644)">
        <path
          d="M18.1302 8.94337L17.6128 8.33813L17.4269 8.20288L17.9341 8.80812L18.1302 8.94337Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask612_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask612_1782_49644)">
        <path
          d="M10.4379 17.4979L10.4717 17.8597L10.0626 18.2249L10.0186 17.8698L10.4379 17.4979Z"
          fill="#0000FA"
        />
      </g>
      <mask
        height="16"
        id="mask613_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask613_1782_49644)">
        <path
          d="M9.61286 18.4615L9.61963 18.7185L9.1564 18.8233L9.13611 18.5664L9.61286 18.4615Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask614_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask614_1782_49644)">
        <path
          d="M18.4041 13.4303L18.7861 13.1226L18.8808 12.8014L18.4953 13.1327L18.4041 13.4303Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask615_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask615_1782_49644)">
        <path
          d="M17.1564 14.492L17.7819 14.2722L17.9679 14.1234L17.3322 14.3635L17.1564 14.492Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask616_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask616_1782_49644)">
        <path
          d="M16.5816 10.4649L16.3821 10.0761L16.2739 10.4649L16.4734 10.8132L16.5816 10.4649Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask617_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask617_1782_49644)">
        <path
          d="M10.5055 6.7388L10.2384 6.82671L10.4717 7.54015L10.6813 7.42519L10.5055 6.7388Z"
          fill="#FF3F00"
        />
      </g>
      <mask
        height="16"
        id="mask618_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask618_1782_49644)">
        <path
          d="M16.3415 11.4894L16.3956 11.1851L16.3483 11.5672L16.2975 11.8411L16.3415 11.4894Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask619_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask619_1782_49644)">
        <path
          d="M7.50636 5.3153L7.08032 5.61961L7.50298 5.4235L7.86815 5.13609L7.50636 5.3153Z"
          fill="#B20000"
        />
      </g>
      <mask
        height="16"
        id="mask620_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask620_1782_49644)">
        <path
          d="M7.72951 18.3635L7.7498 18.5359L7.31362 18.1539L7.27643 17.9713L7.72951 18.3635Z"
          fill="#0000D6"
        />
      </g>
      <mask
        height="16"
        id="mask621_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask621_1782_49644)">
        <path
          d="M15.5943 13.5723L16.1556 13.3829L16.2739 13.6568L15.7092 13.836L15.5943 13.5723Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask622_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask622_1782_49644)">
        <path
          d="M7.27642 17.9713L7.31361 18.1538L6.91125 17.6737L6.85715 17.4708L7.27642 17.9713Z"
          fill="#0000FA"
        />
      </g>
      <mask
        height="16"
        id="mask623_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask623_1782_49644)">
        <path
          d="M15.9797 12.4396L16.2908 12.1961L16.3179 12.5478L16.0068 12.7675L15.9797 12.4396Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask624_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask624_1782_49644)">
        <path
          d="M11.1851 11.4624L11.3948 11.8039L11.3914 12.6932L11.1851 12.3415V11.4624Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask625_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask625_1782_49644)">
        <path
          d="M10.0828 5.64328L9.67371 5.69738L9.96787 6.20795L10.3026 6.13694L10.0828 5.64328Z"
          fill="#D60000"
        />
      </g>
      <mask
        height="16"
        id="mask626_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask626_1782_49644)">
        <path
          d="M8.72021 4.93999L8.25021 5.09891L8.64582 5.21049L9.04142 5.05495L8.72021 4.93999Z"
          fill="#960000"
        />
      </g>
      <mask
        height="16"
        id="mask627_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask627_1782_49644)">
        <path
          d="M18.8369 10.2519L18.6035 9.70753L18.4717 9.39983L18.7016 9.96112L18.8369 10.2519Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask628_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask628_1782_49644)">
        <path
          d="M10.3026 6.13695L9.9679 6.20795L10.235 6.82672L10.5055 6.73881L10.3026 6.13695Z"
          fill="#FF1300"
        />
      </g>
      <mask
        height="16"
        id="mask629_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask629_1782_49644)">
        <path
          d="M8.19951 18.6407L8.2029 18.803L7.74981 18.5359L7.72952 18.3635L8.19951 18.6407Z"
          fill="#0000BF"
        />
      </g>
      <mask
        height="16"
        id="mask630_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask630_1782_49644)">
        <path
          d="M16.8724 9.15301L16.4193 8.64582L16.2435 8.93999L16.6864 9.41336L16.8724 9.15301Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask631_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask631_1782_49644)">
        <path
          d="M5.15302 9.44717L5.21726 9.94083L5.39646 9.16315L5.33222 8.66611L5.15302 9.44717Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask632_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask632_1782_49644)">
        <path
          d="M17.9341 8.80812L17.4269 8.20288L17.2274 8.13863L17.721 8.74049L17.9341 8.80812Z"
          fill="#FF9C00"
        />
      </g>
      <mask
        height="16"
        id="mask633_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask633_1782_49644)">
        <path
          d="M18.9721 11.7498L19.0567 11.3102L19.0161 10.9349L18.9315 11.4015L18.9721 11.7498Z"
          fill="#A0FF56"
        />
      </g>
      <mask
        height="16"
        id="mask634_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask634_1782_49644)">
        <path
          d="M10.6712 8.32122L10.6543 8.53424L10.8402 9.38293L10.8199 9.14962L10.6712 8.32122Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask635_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask635_1782_49644)">
        <path
          d="M6.85715 17.4708L6.90787 17.6737L6.5427 17.0989L6.47845 16.8724L6.85715 17.4708Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask636_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask636_1782_49644)">
        <path
          d="M17.3322 14.3635L17.9679 14.1234L18.1369 13.9307L17.4945 14.1877L17.3322 14.3635Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask637_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask637_1782_49644)">
        <path
          d="M5.76164 15.104L5.8766 15.4556L5.67035 14.6678L5.552 14.2891L5.76164 15.104Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask638_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask638_1782_49644)">
        <path
          d="M11.0668 15.9561L11.1175 16.4024L10.8098 17.0042L10.7523 16.5681L11.0668 15.9561Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask639_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask639_1782_49644)">
        <path
          d="M15.5097 13.2883L16.0643 13.0854L16.1556 13.3829L15.5943 13.5723L15.5097 13.2883Z"
          fill="#39FFBE"
        />
      </g>
      <mask
        height="16"
        id="mask640_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask640_1782_49644)">
        <path
          d="M17.0752 8.95013L16.612 8.40913L16.4193 8.64582L16.8724 9.15301L17.0752 8.95013Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask641_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask641_1782_49644)">
        <path
          d="M11.3914 12.6932L11.5131 13.1158L11.4252 13.9713L11.3069 13.5419L11.3914 12.6932Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask642_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask642_1782_49644)">
        <path
          d="M17.7211 8.74049L17.2274 8.13863L17.0211 8.15554L17.5047 8.74387L17.7211 8.74049Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask643_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask643_1782_49644)">
        <path
          d="M5.27811 13.0009L5.41336 13.4472L5.34912 12.5985L5.21387 12.1319L5.27811 13.0009Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask644_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask644_1782_49644)">
        <path
          d="M18.4954 13.1327L18.8808 12.8014L18.9451 12.4599L18.5562 12.8183L18.4954 13.1327Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask645_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask645_1782_49644)">
        <path
          d="M16.7236 10.1505L16.5207 9.72443L16.3821 10.0761L16.585 10.4649L16.7236 10.1505Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask646_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask646_1782_49644)">
        <path
          d="M17.2882 8.8115L16.8149 8.24683L16.612 8.40913L17.0752 8.95013L17.2882 8.8115Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask647_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask647_1782_49644)">
        <path
          d="M17.5047 8.74388L17.0211 8.15554L16.8149 8.24683L17.2883 8.8115L17.5047 8.74388Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask648_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask648_1782_49644)">
        <path
          d="M16.4193 11.1547L16.4734 10.8132L16.3956 11.1851L16.3415 11.4894L16.4193 11.1547Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask649_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask649_1782_49644)">
        <path
          d="M8.67963 18.7963L8.6661 18.9518L8.20287 18.803L8.19949 18.6407L8.67963 18.7963Z"
          fill="#0000B6"
        />
      </g>
      <mask
        height="16"
        id="mask650_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask650_1782_49644)">
        <path
          d="M15.3846 14.6205L16.1826 14.4244L16.3719 14.5359L15.5638 14.7354L15.3846 14.6205Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask651_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask651_1782_49644)">
        <path
          d="M18.7016 9.96112L18.4717 9.39983L18.3128 9.14286L18.5393 9.71767L18.7016 9.96112Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask652_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask652_1782_49644)">
        <path
          d="M15.9899 12.1082L16.2976 11.8411L16.2908 12.1961L15.9797 12.4396L15.9899 12.1082Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask653_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask653_1782_49644)">
        <path
          d="M15.5638 14.7354L16.372 14.5359L16.5681 14.6002L15.7532 14.8064L15.5638 14.7354Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask654_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask654_1782_49644)">
        <path
          d="M15.2156 14.4615L16.0068 14.2688L16.1826 14.4244L15.3846 14.6205L15.2156 14.4615Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask655_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask655_1782_49644)">
        <path
          d="M15.7532 14.8064L16.5714 14.6002L16.7675 14.6137L15.9425 14.8301L15.7532 14.8064Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask656_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask656_1782_49644)">
        <path
          d="M5.70414 7.46239L5.59256 7.96281L5.92392 7.35757L6.02198 6.86391L5.70414 7.46239Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask657_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask657_1782_49644)">
        <path
          d="M15.0634 14.2621L15.8479 14.0693L16.0068 14.2688L15.2156 14.4615L15.0634 14.2621Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask658_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask658_1782_49644)">
        <path
          d="M17.4945 14.1877L18.1369 13.9307L18.2823 13.6974L17.6331 13.9746L17.4945 14.1877Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask659_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask659_1782_49644)">
        <path
          d="M6.47846 16.8724L6.5427 17.0989L6.22824 16.4497L6.14709 16.1961L6.47846 16.8724Z"
          fill="#0048FF"
        />
      </g>
      <mask
        height="16"
        id="mask660_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask660_1782_49644)">
        <path
          d="M15.4522 12.9839L16.0068 12.7675L16.0642 13.0854L15.5097 13.2883L15.4522 12.9839Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask661_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask661_1782_49644)">
        <path
          d="M18.9315 11.4015L19.0161 10.9349L18.9451 10.5799L18.8571 11.0668L18.9315 11.4015Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask662_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask662_1782_49644)">
        <path
          d="M9.04142 5.05495L8.64581 5.21048L9.04142 5.46408L9.36263 5.30854L9.04142 5.05495Z"
          fill="#960000"
        />
      </g>
      <mask
        height="16"
        id="mask663_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask663_1782_49644)">
        <path
          d="M16.8893 9.87321L16.6864 9.41336L16.5207 9.72443L16.7236 10.1505L16.8893 9.87321Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask664_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask664_1782_49644)">
        <path
          d="M10.0626 18.2249L10.0558 18.492L9.61962 18.7185L9.61285 18.4615L10.0626 18.2249Z"
          fill="#0000DA"
        />
      </g>
      <mask
        height="16"
        id="mask665_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask665_1782_49644)">
        <path
          d="M15.9425 14.8301L16.7676 14.6137L16.967 14.5765L16.1285 14.803L15.9425 14.8301Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask666_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask666_1782_49644)">
        <path
          d="M10.8402 9.38293L10.9653 9.67034L11.1107 10.563L10.9687 10.2621L10.8402 9.38293Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask667_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask667_1782_49644)">
        <path
          d="M14.9315 14.0321L15.7092 13.836L15.8479 14.0693L15.0634 14.262L14.9315 14.0321Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask668_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask668_1782_49644)">
        <path
          d="M10.4717 7.54016L10.4108 7.73289L10.6543 8.53424L10.6712 8.32123L10.4717 7.54016Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask669_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask669_1782_49644)">
        <path
          d="M5.11581 10.776L5.23416 11.273L5.33221 10.4514L5.21725 9.94083L5.11581 10.776Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask670_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask670_1782_49644)">
        <path
          d="M18.5562 12.8183L18.9451 12.4599L18.9755 12.1048L18.5833 12.4869L18.5562 12.8183Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask671_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask671_1782_49644)">
        <path
          d="M6.69486 5.95436L6.4007 6.38377L6.83011 6.02874L7.08032 5.61961L6.69486 5.95436Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask672_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask672_1782_49644)">
        <path
          d="M18.5393 9.71767L18.3128 9.14286L18.1302 8.94337L18.3533 9.52494L18.5393 9.71767Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask673_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask673_1782_49644)">
        <path
          d="M11.4252 13.9713L11.4793 14.4582L11.3102 15.2494L11.2561 14.7625L11.4252 13.9713Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask674_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask674_1782_49644)">
        <path
          d="M16.5275 10.8402L16.585 10.4649L16.4734 10.8132L16.4193 11.1547L16.5275 10.8402Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask675_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask675_1782_49644)">
        <path
          d="M7.86813 5.13611L7.50296 5.42351L7.94928 5.37617L8.25021 5.10229L7.86813 5.13611Z"
          fill="#960000"
        />
      </g>
      <mask
        height="16"
        id="mask676_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask676_1782_49644)">
        <path
          d="M16.0304 11.7836L16.3415 11.4894L16.2976 11.8411L15.9899 12.1082L16.0304 11.7836Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask677_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask677_1782_49644)">
        <path
          d="M9.15639 18.8233L9.12257 18.9755L8.66611 18.9518L8.67963 18.7963L9.15639 18.8233Z"
          fill="#0000BB"
        />
      </g>
      <mask
        height="16"
        id="mask678_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask678_1782_49644)">
        <path
          d="M16.1285 14.803L16.9671 14.5765L17.1564 14.492L16.3077 14.732L16.1285 14.803Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask679_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask679_1782_49644)">
        <path
          d="M10.8098 17.0042L10.8369 17.3796L10.4717 17.8597L10.4379 17.4979L10.8098 17.0042Z"
          fill="#0020FF"
        />
      </g>
      <mask
        height="16"
        id="mask680_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask680_1782_49644)">
        <path
          d="M17.6331 13.9746L18.2823 13.6974L18.4041 13.4303L17.7481 13.7244L17.6331 13.9746Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask681_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask681_1782_49644)">
        <path
          d="M14.8199 13.7718L15.5942 13.5723L15.7092 13.836L14.9315 14.0321L14.8199 13.7718Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask682_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask682_1782_49644)">
        <path
          d="M17.0786 9.64667L16.8724 9.15301L16.6864 9.41336L16.8893 9.87321L17.0786 9.64667Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask683_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask683_1782_49644)">
        <path
          d="M15.4252 12.6729L15.9797 12.4396L16.0068 12.7675L15.4523 12.9839L15.4252 12.6729Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask684_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask684_1782_49644)">
        <path
          d="M18.8572 11.0668L18.9417 10.5799L18.8369 10.2519L18.7523 10.7591L18.8572 11.0668Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask685_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask685_1782_49644)">
        <path
          d="M18.3534 9.52494L18.1302 8.94337L17.9341 8.80812L18.1539 9.38969L18.3534 9.52494Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask686_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask686_1782_49644)">
        <path
          d="M9.36266 5.30855L9.04144 5.46408L9.42352 5.85631L9.67373 5.69739L9.36266 5.30855Z"
          fill="#A80000"
        />
      </g>
      <mask
        height="16"
        id="mask687_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask687_1782_49644)">
        <path
          d="M5.55199 14.2891L5.67033 14.6678L5.53847 13.8495L5.41336 13.4472L5.55199 14.2891Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask688_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask688_1782_49644)">
        <path
          d="M11.1107 10.563L11.3136 10.8977L11.3948 11.8039L11.1851 11.4624L11.1107 10.563Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask689_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask689_1782_49644)">
        <path
          d="M10.2384 6.82672L10.1201 7.00593L10.4108 7.73289L10.4717 7.54016L10.2384 6.82672Z"
          fill="#FF3400"
        />
      </g>
      <mask
        height="16"
        id="mask690_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask690_1782_49644)">
        <path
          d="M6.14709 16.1961L6.22824 16.4497L5.96788 15.7363L5.87659 15.4556L6.14709 16.1961Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask691_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask691_1782_49644)">
        <path
          d="M17.2815 9.47423L17.0752 8.95013L16.8724 9.15301L17.0786 9.64667L17.2815 9.47423Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask692_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask692_1782_49644)">
        <path
          d="M16.3077 14.732L17.1564 14.4954L17.3322 14.3635L16.4768 14.6137L16.3077 14.732Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask693_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask693_1782_49644)">
        <path
          d="M18.5833 12.4869L18.9755 12.1048L18.9721 11.7498L18.5799 12.1555L18.5833 12.4869Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask694_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask694_1782_49644)">
        <path
          d="M14.7388 13.4877L15.5063 13.2883L15.5943 13.5757L14.8199 13.7718L14.7388 13.4877Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask695_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask695_1782_49644)">
        <path
          d="M16.6661 10.5562L16.7236 10.1505L16.585 10.4649L16.5275 10.8402L16.6661 10.5562Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask696_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask696_1782_49644)">
        <path
          d="M18.1539 9.38969L17.9341 8.80812L17.7211 8.74049L17.9375 9.3153L18.1539 9.38969Z"
          fill="#FFB200"
        />
      </g>
      <mask
        height="16"
        id="mask697_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask697_1782_49644)">
        <path
          d="M9.67373 5.69739L9.42352 5.85631L9.7887 6.37702L9.9679 6.20795L9.67373 5.69739Z"
          fill="#C80000"
        />
      </g>
      <mask
        height="16"
        id="mask698_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask698_1782_49644)">
        <path
          d="M9.9679 6.20795L9.7887 6.37701L10.1201 7.00592L10.2384 6.82671L9.9679 6.20795Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask699_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask699_1782_49644)">
        <path
          d="M16.1015 11.4725L16.4193 11.1547L16.3415 11.4894L16.0305 11.7836L16.1015 11.4725Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask700_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask700_1782_49644)">
        <path
          d="M17.4979 9.35926L17.2849 8.8115L17.0753 8.95013L17.2815 9.47422L17.4979 9.35926Z"
          fill="#FFB200"
        />
      </g>
      <mask
        height="16"
        id="mask701_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask701_1782_49644)">
        <path
          d="M5.33221 8.66611L5.39646 9.16315L5.65681 8.46662L5.59257 7.96281L5.33221 8.66611Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask702_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask702_1782_49644)">
        <path
          d="M17.7481 13.7244L18.4041 13.4303L18.4954 13.1327L17.836 13.4472L17.7481 13.7244Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask703_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask703_1782_49644)">
        <path
          d="M17.9375 9.3153L17.7211 8.74049H17.5047L17.7177 9.30516L17.9375 9.3153Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask704_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask704_1782_49644)">
        <path
          d="M17.7177 9.30516L17.5047 8.74049L17.2849 8.8115L17.4979 9.35926L17.7177 9.30516Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask705_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask705_1782_49644)">
        <path
          d="M15.432 12.3584L15.9899 12.1082L15.9797 12.4396L15.4252 12.6729L15.432 12.3584Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask706_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask706_1782_49644)">
        <path
          d="M14.4243 14.8369L15.3846 14.6205L15.5638 14.7354L14.5968 14.962L14.4243 14.8369Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask707_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask707_1782_49644)">
        <path
          d="M14.2654 14.6712L15.2156 14.4615L15.3846 14.6205L14.4244 14.8369L14.2654 14.6712Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask708_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask708_1782_49644)">
        <path
          d="M5.21387 12.1319L5.34912 12.5985L5.3694 11.7599L5.23415 11.273L5.21387 12.1319Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask709_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask709_1782_49644)">
        <path
          d="M18.7523 10.7591L18.8369 10.2519L18.7016 9.96112L18.6171 10.4852L18.7523 10.7591Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask710_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask710_1782_49644)">
        <path
          d="M7.74981 18.5359L7.72952 18.6036L7.31363 18.2418V18.1539L7.74981 18.5359Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask711_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask711_1782_49644)">
        <path
          d="M11.3103 15.2494L11.361 15.7025L11.1175 16.4024L11.0668 15.9594L11.3103 15.2494Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask712_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask712_1782_49644)">
        <path
          d="M11.3948 11.8039L11.5165 12.2232L11.5131 13.1158L11.3914 12.6932L11.3948 11.8039Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask713_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask713_1782_49644)">
        <path
          d="M14.5968 14.962L15.5638 14.7354L15.7532 14.8064L14.7726 15.0397L14.5968 14.962Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask714_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask714_1782_49644)">
        <path
          d="M7.31362 18.1539V18.2418L6.92816 17.7819L6.91125 17.6737L7.31362 18.1539Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask715_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask715_1782_49644)">
        <path
          d="M10.6543 8.53424L10.7489 8.80812L10.9653 9.67034L10.8402 9.38293L10.6543 8.53424Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask716_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask716_1782_49644)">
        <path
          d="M14.1201 14.4683L15.0634 14.2621L15.2156 14.4615L14.2654 14.6712L14.1201 14.4683Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask717_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask717_1782_49644)">
        <path
          d="M16.4768 14.6137L17.3322 14.3635L17.4945 14.1877L16.6289 14.4514L16.4768 14.6137Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask718_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask718_1782_49644)">
        <path
          d="M9.61964 18.7185L9.56892 18.8774L9.12598 18.9755L9.15641 18.8233L9.61964 18.7185Z"
          fill="#0000CD"
        />
      </g>
      <mask
        height="16"
        id="mask719_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask719_1782_49644)">
        <path
          d="M14.6813 13.1936L15.4523 12.9839L15.5064 13.2883L14.7388 13.4878L14.6813 13.1936Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask720_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask720_1782_49644)">
        <path
          d="M8.2029 18.8031L8.16232 18.8572L7.72952 18.6036L7.74981 18.5359L8.2029 18.8031Z"
          fill="#0000D6"
        />
      </g>
      <mask
        height="16"
        id="mask721_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask721_1782_49644)">
        <path
          d="M16.8284 10.3094L16.8893 9.87321L16.7236 10.1505L16.6661 10.5562L16.8284 10.3094Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask722_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask722_1782_49644)">
        <path
          d="M14.7726 15.0397L15.7532 14.8064L15.9425 14.8301L14.9518 15.0702L14.7726 15.0397Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask723_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask723_1782_49644)">
        <path
          d="M8.25021 5.10229L7.94928 5.37617L8.40913 5.47761L8.64581 5.21049L8.25021 5.10229Z"
          fill="#890000"
        />
      </g>
      <mask
        height="16"
        id="mask724_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask724_1782_49644)">
        <path
          d="M13.9949 14.235L14.9315 14.0321L15.0634 14.2621L14.12 14.4717L13.9949 14.235Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask725_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask725_1782_49644)">
        <path
          d="M10.4717 17.8597L10.4548 18.1369L10.0558 18.492L10.0625 18.2249L10.4717 17.8597Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask726_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask726_1782_49644)">
        <path
          d="M18.5799 12.1555L18.9721 11.7498L18.9315 11.4015L18.5393 11.8276L18.5799 12.1555Z"
          fill="#A0FF56"
        />
      </g>
      <mask
        height="16"
        id="mask727_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask727_1782_49644)">
        <path
          d="M6.91125 17.6737L6.92815 17.7819L6.57989 17.2342L6.54269 17.0989L6.91125 17.6737Z"
          fill="#0024FF"
        />
      </g>
      <mask
        height="16"
        id="mask728_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask728_1782_49644)">
        <path
          d="M6.02198 6.86391L5.92392 7.35757L6.31614 6.86729L6.40067 6.38377L6.02198 6.86391Z"
          fill="#FF1600"
        />
      </g>
      <mask
        height="16"
        id="mask729_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask729_1782_49644)">
        <path
          d="M16.2063 11.1851L16.5275 10.8402L16.4193 11.1547L16.1014 11.4725L16.2063 11.1851Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask730_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask730_1782_49644)">
        <path
          d="M18.6171 10.4852L18.7016 9.9645L18.5393 9.71767L18.4582 10.2519L18.6171 10.4852Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask731_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask731_1782_49644)">
        <path
          d="M17.836 13.4472L18.4954 13.1327L18.5562 12.8183L17.8935 13.153L17.836 13.4472Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask732_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask732_1782_49644)">
        <path
          d="M7.08032 5.61962L6.83011 6.02875L7.3001 5.81911L7.50297 5.42351L7.08032 5.61962Z"
          fill="#A80000"
        />
      </g>
      <mask
        height="16"
        id="mask733_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask733_1782_49644)">
        <path
          d="M14.9518 15.0702L15.9425 14.8301L16.1285 14.803L15.131 15.0533L14.9518 15.0702Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask734_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask734_1782_49644)">
        <path
          d="M15.4692 12.0541L16.0305 11.7836L15.9899 12.1082L15.432 12.3584L15.4692 12.0541Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask735_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask735_1782_49644)">
        <path
          d="M13.8901 13.9713L14.8199 13.7718L14.9315 14.0321L13.9949 14.235L13.8901 13.9713Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask736_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask736_1782_49644)">
        <path
          d="M5.8766 15.4556L5.9679 15.7363L5.7684 14.9755L5.67035 14.6678L5.8766 15.4556Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask737_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask737_1782_49644)">
        <path
          d="M16.6289 14.4514L17.4945 14.1877L17.6331 13.9746L16.7608 14.2485L16.6289 14.4514Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask738_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask738_1782_49644)">
        <path
          d="M8.66613 18.9518L8.60526 18.9992L8.16232 18.8571L8.2029 18.803L8.66613 18.9518Z"
          fill="#0000CD"
        />
      </g>
      <mask
        height="16"
        id="mask739_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask739_1782_49644)">
        <path
          d="M14.6577 12.8893L15.4252 12.6729L15.4522 12.9839L14.6813 13.1936L14.6577 12.8893Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask740_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask740_1782_49644)">
        <path
          d="M17.0144 10.1099L17.0786 9.64667L16.8893 9.87321L16.8284 10.3094L17.0144 10.1099Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask741_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask741_1782_49644)">
        <path
          d="M5.21725 9.94083L5.33222 10.4514L5.51142 9.68386L5.39646 9.16315L5.21725 9.94083Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask742_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask742_1782_49644)">
        <path
          d="M11.5131 13.1158L11.5672 13.6095L11.4793 14.4582L11.4252 13.9713L11.5131 13.1158Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask743_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask743_1782_49644)">
        <path
          d="M15.131 15.0533L16.1285 14.803L16.3077 14.732L15.3001 14.989L15.131 15.0533Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask744_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask744_1782_49644)">
        <path
          d="M18.4582 10.2519L18.5393 9.71767L18.3533 9.52494L18.2756 10.0659L18.4582 10.2519Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask745_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask745_1782_49644)">
        <path
          d="M18.5393 11.8276L18.9316 11.4015L18.8572 11.0702L18.4683 11.5131L18.5393 11.8276Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask746_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask746_1782_49644)">
        <path
          d="M10.9654 9.67033L11.1479 9.99831L11.3136 10.8977L11.1108 10.563L10.9654 9.67033Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask747_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask747_1782_49644)">
        <path
          d="M6.5427 17.0989L6.57989 17.2342L6.27896 16.612L6.22824 16.4497L6.5427 17.0989Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask748_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask748_1782_49644)">
        <path
          d="M5.41336 13.4472L5.53847 13.8495L5.47761 13.0245L5.34912 12.5985L5.41336 13.4472Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask749_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask749_1782_49644)">
        <path
          d="M13.809 13.6906L14.7388 13.4877L14.8199 13.7718L13.8901 13.9746L13.809 13.6906Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask750_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask750_1782_49644)">
        <path
          d="M11.1175 16.4024L11.1412 16.7878L10.8369 17.3796L10.8098 17.0042L11.1175 16.4024Z"
          fill="#005CFF"
        />
      </g>
      <mask
        height="16"
        id="mask751_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask751_1782_49644)">
        <path
          d="M10.4108 7.73289L10.4649 8.00001L10.749 8.80813L10.6543 8.53425L10.4108 7.73289Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask752_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask752_1782_49644)">
        <path
          d="M17.2139 9.96113L17.2815 9.47423L17.0786 9.64667L17.0144 10.1099L17.2139 9.96113Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask753_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask753_1782_49644)">
        <path
          d="M16.3415 10.9282L16.6661 10.5562L16.5275 10.8402L16.2063 11.1851L16.3415 10.9282Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask754_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask754_1782_49644)">
        <path
          d="M8.64584 5.21049L8.40915 5.47761L8.87238 5.72444L9.04144 5.46409L8.64584 5.21049Z"
          fill="#890000"
        />
      </g>
      <mask
        height="16"
        id="mask755_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask755_1782_49644)">
        <path
          d="M17.8935 13.153L18.5562 12.8183L18.5833 12.4869L17.9205 12.8419L17.8935 13.153Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask756_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask756_1782_49644)">
        <path
          d="M18.2722 10.0659L18.3533 9.52494L18.1538 9.38969L18.0727 9.93069L18.2722 10.0659Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask757_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask757_1782_49644)">
        <path
          d="M15.5402 11.7633L16.1014 11.4725L16.0304 11.7836L15.4691 12.0541L15.5402 11.7633Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask758_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask758_1782_49644)">
        <path
          d="M16.7608 14.2485L17.6332 13.9746L17.7481 13.7244L16.8724 14.0152L16.7608 14.2485Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask759_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask759_1782_49644)">
        <path
          d="M14.661 12.5883L15.432 12.3584L15.4252 12.6729L14.6577 12.8893L14.661 12.5883Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask760_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask760_1782_49644)">
        <path
          d="M13.4303 15.1648L14.5089 14.9045L14.3432 14.7591L13.2612 15.0161L13.4303 15.1648Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask761_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask761_1782_49644)">
        <path
          d="M13.2612 15.0161L14.3432 14.7591L14.1911 14.5765L13.1057 14.8301L13.2612 15.0161Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask762_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask762_1782_49644)">
        <path
          d="M17.4269 9.86645L17.4979 9.35926L17.2815 9.47422L17.2139 9.96112L17.4269 9.86645Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask763_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask763_1782_49644)">
        <path
          d="M10.0558 18.492L9.98818 18.6577L9.56891 18.8774L9.61963 18.7185L10.0558 18.492Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask764_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask764_1782_49644)">
        <path
          d="M15.3001 14.989L16.3077 14.732L16.4768 14.6137L15.459 14.8774L15.3001 14.989Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask765_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask765_1782_49644)">
        <path
          d="M13.6061 15.2663L14.6847 15.0059L14.5089 14.9045L13.4303 15.1648L13.6061 15.2663Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask766_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask766_1782_49644)">
        <path
          d="M9.12596 18.9755L9.04143 19.0228L8.60187 18.9992L8.66611 18.9518L9.12596 18.9755Z"
          fill="#0000D1"
        />
      </g>
      <mask
        height="16"
        id="mask767_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask767_1782_49644)">
        <path
          d="M18.0727 9.93069L18.1539 9.38969L17.9375 9.31531L17.8631 9.85292L18.0727 9.93069Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask768_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask768_1782_49644)">
        <path
          d="M13.7549 13.3965L14.6847 13.1936L14.7388 13.4877L13.809 13.6906L13.7549 13.3965Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask769_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask769_1782_49644)">
        <path
          d="M13.1057 14.8301L14.191 14.5765L14.0558 14.3567L12.967 14.6069L13.1057 14.8301Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask770_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask770_1782_49644)">
        <path
          d="M17.6433 9.82925L17.7177 9.30516L17.4979 9.35926L17.4269 9.86645L17.6433 9.82925Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask771_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask771_1782_49644)">
        <path
          d="M18.4683 11.5131L18.8572 11.0668L18.7523 10.7591L18.3635 11.2223L18.4683 11.5131Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask772_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask772_1782_49644)">
        <path
          d="M17.8631 9.85292L17.9374 9.31869L17.7177 9.30516L17.6433 9.82925L17.8631 9.85292Z"
          fill="#FFBD00"
        />
      </g>
      <mask
        height="16"
        id="mask773_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask773_1782_49644)">
        <path
          d="M13.7887 15.3238L14.8639 15.06L14.6847 15.0059L13.6061 15.2663L13.7887 15.3238Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask774_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask774_1782_49644)">
        <path
          d="M16.4971 10.7084L16.8284 10.3094L16.6661 10.5562L16.3415 10.9282L16.4971 10.7084Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask775_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask775_1782_49644)">
        <path
          d="M10.1201 7.00592L10.1268 7.26628L10.4649 8L10.4108 7.73289L10.1201 7.00592Z"
          fill="#FF2D00"
        />
      </g>
      <mask
        height="16"
        id="mask776_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask776_1782_49644)">
        <path
          d="M12.967 14.6069L14.0558 14.3567L13.9375 14.1065L12.8487 14.3533L12.967 14.6069Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask777_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask777_1782_49644)">
        <path
          d="M5.59256 7.96282L5.6568 8.46662L5.98816 7.86138L5.92392 7.35757L5.59256 7.96282Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask778_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask778_1782_49644)">
        <path
          d="M11.3136 10.8977L11.432 11.3136L11.5165 12.2232L11.3948 11.8039L11.3136 10.8977Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask779_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask779_1782_49644)">
        <path
          d="M9.04144 5.46408L8.87238 5.72444L9.3187 6.11328L9.42352 5.8563L9.04144 5.46408Z"
          fill="#9B0000"
        />
      </g>
      <mask
        height="16"
        id="mask780_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask780_1782_49644)">
        <path
          d="M5.23416 11.273L5.36941 11.7599L5.46747 10.9552L5.33222 10.4514L5.23416 11.273Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask781_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask781_1782_49644)">
        <path
          d="M6.22825 16.4497L6.27897 16.612L6.02876 15.9324L5.9679 15.7363L6.22825 16.4497Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask782_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask782_1782_49644)">
        <path
          d="M17.9206 12.8419L18.5833 12.4869L18.5799 12.1555L17.9138 12.5275L17.9206 12.8419Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask783_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask783_1782_49644)">
        <path
          d="M13.9713 15.3305L15.0431 15.0668L14.8639 15.06L13.7887 15.3238L13.9713 15.3305Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask784_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask784_1782_49644)">
        <path
          d="M7.50298 5.42351L7.30011 5.81912L7.79715 5.75487L7.94931 5.37617L7.50298 5.42351Z"
          fill="#8D0000"
        />
      </g>
      <mask
        height="16"
        id="mask785_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask785_1782_49644)">
        <path
          d="M15.6382 11.4962L16.2063 11.1851L16.1014 11.4725L15.5402 11.7633L15.6382 11.4962Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask786_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask786_1782_49644)">
        <path
          d="M16.8724 14.0152L17.7481 13.7244L17.836 13.4472L16.9535 13.7515L16.8724 14.0152Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask787_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask787_1782_49644)">
        <path
          d="M15.459 14.8774L16.4768 14.6137L16.6289 14.4514L15.6044 14.7253L15.459 14.8774Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask788_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask788_1782_49644)">
        <path
          d="M10.8369 17.3795L10.8064 17.6703L10.4548 18.1369L10.4717 17.8597L10.8369 17.3795Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask789_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask789_1782_49644)">
        <path
          d="M11.4793 14.4582L11.5266 14.9248L11.361 15.7025L11.3102 15.2494L11.4793 14.4582Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask790_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask790_1782_49644)">
        <path
          d="M14.6982 12.2942L15.4692 12.0541L15.432 12.3584L14.661 12.5883L14.6982 12.2942Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask791_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask791_1782_49644)">
        <path
          d="M5.67035 14.6678L5.76841 14.9755L5.63992 14.191L5.53848 13.8495L5.67035 14.6678Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask792_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask792_1782_49644)">
        <path
          d="M7.72951 18.6036L7.68556 18.5664L7.29333 18.2249L7.31362 18.2418L7.72951 18.6036Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask793_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask793_1782_49644)">
        <path
          d="M13.7312 13.0955L14.6577 12.8893L14.6813 13.1936L13.7549 13.3965L13.7312 13.0955Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask794_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask794_1782_49644)">
        <path
          d="M7.31362 18.2418L7.29333 18.2249L6.92816 17.7921V17.7819L7.31362 18.2418Z"
          fill="#0010FF"
        />
      </g>
      <mask
        height="16"
        id="mask795_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask795_1782_49644)">
        <path
          d="M12.8487 14.3533L13.9375 14.1065L13.8462 13.8326L12.754 14.0761L12.8487 14.3533Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask796_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask796_1782_49644)">
        <path
          d="M18.3635 11.2223L18.7523 10.7591L18.6171 10.4852L18.2316 10.9586L18.3635 11.2223Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask797_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask797_1782_49644)">
        <path
          d="M9.78867 6.37701L9.74133 6.63061L10.1268 7.26628L10.12 7.00592L9.78867 6.37701Z"
          fill="#E80000"
        />
      </g>
      <mask
        height="16"
        id="mask798_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask798_1782_49644)">
        <path
          d="M9.42351 5.85631L9.3187 6.11328L9.74135 6.63061L9.78869 6.37702L9.42351 5.85631Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask799_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask799_1782_49644)">
        <path
          d="M6.40069 6.38378L6.31616 6.86729L6.76587 6.5055L6.83011 6.02875L6.40069 6.38378Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask800_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask800_1782_49644)">
        <path
          d="M16.6762 10.5292L17.0144 10.1099L16.8284 10.3094L16.497 10.7084L16.6762 10.5292Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask801_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask801_1782_49644)">
        <path
          d="M8.1623 18.8571L8.09468 18.8098L7.68555 18.5664L7.7295 18.6036L8.1623 18.8571Z"
          fill="#0000F1"
        />
      </g>
      <mask
        height="16"
        id="mask802_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask802_1782_49644)">
        <path
          d="M10.749 8.80812L10.9079 9.13272L11.1513 9.99832L10.9654 9.67034L10.749 8.80812Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask803_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask803_1782_49644)">
        <path
          d="M14.1471 15.29L15.2156 15.0262L15.0431 15.0668L13.9713 15.3305L14.1471 15.29Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask804_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask804_1782_49644)">
        <path
          d="M6.92816 17.7819V17.7921L6.5968 17.2747L6.5799 17.2342L6.92816 17.7819Z"
          fill="#0038FF"
        />
      </g>
      <mask
        height="16"
        id="mask805_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask805_1782_49644)">
        <path
          d="M9.56892 18.8774L9.46748 18.9315L9.04144 19.0228L9.12259 18.9755L9.56892 18.8774Z"
          fill="#0000E3"
        />
      </g>
      <mask
        height="16"
        id="mask806_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask806_1782_49644)">
        <path
          d="M17.9138 12.5275L18.5799 12.1555L18.5393 11.8276L17.8732 12.213L17.9138 12.5275Z"
          fill="#9DFF5A"
        />
      </g>
      <mask
        height="16"
        id="mask807_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask807_1782_49644)">
        <path
          d="M12.754 14.0761L13.8462 13.8326L13.7785 13.5452L12.6864 13.7819L12.754 14.0761Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask808_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask808_1782_49644)">
        <path
          d="M18.2316 10.9586L18.6171 10.4852L18.4582 10.2519L18.0761 10.7354L18.2316 10.9586Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask809_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask809_1782_49644)">
        <path
          d="M15.6044 14.7253L16.6289 14.4514L16.7608 14.2485L15.7295 14.5292L15.6044 14.7253Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask810_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask810_1782_49644)">
        <path
          d="M15.7633 11.2595L16.3415 10.9282L16.2063 11.1851L15.6382 11.4962L15.7633 11.2595Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask811_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask811_1782_49644)">
        <path
          d="M12.3179 15.4522L13.4303 15.1648L13.2612 15.0161L12.142 15.3001L12.3179 15.4522Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask812_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask812_1782_49644)">
        <path
          d="M16.9535 13.7515L17.836 13.4472L17.8935 13.153L17.0076 13.4675L16.9535 13.7515Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask813_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask813_1782_49644)">
        <path
          d="M12.142 15.3001L13.2612 15.0161L13.1057 14.8301L11.9797 15.104L12.142 15.3001Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask814_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask814_1782_49644)">
        <path
          d="M16.869 10.4007L17.2139 9.96112L17.0144 10.1099L16.6763 10.5325L16.869 10.4007Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask815_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask815_1782_49644)">
        <path
          d="M13.7346 12.7946L14.661 12.5883L14.6577 12.8893L13.7312 13.0955L13.7346 12.7946Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask816_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask816_1782_49644)">
        <path
          d="M14.7625 12.0169L15.5402 11.7633L15.4692 12.0541L14.6982 12.2942L14.7625 12.0169Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask817_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask817_1782_49644)">
        <path
          d="M8.60188 18.9992L8.51059 18.9417L8.0947 18.8098L8.16232 18.8572L8.60188 18.9992Z"
          fill="#0000E8"
        />
      </g>
      <mask
        height="16"
        id="mask818_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask818_1782_49644)">
        <path
          d="M12.5038 15.5571L13.6061 15.2663L13.4303 15.1648L12.3178 15.4522L12.5038 15.5571Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask819_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask819_1782_49644)">
        <path
          d="M5.34912 12.5985L5.47761 13.0245L5.4979 12.213L5.36941 11.7599L5.34912 12.5985Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask820_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask820_1782_49644)">
        <path
          d="M11.9797 15.104L13.1057 14.8301L12.967 14.6069L11.8377 14.8707L11.9797 15.104Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask821_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask821_1782_49644)">
        <path
          d="M14.3128 15.1987L15.3812 14.9383L15.2156 15.0262L14.1471 15.2899L14.3128 15.1987Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask822_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask822_1782_49644)">
        <path
          d="M11.5165 12.2232L11.5672 12.7202V13.6095L11.5131 13.1158L11.5165 12.2232Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask823_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask823_1782_49644)">
        <path
          d="M5.39645 9.16315L5.51142 9.68386L5.76839 8.99409L5.65681 8.46661L5.39645 9.16315Z"
          fill="#FF9100"
        />
      </g>
      <mask
        height="16"
        id="mask824_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask824_1782_49644)">
        <path
          d="M10.4548 18.1369L10.3736 18.3195L9.98819 18.6577L10.0558 18.492L10.4548 18.1369Z"
          fill="#0008FF"
        />
      </g>
      <mask
        height="16"
        id="mask825_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask825_1782_49644)">
        <path
          d="M18.0761 10.7354L18.4582 10.2519L18.2756 10.0659L17.8969 10.5495L18.0761 10.7354Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask826_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask826_1782_49644)">
        <path
          d="M12.6898 15.6145L13.7887 15.3238L13.6061 15.2663L12.5038 15.5571L12.6898 15.6145Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask827_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask827_1782_49644)">
        <path
          d="M11.3609 15.7025L11.3778 16.1048L11.1412 16.7878L11.1175 16.4024L11.3609 15.7025Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask828_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask828_1782_49644)">
        <path
          d="M5.96789 15.7363L6.02876 15.9324L5.83941 15.2088L5.7684 14.9755L5.96789 15.7363Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask829_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask829_1782_49644)">
        <path
          d="M17.0752 10.3229L17.4269 9.86645L17.2139 9.96112L16.869 10.4007L17.0752 10.3229Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask830_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask830_1782_49644)">
        <path
          d="M6.57989 17.2342L6.5968 17.2747L6.31277 16.6864L6.27896 16.612L6.57989 17.2342Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask831_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask831_1782_49644)">
        <path
          d="M11.8377 14.8707L12.9671 14.6069L12.8487 14.3533L11.7126 14.6103L11.8377 14.8707Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask832_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask832_1782_49644)">
        <path
          d="M12.6864 13.7819L13.7786 13.5452L13.7414 13.2443L12.6425 13.481L12.6864 13.7819Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask833_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask833_1782_49644)">
        <path
          d="M7.94931 5.37617L7.79715 5.75487L8.3111 5.8394L8.40915 5.47761L7.94931 5.37617Z"
          fill="#800000"
        />
      </g>
      <mask
        height="16"
        id="mask834_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask834_1782_49644)">
        <path
          d="M17.8732 12.213L18.5393 11.8276L18.4683 11.5131L17.8056 11.9121L17.8732 12.213Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask835_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask835_1782_49644)">
        <path
          d="M17.8969 10.5495L18.2756 10.0659L18.0727 9.93069L17.7042 10.4142L17.8969 10.5495Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask836_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask836_1782_49644)">
        <path
          d="M15.9155 11.0566L16.4971 10.7084L16.3415 10.9282L15.7633 11.2595L15.9155 11.0566Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask837_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask837_1782_49644)">
        <path
          d="M17.2883 10.2992L17.6433 9.82925L17.4269 9.86645L17.0753 10.3229L17.2883 10.2992Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask838_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask838_1782_49644)">
        <path
          d="M12.8791 15.6247L13.9713 15.3305L13.7887 15.3238L12.6898 15.6179L12.8791 15.6247Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask839_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask839_1782_49644)">
        <path
          d="M15.7295 14.5292L16.7608 14.2485L16.8724 14.0152L15.831 14.3026L15.7295 14.5292Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask840_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask840_1782_49644)">
        <path
          d="M17.7041 10.4142L18.0727 9.93069L17.8631 9.84954L17.4979 10.3297L17.7041 10.4142Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask841_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask841_1782_49644)">
        <path
          d="M17.4979 10.3297L17.8631 9.85292L17.6433 9.82925L17.2883 10.2992L17.4979 10.3297Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask842_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask842_1782_49644)">
        <path
          d="M17.0076 13.4675L17.8935 13.153L17.9206 12.8419L17.0313 13.1665L17.0076 13.4675Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask843_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask843_1782_49644)">
        <path
          d="M13.7684 12.5072L14.6982 12.2942L14.661 12.5883L13.7346 12.798L13.7684 12.5072Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask844_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask844_1782_49644)">
        <path
          d="M14.8572 11.7633L15.6382 11.4962L15.5402 11.7633L14.7625 12.0169L14.8572 11.7633Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask845_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask845_1782_49644)">
        <path
          d="M14.4649 15.0668L15.5334 14.8064L15.3812 14.9383L14.3128 15.1987L14.4649 15.0668Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask846_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask846_1782_49644)">
        <path
          d="M11.7126 14.6103L12.8487 14.3533L12.754 14.0761L11.6112 14.3195L11.7126 14.6103Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask847_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask847_1782_49644)">
        <path
          d="M10.4649 8.00001L10.5968 8.32461L10.9079 9.13272L10.749 8.80812L10.4649 8.00001Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask848_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask848_1782_49644)">
        <path
          d="M11.1479 9.99831L11.2629 10.4176L11.432 11.3136L11.3136 10.8977L11.1479 9.99831Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask849_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask849_1782_49644)">
        <path
          d="M9.04144 19.0262L8.9231 18.9653L8.51059 18.9417L8.60188 18.9992L9.04144 19.0262Z"
          fill="#0000ED"
        />
      </g>
      <mask
        height="16"
        id="mask850_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask850_1782_49644)">
        <path
          d="M5.53846 13.8495L5.6399 14.191L5.58242 13.3998L5.4776 13.0245L5.53846 13.8495Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask851_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask851_1782_49644)">
        <path
          d="M12.6424 13.481L13.7413 13.2443L13.7278 12.9434L12.6323 13.1733L12.6424 13.481Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask852_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask852_1782_49644)">
        <path
          d="M13.0617 15.5807L14.1471 15.2899L13.9713 15.3305L12.8791 15.6247L13.0617 15.5807Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask853_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask853_1782_49644)">
        <path
          d="M5.92392 7.35757L5.98816 7.86476L6.38039 7.37448L6.31614 6.86729L5.92392 7.35757Z"
          fill="#FF1A00"
        />
      </g>
      <mask
        height="16"
        id="mask854_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask854_1782_49644)">
        <path
          d="M17.8056 11.9121L18.4683 11.5131L18.3635 11.2223L17.7042 11.6315L17.8056 11.9121Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask855_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask855_1782_49644)">
        <path
          d="M7.68555 18.5664L7.6416 18.4379L7.27304 18.1167L7.29333 18.2249L7.68555 18.5664Z"
          fill="#000CFF"
        />
      </g>
      <mask
        height="16"
        id="mask856_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask856_1782_49644)">
        <path
          d="M9.98818 18.6577L9.86645 18.7219L9.46747 18.9315L9.56891 18.8774L9.98818 18.6577Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask857_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask857_1782_49644)">
        <path
          d="M11.2426 15.7667L12.3178 15.4523L12.142 15.3001L11.0566 15.6044L11.2426 15.7667Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask858_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask858_1782_49644)">
        <path
          d="M11.0567 15.6044L12.142 15.3001L11.9797 15.104L10.8842 15.3981L11.0567 15.6044Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask859_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask859_1782_49644)">
        <path
          d="M7.29334 18.2249L7.27305 18.1167L6.93154 17.7075L6.92816 17.7921L7.29334 18.2249Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask860_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask860_1782_49644)">
        <path
          d="M16.0845 10.8977L16.6762 10.5292L16.497 10.7084L15.9155 11.0566L16.0845 10.8977Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask861_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask861_1782_49644)">
        <path
          d="M6.83011 6.02874L6.76587 6.5055L7.25615 6.28234L7.3001 5.81911L6.83011 6.02874Z"
          fill="#A40000"
        />
      </g>
      <mask
        height="16"
        id="mask862_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask862_1782_49644)">
        <path
          d="M11.4353 15.8817L12.5038 15.5571L12.3178 15.4522L11.2426 15.7667L11.4353 15.8817Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask863_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask863_1782_49644)">
        <path
          d="M11.1412 16.7878L11.104 17.0989L10.8064 17.6703L10.8369 17.3796L11.1412 16.7878Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask864_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask864_1782_49644)">
        <path
          d="M5.33221 10.4514L5.46746 10.9552L5.64329 10.2046L5.51142 9.68386L5.33221 10.4514Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask865_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask865_1782_49644)">
        <path
          d="M6.27558 16.612L6.31277 16.6864L6.07608 16.044L6.02875 15.9324L6.27558 16.612Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask866_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask866_1782_49644)">
        <path
          d="M11.6112 14.3195L12.754 14.0761L12.6864 13.7819L11.5402 14.0152L11.6112 14.3195Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask867_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask867_1782_49644)">
        <path
          d="M10.8842 15.3981L11.9797 15.104L11.8377 14.8707L10.7287 15.1513L10.8842 15.3981Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask868_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask868_1782_49644)">
        <path
          d="M8.09469 18.8098L8.02368 18.6644L7.6416 18.4379L7.68556 18.5664L8.09469 18.8098Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask869_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask869_1782_49644)">
        <path
          d="M15.831 14.3026L16.869 14.0152L16.9535 13.7515L15.9087 14.0457L15.831 14.3026Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask870_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask870_1782_49644)">
        <path
          d="M17.0313 13.1665L17.9206 12.8419L17.9138 12.5275L17.0245 12.8622L17.0313 13.1665Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask871_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask871_1782_49644)">
        <path
          d="M8.40915 5.4776L8.3111 5.83939L8.82504 6.07608L8.87238 5.72443L8.40915 5.4776Z"
          fill="#840000"
        />
      </g>
      <mask
        height="16"
        id="mask872_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask872_1782_49644)">
        <path
          d="M14.6002 14.8876L15.6687 14.6306L15.5334 14.8064L14.4649 15.0668L14.6002 14.8876Z"
          fill="#19FFDE"
        />
      </g>
      <mask
        height="16"
        id="mask873_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask873_1782_49644)">
        <path
          d="M11.5638 13.6095L11.6146 14.0862L11.5266 14.9248L11.4793 14.4582L11.5638 13.6095Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask874_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask874_1782_49644)">
        <path
          d="M11.6348 15.9425L12.6898 15.6179L12.5038 15.5571L11.4353 15.8817L11.6348 15.9425Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask875_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask875_1782_49644)">
        <path
          d="M14.9755 11.5402L15.7633 11.2595L15.6382 11.4962L14.8571 11.7633L14.9755 11.5402Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask876_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask876_1782_49644)">
        <path
          d="M13.8293 12.2367L14.7625 12.0169L14.6982 12.2942L13.7684 12.5072L13.8293 12.2367Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask877_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask877_1782_49644)">
        <path
          d="M6.92816 17.7921L6.93154 17.7075L6.62047 17.224L6.5968 17.2747L6.92816 17.7921Z"
          fill="#004CFF"
        />
      </g>
      <mask
        height="16"
        id="mask878_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask878_1782_49644)">
        <path
          d="M13.2341 15.4894L14.3128 15.1987L14.1471 15.2899L13.0617 15.5807L13.2341 15.4894Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask879_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask879_1782_49644)">
        <path
          d="M10.7287 15.1513L11.8377 14.8707L11.7126 14.6103L10.5968 14.8741L10.7287 15.1513Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask880_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask880_1782_49644)">
        <path
          d="M17.7041 11.6315L18.3635 11.2223L18.2316 10.9586L17.5757 11.3779L17.7041 11.6315Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask881_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask881_1782_49644)">
        <path
          d="M10.1268 7.26628L10.2215 7.59426L10.5968 8.32461L10.4649 8.00001L10.1268 7.26628Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask882_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask882_1782_49644)">
        <path
          d="M16.2705 10.7861L16.869 10.4007L16.6762 10.5292L16.0845 10.8977L16.2705 10.7861Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask883_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask883_1782_49644)">
        <path
          d="M12.6323 13.1733L13.7278 12.9434L13.7481 12.6492L12.6492 12.8757L12.6323 13.1733Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask884_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask884_1782_49644)">
        <path
          d="M5.76841 14.9755L5.83942 15.2088L5.71769 14.4615L5.63992 14.191L5.76841 14.9755Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask885_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask885_1782_49644)">
        <path
          d="M8.51058 18.9417L8.41252 18.7895L8.02368 18.6644L8.09469 18.8098L8.51058 18.9417Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask886_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask886_1782_49644)">
        <path
          d="M11.8343 15.9527L12.8791 15.6247L12.6898 15.6145L11.6348 15.9425L11.8343 15.9527Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask887_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask887_1782_49644)">
        <path
          d="M11.5402 14.0152L12.6864 13.7819L12.6425 13.481L11.4962 13.7008L11.5402 14.0152Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask888_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask888_1782_49644)">
        <path
          d="M9.46747 18.9315L9.32546 18.8774L8.9231 18.9653L9.04144 19.0262L9.46747 18.9315Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask889_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask889_1782_49644)">
        <path
          d="M10.5968 14.8741L11.7126 14.6103L11.6112 14.3195L10.4886 14.5664L10.5968 14.8741Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask890_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask890_1782_49644)">
        <path
          d="M17.5757 11.3779L18.2316 10.9586L18.0761 10.7354L17.4269 11.1547L17.5757 11.3779Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask891_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask891_1782_49644)">
        <path
          d="M8.869 5.72444L8.82504 6.07609L9.32208 6.45479L9.3187 6.11328L8.869 5.72444Z"
          fill="#920000"
        />
      </g>
      <mask
        height="16"
        id="mask892_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask892_1782_49644)">
        <path
          d="M10.8064 17.6703L10.7118 17.8698L10.3736 18.3195L10.4548 18.1369L10.8064 17.6703Z"
          fill="#0038FF"
        />
      </g>
      <mask
        height="16"
        id="mask893_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask893_1782_49644)">
        <path
          d="M16.4666 10.7219L17.0752 10.3229L16.869 10.4007L16.2705 10.7861L16.4666 10.7219Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask894_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask894_1782_49644)">
        <path
          d="M17.0245 12.8622L17.9138 12.5275L17.8732 12.213L16.9873 12.5579L17.0245 12.8622Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask895_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask895_1782_49644)">
        <path
          d="M6.5968 17.2747L6.62047 17.224L6.35335 16.6729L6.31277 16.6864L6.5968 17.2747Z"
          fill="#0074FF"
        />
      </g>
      <mask
        height="16"
        id="mask896_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask896_1782_49644)">
        <path
          d="M15.9087 14.0457L16.9535 13.7515L17.0076 13.4675L15.9594 13.765L15.9087 14.0457Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask897_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask897_1782_49644)">
        <path
          d="M10.2553 16.1116L11.2426 15.7667L11.0566 15.6044L10.0558 15.9358L10.2553 16.1116Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask898_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask898_1782_49644)">
        <path
          d="M10.0558 15.9358L11.0567 15.6044L10.8842 15.3981L9.86984 15.7126L10.0558 15.9358Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask899_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask899_1782_49644)">
        <path
          d="M5.36942 11.7599L5.4979 12.213L5.59258 11.432L5.46747 10.9552L5.36942 11.7599Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask900_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask900_1782_49644)">
        <path
          d="M15.1175 11.3542L15.9155 11.0566L15.7633 11.2595L14.9755 11.5402L15.1175 11.3542Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask901_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask901_1782_49644)">
        <path
          d="M9.74133 6.6306L9.79205 6.96534L10.2215 7.59425L10.1268 7.26627L9.74133 6.6306Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask902_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask902_1782_49644)">
        <path
          d="M14.7152 14.6746L15.7836 14.421L15.6687 14.6306L14.6002 14.8876L14.7152 14.6746Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask903_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask903_1782_49644)">
        <path
          d="M11.432 11.3136L11.4827 11.814L11.5672 12.7202L11.5165 12.2232L11.432 11.3136Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask904_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask904_1782_49644)">
        <path
          d="M10.4683 16.2333L11.4353 15.8817L11.2426 15.7667L10.2553 16.1116L10.4683 16.2333Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask905_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask905_1782_49644)">
        <path
          d="M13.3897 15.3508L14.4649 15.0668L14.3128 15.1987L13.2342 15.4894L13.3897 15.3508Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask906_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask906_1782_49644)">
        <path
          d="M13.9138 11.9899L14.8571 11.7633L14.7625 12.0169L13.8293 12.2367L13.9138 11.9899Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask907_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask907_1782_49644)">
        <path
          d="M9.3187 6.11328L9.32208 6.45479L9.79207 6.96535L9.74135 6.63061L9.3187 6.11328Z"
          fill="#B20000"
        />
      </g>
      <mask
        height="16"
        id="mask908_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask908_1782_49644)">
        <path
          d="M12.0271 15.9053L13.0617 15.5807L12.8791 15.6247L11.8343 15.9527L12.0271 15.9053Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask909_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask909_1782_49644)">
        <path
          d="M9.86984 15.7126L10.8842 15.3981L10.7287 15.1513L9.70416 15.4455L9.86984 15.7126Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask910_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask910_1782_49644)">
        <path
          d="M17.4269 11.1547L18.0761 10.7354L17.8969 10.5495L17.2545 10.9755L17.4269 11.1547Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask911_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask911_1782_49644)">
        <path
          d="M5.65683 8.46662L5.76841 8.99409L6.09639 8.40238L5.98819 7.86476L5.65683 8.46662Z"
          fill="#FF5200"
        />
      </g>
      <mask
        height="16"
        id="mask912_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask912_1782_49644)">
        <path
          d="M16.6695 10.7084L17.2883 10.2992L17.0752 10.3229L16.4666 10.7219L16.6695 10.7084Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask913_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask913_1782_49644)">
        <path
          d="M10.9079 9.13272L11.0127 9.55199L11.2629 10.4176L11.1479 9.99832L10.9079 9.13272Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask914_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask914_1782_49644)">
        <path
          d="M6.02877 15.9324L6.0761 16.044L5.8969 15.361L5.83942 15.2088L6.02877 15.9324Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask915_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask915_1782_49644)">
        <path
          d="M7.6416 18.4379L7.63145 18.2215L7.28995 17.9239L7.27304 18.1167L7.6416 18.4379Z"
          fill="#0028FF"
        />
      </g>
      <mask
        height="16"
        id="mask916_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask916_1782_49644)">
        <path
          d="M10.6813 16.3009L11.6348 15.9425L11.4354 15.8817L10.4683 16.2333L10.6813 16.3009Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask917_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask917_1782_49644)">
        <path
          d="M12.6492 12.8757L13.7481 12.6492L13.7954 12.3686L12.6932 12.5917L12.6492 12.8757Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask918_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask918_1782_49644)">
        <path
          d="M11.5266 14.9248L11.5402 15.3407L11.3779 16.1048L11.361 15.7025L11.5266 14.9248Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask919_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask919_1782_49644)">
        <path
          d="M7.27305 18.1167L7.28996 17.9239L6.97212 17.5452L6.93155 17.7075L7.27305 18.1167Z"
          fill="#0040FF"
        />
      </g>
      <mask
        height="16"
        id="mask920_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask920_1782_49644)">
        <path
          d="M11.4962 13.7008L12.6425 13.481L12.6323 13.1733L11.4793 13.3829L11.4962 13.7008Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask921_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask921_1782_49644)">
        <path
          d="M10.4886 14.5664L11.6112 14.3195L11.5402 14.0152L10.4108 14.2418L10.4886 14.5664Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask922_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask922_1782_49644)">
        <path
          d="M17.2544 10.9721L17.8969 10.5495L17.7042 10.4142L17.0685 10.8369L17.2544 10.9721Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask923_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask923_1782_49644)">
        <path
          d="M16.8724 10.749L17.4979 10.3297L17.2883 10.2992L16.6695 10.7084L16.8724 10.749Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask924_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask924_1782_49644)">
        <path
          d="M8.92307 18.9653L8.80135 18.8098L8.41251 18.7895L8.51056 18.9417L8.92307 18.9653Z"
          fill="#0000FF"
        />
      </g>
      <mask
        height="16"
        id="mask925_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask925_1782_49644)">
        <path
          d="M17.0685 10.8369L17.7042 10.4142L17.4979 10.3297L16.8724 10.7456L17.0685 10.8369Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask926_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask926_1782_49644)">
        <path
          d="M9.70076 15.4455L10.7287 15.1513L10.5968 14.8741L9.55875 15.1446L9.70076 15.4455Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask927_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask927_1782_49644)">
        <path
          d="M8.02369 18.6644L7.98988 18.4311L7.63147 18.2215L7.64161 18.4379L8.02369 18.6644Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask928_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask928_1782_49644)">
        <path
          d="M7.30009 5.81911L7.25613 6.28572L7.77684 6.21133L7.79713 5.75487L7.30009 5.81911Z"
          fill="#890000"
        />
      </g>
      <mask
        height="16"
        id="mask929_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask929_1782_49644)">
        <path
          d="M10.3736 18.3195L10.2316 18.3973L9.86646 18.7219L9.98818 18.6577L10.3736 18.3195Z"
          fill="#0018FF"
        />
      </g>
      <mask
        height="16"
        id="mask930_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask930_1782_49644)">
        <path
          d="M16.9839 12.5579L17.8732 12.213L17.8056 11.9121L16.9163 12.2637L16.9839 12.5579Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask931_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask931_1782_49644)">
        <path
          d="M15.2798 11.2054L16.0846 10.8977L15.9155 11.0566L15.1175 11.3542L15.2798 11.2054Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask932_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask932_1782_49644)">
        <path
          d="M6.93153 17.7075L6.9721 17.5452L6.6847 17.0955L6.62045 17.224L6.93153 17.7075Z"
          fill="#0060FF"
        />
      </g>
      <mask
        height="16"
        id="mask933_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask933_1782_49644)">
        <path
          d="M15.9594 13.765L17.0076 13.4675L17.0313 13.1665L15.9797 13.4708L15.9594 13.765Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask934_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask934_1782_49644)">
        <path
          d="M10.8944 16.3111L11.8343 15.9527L11.6348 15.9425L10.6813 16.3009L10.8944 16.3111Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask935_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask935_1782_49644)">
        <path
          d="M5.4776 13.0245L5.58242 13.3998L5.59932 12.6188L5.49451 12.213L5.4776 13.0245Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask936_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask936_1782_49644)">
        <path
          d="M12.2097 15.8073L13.2342 15.4894L13.0617 15.5807L12.0271 15.9053L12.2097 15.8073Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask937_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask937_1782_49644)">
        <path
          d="M9.41 16.4768L10.2553 16.1116L10.0558 15.9358L9.19022 16.284L9.41 16.4768Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask938_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask938_1782_49644)">
        <path
          d="M14.8064 14.4277L15.8715 14.1775L15.7836 14.421L14.7151 14.6746L14.8064 14.4277Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask939_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask939_1782_49644)">
        <path
          d="M13.5317 15.1648L14.6002 14.8876L14.4649 15.0668L13.3897 15.3508L13.5317 15.1648Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask940_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask940_1782_49644)">
        <path
          d="M9.19021 16.284L10.0558 15.9358L9.86984 15.7126L8.98734 16.0406L9.19021 16.284Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask941_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask941_1782_49644)">
        <path
          d="M6.30939 16.6864L6.35334 16.6729L6.13356 16.071L6.07608 16.044L6.30939 16.6864Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask942_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask942_1782_49644)">
        <path
          d="M14.0287 11.7735L14.9755 11.5402L14.8572 11.7633L13.9138 11.9899L14.0287 11.7735Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask943_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask943_1782_49644)">
        <path
          d="M6.31955 6.86729L6.38041 7.37447L6.82673 7.01606L6.76587 6.50549L6.31955 6.86729Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask944_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask944_1782_49644)">
        <path
          d="M9.6399 16.6086L10.4683 16.2333L10.2553 16.1116L9.40997 16.4768L9.6399 16.6086Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask945_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask945_1782_49644)">
        <path
          d="M9.55877 15.1446L10.5968 14.8741L10.4886 14.5664L9.44043 14.8166L9.55877 15.1446Z"
          fill="#23FFD4"
        />
      </g>
      <mask
        height="16"
        id="mask946_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask946_1782_49644)">
        <path
          d="M8.41252 18.7895L8.35166 18.5461L7.98987 18.4311L8.02368 18.661L8.41252 18.7895Z"
          fill="#0010FF"
        />
      </g>
      <mask
        height="16"
        id="mask947_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask947_1782_49644)">
        <path
          d="M8.98734 16.0406L9.86984 15.7126L9.70078 15.4455L8.80475 15.7532L8.98734 16.0406Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask948_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask948_1782_49644)">
        <path
          d="M10.4108 14.2418L11.5402 14.0152L11.4962 13.7008L10.3601 13.907L10.4108 14.2418Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask949_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask949_1782_49644)">
        <path
          d="M9.86644 18.7219L9.70414 18.6779L9.32544 18.8774L9.46745 18.9315L9.86644 18.7219Z"
          fill="#0008FF"
        />
      </g>
      <mask
        height="16"
        id="mask950_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask950_1782_49644)">
        <path
          d="M11.4793 13.3829L12.6323 13.1733L12.6492 12.8757L11.4962 13.0719L11.4793 13.3829Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask951_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask951_1782_49644)">
        <path
          d="M12.6932 12.5917L13.7955 12.3686L13.8698 12.1082L12.7676 12.3246L12.6932 12.5917Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask952_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask952_1782_49644)">
        <path
          d="M16.9163 12.2637L17.8056 11.9121L17.7042 11.6315L16.8217 11.9865L16.9163 12.2637Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask953_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask953_1782_49644)">
        <path
          d="M9.87323 16.683L10.6813 16.3009L10.4683 16.2333L9.63992 16.6086L9.87323 16.683Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask954_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask954_1782_49644)">
        <path
          d="M15.4556 11.1074L16.2705 10.7861L16.0845 10.8977L15.2798 11.2054L15.4556 11.1074Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask955_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask955_1782_49644)">
        <path
          d="M11.3779 16.1048L11.3339 16.4362L11.104 17.0989L11.1412 16.7878L11.3779 16.1048Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask956_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask956_1782_49644)">
        <path
          d="M11.1006 16.2604L12.0271 15.9053L11.8343 15.9527L10.8943 16.3111L11.1006 16.2604Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask957_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask957_1782_49644)">
        <path
          d="M7.63145 18.2215L7.70246 17.9375L7.388 17.6636L7.28995 17.9239L7.63145 18.2215Z"
          fill="#0040FF"
        />
      </g>
      <mask
        height="16"
        id="mask958_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask958_1782_49644)">
        <path
          d="M6.62048 17.224L6.68472 17.0955L6.43789 16.5816L6.35336 16.6729L6.62048 17.224Z"
          fill="#0088FF"
        />
      </g>
      <mask
        height="16"
        id="mask959_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask959_1782_49644)">
        <path
          d="M5.63991 14.191L5.71768 14.4615L5.66358 13.7075L5.58243 13.3998L5.63991 14.191Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask960_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask960_1782_49644)">
        <path
          d="M7.28994 17.9239L7.388 17.6636L7.09383 17.3119L6.97211 17.5452L7.28994 17.9239Z"
          fill="#0058FF"
        />
      </g>
      <mask
        height="16"
        id="mask961_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask961_1782_49644)">
        <path
          d="M5.51141 9.68386L5.64328 10.2046L5.89687 9.5317L5.76839 8.99409L5.51141 9.68386Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask962_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask962_1782_49644)">
        <path
          d="M8.80473 15.7532L9.70414 15.4455L9.55875 15.1446L8.64581 15.4252L8.80473 15.7532Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask963_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask963_1782_49644)">
        <path
          d="M8.72698 16.8555L9.40999 16.4768L9.19021 16.284L8.48691 16.6458L8.72698 16.8555Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask964_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask964_1782_49644)">
        <path
          d="M12.3753 15.6619L13.3897 15.3508L13.2342 15.4894L12.2097 15.8107L12.3753 15.6619Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask965_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask965_1782_49644)">
        <path
          d="M8.48689 16.6458L9.19019 16.284L8.98732 16.0406L8.26373 16.3787L8.48689 16.6458Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask966_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask966_1782_49644)">
        <path
          d="M11.5672 12.7202L11.6179 13.2071L11.6146 14.0828L11.5638 13.6095L11.5672 12.7202Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask967_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask967_1782_49644)">
        <path
          d="M7.98988 18.4311L8.03046 18.1302L7.70248 17.9375L7.63147 18.2215L7.98988 18.4311Z"
          fill="#0034FF"
        />
      </g>
      <mask
        height="16"
        id="mask968_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask968_1782_49644)">
        <path
          d="M15.9797 13.4708L17.0313 13.1665L17.0245 12.8622L15.973 13.1665L15.9797 13.4708Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask969_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask969_1782_49644)">
        <path
          d="M9.32545 18.8774L9.17668 18.7253L8.80136 18.8098L8.92309 18.9653L9.32545 18.8774Z"
          fill="#0008FF"
        />
      </g>
      <mask
        height="16"
        id="mask970_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask970_1782_49644)">
        <path
          d="M9.44043 14.8166L10.4886 14.5664L10.4108 14.2418L9.3559 14.4649L9.44043 14.8166Z"
          fill="#39FFBE"
        />
      </g>
      <mask
        height="16"
        id="mask971_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask971_1782_49644)">
        <path
          d="M10.5968 8.32461L10.6881 8.74388L11.0127 9.552L10.9079 9.13272L10.5968 8.32461Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask972_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask972_1782_49644)">
        <path
          d="M8.9772 17.0009L9.63992 16.6086L9.41 16.4768L8.72699 16.8555L8.9772 17.0009Z"
          fill="#0094FF"
        />
      </g>
      <mask
        height="16"
        id="mask973_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask973_1782_49644)">
        <path
          d="M14.1606 11.5909L15.1175 11.3542L14.9755 11.5402L14.0287 11.7735L14.1606 11.5909Z"
          fill="#E1FF16"
        />
      </g>
      <mask
        height="16"
        id="mask974_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask974_1782_49644)">
        <path
          d="M13.6467 14.9451L14.7151 14.6746L14.6002 14.8876L13.5317 15.1682L13.6467 14.9451Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask975_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask975_1782_49644)">
        <path
          d="M14.8673 14.1539L15.9358 13.907L15.8715 14.1775L14.8064 14.4277L14.8673 14.1539Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask976_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask976_1782_49644)">
        <path
          d="M6.97213 17.5452L7.09385 17.3119L6.83012 16.896L6.68472 17.0955L6.97213 17.5452Z"
          fill="#0074FF"
        />
      </g>
      <mask
        height="16"
        id="mask977_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask977_1782_49644)">
        <path
          d="M10.1031 16.6932L10.8943 16.3111L10.6813 16.3009L9.8732 16.683L10.1031 16.6932Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask978_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask978_1782_49644)">
        <path
          d="M16.8216 11.9865L17.7041 11.6315L17.5757 11.3779L16.6999 11.7363L16.8216 11.9865Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask979_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask979_1782_49644)">
        <path
          d="M8.26373 16.3787L8.98732 16.0406L8.80473 15.7532L8.06424 16.0609L8.26373 16.3787Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask980_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask980_1782_49644)">
        <path
          d="M15.6416 11.0533L16.4666 10.7219L16.2705 10.7861L15.4556 11.1074L15.6416 11.0533Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask981_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask981_1782_49644)">
        <path
          d="M5.83941 15.2088L5.89689 15.361L5.78193 14.6543L5.71768 14.4615L5.83941 15.2088Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask982_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask982_1782_49644)">
        <path
          d="M8.80136 18.8098L8.71345 18.5664L8.35165 18.5495L8.41252 18.7895L8.80136 18.8098Z"
          fill="#0014FF"
        />
      </g>
      <mask
        height="16"
        id="mask983_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask983_1782_49644)">
        <path
          d="M10.3601 13.907L11.4962 13.7008L11.4793 13.3829L10.3432 13.5689L10.3601 13.907Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask984_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask984_1782_49644)">
        <path
          d="M7.79714 5.75487L7.77686 6.21133L8.31109 6.2891V5.8394L7.79714 5.75487Z"
          fill="#800000"
        />
      </g>
      <mask
        height="16"
        id="mask985_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask985_1782_49644)">
        <path
          d="M7.70246 17.9375L7.88842 17.6027L7.60102 17.3491L7.388 17.6636L7.70246 17.9375Z"
          fill="#005CFF"
        />
      </g>
      <mask
        height="16"
        id="mask986_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask986_1782_49644)">
        <path
          d="M8.22318 17.2375L8.72698 16.8555L8.48691 16.6458L7.95944 17.0076L8.22318 17.2375Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask987_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask987_1782_49644)">
        <path
          d="M7.38801 17.6636L7.60103 17.3491L7.33053 17.0313L7.09384 17.3119L7.38801 17.6636Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask988_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask988_1782_49644)">
        <path
          d="M8.64582 15.4252L9.55875 15.1446L9.44041 14.8166L8.51733 15.0634L8.64582 15.4252Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask989_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask989_1782_49644)">
        <path
          d="M7.95945 17.0076L8.48692 16.6458L8.26376 16.3787L7.71262 16.7134L7.95945 17.0076Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask990_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask990_1782_49644)">
        <path
          d="M9.23415 17.082L9.8732 16.683L9.63989 16.6086L8.97717 17.0009L9.23415 17.082Z"
          fill="#0094FF"
        />
      </g>
      <mask
        height="16"
        id="mask991_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask991_1782_49644)">
        <path
          d="M8.35166 18.5495L8.36519 18.2384L8.03044 18.1302L7.98987 18.4311L8.35166 18.5495Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask992_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask992_1782_49644)">
        <path
          d="M11.2629 10.4176L11.317 10.9214L11.4827 11.814L11.432 11.3136L11.2629 10.4176Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask993_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask993_1782_49644)">
        <path
          d="M11.2967 16.1555L12.2097 15.8107L12.0271 15.9053L11.1006 16.2604L11.2967 16.1555Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask994_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask994_1782_49644)">
        <path
          d="M11.104 17.0989L10.9958 17.3221L10.7118 17.8698L10.8064 17.6703L11.104 17.0989Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask995_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask995_1782_49644)">
        <path
          d="M11.4962 13.0719L12.6492 12.8757L12.6932 12.5917L11.5436 12.7743L11.4962 13.0719Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask996_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask996_1782_49644)">
        <path
          d="M12.7675 12.3246L13.8698 12.1082L13.9679 11.8749L12.869 12.0913L12.7675 12.3246Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask997_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask997_1782_49644)">
        <path
          d="M7.88842 17.6027L8.22316 17.2342L7.95942 17.0042L7.60101 17.3491L7.88842 17.6027Z"
          fill="#0074FF"
        />
      </g>
      <mask
        height="16"
        id="mask998_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask998_1782_49644)">
        <path
          d="M8.49705 17.3965L8.97719 17.0009L8.72698 16.8555L8.22318 17.2342L8.49705 17.3965Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask999_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask999_1782_49644)">
        <path
          d="M8.03043 18.1302L8.18935 17.7785L7.88842 17.6027L7.70245 17.9375L8.03043 18.1302Z"
          fill="#004CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1000_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1000_1782_49644)">
        <path
          d="M6.07609 16.044L6.13357 16.071L5.96789 15.432L5.89688 15.361L6.07609 16.044Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask1001_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1001_1782_49644)">
        <path
          d="M16.6999 11.7363L17.5757 11.3779L17.4269 11.1547L16.5545 11.5131L16.6999 11.7363Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask1002_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1002_1782_49644)">
        <path
          d="M7.60103 17.3491L7.95945 17.0042L7.716 16.7134L7.33054 17.0313L7.60103 17.3491Z"
          fill="#0084FF"
        />
      </g>
      <mask
        height="16"
        id="mask1003_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1003_1782_49644)">
        <path
          d="M15.8343 11.0499L16.6695 10.7084L16.4666 10.7219L15.6416 11.0533L15.8343 11.0499Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1004_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1004_1782_49644)">
        <path
          d="M8.06426 16.0609L8.80475 15.7532L8.64583 15.4252L7.89182 15.7025L8.06426 16.0609Z"
          fill="#00E0FB"
        />
      </g>
      <mask
        height="16"
        id="mask1005_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1005_1782_49644)">
        <path
          d="M7.71261 16.7134L8.26375 16.3787L8.06426 16.0609L7.49283 16.3652L7.71261 16.7134Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1006_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1006_1782_49644)">
        <path
          d="M7.09385 17.3119L7.33053 17.0313L7.08708 16.6492L6.83011 16.896L7.09385 17.3119Z"
          fill="#008CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1007_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1007_1782_49644)">
        <path
          d="M6.35336 16.6729L6.43789 16.5816L6.23163 16.0237L6.13358 16.071L6.35336 16.6729Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1008_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1008_1782_49644)">
        <path
          d="M8.18936 17.7785L8.49705 17.3965L8.22317 17.2342L7.88843 17.6027L8.18936 17.7785Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask1009_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1009_1782_49644)">
        <path
          d="M15.973 13.1665L17.0245 12.8622L16.9873 12.5579L15.9358 12.8622L15.973 13.1665Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask1010_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1010_1782_49644)">
        <path
          d="M6.6847 17.0955L6.83009 16.896L6.60017 16.426L6.43787 16.5816L6.6847 17.0955Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1011_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1011_1782_49644)">
        <path
          d="M12.5207 15.4658L13.5317 15.1682L13.3897 15.3508L12.3753 15.6619L12.5207 15.4658Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask1012_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1012_1782_49644)">
        <path
          d="M10.3297 16.6424L11.1006 16.2604L10.8944 16.3111L10.1031 16.6932L10.3297 16.6424Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1013_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1013_1782_49644)">
        <path
          d="M5.46747 10.9552L5.59257 11.432L5.76502 10.7084L5.64329 10.2046L5.46747 10.9552Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1014_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1014_1782_49644)">
        <path
          d="M9.35589 14.4649L10.4108 14.2418L10.3601 13.907L9.30179 14.1031L9.35589 14.4649Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask1015_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1015_1782_49644)">
        <path
          d="M14.3128 11.4556L15.2798 11.2088L15.1175 11.3542L14.1606 11.5943L14.3128 11.4556Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask1016_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1016_1782_49644)">
        <path
          d="M16.5545 11.5131L17.4269 11.1547L17.2545 10.9755L16.3922 11.3305L16.5545 11.5131Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask1017_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1017_1782_49644)">
        <path
          d="M7.33052 17.0313L7.71259 16.7134L7.49281 16.3652L7.08707 16.6492L7.33052 17.0313Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1018_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1018_1782_49644)">
        <path
          d="M16.0271 11.0972L16.8724 10.7489L16.6695 10.7084L15.8343 11.0499L16.0271 11.0972Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1019_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1019_1782_49644)">
        <path
          d="M8.77768 17.4878L9.23415 17.082L8.97717 17.0009L8.49704 17.3965L8.77768 17.4878Z"
          fill="#007CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1020_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1020_1782_49644)">
        <path
          d="M9.48775 17.0955L10.1031 16.6932L9.87321 16.683L9.23416 17.082L9.48775 17.0955Z"
          fill="#0094FF"
        />
      </g>
      <mask
        height="16"
        id="mask1021_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1021_1782_49644)">
        <path
          d="M10.7118 17.8698L10.5562 17.9713L10.2316 18.3973L10.3736 18.3195L10.7118 17.8698Z"
          fill="#0044FF"
        />
      </g>
      <mask
        height="16"
        id="mask1022_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1022_1782_49644)">
        <path
          d="M5.98819 7.86476L6.09639 8.40238L6.48523 7.92224L6.38041 7.37448L5.98819 7.86476Z"
          fill="#FF1E00"
        />
      </g>
      <mask
        height="16"
        id="mask1023_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1023_1782_49644)">
        <path
          d="M14.9045 13.8597L15.973 13.6196L15.9358 13.907L14.8673 14.1538L14.9045 13.8597Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask1024_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1024_1782_49644)">
        <path
          d="M8.3652 18.2384L8.49707 17.8766L8.18937 17.7785L8.03046 18.1302L8.3652 18.2384Z"
          fill="#0048FF"
        />
      </g>
      <mask
        height="16"
        id="mask1025_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1025_1782_49644)">
        <path
          d="M16.3922 11.3305L17.2545 10.9755L17.0685 10.8369L16.213 11.1919L16.3922 11.3305Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1026_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1026_1782_49644)">
        <path
          d="M13.7413 14.6881L14.8064 14.4277L14.7151 14.6746L13.6467 14.9451L13.7413 14.6881Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask1027_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1027_1782_49644)">
        <path
          d="M16.213 11.1919L17.0685 10.8369L16.8724 10.7489L16.0271 11.0972L16.213 11.1919Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1028_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1028_1782_49644)">
        <path
          d="M8.51734 15.0634L9.44042 14.8166L9.35589 14.4649L8.42267 14.6847L8.51734 15.0634Z"
          fill="#36FFC1"
        />
      </g>
      <mask
        height="16"
        id="mask1029_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1029_1782_49644)">
        <path
          d="M8.49705 17.8766L8.7777 17.4877L8.49705 17.3965L8.18936 17.7785L8.49705 17.8766Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask1030_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1030_1782_49644)">
        <path
          d="M7.49282 16.3652L8.06424 16.0609L7.8918 15.7025L7.30347 15.9696L7.49282 16.3652Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1031_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1031_1782_49644)">
        <path
          d="M10.2215 7.59425L10.2992 8.01691L10.6881 8.74387L10.5968 8.3246L10.2215 7.59425Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask1032_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1032_1782_49644)">
        <path
          d="M10.3432 13.5689L11.4793 13.3829L11.4962 13.0719L10.3601 13.2409L10.3432 13.5689Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask1033_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1033_1782_49644)">
        <path
          d="M8.71345 18.5664L8.69654 18.2553L8.36518 18.2384L8.35165 18.5461L8.71345 18.5664Z"
          fill="#0030FF"
        />
      </g>
      <mask
        height="16"
        id="mask1034_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1034_1782_49644)">
        <path
          d="M7.8918 15.7025L8.64581 15.4252L8.51733 15.0634L7.74979 15.3069L7.8918 15.7025Z"
          fill="#13FCE4"
        />
      </g>
      <mask
        height="16"
        id="mask1035_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1035_1782_49644)">
        <path
          d="M11.6146 14.0828L11.6247 14.5224L11.5402 15.3407L11.5266 14.9248L11.6146 14.0828Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask1036_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1036_1782_49644)">
        <path
          d="M6.83011 16.896L7.08709 16.6492L6.87745 16.2164L6.60019 16.426L6.83011 16.896Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask1037_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1037_1782_49644)">
        <path
          d="M10.2316 18.3973L10.049 18.3736L9.70416 18.6779L9.86646 18.7219L10.2316 18.3973Z"
          fill="#002CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1038_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1038_1782_49644)">
        <path
          d="M6.76587 6.5055L6.82673 7.01607L7.31701 6.79629L7.25615 6.28572L6.76587 6.5055Z"
          fill="#A80000"
        />
      </g>
      <mask
        height="16"
        id="mask1039_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1039_1782_49644)">
        <path
          d="M11.4759 15.9966L12.3753 15.6619L12.2097 15.8107L11.2967 16.1555L11.4759 15.9966Z"
          fill="#00DCFE"
        />
      </g>
      <mask
        height="16"
        id="mask1040_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1040_1782_49644)">
        <path
          d="M8.3111 5.8394V6.28911L8.84195 6.51565L8.82504 6.07609L8.3111 5.8394Z"
          fill="#800000"
        />
      </g>
      <mask
        height="16"
        id="mask1041_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1041_1782_49644)">
        <path
          d="M9.17667 18.7287L9.06171 18.4886L8.71344 18.5664L8.80135 18.8098L9.17667 18.7287Z"
          fill="#0020FF"
        />
      </g>
      <mask
        height="16"
        id="mask1042_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1042_1782_49644)">
        <path
          d="M12.869 12.0913L13.9679 11.8749L14.093 11.6788L12.9907 11.8884L12.869 12.0913Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1043_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1043_1782_49644)">
        <path
          d="M7.08708 16.6492L7.49283 16.3652L7.30348 15.9696L6.87744 16.2164L7.08708 16.6492Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1044_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1044_1782_49644)">
        <path
          d="M9.70414 18.6779L9.5317 18.5393L9.17667 18.7253L9.32544 18.8774L9.70414 18.6779Z"
          fill="#0020FF"
        />
      </g>
      <mask
        height="16"
        id="mask1045_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1045_1782_49644)">
        <path
          d="M11.5435 12.7743L12.6932 12.5883L12.7676 12.3246L11.6179 12.5004L11.5435 12.7743Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask1046_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1046_1782_49644)">
        <path
          d="M15.9358 12.8622L16.9839 12.5579L16.9163 12.2637L15.8681 12.5681L15.9358 12.8622Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask1047_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1047_1782_49644)">
        <path
          d="M5.49789 12.213L5.59933 12.6188L5.69401 11.8681L5.59257 11.432L5.49789 12.213Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask1048_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1048_1782_49644)">
        <path
          d="M9.05494 17.5013L9.48774 17.0955L9.23415 17.082L8.77768 17.4877L9.05494 17.5013Z"
          fill="#007CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1049_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1049_1782_49644)">
        <path
          d="M14.4785 11.361L15.4556 11.1074L15.2798 11.2054L14.3128 11.4556L14.4785 11.361Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1050_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1050_1782_49644)">
        <path
          d="M10.5393 16.5275L11.2967 16.1555L11.1006 16.2604L10.3297 16.6424L10.5393 16.5275Z"
          fill="#00BCFF"
        />
      </g>
      <mask
        height="16"
        id="mask1051_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1051_1782_49644)">
        <path
          d="M6.43788 16.5816L6.60018 16.426L6.41083 15.9087L6.23163 16.0237L6.43788 16.5816Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1052_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1052_1782_49644)">
        <path
          d="M9.73459 17.038L10.3297 16.6424L10.1031 16.6932L9.48776 17.0955L9.73459 17.038Z"
          fill="#009CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1053_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1053_1782_49644)">
        <path
          d="M9.30178 14.1031L10.3601 13.907L10.3432 13.5689L9.28149 13.7413L9.30178 14.1031Z"
          fill="#6DFF8A"
        />
      </g>
      <mask
        height="16"
        id="mask1054_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1054_1782_49644)">
        <path
          d="M8.69653 18.2553L8.80135 17.8935L8.49704 17.8766L8.36517 18.2384L8.69653 18.2553Z"
          fill="#004CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1055_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1055_1782_49644)">
        <path
          d="M12.6458 15.2291L13.6467 14.9451L13.5317 15.1682L12.5207 15.4658L12.6458 15.2291Z"
          fill="#23FFD4"
        />
      </g>
      <mask
        height="16"
        id="mask1056_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1056_1782_49644)">
        <path
          d="M8.80135 17.8935L9.05494 17.5013L8.77768 17.4877L8.49704 17.8766L8.80135 17.8935Z"
          fill="#0064FF"
        />
      </g>
      <mask
        height="16"
        id="mask1057_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1057_1782_49644)">
        <path
          d="M7.30348 15.9696L7.89181 15.7025L7.7498 15.3069L7.14456 15.5368L7.30348 15.9696Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask1058_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1058_1782_49644)">
        <path
          d="M9.79205 6.96535L9.85292 7.39476L10.2992 8.01691L10.2215 7.59426L9.79205 6.96535Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask1059_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1059_1782_49644)">
        <path
          d="M14.9079 13.5554L15.9797 13.3187L15.973 13.6196L14.9045 13.8597L14.9079 13.5554Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask1060_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1060_1782_49644)">
        <path
          d="M6.13358 16.071L6.23163 16.0237L6.07948 15.4286L5.9679 15.432L6.13358 16.071Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask1061_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1061_1782_49644)">
        <path
          d="M5.58243 13.3998L5.66358 13.7042L5.68048 12.9637L5.60272 12.6188L5.58243 13.3998Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask1062_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1062_1782_49644)">
        <path
          d="M8.82504 6.07608L8.84195 6.51564L9.36266 6.88758L9.32208 6.45478L8.82504 6.07608Z"
          fill="#920000"
        />
      </g>
      <mask
        height="16"
        id="mask1063_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1063_1782_49644)">
        <path
          d="M13.8056 14.4007L14.8673 14.1539L14.8064 14.4277L13.7413 14.6881L13.8056 14.4007Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask1064_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1064_1782_49644)">
        <path
          d="M8.42268 14.6847L9.3559 14.4649L9.3018 14.1031L8.36182 14.2891L8.42268 14.6847Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask1065_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1065_1782_49644)">
        <path
          d="M7.7498 15.3069L8.51734 15.0634L8.42267 14.6847L7.6416 14.891L7.7498 15.3069Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask1066_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1066_1782_49644)">
        <path
          d="M15.8681 12.5681L16.9163 12.2637L16.8217 11.9865L15.7769 12.2908L15.8681 12.5681Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask1067_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1067_1782_49644)">
        <path
          d="M5.89688 15.361L5.96789 15.432L5.85969 14.7692L5.78192 14.6543L5.89688 15.361Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask1068_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1068_1782_49644)">
        <path
          d="M6.60018 16.426L6.87744 16.2164L6.705 15.743L6.41083 15.9087L6.60018 16.426Z"
          fill="#00D4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1069_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1069_1782_49644)">
        <path
          d="M5.71767 14.4615L5.78192 14.6543L5.7312 13.9408L5.66357 13.7075L5.71767 14.4615Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask1070_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1070_1782_49644)">
        <path
          d="M9.32208 6.45479L9.36266 6.88759L9.85294 7.39477L9.79207 6.96535L9.32208 6.45479Z"
          fill="#B20000"
        />
      </g>
      <mask
        height="16"
        id="mask1071_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1071_1782_49644)">
        <path
          d="M6.87743 16.2164L7.30347 15.9696L7.14455 15.5368L6.70499 15.743L6.87743 16.2164Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask1072_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1072_1782_49644)">
        <path
          d="M14.6543 11.317L15.6416 11.0533L15.4556 11.1074L14.4785 11.361L14.6543 11.317Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1073_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1073_1782_49644)">
        <path
          d="M10.3601 13.2409L11.4962 13.0719L11.5435 12.7743L10.4074 12.9265L10.3601 13.2409Z"
          fill="#9DFF5A"
        />
      </g>
      <mask
        height="16"
        id="mask1074_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1074_1782_49644)">
        <path
          d="M12.9907 11.8884L14.093 11.6788L14.235 11.5165L13.1361 11.7261L12.9907 11.8884Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask1075_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1075_1782_49644)">
        <path
          d="M11.6315 15.787L12.5207 15.4658L12.3753 15.6619L11.4759 15.9966L11.6315 15.787Z"
          fill="#09F0EE"
        />
      </g>
      <mask
        height="16"
        id="mask1076_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1076_1782_49644)">
        <path
          d="M11.5401 15.3407L11.4928 15.6957L11.3339 16.4362L11.3778 16.1048L11.5401 15.3407Z"
          fill="#06ECF1"
        />
      </g>
      <mask
        height="16"
        id="mask1077_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1077_1782_49644)">
        <path
          d="M9.06171 18.4886L9.01775 18.1843L8.69653 18.2553L8.71344 18.5664L9.06171 18.4886Z"
          fill="#003CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1078_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1078_1782_49644)">
        <path
          d="M9.32546 17.4404L9.73459 17.038L9.48776 17.0955L9.05496 17.5013L9.32546 17.4404Z"
          fill="#0088FF"
        />
      </g>
      <mask
        height="16"
        id="mask1079_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1079_1782_49644)">
        <path
          d="M11.4827 11.814L11.5334 12.3145L11.6179 13.2071L11.5672 12.7202L11.4827 11.814Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask1080_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1080_1782_49644)">
        <path
          d="M11.0127 9.55199L11.0668 10.0592L11.317 10.9214L11.2629 10.4176L11.0127 9.55199Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask1081_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1081_1782_49644)">
        <path
          d="M11.6179 12.5004L12.7675 12.3246L12.869 12.0913L11.7194 12.2536L11.6179 12.5004Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask1082_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1082_1782_49644)">
        <path
          d="M5.7684 8.99409L5.89689 9.53171L6.21811 8.95352L6.09638 8.40237L5.7684 8.99409Z"
          fill="#FF5900"
        />
      </g>
      <mask
        height="16"
        id="mask1083_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1083_1782_49644)">
        <path
          d="M10.732 16.355L11.4759 15.9966L11.2967 16.1555L10.5393 16.5275L10.732 16.355Z"
          fill="#00D0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1084_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1084_1782_49644)">
        <path
          d="M15.7769 12.2908L16.8217 11.9865L16.6999 11.7363L15.6619 12.0372L15.7769 12.2908Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1085_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1085_1782_49644)">
        <path
          d="M9.96451 16.9163L10.5393 16.5275L10.3297 16.6424L9.73459 17.038L9.96451 16.9163Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask1086_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1086_1782_49644)">
        <path
          d="M14.8369 11.3204L15.8343 11.0499L15.6416 11.0533L14.6577 11.317L14.8369 11.3204Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1087_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1087_1782_49644)">
        <path
          d="M9.09553 17.8259L9.32545 17.4404L9.05495 17.5013L8.80136 17.8935L9.09553 17.8259Z"
          fill="#0070FF"
        />
      </g>
      <mask
        height="16"
        id="mask1088_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1088_1782_49644)">
        <path
          d="M9.01775 18.1843L9.09552 17.8259L8.80135 17.8935L8.69653 18.2553L9.01775 18.1843Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask1089_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1089_1782_49644)">
        <path
          d="M9.28149 13.7413L10.3432 13.5689L10.3601 13.2409L9.29502 13.3829L9.28149 13.7413Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask1090_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1090_1782_49644)">
        <path
          d="M14.8842 13.2477L15.9561 13.0144L15.9797 13.3187L14.9079 13.5554L14.8842 13.2477Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask1091_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1091_1782_49644)">
        <path
          d="M7.14456 15.5368L7.74981 15.3069L7.64161 14.891L7.0296 15.0803L7.14456 15.5368Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask1092_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1092_1782_49644)">
        <path
          d="M12.7405 14.9586L13.7413 14.6881L13.6467 14.9451L12.6458 15.2291L12.7405 14.9586Z"
          fill="#39FFBE"
        />
      </g>
      <mask
        height="16"
        id="mask1093_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1093_1782_49644)">
        <path
          d="M9.5317 18.5393L9.38969 18.3162L9.06171 18.4886L9.17667 18.7253L9.5317 18.5393Z"
          fill="#0038FF"
        />
      </g>
      <mask
        height="16"
        id="mask1094_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1094_1782_49644)">
        <path
          d="M6.23162 16.0237L6.41083 15.9087L6.26882 15.361L6.07947 15.4286L6.23162 16.0237Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask1095_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1095_1782_49644)">
        <path
          d="M13.8394 14.0964L14.9045 13.8597L14.8707 14.1539L13.8056 14.4041L13.8394 14.0964Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask1096_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1096_1782_49644)">
        <path
          d="M11.3339 16.4362L11.2189 16.683L10.9958 17.3221L11.104 17.0989L11.3339 16.4362Z"
          fill="#00B0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1097_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1097_1782_49644)">
        <path
          d="M15.6619 12.0372L16.6999 11.7363L16.5545 11.5131L15.5233 11.8107L15.6619 12.0372Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask1098_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1098_1782_49644)">
        <path
          d="M8.3618 14.2891L9.30179 14.1031L9.2815 13.7413L8.33813 13.8935L8.3618 14.2891Z"
          fill="#6AFF8D"
        />
      </g>
      <mask
        height="16"
        id="mask1099_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1099_1782_49644)">
        <path
          d="M15.0194 11.3745L16.027 11.0972L15.8343 11.0499L14.8369 11.3204L15.0194 11.3745Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1100_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1100_1782_49644)">
        <path
          d="M7.6416 14.891L8.42266 14.6847L8.3618 14.2891L7.57397 14.4582L7.6416 14.891Z"
          fill="#49FFAD"
        />
      </g>
      <mask
        height="16"
        id="mask1101_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1101_1782_49644)">
        <path
          d="M13.1361 11.7261L14.235 11.5165L14.3939 11.4015L13.295 11.6078L13.1361 11.7261Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask1102_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1102_1782_49644)">
        <path
          d="M7.25613 6.28572L7.317 6.79629L7.83433 6.72528L7.77684 6.21133L7.25613 6.28572Z"
          fill="#8D0000"
        />
      </g>
      <mask
        height="16"
        id="mask1103_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1103_1782_49644)">
        <path
          d="M10.049 18.3736L9.85292 18.2553L9.53171 18.5393L9.70415 18.6813L10.049 18.3736Z"
          fill="#0040FF"
        />
      </g>
      <mask
        height="16"
        id="mask1104_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1104_1782_49644)">
        <path
          d="M6.70499 15.743L7.14455 15.5368L7.02959 15.0803L6.57651 15.2392L6.70499 15.743Z"
          fill="#1FFFD7"
        />
      </g>
      <mask
        height="16"
        id="mask1105_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1105_1782_49644)">
        <path
          d="M6.41084 15.9087L6.70501 15.743L6.57652 15.2392L6.26883 15.361L6.41084 15.9087Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask1106_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1106_1782_49644)">
        <path
          d="M15.5232 11.8107L16.5545 11.5131L16.3922 11.3305L15.3677 11.6247L15.5232 11.8107Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask1107_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1107_1782_49644)">
        <path
          d="M10.4074 12.9265L11.5435 12.7743L11.6179 12.5004L10.4852 12.6323L10.4074 12.9265Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask1108_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1108_1782_49644)">
        <path
          d="M15.1986 11.4759L16.213 11.1919L16.027 11.0972L15.0194 11.3745L15.1986 11.4759Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1109_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1109_1782_49644)">
        <path
          d="M10.9958 17.3221L10.8267 17.4472L10.5562 17.9713L10.7117 17.8698L10.9958 17.3221Z"
          fill="#007CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1110_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1110_1782_49644)">
        <path
          d="M15.3677 11.6247L16.3922 11.3305L16.213 11.1919L15.1987 11.4759L15.3677 11.6247Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1111_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1111_1782_49644)">
        <path
          d="M11.7633 15.5334L12.6458 15.2291L12.5207 15.4658L11.6315 15.787L11.7633 15.5334Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask1112_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1112_1782_49644)">
        <path
          d="M10.5562 17.9713H10.3534L10.049 18.3736L10.2316 18.3973L10.5562 17.9713Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask1113_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1113_1782_49644)">
        <path
          d="M6.3804 7.37448L6.48522 7.92224L6.92816 7.56721L6.82672 7.01607L6.3804 7.37448Z"
          fill="#D60000"
        />
      </g>
      <mask
        height="16"
        id="mask1114_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1114_1782_49644)">
        <path
          d="M9.57903 17.3018L9.96449 16.9129L9.73457 17.038L9.32544 17.4404L9.57903 17.3018Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask1115_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1115_1782_49644)">
        <path
          d="M11.7194 12.2536L12.869 12.0913L12.9907 11.8884L11.8478 12.044L11.7194 12.2536Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1116_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1116_1782_49644)">
        <path
          d="M5.64328 10.2046L5.765 10.7084L6.00845 10.0558L5.89687 9.53171L5.64328 10.2046Z"
          fill="#FF9C00"
        />
      </g>
      <mask
        height="16"
        id="mask1117_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1117_1782_49644)">
        <path
          d="M5.96788 15.432L6.07946 15.4286L5.97802 14.8132L5.85968 14.7692L5.96788 15.432Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask1118_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1118_1782_49644)">
        <path
          d="M14.8301 12.9434L15.9053 12.7168L15.9561 13.0144L14.8842 13.2477L14.8301 12.9434Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask1119_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1119_1782_49644)">
        <path
          d="M9.3897 18.3162L9.32207 18.022L9.01776 18.1843L9.06172 18.4886L9.3897 18.3162Z"
          fill="#0050FF"
        />
      </g>
      <mask
        height="16"
        id="mask1120_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1120_1782_49644)">
        <path
          d="M10.9045 16.1285L11.6315 15.787L11.4759 15.9966L10.7321 16.355L10.9045 16.1285Z"
          fill="#00E4F8"
        />
      </g>
      <mask
        height="16"
        id="mask1121_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1121_1782_49644)">
        <path
          d="M10.1775 16.727L10.732 16.355L10.5393 16.5275L9.96451 16.9129L10.1775 16.727Z"
          fill="#00C0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1122_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1122_1782_49644)">
        <path
          d="M13.2984 11.6078L14.3939 11.4015L14.5664 11.3339L13.4708 11.5402L13.2984 11.6078Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1123_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1123_1782_49644)">
        <path
          d="M9.29501 13.3829L10.3601 13.2409L10.4074 12.9265L9.34573 13.0448L9.29501 13.3829Z"
          fill="#A0FF56"
        />
      </g>
      <mask
        height="16"
        id="mask1124_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1124_1782_49644)">
        <path
          d="M12.8081 14.6543L13.8056 14.4041L13.7414 14.6881L12.7405 14.9586L12.8081 14.6543Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask1125_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1125_1782_49644)">
        <path
          d="M9.37278 17.6805L9.57904 17.3018L9.32544 17.4404L9.09552 17.8259L9.37278 17.6805Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask1126_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1126_1782_49644)">
        <path
          d="M7.02958 15.0803L7.64158 14.891L7.57396 14.4582L6.95181 14.6069L7.02958 15.0803Z"
          fill="#46FFB1"
        />
      </g>
      <mask
        height="16"
        id="mask1127_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1127_1782_49644)">
        <path
          d="M11.6179 13.2071L11.6281 13.6636L11.6247 14.5224L11.6146 14.0828L11.6179 13.2071Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask1128_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1128_1782_49644)">
        <path
          d="M13.8428 13.7819L14.9079 13.5554L14.9045 13.8597L13.8394 14.0964L13.8428 13.7819Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask1129_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1129_1782_49644)">
        <path
          d="M9.32207 18.022L9.37279 17.6771L9.09553 17.8259L9.01776 18.1843L9.32207 18.022Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask1130_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1130_1782_49644)">
        <path
          d="M5.78192 14.6543L5.85969 14.7692L5.81235 14.1031L5.7312 13.9408L5.78192 14.6543Z"
          fill="#5DFF9A"
        />
      </g>
      <mask
        height="16"
        id="mask1131_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1131_1782_49644)">
        <path
          d="M10.6881 8.74388L10.7422 9.25445L11.0668 10.0592L11.0127 9.552L10.6881 8.74388Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask1132_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1132_1782_49644)">
        <path
          d="M5.59256 11.432L5.694 11.8681L5.85968 11.1716L5.765 10.7084L5.59256 11.432Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1133_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1133_1782_49644)">
        <path
          d="M8.33813 13.8935L9.2815 13.7413L9.29502 13.3829L8.35166 13.5047L8.33813 13.8935Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask1134_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1134_1782_49644)">
        <path
          d="M7.57399 14.4582L8.36181 14.2891L8.33814 13.8935L7.54694 14.0254L7.57399 14.4582Z"
          fill="#6AFF8D"
        />
      </g>
      <mask
        height="16"
        id="mask1135_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1135_1782_49644)">
        <path
          d="M14.749 12.6526L15.8276 12.4294L15.9053 12.7168L14.8301 12.9434L14.749 12.6526Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask1136_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1136_1782_49644)">
        <path
          d="M6.07946 15.4286L6.26881 15.361L6.17752 14.7929L5.97803 14.8132L6.07946 15.4286Z"
          fill="#36FFC1"
        />
      </g>
      <mask
        height="16"
        id="mask1137_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1137_1782_49644)">
        <path
          d="M10.4852 12.6323L11.6179 12.5004L11.7194 12.2536L10.59 12.3719L10.4852 12.6323Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask1138_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1138_1782_49644)">
        <path
          d="M11.8479 12.044L12.9907 11.8884L13.1361 11.7261L11.9966 11.8749L11.8479 12.044Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask1139_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1139_1782_49644)">
        <path
          d="M5.66357 13.7042L5.7312 13.9408L5.74811 13.2409L5.68048 12.9637L5.66357 13.7042Z"
          fill="#90FF66"
        />
      </g>
      <mask
        height="16"
        id="mask1140_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1140_1782_49644)">
        <path
          d="M5.60272 12.6188L5.68049 12.9637L5.7684 12.2502L5.69402 11.8681L5.60272 12.6188Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask1141_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1141_1782_49644)">
        <path
          d="M6.5765 15.2392L7.02959 15.0803L6.95182 14.6069L6.49197 14.7219L6.5765 15.2392Z"
          fill="#40FFB7"
        />
      </g>
      <mask
        height="16"
        id="mask1142_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1142_1782_49644)">
        <path
          d="M13.4709 11.5402L14.5664 11.3339L14.7456 11.3136L13.6534 11.5199L13.4709 11.5402Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1143_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1143_1782_49644)">
        <path
          d="M9.85294 18.2553L9.68726 18.049L9.38971 18.3162L9.53172 18.5393L9.85294 18.2553Z"
          fill="#0054FF"
        />
      </g>
      <mask
        height="16"
        id="mask1144_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1144_1782_49644)">
        <path
          d="M11.8648 15.2392L12.7405 14.9586L12.6458 15.2291L11.7633 15.5334L11.8648 15.2392Z"
          fill="#33FFC4"
        />
      </g>
      <mask
        height="16"
        id="mask1145_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1145_1782_49644)">
        <path
          d="M6.26881 15.361L6.57651 15.2392L6.49198 14.7219L6.17752 14.7963L6.26881 15.361Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask1146_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1146_1782_49644)">
        <path
          d="M9.80896 17.0955L10.1741 16.727L9.9645 16.9129L9.57904 17.3018L9.80896 17.0955Z"
          fill="#00ACFF"
        />
      </g>
      <mask
        height="16"
        id="mask1147_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1147_1782_49644)">
        <path
          d="M7.77686 6.21133L7.83434 6.72528L8.36857 6.80305L8.30771 6.2891L7.77686 6.21133Z"
          fill="#800000"
        />
      </g>
      <mask
        height="16"
        id="mask1148_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1148_1782_49644)">
        <path
          d="M11.317 10.9214L11.3677 11.4286L11.5334 12.3145L11.4827 11.814L11.317 10.9214Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1149_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1149_1782_49644)">
        <path
          d="M14.6441 12.3787L15.7227 12.1589L15.8276 12.4294L14.749 12.6526L14.6441 12.3787Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask1150_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1150_1782_49644)">
        <path
          d="M13.8157 13.4607L14.8842 13.2477L14.9079 13.5554L13.8428 13.7819L13.8157 13.4607Z"
          fill="#94FF63"
        />
      </g>
      <mask
        height="16"
        id="mask1151_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1151_1782_49644)">
        <path
          d="M11.0465 15.8512L11.7633 15.5334L11.6314 15.787L10.9045 16.1285L11.0465 15.8512Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask1152_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1152_1782_49644)">
        <path
          d="M10.3601 16.4768L10.9045 16.1285L10.732 16.355L10.1741 16.727L10.3601 16.4768Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1153_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1153_1782_49644)">
        <path
          d="M12.8419 14.3331L13.8394 14.0964L13.8056 14.4041L12.8081 14.6577L12.8419 14.3331Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask1154_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1154_1782_49644)">
        <path
          d="M13.6534 11.5199L14.7456 11.3136L14.9282 11.3407L13.836 11.5469L13.6534 11.5199Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1155_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1155_1782_49644)">
        <path
          d="M9.34573 13.0448L10.4074 12.9265L10.4852 12.6323L9.42688 12.7303L9.34573 13.0448Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask1156_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1156_1782_49644)">
        <path
          d="M11.6247 14.5224L11.574 14.9011L11.4928 15.6957L11.5402 15.3407L11.6247 14.5224Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask1157_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1157_1782_49644)">
        <path
          d="M6.09637 8.40238L6.2181 8.95352L6.60018 8.48353L6.48522 7.92224L6.09637 8.40238Z"
          fill="#FF2500"
        />
      </g>
      <mask
        height="16"
        id="mask1158_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1158_1782_49644)">
        <path
          d="M6.95182 14.6069L7.57396 14.4582L7.54692 14.0254L6.92139 14.1302L6.95182 14.6069Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask1159_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1159_1782_49644)">
        <path
          d="M9.6264 17.4539L9.80898 17.0955L9.57906 17.3018L9.3728 17.6771L9.6264 17.4539Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask1160_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1160_1782_49644)">
        <path
          d="M10.3534 17.9713L10.137 17.8766L9.85294 18.2553L10.049 18.3736L10.3534 17.9713Z"
          fill="#0068FF"
        />
      </g>
      <mask
        height="16"
        id="mask1161_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1161_1782_49644)">
        <path
          d="M14.5123 12.1353L15.5943 11.9189L15.7228 12.1589L14.6441 12.3787L14.5123 12.1353Z"
          fill="#E1FF16"
        />
      </g>
      <mask
        height="16"
        id="mask1162_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1162_1782_49644)">
        <path
          d="M11.9966 11.8749L13.1361 11.7261L13.2984 11.6078L12.1623 11.7532L11.9966 11.8749Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask1163_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1163_1782_49644)">
        <path
          d="M9.68726 18.049L9.59934 17.7752L9.32208 18.022L9.38971 18.3162L9.68726 18.049Z"
          fill="#006CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1164_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1164_1782_49644)">
        <path
          d="M10.2993 8.01691L10.3534 8.53086L10.7422 9.25445L10.6881 8.74388L10.2993 8.01691Z"
          fill="#FF2900"
        />
      </g>
      <mask
        height="16"
        id="mask1165_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1165_1782_49644)">
        <path
          d="M5.85968 14.7692L5.97803 14.8132L5.93407 14.1944L5.81235 14.1031L5.85968 14.7692Z"
          fill="#60FF97"
        />
      </g>
      <mask
        height="16"
        id="mask1166_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1166_1782_49644)">
        <path
          d="M13.836 11.5503L14.9282 11.3407L15.1108 11.4218L14.0186 11.6281L13.836 11.5503Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1167_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1167_1782_49644)">
        <path
          d="M10.59 12.3719L11.7194 12.2536L11.8478 12.044L10.7253 12.1488L10.59 12.3719Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask1168_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1168_1782_49644)">
        <path
          d="M8.35165 13.5047L9.29502 13.3829L9.34574 13.0448L8.40575 13.1327L8.35165 13.5047Z"
          fill="#A4FF53"
        />
      </g>
      <mask
        height="16"
        id="mask1169_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1169_1782_49644)">
        <path
          d="M9.59934 17.7785L9.62639 17.4539L9.3728 17.6805L9.32208 18.022L9.59934 17.7785Z"
          fill="#0080FF"
        />
      </g>
      <mask
        height="16"
        id="mask1170_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1170_1782_49644)">
        <path
          d="M7.54694 14.0254L8.33814 13.8935L8.35167 13.5047L7.56046 13.5993L7.54694 14.0254Z"
          fill="#87FF70"
        />
      </g>
      <mask
        height="16"
        id="mask1171_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1171_1782_49644)">
        <path
          d="M14.3635 11.9256L15.4455 11.7126L15.5943 11.9189L14.5123 12.1353L14.3635 11.9256Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask1172_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1172_1782_49644)">
        <path
          d="M14.0186 11.6281L15.1108 11.4184L15.2832 11.5435L14.1978 11.7532L14.0186 11.6281Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1173_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1173_1782_49644)">
        <path
          d="M6.82672 7.01606L6.92816 7.5672L7.41506 7.35418L7.317 6.79628L6.82672 7.01606Z"
          fill="#AD0000"
        />
      </g>
      <mask
        height="16"
        id="mask1174_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1174_1782_49644)">
        <path
          d="M14.1978 11.7532L15.2832 11.5435L15.4455 11.7126L14.3635 11.9256L14.1978 11.7532Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1175_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1175_1782_49644)">
        <path
          d="M11.4928 15.6957L11.3711 15.973L11.2189 16.683L11.3339 16.4362L11.4928 15.6957Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask1176_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1176_1782_49644)">
        <path
          d="M13.7582 13.1429L14.8301 12.9434L14.8842 13.2477L13.8157 13.4607L13.7582 13.1429Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask1177_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1177_1782_49644)">
        <path
          d="M11.9358 14.9146L12.8081 14.6543L12.7405 14.9586L11.8647 15.2392L11.9358 14.9146Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask1178_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1178_1782_49644)">
        <path
          d="M10.8267 17.4472L10.607 17.4742L10.3534 17.9713H10.5562L10.8267 17.4472Z"
          fill="#0088FF"
        />
      </g>
      <mask
        height="16"
        id="mask1179_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1179_1782_49644)">
        <path
          d="M8.3111 6.28911L8.36858 6.80306L8.90281 7.03298L8.84195 6.51565L8.3111 6.28911Z"
          fill="#800000"
        />
      </g>
      <mask
        height="16"
        id="mask1180_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1180_1782_49644)">
        <path
          d="M6.49197 14.7219L6.95182 14.6069L6.92139 14.1302L6.45816 14.1978L6.49197 14.7219Z"
          fill="#66FF90"
        />
      </g>
      <mask
        height="16"
        id="mask1181_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1181_1782_49644)">
        <path
          d="M11.2189 16.683L11.0364 16.8386L10.8267 17.4472L10.9958 17.3221L11.2189 16.683Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1182_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1182_1782_49644)">
        <path
          d="M10.0118 16.8216L10.3601 16.4768L10.1741 16.727L9.80896 17.0955L10.0118 16.8216Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1183_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1183_1782_49644)">
        <path
          d="M12.8453 13.9949L13.8428 13.7819L13.8394 14.0964L12.8419 14.3331L12.8453 13.9949Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask1184_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1184_1782_49644)">
        <path
          d="M5.97804 14.8132L6.17753 14.7929L6.13696 14.2249L5.93408 14.1944L5.97804 14.8132Z"
          fill="#60FF97"
        />
      </g>
      <mask
        height="16"
        id="mask1185_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1185_1782_49644)">
        <path
          d="M12.1623 11.7532L13.295 11.6078L13.4709 11.5402L12.3415 11.6822L12.1623 11.7532Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1186_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1186_1782_49644)">
        <path
          d="M9.85294 7.39476L9.90704 7.90533L10.3534 8.53085L10.2993 8.01691L9.85294 7.39476Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask1187_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1187_1782_49644)">
        <path
          d="M6.17754 14.7929L6.49199 14.7219L6.45818 14.1978L6.13696 14.2249L6.17754 14.7929Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask1188_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1188_1782_49644)">
        <path
          d="M5.7312 13.9408L5.81235 14.1031L5.82588 13.4472L5.74811 13.2409L5.7312 13.9408Z"
          fill="#90FF66"
        />
      </g>
      <mask
        height="16"
        id="mask1189_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1189_1782_49644)">
        <path
          d="M11.1547 15.53L11.8648 15.2392L11.7633 15.5334L11.0465 15.8512L11.1547 15.53Z"
          fill="#2CFFCA"
        />
      </g>
      <mask
        height="16"
        id="mask1190_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1190_1782_49644)">
        <path
          d="M9.42688 12.7304L10.4852 12.6323L10.59 12.3719L9.54184 12.4497L9.42688 12.7304Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask1191_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1191_1782_49644)">
        <path
          d="M5.89688 9.53171L6.00846 10.0558L6.32292 9.49451L6.2181 8.95351L5.89688 9.53171Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask1192_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1192_1782_49644)">
        <path
          d="M10.5156 16.1725L11.0465 15.8512L10.9045 16.1285L10.3601 16.4768L10.5156 16.1725Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask1193_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1193_1782_49644)">
        <path
          d="M8.84195 6.51564L8.90281 7.03297L9.42014 7.40152L9.36266 6.88758L8.84195 6.51564Z"
          fill="#920000"
        />
      </g>
      <mask
        height="16"
        id="mask1194_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1194_1782_49644)">
        <path
          d="M10.7253 12.1488L11.8479 12.044L11.9966 11.8749L10.8808 11.9696L10.7253 12.1488Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask1195_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1195_1782_49644)">
        <path
          d="M13.6737 12.8386L14.7489 12.6526L14.8301 12.9434L13.7582 13.1429L13.6737 12.8386Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask1196_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1196_1782_49644)">
        <path
          d="M10.137 17.8766L9.95099 17.6974L9.68726 18.049L9.85294 18.2553L10.137 17.8766Z"
          fill="#007CFF"
        />
      </g>
      <mask
        height="16"
        id="mask1197_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1197_1782_49644)">
        <path
          d="M9.36264 6.88757L9.42012 7.40152L9.90702 7.90532L9.85292 7.39476L9.36264 6.88757Z"
          fill="#B20000"
        />
      </g>
      <mask
        height="16"
        id="mask1198_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1198_1782_49644)">
        <path
          d="M11.5334 12.3145L11.5469 12.7912L11.6281 13.6636L11.6179 13.2071L11.5334 12.3145Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask1199_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1199_1782_49644)">
        <path
          d="M6.92139 14.1302L7.54692 14.0254L7.56044 13.5993L6.93491 13.6602L6.92139 14.1302Z"
          fill="#8AFF6D"
        />
      </g>
      <mask
        height="16"
        id="mask1200_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1200_1782_49644)">
        <path
          d="M12.3415 11.6822L13.4709 11.5402L13.6534 11.5199L12.5309 11.6619L12.3415 11.6822Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1201_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1201_1782_49644)">
        <path
          d="M8.40576 13.1327L9.34574 13.0448L9.42689 12.7304L8.49367 12.7912L8.40576 13.1327Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask1202_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1202_1782_49644)">
        <path
          d="M9.84953 17.153L10.0118 16.8216L9.80896 17.0955L9.62637 17.4539L9.84953 17.153Z"
          fill="#00B4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1203_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1203_1782_49644)">
        <path
          d="M11.0668 10.0592L11.1209 10.5799L11.3677 11.4286L11.317 10.918L11.0668 10.0592Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask1204_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1204_1782_49644)">
        <path
          d="M5.68048 12.9637L5.74811 13.2409L5.83264 12.568L5.76839 12.2502L5.68048 12.9637Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask1205_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1205_1782_49644)">
        <path
          d="M7.56046 13.5993L8.35166 13.5047L8.40576 13.1327L7.61794 13.1902L7.56046 13.5993Z"
          fill="#A7FF50"
        />
      </g>
      <mask
        height="16"
        id="mask1206_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1206_1782_49644)">
        <path
          d="M5.76501 10.7084L5.85969 11.1716L6.09638 10.5461L6.00846 10.0558L5.76501 10.7084Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask1207_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1207_1782_49644)">
        <path
          d="M11.973 14.5664L12.8419 14.3331L12.8081 14.6577L11.9358 14.9146L11.973 14.5664Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask1208_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1208_1782_49644)">
        <path
          d="M12.8149 13.6568L13.8157 13.4607L13.8428 13.7819L12.8453 13.9949L12.8149 13.6568Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask1209_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1209_1782_49644)">
        <path
          d="M5.694 11.8681L5.76839 12.2502L5.92731 11.5875L5.85968 11.1716L5.694 11.8681Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1210_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1210_1782_49644)">
        <path
          d="M13.5621 12.5579L14.6441 12.3787L14.7489 12.6526L13.6737 12.8386L13.5621 12.5579Z"
          fill="#D4FF23"
        />
      </g>
      <mask
        height="16"
        id="mask1211_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1211_1782_49644)">
        <path
          d="M9.95098 17.6974L9.8394 17.4506L9.59933 17.7752L9.68725 18.049L9.95098 17.6974Z"
          fill="#0090FF"
        />
      </g>
      <mask
        height="16"
        id="mask1212_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1212_1782_49644)">
        <path
          d="M9.8394 17.4506L9.84616 17.153L9.62638 17.4539L9.59933 17.7785L9.8394 17.4506Z"
          fill="#00A0FF"
        />
      </g>
      <mask
        height="16"
        id="mask1213_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1213_1782_49644)">
        <path
          d="M12.5309 11.6619L13.6534 11.5199L13.836 11.5503L12.7202 11.6923L12.5309 11.6619Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask1214_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1214_1782_49644)">
        <path
          d="M5.81235 14.1031L5.93407 14.1944L5.95098 13.5858L5.82587 13.4472L5.81235 14.1031Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask1215_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1215_1782_49644)">
        <path
          d="M9.54184 12.4463L10.59 12.3719L10.7253 12.1488L9.68385 12.2063L9.54184 12.4463Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask1216_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1216_1782_49644)">
        <path
          d="M10.8808 11.9696L11.9966 11.8749L12.1623 11.7532L11.0567 11.8411L10.8808 11.9696Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1217_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1217_1782_49644)">
        <path
          d="M6.45816 14.1978L6.92139 14.1302L6.93491 13.6602L6.47169 13.6839L6.45816 14.1978Z"
          fill="#8AFF6D"
        />
      </g>
      <mask
        height="16"
        id="mask1218_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1218_1782_49644)">
        <path
          d="M10.1809 16.4903L10.5156 16.1724L10.3601 16.4768L10.0118 16.8216L10.1809 16.4903Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask1219_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1219_1782_49644)">
        <path
          d="M10.607 17.4742L10.377 17.4134L10.137 17.8766L10.3534 17.9713L10.607 17.4742Z"
          fill="#0098FF"
        />
      </g>
      <mask
        height="16"
        id="mask1220_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1220_1782_49644)">
        <path
          d="M11.2325 15.175L11.9358 14.9146L11.8648 15.2392L11.1547 15.53L11.2325 15.175Z"
          fill="#46FFB1"
        />
      </g>
      <mask
        height="16"
        id="mask1221_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1221_1782_49644)">
        <path
          d="M13.4235 12.3043L14.5123 12.1353L14.6441 12.3787L13.5622 12.5579L13.4235 12.3043Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask1222_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1222_1782_49644)">
        <path
          d="M7.31699 6.79629L7.41504 7.35419L7.92561 7.28318L7.83431 6.72528L7.31699 6.79629Z"
          fill="#920000"
        />
      </g>
      <mask
        height="16"
        id="mask1223_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1223_1782_49644)">
        <path
          d="M6.48523 7.92224L6.60019 8.48353L7.03299 8.13864L6.92817 7.56721L6.48523 7.92224Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask1224_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1224_1782_49644)">
        <path
          d="M12.7202 11.6923L13.836 11.5503L14.022 11.6281L12.9129 11.7735L12.7202 11.6923Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask1225_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1225_1782_49644)">
        <path
          d="M10.6374 15.8208L11.1547 15.53L11.0465 15.8512L10.5157 16.1724L10.6374 15.8208Z"
          fill="#26FFD1"
        />
      </g>
      <mask
        height="16"
        id="mask1226_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1226_1782_49644)">
        <path
          d="M11.6281 13.6636L11.5774 14.0693L11.574 14.9011L11.6247 14.5224L11.6281 13.6636Z"
          fill="#83FF73"
        />
      </g>
      <mask
        height="16"
        id="mask1227_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1227_1782_49644)">
        <path
          d="M13.268 12.0845L14.3635 11.9256L14.5123 12.1353L13.4235 12.3043L13.268 12.0845Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask1228_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1228_1782_49644)">
        <path
          d="M12.9129 11.7735L14.022 11.6281L14.1978 11.7566L13.0955 11.9053L12.9129 11.7735Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1229_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1229_1782_49644)">
        <path
          d="M5.93408 14.1944L6.13696 14.2249L6.15386 13.6636L5.95099 13.5858L5.93408 14.1944Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask1230_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1230_1782_49644)">
        <path
          d="M6.13696 14.2249L6.45818 14.1978L6.47171 13.6839L6.15387 13.6636L6.13696 14.2249Z"
          fill="#8DFF6A"
        />
      </g>
      <mask
        height="16"
        id="mask1231_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1231_1782_49644)">
        <path
          d="M12.754 13.3187L13.7583 13.1429L13.8157 13.4607L12.8149 13.6568L12.754 13.3187Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask1232_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1232_1782_49644)">
        <path
          d="M13.0955 11.9053L14.1978 11.7532L14.3635 11.9256L13.268 12.0845L13.0955 11.9053Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1233_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1233_1782_49644)">
        <path
          d="M8.49368 12.7912L9.4269 12.7303L9.54187 12.4497L8.61541 12.4835L8.49368 12.7912Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1234_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1234_1782_49644)">
        <path
          d="M11.973 14.2012L12.8453 13.9949L12.8419 14.3331L11.973 14.5664V14.2012Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask1235_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1235_1782_49644)">
        <path
          d="M6.93494 13.6602L7.56046 13.5993L7.61795 13.1902L6.9958 13.2139L6.93494 13.6602Z"
          fill="#AAFF4D"
        />
      </g>
      <mask
        height="16"
        id="mask1236_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1236_1782_49644)">
        <path
          d="M11.0364 16.8386L10.8064 16.9028L10.6069 17.4742L10.8267 17.4472L11.0364 16.8386Z"
          fill="#00C4FF"
        />
      </g>
      <mask
        height="16"
        id="mask1237_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1237_1782_49644)">
        <path
          d="M11.0566 11.8411L12.1623 11.7532L12.3415 11.6822L11.246 11.7633L11.0566 11.8411Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1238_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1238_1782_49644)">
        <path
          d="M7.61792 13.1902L8.40575 13.1327L8.49366 12.7912L7.71259 12.8149L7.61792 13.1902Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask1239_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1239_1782_49644)">
        <path
          d="M10.0321 16.7878L10.1809 16.4903L10.0118 16.8216L9.84616 17.153L10.0321 16.7878Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1240_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1240_1782_49644)">
        <path
          d="M11.574 14.9011L11.4489 15.2088L11.3711 15.973L11.4928 15.6957L11.574 14.9011Z"
          fill="#46FFB1"
        />
      </g>
      <mask
        height="16"
        id="mask1241_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1241_1782_49644)">
        <path
          d="M5.74811 13.2409L5.82588 13.4472L5.90703 12.8183L5.83264 12.5681L5.74811 13.2409Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask1242_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1242_1782_49644)">
        <path
          d="M10.7422 9.25444L10.803 9.78529L11.1209 10.5799L11.0668 10.0592L10.7422 9.25444Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask1243_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1243_1782_49644)">
        <path
          d="M9.68387 12.2063L10.7253 12.1488L10.8808 11.9696L9.84955 12.0135L9.68387 12.2063Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask1244_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1244_1782_49644)">
        <path
          d="M10.377 17.4134L10.1708 17.268L9.95099 17.6974L10.137 17.8766L10.377 17.4134Z"
          fill="#00A8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1245_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1245_1782_49644)">
        <path
          d="M11.3711 15.973L11.1784 16.1623L11.0363 16.8385L11.2189 16.683L11.3711 15.973Z"
          fill="#0FF8E7"
        />
      </g>
      <mask
        height="16"
        id="mask1246_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1246_1782_49644)">
        <path
          d="M12.6627 12.9975L13.6737 12.8386L13.7582 13.1429L12.754 13.3187L12.6627 12.9975Z"
          fill="#C4FF33"
        />
      </g>
      <mask
        height="16"
        id="mask1247_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1247_1782_49644)">
        <path
          d="M11.2697 14.7963L11.973 14.5664L11.9358 14.9146L11.2325 15.175L11.2697 14.7963Z"
          fill="#63FF94"
        />
      </g>
      <mask
        height="16"
        id="mask1248_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1248_1782_49644)">
        <path
          d="M11.3677 11.4286L11.3846 11.9256L11.5469 12.7878L11.5334 12.3145L11.3677 11.4286Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1249_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1249_1782_49644)">
        <path
          d="M6.21811 8.95351L6.32293 9.49451L6.69486 9.04142L6.60019 8.48352L6.21811 8.95351Z"
          fill="#FF3000"
        />
      </g>
      <mask
        height="16"
        id="mask1250_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1250_1782_49644)">
        <path
          d="M10.0423 17.055L10.0321 16.7878L9.84956 17.153L9.83942 17.4506L10.0423 17.055Z"
          fill="#00C8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1251_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1251_1782_49644)">
        <path
          d="M11.246 11.7633L12.3415 11.6822L12.5309 11.6619L11.4455 11.743L11.246 11.7633Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1252_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1252_1782_49644)">
        <path
          d="M10.1708 17.268L10.0423 17.055L9.83942 17.4506L9.951 17.6974L10.1708 17.268Z"
          fill="#00B8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1253_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1253_1782_49644)">
        <path
          d="M10.3128 16.1048L10.634 15.8208L10.5157 16.1724L10.1809 16.4903L10.3128 16.1048Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask1254_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1254_1782_49644)">
        <path
          d="M6.47168 13.6839L6.93491 13.6602L6.99577 13.2139L6.53592 13.1936L6.47168 13.6839Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask1255_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1255_1782_49644)">
        <path
          d="M7.83432 6.72528L7.92561 7.28318L8.45309 7.36095L8.36855 6.80305L7.83432 6.72528Z"
          fill="#840000"
        />
      </g>
      <mask
        height="16"
        id="mask1256_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1256_1782_49644)">
        <path
          d="M11.9391 13.836L12.8149 13.6568L12.8453 13.9949L11.973 14.2012L11.9391 13.836Z"
          fill="#97FF60"
        />
      </g>
      <mask
        height="16"
        id="mask1257_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1257_1782_49644)">
        <path
          d="M10.7185 15.432L11.2291 15.175L11.1547 15.53L10.634 15.8208L10.7185 15.432Z"
          fill="#43FFB4"
        />
      </g>
      <mask
        height="16"
        id="mask1258_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1258_1782_49644)">
        <path
          d="M5.7684 12.2502L5.83265 12.5681L5.98142 11.9425L5.92732 11.5875L5.7684 12.2502Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1259_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1259_1782_49644)">
        <path
          d="M12.541 12.6965L13.5621 12.5579L13.6737 12.8385L12.6627 12.9975L12.541 12.6965Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1260_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1260_1782_49644)">
        <path
          d="M8.61539 12.4835L9.54185 12.4463L9.68386 12.2063L8.76754 12.2198L8.61539 12.4835Z"
          fill="#EEFF09"
        />
      </g>
      <mask
        height="16"
        id="mask1261_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1261_1782_49644)">
        <path
          d="M5.82587 13.4472L5.95097 13.5858L6.02198 13.0042L5.90702 12.8183L5.82587 13.4472Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask1262_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1262_1782_49644)">
        <path
          d="M9.84955 12.0135L10.8808 11.9696L11.0566 11.8411L10.0389 11.8749L9.84955 12.0135Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1263_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1263_1782_49644)">
        <path
          d="M11.4455 11.743L12.5309 11.6619L12.7202 11.6923L11.6484 11.7735L11.4455 11.743Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask1264_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1264_1782_49644)">
        <path
          d="M6.00845 10.0558L6.09637 10.5461L6.3973 10.0085L6.32291 9.49451L6.00845 10.0558Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask1265_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1265_1782_49644)">
        <path
          d="M6.99579 13.2139L7.61794 13.1902L7.71261 12.8149L7.09723 12.798L6.99579 13.2139Z"
          fill="#CAFF2C"
        />
      </g>
      <mask
        height="16"
        id="mask1266_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1266_1782_49644)">
        <path
          d="M6.15387 13.6636L6.47171 13.6839L6.53595 13.1936L6.22149 13.126L6.15387 13.6636Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask1267_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1267_1782_49644)">
        <path
          d="M5.85968 11.1716L5.9273 11.5875L6.15385 10.9924L6.09637 10.5461L5.85968 11.1716Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask1268_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1268_1782_49644)">
        <path
          d="M7.71262 12.8149L8.49368 12.7912L8.61541 12.4835L7.84448 12.4768L7.71262 12.8149Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask1269_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1269_1782_49644)">
        <path
          d="M10.3534 8.53086L10.4176 9.06848L10.8031 9.7853L10.7422 9.25445L10.3534 8.53086Z"
          fill="#FF2D00"
        />
      </g>
      <mask
        height="16"
        id="mask1270_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1270_1782_49644)">
        <path
          d="M12.3956 12.426L13.4235 12.3043L13.5622 12.5579L12.541 12.6965L12.3956 12.426Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask1271_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1271_1782_49644)">
        <path
          d="M10.8064 16.9028L10.5596 16.8757L10.377 17.4134L10.6069 17.4742L10.8064 16.9028Z"
          fill="#00CCFF"
        />
      </g>
      <mask
        height="16"
        id="mask1272_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1272_1782_49644)">
        <path
          d="M6.92816 7.5672L7.03298 8.13863L7.50973 7.93238L7.41506 7.35419L6.92816 7.5672Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask1273_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1273_1782_49644)">
        <path
          d="M5.95099 13.5858L6.15386 13.6636L6.22149 13.126L6.02199 13.0042L5.95099 13.5858Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask1274_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1274_1782_49644)">
        <path
          d="M11.6484 11.7735L12.7202 11.6923L12.913 11.7735L11.8513 11.8614L11.6484 11.7735Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask1275_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1275_1782_49644)">
        <path
          d="M11.2697 14.3973L11.9729 14.2012V14.5664L11.2697 14.7963V14.3973Z"
          fill="#7DFF7A"
        />
      </g>
      <mask
        height="16"
        id="mask1276_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1276_1782_49644)">
        <path
          d="M11.8715 13.4708L12.754 13.3187L12.8149 13.6568L11.9391 13.836L11.8715 13.4708Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask1277_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1277_1782_49644)">
        <path
          d="M10.1775 16.3686L10.3128 16.1048L10.1809 16.4903L10.0321 16.7878L10.1775 16.3686Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask1278_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1278_1782_49644)">
        <path
          d="M12.2299 12.1927L13.268 12.0845L13.4235 12.3043L12.3956 12.426L12.2299 12.1927Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask1279_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1279_1782_49644)">
        <path
          d="M11.8512 11.8614L12.9129 11.7735L13.0955 11.9053L12.044 12.0034L11.8512 11.8614Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1280_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1280_1782_49644)">
        <path
          d="M8.36856 6.80305L8.45309 7.36434L8.98056 7.5875L8.9028 7.03298L8.36856 6.80305Z"
          fill="#890000"
        />
      </g>
      <mask
        height="16"
        id="mask1281_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1281_1782_49644)">
        <path
          d="M12.044 12.0034L13.0955 11.9053L13.268 12.0845L12.2299 12.1927L12.044 12.0034Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1282_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1282_1782_49644)">
        <path
          d="M11.5469 12.7912L11.4962 13.224L11.5774 14.0693L11.6281 13.6636L11.5469 12.7912Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask1283_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1283_1782_49644)">
        <path
          d="M10.0389 11.8749L11.0566 11.8411L11.246 11.7633L10.2418 11.7904L10.0389 11.8749Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask1284_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1284_1782_49644)">
        <path
          d="M8.76755 12.2198L9.68386 12.2063L9.84954 12.0135L8.95013 12.0101L8.76755 12.2198Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1285_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1285_1782_49644)">
        <path
          d="M10.7591 15.0161L11.2697 14.7963L11.2291 15.175L10.7185 15.432L10.7591 15.0161Z"
          fill="#60FF97"
        />
      </g>
      <mask
        height="16"
        id="mask1286_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1286_1782_49644)">
        <path
          d="M10.4007 15.6788L10.7185 15.432L10.634 15.8208L10.3128 16.1048L10.4007 15.6788Z"
          fill="#3CFFBA"
        />
      </g>
      <mask
        height="16"
        id="mask1287_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1287_1782_49644)">
        <path
          d="M9.90701 7.90533L9.97802 8.45309L10.4176 9.06848L10.3533 8.53086L9.90701 7.90533Z"
          fill="#E40000"
        />
      </g>
      <mask
        height="16"
        id="mask1288_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1288_1782_49644)">
        <path
          d="M10.5596 16.8757L10.3432 16.7675L10.1708 17.268L10.377 17.4134L10.5596 16.8757Z"
          fill="#00D8FF"
        />
      </g>
      <mask
        height="16"
        id="mask1289_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1289_1782_49644)">
        <path
          d="M11.1817 16.1623L10.9417 16.2637L10.8064 16.9028L11.0364 16.8386L11.1817 16.1623Z"
          fill="#16FFE1"
        />
      </g>
      <mask
        height="16"
        id="mask1290_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1290_1782_49644)">
        <path
          d="M10.1978 16.5951L10.1775 16.3686L10.0321 16.7878L10.0423 17.055L10.1978 16.5951Z"
          fill="#0CF4EB"
        />
      </g>
      <mask
        height="16"
        id="mask1291_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1291_1782_49644)">
        <path
          d="M6.53595 13.1936L6.9958 13.2139L7.09723 12.798L6.64753 12.7405L6.53595 13.1936Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask1292_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1292_1782_49644)">
        <path
          d="M8.9028 7.03297L8.98057 7.5875L9.49452 7.95605L9.42013 7.40153L8.9028 7.03297Z"
          fill="#9B0000"
        />
      </g>
      <mask
        height="16"
        id="mask1293_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1293_1782_49644)">
        <path
          d="M5.83264 12.5681L5.90703 12.8183L6.04566 12.2367L5.98142 11.9425L5.83264 12.5681Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask1294_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1294_1782_49644)">
        <path
          d="M11.7701 13.1226L12.6627 12.9975L12.754 13.3187L11.8715 13.4708L11.7701 13.1226Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask1295_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1295_1782_49644)">
        <path
          d="M11.1209 10.5799L11.1445 11.0905L11.3846 11.9256L11.3677 11.4286L11.1209 10.5799Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1296_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1296_1782_49644)">
        <path
          d="M9.42014 7.40152L9.49452 7.95604L9.97804 8.45308L9.90703 7.90532L9.42014 7.40152Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask1297_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1297_1782_49644)">
        <path
          d="M10.3432 16.7675L10.1978 16.5951L10.0423 17.0549L10.1708 17.268L10.3432 16.7675Z"
          fill="#02E8F4"
        />
      </g>
      <mask
        height="16"
        id="mask1298_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1298_1782_49644)">
        <path
          d="M11.5774 14.0693L11.4489 14.4108V15.2088L11.574 14.9011L11.5774 14.0693Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1299_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1299_1782_49644)">
        <path
          d="M7.84448 12.4768L8.6154 12.4835L8.76756 12.2198L8.01016 12.1894L7.84448 12.4768Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask1300_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1300_1782_49644)">
        <path
          d="M11.2325 13.9983L11.9392 13.836L11.973 14.2012L11.2697 14.3973L11.2325 13.9983Z"
          fill="#9AFF5D"
        />
      </g>
      <mask
        height="16"
        id="mask1301_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1301_1782_49644)">
        <path
          d="M11.4489 15.2088L11.2528 15.4353L11.1818 16.1623L11.3711 15.973L11.4489 15.2088Z"
          fill="#49FFAD"
        />
      </g>
      <mask
        height="16"
        id="mask1302_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1302_1782_49644)">
        <path
          d="M10.2418 11.7904L11.246 11.7633L11.4455 11.743L10.4548 11.7667L10.2418 11.7904Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask1303_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1303_1782_49644)">
        <path
          d="M7.09723 12.798L7.71261 12.8149L7.84448 12.4768L7.24262 12.426L7.09723 12.798Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask1304_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1304_1782_49644)">
        <path
          d="M6.60019 8.48352L6.69486 9.04142L7.11752 8.71006L7.03299 8.13863L6.60019 8.48352Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask1305_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1305_1782_49644)">
        <path
          d="M6.2215 13.126L6.53595 13.1936L6.64753 12.7371L6.34322 12.6289L6.2215 13.126Z"
          fill="#DBFF1C"
        />
      </g>
      <mask
        height="16"
        id="mask1306_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1306_1782_49644)">
        <path
          d="M11.6416 12.798L12.541 12.6965L12.6627 12.9975L11.7701 13.1226L11.6416 12.798Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask1307_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1307_1782_49644)">
        <path
          d="M8.95013 12.0101L9.84954 12.0135L10.0389 11.8749L9.15301 11.858L8.95013 12.0101Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask1308_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1308_1782_49644)">
        <path
          d="M5.90701 12.8183L6.02197 13.0042L6.15384 12.4632L6.04564 12.2367L5.90701 12.8183Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask1309_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1309_1782_49644)">
        <path
          d="M7.41507 7.35419L7.50974 7.929L8.01355 7.86476L7.92564 7.28319L7.41507 7.35419Z"
          fill="#9F0000"
        />
      </g>
      <mask
        height="16"
        id="mask1310_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1310_1782_49644)">
        <path
          d="M10.2756 15.8986L10.4007 15.6788L10.3128 16.1048L10.1775 16.3686L10.2756 15.8986Z"
          fill="#36FFC1"
        />
      </g>
      <mask
        height="16"
        id="mask1311_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1311_1782_49644)">
        <path
          d="M5.92731 11.5875L5.98141 11.9425L6.19442 11.3846L6.15385 10.9924L5.92731 11.5875Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask1312_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1312_1782_49644)">
        <path
          d="M10.4548 11.7667L11.4455 11.743L11.6483 11.7735L10.6746 11.8039L10.4548 11.7667Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask1313_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1313_1782_49644)">
        <path
          d="M10.7591 14.5799L11.2697 14.3973V14.7963L10.7591 15.0161V14.5799Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1314_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1314_1782_49644)">
        <path
          d="M6.022 13.0042L6.2215 13.126L6.34322 12.6289L6.15387 12.4632L6.022 13.0042Z"
          fill="#E1FF16"
        />
      </g>
      <mask
        height="16"
        id="mask1315_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1315_1782_49644)">
        <path
          d="M11.4827 12.5072L12.3956 12.426L12.541 12.6965L11.6416 12.798L11.4827 12.5072Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask1316_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1316_1782_49644)">
        <path
          d="M10.448 15.2189L10.7591 15.0161L10.7185 15.432L10.4007 15.6788L10.448 15.2189Z"
          fill="#5DFF9A"
        />
      </g>
      <mask
        height="16"
        id="mask1317_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1317_1782_49644)">
        <path
          d="M6.32291 9.49451L6.39729 10.0085L6.7557 9.57566L6.69484 9.04143L6.32291 9.49451Z"
          fill="#FF3B00"
        />
      </g>
      <mask
        height="16"
        id="mask1318_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1318_1782_49644)">
        <path
          d="M10.9417 16.2637L10.6847 16.2773L10.5596 16.8757L10.8064 16.9028L10.9417 16.2637Z"
          fill="#1CFFDB"
        />
      </g>
      <mask
        height="16"
        id="mask1319_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1319_1782_49644)">
        <path
          d="M11.1581 13.5993L11.8715 13.4708L11.9391 13.836L11.2325 13.9983L11.1581 13.5993Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask1320_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1320_1782_49644)">
        <path
          d="M10.6746 11.8039L11.6484 11.7735L11.8512 11.8614L10.891 11.8986L10.6746 11.8039Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask1321_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1321_1782_49644)">
        <path
          d="M6.09637 10.5461L6.15386 10.9924L6.44126 10.4818L6.3973 10.0085L6.09637 10.5461Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask1322_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1322_1782_49644)">
        <path
          d="M11.3035 12.2536L12.2299 12.1927L12.3956 12.426L11.4827 12.5072L11.3035 12.2536Z"
          fill="#FFEA00"
        />
      </g>
      <mask
        height="16"
        id="mask1323_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1323_1782_49644)">
        <path
          d="M8.01016 12.1894L8.76756 12.2198L8.95015 12.0101L8.20966 11.956L8.01016 12.1894Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1324_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1324_1782_49644)">
        <path
          d="M10.891 11.8986L11.8512 11.8614L12.044 12.0034L11.104 12.0507L10.891 11.8986Z"
          fill="#FFD000"
        />
      </g>
      <mask
        height="16"
        id="mask1325_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1325_1782_49644)">
        <path
          d="M6.64752 12.7405L7.09723 12.798L7.24262 12.426L6.80306 12.3314L6.64752 12.7405Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask1326_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1326_1782_49644)">
        <path
          d="M11.104 12.0507L12.044 12.0034L12.2299 12.1927L11.3035 12.2536L11.104 12.0507Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1327_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1327_1782_49644)">
        <path
          d="M9.15302 11.858L10.0389 11.8749L10.2418 11.7904L9.3728 11.7667L9.15302 11.858Z"
          fill="#FFC800"
        />
      </g>
      <mask
        height="16"
        id="mask1328_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1328_1782_49644)">
        <path
          d="M10.803 9.7853L10.8301 10.3162L11.1445 11.0905L11.1209 10.5799L10.803 9.7853Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask1329_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1329_1782_49644)">
        <path
          d="M11.3846 11.9256L11.3407 12.3855L11.4962 13.224L11.5469 12.7912L11.3846 11.9256Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1330_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1330_1782_49644)">
        <path
          d="M10.306 16.0845L10.2756 15.8986L10.1775 16.3686L10.1978 16.5951L10.306 16.0845Z"
          fill="#30FFC7"
        />
      </g>
      <mask
        height="16"
        id="mask1331_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1331_1782_49644)">
        <path
          d="M7.24261 12.426L7.84447 12.4768L8.01015 12.1894L7.4252 12.1116L7.24261 12.426Z"
          fill="#FFE600"
        />
      </g>
      <mask
        height="16"
        id="mask1332_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1332_1782_49644)">
        <path
          d="M10.6847 16.2773L10.4582 16.213L10.3432 16.7676L10.5596 16.8758L10.6847 16.2773Z"
          fill="#23FFD4"
        />
      </g>
      <mask
        height="16"
        id="mask1333_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1333_1782_49644)">
        <path
          d="M10.4582 16.213L10.306 16.0845L10.1978 16.5951L10.3432 16.7675L10.4582 16.213Z"
          fill="#29FFCE"
        />
      </g>
      <mask
        height="16"
        id="mask1334_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1334_1782_49644)">
        <path
          d="M11.0465 13.2206L11.7701 13.1226L11.8715 13.4708L11.1581 13.5993L11.0465 13.2206Z"
          fill="#D1FF26"
        />
      </g>
      <mask
        height="16"
        id="mask1335_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1335_1782_49644)">
        <path
          d="M10.7151 14.1403L11.2325 13.9983L11.2697 14.3973L10.7591 14.5799L10.7151 14.1403Z"
          fill="#9DFF5A"
        />
      </g>
      <mask
        height="16"
        id="mask1336_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1336_1782_49644)">
        <path
          d="M5.98141 11.9425L6.04566 12.2367L6.24515 11.7126L6.19443 11.3846L5.98141 11.9425Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask1337_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1337_1782_49644)">
        <path
          d="M11.2528 15.4353L11.0093 15.5774L10.9417 16.2637L11.1784 16.1623L11.2528 15.4353Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask1338_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1338_1782_49644)">
        <path
          d="M7.92563 7.28319L8.01354 7.86476L8.52749 7.94253L8.4531 7.36096L7.92563 7.28319Z"
          fill="#920000"
        />
      </g>
      <mask
        height="16"
        id="mask1339_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1339_1782_49644)">
        <path
          d="M9.3728 11.7667L10.2418 11.7904L10.4548 11.7667L9.60611 11.7397L9.3728 11.7667Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask1340_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1340_1782_49644)">
        <path
          d="M6.3432 12.6289L6.64751 12.7405L6.80305 12.3314L6.51226 12.1826L6.3432 12.6289Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1341_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1341_1782_49644)">
        <path
          d="M10.3229 15.3981L10.448 15.2189L10.4007 15.6788L10.2756 15.8986L10.3229 15.3981Z"
          fill="#5AFF9D"
        />
      </g>
      <mask
        height="16"
        id="mask1342_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1342_1782_49644)">
        <path
          d="M7.03299 8.13864L7.11752 8.71007L7.57737 8.5072L7.50974 7.93239L7.03299 8.13864Z"
          fill="#C80000"
        />
      </g>
      <mask
        height="16"
        id="mask1343_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1343_1782_49644)">
        <path
          d="M11.4962 13.224L11.3745 13.596L11.4489 14.4108L11.5774 14.0693L11.4962 13.224Z"
          fill="#BEFF39"
        />
      </g>
      <mask
        height="16"
        id="mask1344_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1344_1782_49644)">
        <path
          d="M10.4446 14.7422L10.7591 14.5799V15.0161L10.448 15.2189L10.4446 14.7422Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1345_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1345_1782_49644)">
        <path
          d="M8.20966 11.956L8.95015 12.0101L9.15302 11.858L8.42944 11.7904L8.20966 11.956Z"
          fill="#FFC800"
        />
      </g>
      <mask
        height="16"
        id="mask1346_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1346_1782_49644)">
        <path
          d="M11.4489 14.4108L11.2561 14.6746L11.2527 15.4353L11.4489 15.2088V14.4108Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1347_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1347_1782_49644)">
        <path
          d="M10.9011 12.8656L11.6416 12.798L11.7735 13.1226L11.0465 13.2206L10.9011 12.8656Z"
          fill="#E7FF0F"
        />
      </g>
      <mask
        height="16"
        id="mask1348_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1348_1782_49644)">
        <path
          d="M6.04565 12.2367L6.15385 12.4632L6.33982 11.9797L6.24515 11.7126L6.04565 12.2367Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask1349_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1349_1782_49644)">
        <path
          d="M10.4176 9.06847L10.4514 9.61623L10.8301 10.3161L10.8031 9.7853L10.4176 9.06847Z"
          fill="#FF3400"
        />
      </g>
      <mask
        height="16"
        id="mask1350_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1350_1782_49644)">
        <path
          d="M6.15387 12.4632L6.34322 12.6289L6.51228 12.1826L6.33984 11.9797L6.15387 12.4632Z"
          fill="#FFDE00"
        />
      </g>
      <mask
        height="16"
        id="mask1351_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1351_1782_49644)">
        <path
          d="M9.60611 11.7397L10.4548 11.7667L10.6746 11.8039L9.84618 11.7768L9.60611 11.7397Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask1352_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1352_1782_49644)">
        <path
          d="M6.80304 12.3314L7.2426 12.426L7.42519 12.1116L7.00253 11.9831L6.80304 12.3314Z"
          fill="#FFD700"
        />
      </g>
      <mask
        height="16"
        id="mask1353_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1353_1782_49644)">
        <path
          d="M7.4252 12.1116L8.01016 12.1894L8.20965 11.9561L7.63822 11.8546L7.4252 12.1116Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask1354_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1354_1782_49644)">
        <path
          d="M6.15387 10.9924L6.19444 11.3846L6.46494 10.9045L6.44127 10.4818L6.15387 10.9924Z"
          fill="#FF8600"
        />
      </g>
      <mask
        height="16"
        id="mask1355_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1355_1782_49644)">
        <path
          d="M10.6306 13.7041L11.1581 13.5993L11.2325 13.9983L10.7151 14.1403L10.6306 13.7041Z"
          fill="#BAFF3C"
        />
      </g>
      <mask
        height="16"
        id="mask1356_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1356_1782_49644)">
        <path
          d="M8.45309 7.36433L8.52748 7.94253L9.04481 8.16569L8.98057 7.5875L8.45309 7.36433Z"
          fill="#960000"
        />
      </g>
      <mask
        height="16"
        id="mask1357_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1357_1782_49644)">
        <path
          d="M10.7287 12.5478L11.4827 12.5072L11.6416 12.798L10.9011 12.8656L10.7287 12.5478Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1358_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1358_1782_49644)">
        <path
          d="M10.3601 15.5334L10.3229 15.3981L10.2756 15.8986L10.306 16.0845L10.3601 15.5334Z"
          fill="#56FFA0"
        />
      </g>
      <mask
        height="16"
        id="mask1359_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1359_1782_49644)">
        <path
          d="M11.0093 15.5774L10.749 15.6348L10.6847 16.2773L10.9417 16.2637L11.0093 15.5774Z"
          fill="#4DFFAA"
        />
      </g>
      <mask
        height="16"
        id="mask1360_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1360_1782_49644)">
        <path
          d="M6.69485 9.04143L6.75572 9.57566L7.16147 9.25783L7.11751 8.71007L6.69485 9.04143Z"
          fill="#FF1300"
        />
      </g>
      <mask
        height="16"
        id="mask1361_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1361_1782_49644)">
        <path
          d="M9.84616 11.7768L10.6746 11.8039L10.891 11.8986L10.0828 11.8817L9.84616 11.7768Z"
          fill="#FFBD00"
        />
      </g>
      <mask
        height="16"
        id="mask1362_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1362_1782_49644)">
        <path
          d="M11.1445 11.0905L11.104 11.5774L11.3407 12.3855L11.3846 11.9256L11.1445 11.0905Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask1363_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1363_1782_49644)">
        <path
          d="M9.97803 8.45309L10.022 9.011L10.4514 9.61624L10.4176 9.06848L9.97803 8.45309Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask1364_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1364_1782_49644)">
        <path
          d="M8.42941 11.787L9.153 11.858L9.37278 11.7667L8.67286 11.6889L8.42941 11.787Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask1365_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1365_1782_49644)">
        <path
          d="M10.5292 12.2705L11.3035 12.2536L11.4827 12.5072L10.7287 12.5478L10.5292 12.2705Z"
          fill="#FFDB00"
        />
      </g>
      <mask
        height="16"
        id="mask1366_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1366_1782_49644)">
        <path
          d="M6.39731 10.0085L6.44126 10.4818L6.78277 10.0727L6.75572 9.57566L6.39731 10.0085Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1367_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1367_1782_49644)">
        <path
          d="M10.0829 11.8817L10.891 11.8986L11.104 12.0507L10.3128 12.0473L10.0829 11.8817Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask1368_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1368_1782_49644)">
        <path
          d="M10.3128 12.0473L11.104 12.0507L11.3035 12.2536L10.5292 12.2705L10.3128 12.0473Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask1369_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1369_1782_49644)">
        <path
          d="M8.98056 7.58749L9.0448 8.16568L9.54861 8.5241L9.49451 7.95605L8.98056 7.58749Z"
          fill="#A40000"
        />
      </g>
      <mask
        height="16"
        id="mask1370_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1370_1782_49644)">
        <path
          d="M10.3973 14.2587L10.7151 14.1403L10.7591 14.5799L10.4446 14.7422L10.3973 14.2587Z"
          fill="#A0FF56"
        />
      </g>
      <mask
        height="16"
        id="mask1371_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1371_1782_49644)">
        <path
          d="M9.49451 7.95605L9.54861 8.5241L10.022 9.011L9.97802 8.4531L9.49451 7.95605Z"
          fill="#C40000"
        />
      </g>
      <mask
        height="16"
        id="mask1372_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1372_1782_49644)">
        <path
          d="M10.5156 15.6179L10.3601 15.5334L10.306 16.0845L10.4582 16.213L10.5156 15.6179Z"
          fill="#53FFA4"
        />
      </g>
      <mask
        height="16"
        id="mask1373_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1373_1782_49644)">
        <path
          d="M10.3195 14.8741L10.4447 14.7422L10.448 15.2189L10.3229 15.3981L10.3195 14.8741Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1374_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1374_1782_49644)">
        <path
          d="M10.7489 15.6348L10.5156 15.6179L10.4582 16.213L10.6847 16.2773L10.7489 15.6348Z"
          fill="#50FFA7"
        />
      </g>
      <mask
        height="16"
        id="mask1375_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1375_1782_49644)">
        <path
          d="M10.5089 13.2883L11.0465 13.2206L11.1581 13.5993L10.6306 13.7042L10.5089 13.2883Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1376_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1376_1782_49644)">
        <path
          d="M6.51227 12.1826L6.80305 12.3314L7.00255 11.9831L6.73205 11.8039L6.51227 12.1826Z"
          fill="#FFC800"
        />
      </g>
      <mask
        height="16"
        id="mask1377_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1377_1782_49644)">
        <path
          d="M7.50974 7.93239L7.57736 8.5072L8.06426 8.44633L8.01016 7.86476L7.50974 7.93239Z"
          fill="#AD0000"
        />
      </g>
      <mask
        height="16"
        id="mask1378_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1378_1782_49644)">
        <path
          d="M6.19443 11.3846L6.24515 11.7126L6.49874 11.2629L6.46493 10.9045L6.19443 11.3846Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask1379_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1379_1782_49644)">
        <path
          d="M7.6416 11.8546L8.20965 11.9561L8.42943 11.7904L7.88505 11.6686L7.6416 11.8546Z"
          fill="#FFB600"
        />
      </g>
      <mask
        height="16"
        id="mask1380_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1380_1782_49644)">
        <path
          d="M11.2561 14.6746L11.0093 14.8605V15.5773L11.2528 15.4353L11.2561 14.6746Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1381_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1381_1782_49644)">
        <path
          d="M8.67288 11.6889L9.3728 11.7667L9.6061 11.7397L8.92986 11.6585L8.67288 11.6889Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask1382_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1382_1782_49644)">
        <path
          d="M7.00253 11.9831L7.42519 12.1116L7.6382 11.8546L7.23922 11.7025L7.00253 11.9831Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask1383_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1383_1782_49644)">
        <path
          d="M11.3407 12.3855L11.2223 12.7912L11.3745 13.596L11.4962 13.224L11.3407 12.3855Z"
          fill="#F8F500"
        />
      </g>
      <mask
        height="16"
        id="mask1384_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1384_1782_49644)">
        <path
          d="M6.33981 11.9797L6.51226 12.1826L6.73204 11.8039L6.57312 11.5638L6.33981 11.9797Z"
          fill="#FFB600"
        />
      </g>
      <mask
        height="16"
        id="mask1385_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1385_1782_49644)">
        <path
          d="M6.24515 11.7126L6.33982 11.9797L6.57313 11.5638L6.49874 11.2629L6.24515 11.7126Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1386_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1386_1782_49644)">
        <path
          d="M11.3745 13.5959L11.1818 13.9036L11.2561 14.6746L11.4489 14.4108L11.3745 13.5959Z"
          fill="#BAFF3C"
        />
      </g>
      <mask
        height="16"
        id="mask1387_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1387_1782_49644)">
        <path
          d="M10.35 12.896L10.9011 12.8656L11.0465 13.2206L10.5089 13.2883L10.35 12.896Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask1388_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1388_1782_49644)">
        <path
          d="M10.306 13.7785L10.6306 13.7041L10.7151 14.1403L10.3973 14.2587L10.306 13.7785Z"
          fill="#C1FF36"
        />
      </g>
      <mask
        height="16"
        id="mask1389_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1389_1782_49644)">
        <path
          d="M10.3567 14.962L10.3195 14.8741L10.3229 15.3981L10.3601 15.5334L10.3567 14.962Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1390_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1390_1782_49644)">
        <path
          d="M10.8301 10.3162L10.7997 10.8233L11.104 11.5774L11.1446 11.0938L10.8301 10.3162Z"
          fill="#FF7300"
        />
      </g>
      <mask
        height="16"
        id="mask1391_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1391_1782_49644)">
        <path
          d="M8.92984 11.6585L9.60609 11.7397L9.84615 11.7768L9.1902 11.7025L8.92984 11.6585Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1392_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1392_1782_49644)">
        <path
          d="M6.44125 10.4818L6.46492 10.9045L6.78952 10.5156L6.78276 10.0727L6.44125 10.4818Z"
          fill="#FF5D00"
        />
      </g>
      <mask
        height="16"
        id="mask1393_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1393_1782_49644)">
        <path
          d="M10.2688 14.3398L10.3973 14.2587L10.4447 14.7422L10.3195 14.8741L10.2688 14.3398Z"
          fill="#A4FF53"
        />
      </g>
      <mask
        height="16"
        id="mask1394_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1394_1782_49644)">
        <path
          d="M7.11752 8.71007L7.16148 9.25783L7.60442 9.0651L7.57737 8.50719L7.11752 8.71007Z"
          fill="#DA0000"
        />
      </g>
      <mask
        height="16"
        id="mask1395_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1395_1782_49644)">
        <path
          d="M10.1606 12.5478H10.7287L10.9011 12.8656L10.35 12.896L10.1606 12.5478Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1396_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1396_1782_49644)">
        <path
          d="M7.88504 11.6686L8.42942 11.7904L8.67287 11.6889L8.14878 11.5604L7.88504 11.6686Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask1397_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1397_1782_49644)">
        <path
          d="M11.0093 14.8605L10.749 14.9654V15.6348L11.0093 15.5774V14.8605Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1398_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1398_1782_49644)">
        <path
          d="M8.01016 7.86476L8.06426 8.44295L8.56807 8.52072L8.52749 7.94253L8.01016 7.86476Z"
          fill="#A40000"
        />
      </g>
      <mask
        height="16"
        id="mask1399_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1399_1782_49644)">
        <path
          d="M9.19022 11.7025L9.84618 11.7768L10.0829 11.8817L9.45057 11.8174L9.19022 11.7025Z"
          fill="#FFAB00"
        />
      </g>
      <mask
        height="16"
        id="mask1400_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1400_1782_49644)">
        <path
          d="M6.72867 11.8005L7.00255 11.9831L7.23923 11.7025L6.98902 11.4962L6.72867 11.8005Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1401_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1401_1782_49644)">
        <path
          d="M10.5156 14.9958L10.3567 14.962L10.3601 15.5334L10.5156 15.6179V14.9958Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1402_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1402_1782_49644)">
        <path
          d="M6.75571 9.57566L6.78276 10.0727L7.16822 9.76839L7.16145 9.25783L6.75571 9.57566Z"
          fill="#FF2500"
        />
      </g>
      <mask
        height="16"
        id="mask1403_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1403_1782_49644)">
        <path
          d="M9.94083 12.2435L10.5292 12.2705L10.7287 12.5478H10.1606L9.94083 12.2435Z"
          fill="#FFCC00"
        />
      </g>
      <mask
        height="16"
        id="mask1404_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1404_1782_49644)">
        <path
          d="M9.45056 11.8174L10.0829 11.8817L10.3128 12.0473L9.70415 12L9.45056 11.8174Z"
          fill="#FFAE00"
        />
      </g>
      <mask
        height="16"
        id="mask1405_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1405_1782_49644)">
        <path
          d="M10.749 14.9653L10.5157 14.9958V15.6179L10.749 15.6348V14.9653Z"
          fill="#80FF77"
        />
      </g>
      <mask
        height="16"
        id="mask1406_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1406_1782_49644)">
        <path
          d="M7.23923 11.7025L7.64159 11.8546L7.88504 11.6686L7.50634 11.4996L7.23923 11.7025Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask1407_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1407_1782_49644)">
        <path
          d="M9.70416 12L10.3128 12.0473L10.5292 12.2705L9.94085 12.2435L9.70416 12Z"
          fill="#FFBD00"
        />
      </g>
      <mask
        height="16"
        id="mask1408_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1408_1782_49644)">
        <path
          d="M10.1708 13.3187L10.5089 13.2883L10.6306 13.7041L10.306 13.7785L10.1708 13.3187Z"
          fill="#E1FF16"
        />
      </g>
      <mask
        height="16"
        id="mask1409_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1409_1782_49644)">
        <path
          d="M10.4514 9.61623L10.4345 10.1471L10.7997 10.8233L10.8301 10.3161L10.4514 9.61623Z"
          fill="#FF3F00"
        />
      </g>
      <mask
        height="16"
        id="mask1410_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1410_1782_49644)">
        <path
          d="M6.46494 10.9045L6.49875 11.2629L6.79968 10.9045L6.78953 10.5156L6.46494 10.9045Z"
          fill="#FF6F00"
        />
      </g>
      <mask
        height="16"
        id="mask1411_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1411_1782_49644)">
        <path
          d="M11.104 11.5774L10.9958 12.0169L11.2223 12.7912L11.3407 12.3855L11.104 11.5774Z"
          fill="#FFB600"
        />
      </g>
      <mask
        height="16"
        id="mask1412_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1412_1782_49644)">
        <path
          d="M8.14877 11.5604L8.67286 11.6889L8.92646 11.6585L8.42942 11.5266L8.14877 11.5604Z"
          fill="#FF9800"
        />
      </g>
      <mask
        height="16"
        id="mask1413_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1413_1782_49644)">
        <path
          d="M6.57312 11.5638L6.72866 11.8005L6.98901 11.4962L6.85376 11.2291L6.57312 11.5638Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1414_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1414_1782_49644)">
        <path
          d="M11.1817 13.9036L10.9417 14.1302L11.0093 14.8605L11.2561 14.6746L11.1817 13.9036Z"
          fill="#B7FF40"
        />
      </g>
      <mask
        height="16"
        id="mask1415_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1415_1782_49644)">
        <path
          d="M8.5275 7.94252L8.56807 8.52071L9.06849 8.73711L9.04483 8.16568L8.5275 7.94252Z"
          fill="#A40000"
        />
      </g>
      <mask
        height="16"
        id="mask1416_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1416_1782_49644)">
        <path
          d="M10.2993 14.3804L10.2688 14.3398L10.3195 14.8741L10.3567 14.962L10.2993 14.3804Z"
          fill="#A7FF50"
        />
      </g>
      <mask
        height="16"
        id="mask1417_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1417_1782_49644)">
        <path
          d="M10.1674 13.8157L10.306 13.7785L10.3973 14.2587L10.2688 14.3398L10.1674 13.8157Z"
          fill="#C7FF30"
        />
      </g>
      <mask
        height="16"
        id="mask1418_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1418_1782_49644)">
        <path
          d="M6.49875 11.2629L6.57314 11.5638L6.85378 11.2291L6.79968 10.9045L6.49875 11.2629Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask1419_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1419_1782_49644)">
        <path
          d="M11.2223 12.7912L11.0363 13.1361L11.1817 13.9003L11.3745 13.5959L11.2223 12.7912Z"
          fill="#F1FC06"
        />
      </g>
      <mask
        height="16"
        id="mask1420_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1420_1782_49644)">
        <path
          d="M9.99493 12.8893L10.35 12.896L10.5089 13.2883L10.1708 13.3187L9.99493 12.8893Z"
          fill="#FBF100"
        />
      </g>
      <mask
        height="16"
        id="mask1421_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1421_1782_49644)">
        <path
          d="M10.022 9.01099L10.0152 9.56213L10.4345 10.1471L10.4514 9.61623L10.022 9.01099Z"
          fill="#FF1300"
        />
      </g>
      <mask
        height="16"
        id="mask1422_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1422_1782_49644)">
        <path
          d="M7.57736 8.50719L7.60441 9.06509L8.0744 9.00761L8.06426 8.44295L7.57736 8.50719Z"
          fill="#C40000"
        />
      </g>
      <mask
        height="16"
        id="mask1423_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1423_1782_49644)">
        <path
          d="M9.0448 8.16569L9.06847 8.73712L9.55537 9.08876L9.5486 8.5241L9.0448 8.16569Z"
          fill="#B60000"
        />
      </g>
      <mask
        height="16"
        id="mask1424_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1424_1782_49644)">
        <path
          d="M8.42941 11.5266L8.92645 11.6585L9.19019 11.7025L8.71682 11.574L8.42941 11.5266Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1425_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1425_1782_49644)">
        <path
          d="M7.50635 11.4996L7.88505 11.6686L8.14878 11.5604L7.79713 11.3779L7.50635 11.4996Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask1426_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1426_1782_49644)">
        <path
          d="M6.98901 11.4962L7.23922 11.7025L7.50634 11.4996L7.2798 11.273L6.98901 11.4962Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask1427_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1427_1782_49644)">
        <path
          d="M9.54861 8.52409L9.55538 9.08876L10.0152 9.56213L10.022 9.01099L9.54861 8.52409Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask1428_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1428_1782_49644)">
        <path
          d="M6.78278 10.0727L6.78954 10.5156L7.15471 10.2316L7.16824 9.76839L6.78278 10.0727Z"
          fill="#FF3800"
        />
      </g>
      <mask
        height="16"
        id="mask1429_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1429_1782_49644)">
        <path
          d="M10.4548 14.3635L10.2993 14.3804L10.3567 14.962L10.5157 14.9958L10.4548 14.3635Z"
          fill="#ADFF49"
        />
      </g>
      <mask
        height="16"
        id="mask1430_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1430_1782_49644)">
        <path
          d="M10.9417 14.1302L10.6847 14.2823L10.749 14.9654L11.0093 14.8605L10.9417 14.1302Z"
          fill="#B4FF43"
        />
      </g>
      <mask
        height="16"
        id="mask1431_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1431_1782_49644)">
        <path
          d="M9.78531 12.5038L10.1606 12.5478L10.35 12.896L9.99495 12.8893L9.78531 12.5038Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask1432_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1432_1782_49644)">
        <path
          d="M7.16147 9.25782L7.16823 9.76839L7.59089 9.58919L7.60441 9.06509L7.16147 9.25782Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask1433_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1433_1782_49644)">
        <path
          d="M8.71683 11.574L9.1902 11.7025L9.45056 11.8174L9.00423 11.6991L8.71683 11.574Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1434_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1434_1782_49644)">
        <path
          d="M10.6847 14.2857L10.4548 14.3635L10.5156 14.9958L10.7489 14.9653L10.6847 14.2857Z"
          fill="#B1FF46"
        />
      </g>
      <mask
        height="16"
        id="mask1435_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1435_1782_49644)">
        <path
          d="M10.0186 13.3085L10.1708 13.3187L10.306 13.7785L10.1674 13.8157L10.0186 13.3085Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask1436_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1436_1782_49644)">
        <path
          d="M10.7997 10.8233L10.705 11.2933L10.9958 12.0169L11.104 11.5773L10.7997 10.8233Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask1437_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1437_1782_49644)">
        <path
          d="M9.54523 12.1724L9.94083 12.2435L10.1606 12.5478L9.78529 12.5038L9.54523 12.1724Z"
          fill="#FFB900"
        />
      </g>
      <mask
        height="16"
        id="mask1438_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1438_1782_49644)">
        <path
          d="M9.00424 11.6991L9.45057 11.8174L9.70416 12L9.2815 11.9019L9.00424 11.6991Z"
          fill="#FF9C00"
        />
      </g>
      <mask
        height="16"
        id="mask1439_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1439_1782_49644)">
        <path
          d="M10.1877 13.8056L10.1674 13.8157L10.2688 14.3398L10.2993 14.3804L10.1877 13.8056Z"
          fill="#CEFF29"
        />
      </g>
      <mask
        height="16"
        id="mask1440_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1440_1782_49644)">
        <path
          d="M9.28149 11.9019L9.70415 12L9.94084 12.2435L9.54523 12.1724L9.28149 11.9019Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1441_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1441_1782_49644)">
        <path
          d="M6.85376 11.2291L6.98901 11.4962L7.2798 11.273L7.1716 10.9856L6.85376 11.2291Z"
          fill="#FF7700"
        />
      </g>
      <mask
        height="16"
        id="mask1442_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1442_1782_49644)">
        <path
          d="M7.79715 11.3779L8.1488 11.5604L8.42944 11.5266L8.10484 11.3407L7.79715 11.3779Z"
          fill="#FF8200"
        />
      </g>
      <mask
        height="16"
        id="mask1443_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1443_1782_49644)">
        <path
          d="M6.78952 10.5156L6.79966 10.9045L7.14117 10.6408L7.15469 10.2316L6.78952 10.5156Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1444_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1444_1782_49644)">
        <path
          d="M8.06424 8.44295L8.07438 9.00761L8.5579 9.082L8.56804 8.52072L8.06424 8.44295Z"
          fill="#B60000"
        />
      </g>
      <mask
        height="16"
        id="mask1445_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1445_1782_49644)">
        <path
          d="M11.0363 13.1361L10.803 13.41L10.9417 14.1302L11.1817 13.9036L11.0363 13.1361Z"
          fill="#EBFF0C"
        />
      </g>
      <mask
        height="16"
        id="mask1446_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1446_1782_49644)">
        <path
          d="M6.79968 10.9045L6.85378 11.2291L7.17162 10.9856L7.14119 10.6407L6.79968 10.9045Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask1447_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1447_1782_49644)">
        <path
          d="M7.27982 11.273L7.50636 11.4996L7.79714 11.3779L7.59765 11.1378L7.27982 11.273Z"
          fill="#FF7700"
        />
      </g>
      <mask
        height="16"
        id="mask1448_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1448_1782_49644)">
        <path
          d="M10.9958 12.0169L10.8199 12.399L11.0363 13.1361L11.2223 12.7912L10.9958 12.0169Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask1449_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1449_1782_49644)">
        <path
          d="M9.82587 12.8386L9.99493 12.8893L10.1708 13.3187L10.0186 13.3085L9.82587 12.8386Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1450_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1450_1782_49644)">
        <path
          d="M10.3331 13.738L10.1877 13.8056L10.2993 14.3804L10.4548 14.3635L10.3331 13.738Z"
          fill="#D7FF1F"
        />
      </g>
      <mask
        height="16"
        id="mask1451_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1451_1782_49644)">
        <path
          d="M10.4345 10.1471L10.35 10.6441L10.705 11.2933L10.7997 10.8233L10.4345 10.1471Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1452_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1452_1782_49644)">
        <path
          d="M8.10483 11.3407L8.42943 11.5266L8.71683 11.574L8.41928 11.3948L8.10483 11.3407Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask1453_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1453_1782_49644)">
        <path
          d="M7.16822 9.76839L7.15469 10.2316L7.5503 10.0626L7.59087 9.58919L7.16822 9.76839Z"
          fill="#FF1A00"
        />
      </g>
      <mask
        height="16"
        id="mask1454_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1454_1782_49644)">
        <path
          d="M7.60441 9.0651L7.59088 9.58919L8.0372 9.53171L8.0744 9.00761L7.60441 9.0651Z"
          fill="#DA0000"
        />
      </g>
      <mask
        height="16"
        id="mask1455_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1455_1782_49644)">
        <path
          d="M10.0254 13.2511L10.0186 13.3085L10.1674 13.8157L10.1877 13.8056L10.0254 13.2511Z"
          fill="#F4F802"
        />
      </g>
      <mask
        height="16"
        id="mask1456_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1456_1782_49644)">
        <path
          d="M10.8031 13.41L10.5529 13.6095L10.6813 14.2857L10.9417 14.1302L10.8031 13.41Z"
          fill="#E4FF13"
        />
      </g>
      <mask
        height="16"
        id="mask1457_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1457_1782_49644)">
        <path
          d="M8.56807 8.52071L8.55792 9.082L9.03806 9.28825L9.06849 8.73711L8.56807 8.52071Z"
          fill="#BB0000"
        />
      </g>
      <mask
        height="16"
        id="mask1458_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1458_1782_49644)">
        <path
          d="M9.59256 12.4159L9.78529 12.5038L9.99493 12.8893L9.82586 12.8386L9.59256 12.4159Z"
          fill="#FFC100"
        />
      </g>
      <mask
        height="16"
        id="mask1459_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1459_1782_49644)">
        <path
          d="M10.5528 13.6095L10.3331 13.738L10.4548 14.3635L10.6813 14.2857L10.5528 13.6095Z"
          fill="#DEFF19"
        />
      </g>
      <mask
        height="16"
        id="mask1460_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1460_1782_49644)">
        <path
          d="M7.1716 10.9856L7.2798 11.273L7.59764 11.1378L7.51649 10.8402L7.1716 10.9856Z"
          fill="#FF6000"
        />
      </g>
      <mask
        height="16"
        id="mask1461_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1461_1782_49644)">
        <path
          d="M8.41928 11.3948L8.71683 11.574L9.00423 11.6991L8.73374 11.53L8.41928 11.3948Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask1462_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1462_1782_49644)">
        <path
          d="M7.59766 11.1378L7.79715 11.3779L8.10484 11.3407L7.9324 11.1006L7.59766 11.1378Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask1463_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1463_1782_49644)">
        <path
          d="M10.0152 9.56213L9.9476 10.0828L10.35 10.6441L10.4345 10.1471L10.0152 9.56213Z"
          fill="#FF1E00"
        />
      </g>
      <mask
        height="16"
        id="mask1464_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1464_1782_49644)">
        <path
          d="M9.32883 12.0473L9.54523 12.1724L9.78529 12.5038L9.59256 12.4159L9.32883 12.0473Z"
          fill="#FFA700"
        />
      </g>
      <mask
        height="16"
        id="mask1465_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1465_1782_49644)">
        <path
          d="M9.06849 8.73711L9.03806 9.28826L9.50805 9.62638L9.55538 9.08876L9.06849 8.73711Z"
          fill="#C80000"
        />
      </g>
      <mask
        height="16"
        id="mask1466_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1466_1782_49644)">
        <path
          d="M8.73373 11.53L9.00423 11.6991L9.28149 11.9019L9.04143 11.7498L8.73373 11.53Z"
          fill="#FF8600"
        />
      </g>
      <mask
        height="16"
        id="mask1467_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1467_1782_49644)">
        <path
          d="M7.1547 10.2316L7.14117 10.6408L7.51311 10.4818L7.5503 10.0626L7.1547 10.2316Z"
          fill="#FF3000"
        />
      </g>
      <mask
        height="16"
        id="mask1468_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1468_1782_49644)">
        <path
          d="M10.705 11.2933L10.5427 11.7126L10.82 12.399L10.9958 12.0169L10.705 11.2933Z"
          fill="#FF8900"
        />
      </g>
      <mask
        height="16"
        id="mask1469_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1469_1782_49644)">
        <path
          d="M9.04144 11.7498L9.28151 11.9019L9.54525 12.1724L9.32885 12.0473L9.04144 11.7498Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1470_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1470_1782_49644)">
        <path
          d="M9.55536 9.08876L9.50803 9.62638L9.94759 10.0828L10.0152 9.56213L9.55536 9.08876Z"
          fill="#E40000"
        />
      </g>
      <mask
        height="16"
        id="mask1471_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1471_1782_49644)">
        <path
          d="M10.82 12.399L10.5968 12.7134L10.8031 13.41L11.0364 13.1361L10.82 12.399Z"
          fill="#FFC800"
        />
      </g>
      <mask
        height="16"
        id="mask1472_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1472_1782_49644)">
        <path
          d="M7.14117 10.6408L7.17161 10.9856L7.51649 10.8402L7.51311 10.4818L7.14117 10.6408Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1473_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1473_1782_49644)">
        <path
          d="M9.81573 12.7371L9.82588 12.8386L10.0186 13.3085L10.0254 13.2511L9.81573 12.7371Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask1474_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1474_1782_49644)">
        <path
          d="M10.1538 13.1395L10.0254 13.2511L10.1877 13.8056L10.3331 13.738L10.1538 13.1395Z"
          fill="#FEED00"
        />
      </g>
      <mask
        height="16"
        id="mask1475_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1475_1782_49644)">
        <path
          d="M8.07439 9.00761L8.0372 9.53171L8.49705 9.60609L8.55791 9.082L8.07439 9.00761Z"
          fill="#CD0000"
        />
      </g>
      <mask
        height="16"
        id="mask1476_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1476_1782_49644)">
        <path
          d="M7.93237 11.1006L8.10482 11.3407L8.41927 11.3948L8.27726 11.1547L7.93237 11.1006Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask1477_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1477_1782_49644)">
        <path
          d="M7.59087 9.58919L7.55029 10.0626L7.97295 10.0118L8.03719 9.53171L7.59087 9.58919Z"
          fill="#F10800"
        />
      </g>
      <mask
        height="16"
        id="mask1478_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1478_1782_49644)">
        <path
          d="M10.5968 12.7134L10.3635 12.9603L10.5528 13.6095L10.8031 13.41L10.5968 12.7134Z"
          fill="#FFD300"
        />
      </g>
      <mask
        height="16"
        id="mask1479_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1479_1782_49644)">
        <path
          d="M7.51648 10.8402L7.59763 11.1378L7.93237 11.1006L7.88165 10.7963L7.51648 10.8402Z"
          fill="#FF5200"
        />
      </g>
      <mask
        height="16"
        id="mask1480_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1480_1782_49644)">
        <path
          d="M10.3635 12.9603L10.1539 13.1395L10.3331 13.7413L10.5529 13.6095L10.3635 12.9603Z"
          fill="#FFE200"
        />
      </g>
      <mask
        height="16"
        id="mask1481_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1481_1782_49644)">
        <path
          d="M9.56213 12.2739L9.59257 12.4159L9.82587 12.8386L9.81573 12.7371L9.56213 12.2739Z"
          fill="#FFB200"
        />
      </g>
      <mask
        height="16"
        id="mask1482_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1482_1782_49644)">
        <path
          d="M10.35 10.6441L10.2046 11.0905L10.5427 11.7126L10.705 11.2933L10.35 10.6441Z"
          fill="#FF5900"
        />
      </g>
      <mask
        height="16"
        id="mask1483_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1483_1782_49644)">
        <path
          d="M8.27728 11.1547L8.41929 11.3948L8.73375 11.53L8.62217 11.3068L8.27728 11.1547Z"
          fill="#FF6400"
        />
      </g>
      <mask
        height="16"
        id="mask1484_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1484_1782_49644)">
        <path
          d="M7.55032 10.0626L7.51312 10.4818L7.90873 10.4345L7.97297 10.0118L7.55032 10.0626Z"
          fill="#FF2200"
        />
      </g>
      <mask
        height="16"
        id="mask1485_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1485_1782_49644)">
        <path
          d="M9.92732 12.5782L9.81573 12.7371L10.0254 13.2511L10.1539 13.1395L9.92732 12.5782Z"
          fill="#FFC400"
        />
      </g>
      <mask
        height="16"
        id="mask1486_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1486_1782_49644)">
        <path
          d="M7.51312 10.4818L7.5165 10.8402L7.88168 10.7963L7.90873 10.4345L7.51312 10.4818Z"
          fill="#FF3800"
        />
      </g>
      <mask
        height="16"
        id="mask1487_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1487_1782_49644)">
        <path
          d="M10.5427 11.7126L10.3331 12.0676L10.5968 12.7134L10.82 12.399L10.5427 11.7126Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1488_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1488_1782_49644)">
        <path
          d="M8.5579 9.082L8.49704 9.60609L8.95689 9.8022L9.03804 9.28826L8.5579 9.082Z"
          fill="#D10000"
        />
      </g>
      <mask
        height="16"
        id="mask1489_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1489_1782_49644)">
        <path
          d="M9.27475 11.8715L9.32885 12.0473L9.59259 12.4159L9.56215 12.2739L9.27475 11.8715Z"
          fill="#FF9400"
        />
      </g>
      <mask
        height="16"
        id="mask1490_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1490_1782_49644)">
        <path
          d="M8.62216 11.3069L8.73374 11.53L9.04143 11.7498L8.9569 11.5469L8.62216 11.3069Z"
          fill="#FF6C00"
        />
      </g>
      <mask
        height="16"
        id="mask1491_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1491_1782_49644)">
        <path
          d="M8.95691 11.5469L9.04144 11.7498L9.32884 12.0473L9.27475 11.8715L8.95691 11.5469Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask1492_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1492_1782_49644)">
        <path
          d="M9.94762 10.0828L9.82251 10.5562L10.2046 11.0905L10.35 10.6441L9.94762 10.0828Z"
          fill="#FF3000"
        />
      </g>
      <mask
        height="16"
        id="mask1493_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1493_1782_49644)">
        <path
          d="M7.88165 10.7963L7.93237 11.1006L8.27726 11.1547L8.25697 10.8571L7.88165 10.7963Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1494_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1494_1782_49644)">
        <path
          d="M8.0372 9.53171L7.97296 10.0118L8.40576 10.0795L8.49705 9.60609L8.0372 9.53171Z"
          fill="#E80000"
        />
      </g>
      <mask
        height="16"
        id="mask1495_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1495_1782_49644)">
        <path
          d="M9.03806 9.28825L8.95691 9.80558L9.40323 10.1234L9.50805 9.62638L9.03806 9.28825Z"
          fill="#DF0000"
        />
      </g>
      <mask
        height="16"
        id="mask1496_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1496_1782_49644)">
        <path
          d="M10.1167 12.355L9.92731 12.5782L10.1572 13.1395L10.3635 12.9603L10.1167 12.355Z"
          fill="#FFB200"
        />
      </g>
      <mask
        height="16"
        id="mask1497_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1497_1782_49644)">
        <path
          d="M9.50805 9.62638L9.40323 10.1234L9.8225 10.5562L9.94761 10.0795L9.50805 9.62638Z"
          fill="#FA0F00"
        />
      </g>
      <mask
        height="16"
        id="mask1498_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1498_1782_49644)">
        <path
          d="M10.3364 12.0676L10.1167 12.355L10.3635 12.9603L10.6002 12.7134L10.3364 12.0676Z"
          fill="#FFA300"
        />
      </g>
      <mask
        height="16"
        id="mask1499_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1499_1782_49644)">
        <path
          d="M9.65005 12.071L9.56213 12.2739L9.81573 12.7371L9.92731 12.5782L9.65005 12.071Z"
          fill="#FF9F00"
        />
      </g>
      <mask
        height="16"
        id="mask1500_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1500_1782_49644)">
        <path
          d="M10.2046 11.0905L10.0186 11.4827L10.3331 12.0676L10.5427 11.7126L10.2046 11.0905Z"
          fill="#FF6800"
        />
      </g>
      <mask
        height="16"
        id="mask1501_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1501_1782_49644)">
        <path
          d="M7.9087 10.4345L7.88165 10.7963L8.25697 10.8571L8.31445 10.4987L7.9087 10.4345Z"
          fill="#FF3000"
        />
      </g>
      <mask
        height="16"
        id="mask1502_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1502_1782_49644)">
        <path
          d="M8.25699 10.8571L8.27728 11.1547L8.62216 11.3069L8.63231 11.0228L8.25699 10.8571Z"
          fill="#FF4A00"
        />
      </g>
      <mask
        height="16"
        id="mask1503_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1503_1782_49644)">
        <path
          d="M7.97297 10.0118L7.90872 10.4345L8.31447 10.4987L8.40576 10.0795L7.97297 10.0118Z"
          fill="#FF1A00"
        />
      </g>
      <mask
        height="16"
        id="mask1504_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1504_1782_49644)">
        <path
          d="M9.33899 11.6382L9.27475 11.8715L9.56215 12.2739L9.65345 12.071L9.33899 11.6382Z"
          fill="#FF7E00"
        />
      </g>
      <mask
        height="16"
        id="mask1505_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1505_1782_49644)">
        <path
          d="M8.49706 9.60609L8.40576 10.0795L8.83856 10.2688L8.9569 9.80221L8.49706 9.60609Z"
          fill="#ED0400"
        />
      </g>
      <mask
        height="16"
        id="mask1506_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1506_1782_49644)">
        <path
          d="M8.63231 11.0228L8.62216 11.3069L8.9569 11.5469L8.9941 11.2832L8.63231 11.0228Z"
          fill="#FF5500"
        />
      </g>
      <mask
        height="16"
        id="mask1507_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1507_1782_49644)">
        <path
          d="M9.81912 11.8107L9.65005 12.071L9.92732 12.5782L10.1167 12.355L9.81912 11.8107Z"
          fill="#FF8D00"
        />
      </g>
      <mask
        height="16"
        id="mask1508_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1508_1782_49644)">
        <path
          d="M8.9941 11.2832L8.95691 11.5469L9.27475 11.8715L9.33899 11.6382L8.9941 11.2832Z"
          fill="#FF6800"
        />
      </g>
      <mask
        height="16"
        id="mask1509_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1509_1782_49644)">
        <path
          d="M10.0186 11.4827L9.81912 11.8107L10.1167 12.355L10.3365 12.0676L10.0186 11.4827Z"
          fill="#FF7A00"
        />
      </g>
      <mask
        height="16"
        id="mask1510_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1510_1782_49644)">
        <path
          d="M9.82251 10.5562L9.65683 10.9789L10.0186 11.4827L10.2046 11.0905L9.82251 10.5562Z"
          fill="#FF4300"
        />
      </g>
      <mask
        height="16"
        id="mask1511_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1511_1782_49644)">
        <path
          d="M8.31447 10.4987L8.25699 10.8572L8.63231 11.0228L8.71684 10.6746L8.31447 10.4987Z"
          fill="#FF3400"
        />
      </g>
      <mask
        height="16"
        id="mask1512_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1512_1782_49644)">
        <path
          d="M8.95691 9.8022L8.83856 10.2688L9.26122 10.5697L9.40323 10.1234L8.95691 9.8022Z"
          fill="#FA0F00"
        />
      </g>
      <mask
        height="16"
        id="mask1513_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1513_1782_49644)">
        <path
          d="M8.40575 10.0795L8.31445 10.4987L8.71682 10.6746L8.83854 10.2688L8.40575 10.0795Z"
          fill="#FF1A00"
        />
      </g>
      <mask
        height="16"
        id="mask1514_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1514_1782_49644)">
        <path
          d="M9.40321 10.1234L9.2612 10.5697L9.6568 10.9789L9.82248 10.5562L9.40321 10.1234Z"
          fill="#FF2200"
        />
      </g>
      <mask
        height="16"
        id="mask1515_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1515_1782_49644)">
        <path
          d="M9.481 11.3407L9.33899 11.6382L9.65006 12.071L9.81912 11.8107L9.481 11.3407Z"
          fill="#FF6800"
        />
      </g>
      <mask
        height="16"
        id="mask1516_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1516_1782_49644)">
        <path
          d="M9.65681 10.9789L9.48099 11.3407L9.81911 11.8107L10.0186 11.4827L9.65681 10.9789Z"
          fill="#FF5500"
        />
      </g>
      <mask
        height="16"
        id="mask1517_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1517_1782_49644)">
        <path
          d="M8.71682 10.6746L8.63229 11.0228L8.99409 11.2832L9.10905 10.9586L8.71682 10.6746Z"
          fill="#FF3B00"
        />
      </g>
      <mask
        height="16"
        id="mask1518_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1518_1782_49644)">
        <path
          d="M9.10904 10.9586L8.99408 11.2832L9.33897 11.6382L9.48098 11.3407L9.10904 10.9586Z"
          fill="#FF4E00"
        />
      </g>
      <mask
        height="16"
        id="mask1519_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1519_1782_49644)">
        <path
          d="M8.83855 10.2688L8.71683 10.6746L9.10905 10.9586L9.26121 10.5697L8.83855 10.2688Z"
          fill="#FF2500"
        />
      </g>
      <mask
        height="16"
        id="mask1520_1782_49644"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="5"
        y="4"
      >
        <path
          d="M19.0566 4.89941H5.03467V19.0262H19.0566V4.89941Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1520_1782_49644)">
        <path
          d="M9.25784 10.5697L9.10907 10.9586L9.48101 11.3407L9.65683 10.9789L9.25784 10.5697Z"
          fill="#FF3800"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1782_49644">
        <rect
          fill="white"
          height="14.1268"
          transform="translate(5.03467 4.89941)"
          width="14.0254"
        />
      </clipPath>
    </defs>
  </svg>
)

export default CurveIcon
