import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { setCookie } from '../utils'

import Dropdown from './Dropdown'
import IconContainer from './icons/IconContainer'

const languageNames = {
  en: 'English',
  es: 'Español',
  fr: 'Française',
  ja: '日本人',
  ko: '한국어',
  nl: 'Nederlands',
  ru: 'Русский',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  zh: '中文'
}

const LanguageSelectorMobile = function ({
  handleLanguageChange,
  locale,
  locales,
  pathname,
  query
}) {
  const ELEMENTS_PER_LINE = 5
  return (
    <div className="text-gray-primary text-sm">
      {locales
        .filter(
          localeOption => languageNames[localeOption] !== languageNames[locale]
        )
        .map(function (localeOption, idx) {
          const isLastElement = idx === locales.length - 1
          const isLineLastElement = (idx + 1) % ELEMENTS_PER_LINE === 0
          return (
            <span className="mb-4 mr-6 inline-block" key={localeOption}>
              <span>
                <Link
                  href={{
                    pathname,
                    query
                  }}
                  locale={localeOption}
                  onClick={() => handleLanguageChange(localeOption)}
                >
                  {languageNames[localeOption]}
                </Link>
              </span>
              {isLineLastElement && !isLastElement ? <br /> : ''}
            </span>
          )
        })}
    </div>
  )
}

const LanguageSelectorDesktop = function ({
  handleLanguageChange,
  locale,
  locales,
  pathname,
  query
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const DropdownRef = useOnClickOutside(() => setDropdownOpen(false))

  const handleClick = function (localeOption) {
    setDropdownOpen(false)
    handleLanguageChange(localeOption)
  }

  return (
    <Dropdown
      className="relative z-30"
      selector={
        <button
          className="flex w-full items-center justify-between p-3 text-white"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          ref={DropdownRef}
        >
          <div className="mr-3 flex items-center">
            <IconContainer className="mr-4 hidden sm:block" name={locale} />
            {languageNames[locale]}
          </div>
          <IconContainer
            className={dropdownOpen ? 'rotate-180 transform' : ''}
            name="caret"
          />
        </button>
      }
    >
      <div className="bottom-12 right-0 z-10 mt-2 md:absolute lg:static">
        <ul className="bg-selector w-full rounded-lg">
          {locales
            .filter(
              localeOption =>
                languageNames[localeOption] !== languageNames[locale]
            )
            .map(localeOption => (
              <li className="w-full" key={localeOption}>
                <Link
                  className="mb-2 flex w-full items-center rounded-md p-3 text-sm text-white"
                  href={{
                    pathname,
                    query
                  }}
                  locale={localeOption}
                  onClick={() => handleClick(localeOption)}
                >
                  <IconContainer
                    className="mr-4 hidden sm:block"
                    name={localeOption}
                  />
                  {languageNames[localeOption]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </Dropdown>
  )
}

const LanguageSelector = function () {
  const { locale, locales, pathname, query } = useRouter()
  const persistLanguage = function (newLang) {
    const languageCookie = 'NEXT_LOCALE'
    setCookie(languageCookie, newLang, 365)
  }
  const handleLanguageChange = function (newLang) {
    persistLanguage(newLang)
    window.gtag?.('event', `Change language from ${locale} to ${newLang}`)
  }
  return (
    <>
      <div className="md:hidden">
        <LanguageSelectorMobile
          handleLanguageChange={handleLanguageChange}
          locale={locale}
          locales={locales}
          pathname={pathname}
          query={query}
        />
      </div>
      <div className="hidden md:block">
        <LanguageSelectorDesktop
          handleLanguageChange={handleLanguageChange}
          locale={locale}
          locales={locales}
          pathname={pathname}
          query={query}
        />
      </div>
    </>
  )
}

export default LanguageSelector
