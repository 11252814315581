const AvalancheIcon = props => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 12 10"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.53988 6.18717C8.78933 5.7563 9.19185 5.7563 9.44129 6.18717L10.9947 8.9141C11.2441 9.34497 11.04 9.69646 10.5411 9.69646H7.41169C6.91846 9.69646 6.71437 9.34497 6.95814 8.9141L8.53988 6.18717ZM5.53515 0.937407C5.7846 0.506541 6.18145 0.506541 6.4309 0.937407L6.77673 1.56103L7.59311 2.99536C7.79153 3.40355 7.79153 3.88544 7.59311 4.29363L4.85484 9.03882C4.60539 9.42434 4.19153 9.66812 3.73232 9.69646H1.45893C0.960035 9.69646 0.75594 9.35063 1.00539 8.9141L5.53515 0.937407Z"
      fill="#E84142"
    />
  </svg>
)

export default AvalancheIcon
