import ExternalLink from './ExternalLink'
import IconContainer from './icons/IconContainer'

const Button = function ({
  buttonType = 'button',
  children,
  className = '',
  disabled = false,
  href = '',
  loading = false,
  onClick = () => null,
  size = 'md',
  ...props
}) {
  const buttonSize = {
    lg: 'px-7 py-2',
    md: 'px-7 py-1',
    xl: 'px-8 py-4'
  }
  const _className = `bg-teal-primary ml-auto grow rounded-lg
  text-white focus:outline-none
  ${buttonSize[size]} ${className} ${
    disabled ? 'cursor-not-allowed opacity-50' : ''
  }`

  if (href) {
    return (
      <ExternalLink className={_className} href={href}>
        {children}
      </ExternalLink>
    )
  }
  return (
    <button
      className={_className}
      disabled={disabled}
      onClick={onClick}
      type={buttonType}
      {...props}
    >
      {loading ? (
        <div className="relative inline-flex items-center justify-center">
          {children}
          <IconContainer
            className="absolute -right-8"
            name="loading"
            width="20"
          />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
