const Optimism = props => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12c0 6.629-5.371 12-12 12S0 18.629 0 12 5.371 0 12 0s12 5.371 12 12Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#ff0420',
        fillOpacity: 1
      }}
    />
    <path
      d="M8.5 15.188c-.715 0-1.3-.168-1.758-.504-.449-.34-.676-.832-.676-1.461 0-.133.016-.293.043-.485.079-.43.188-.949.332-1.558.407-1.653 1.465-2.477 3.164-2.477.461 0 .88.074 1.243.235.363.148.652.378.863.687.21.3.316.66.316 1.078 0 .125-.011.285-.043.477-.09.53-.195 1.054-.324 1.558-.21.82-.57 1.442-1.09 1.848-.515.406-1.207.601-2.07.601Zm.129-1.297c.336 0 .621-.102.855-.297.243-.196.414-.5.516-.914.137-.567.242-1.055.316-1.477.024-.125.04-.254.04-.39 0-.547-.286-.82-.856-.82-.336 0-.625.1-.863.3-.239.195-.407.496-.504.91-.113.402-.219.895-.328 1.477-.024.12-.04.246-.04.383-.003.554.29.828.864.828ZM12.445 15.102a.19.19 0 0 1-.152-.063c-.027-.047-.04-.101-.027-.164l1.242-5.855a.234.234 0 0 1 .101-.165.26.26 0 0 1 .172-.062h2.395c.668 0 1.199.14 1.601.414.41.277.618.676.618 1.2 0 .148-.02.308-.055.468-.149.691-.45 1.203-.91 1.531-.453.332-1.07.496-1.86.496h-1.215l-.41 1.973a.263.263 0 0 1-.101.164.267.267 0 0 1-.172.063Zm3.188-3.442c.254 0 .472-.066.66-.207a.994.994 0 0 0 .379-.594c.02-.101.027-.195.027-.27 0-.175-.054-.308-.152-.398-.102-.097-.281-.144-.524-.144h-1.082l-.34 1.613Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
  </svg>
)

export default Optimism
