import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'

const Modal = dynamic(() => import('./Modal'), {
  ssr: false
})

const WalletSettingsModal = function ({
  account,
  deactivateConnector,
  modalIsOpen,
  onRequestClose,
  openWalletConnector
}) {
  const { t } = useTranslation()

  return (
    <Modal
      className="w-100 bg-main-opacity mx-4 rounded-xl p-4 text-white"
      modalIsOpen={modalIsOpen}
      onRequestClose={onRequestClose}
    >
      <h4 className="mb-6 text-2xl">{t('wallet-settings')}</h4>
      <div className="mb-2 last:mb-0">
        <button
          className="bg-blue-primary hover:bg-blue-secondary flex w-80 items-center rounded-lg p-4 text-lg font-medium leading-[31.32px] focus:outline-none focus:ring"
          data-sentry="wallet-settings-btn"
          onClick={function () {
            openWalletConnector()
            onRequestClose()
          }}
        >
          {t('change-wallet')}
        </button>
      </div>

      <div className="mb-2 last:mb-0">
        <button
          className="bg-blue-primary hover:bg-blue-secondary flex w-80 items-center rounded-lg p-4 text-lg font-medium leading-[31.32px] focus:outline-none focus:ring"
          data-sentry="wallet-settings-btn"
          onClick={function () {
            deactivateConnector()
            onRequestClose()
          }}
        >
          {t('disconnect')}
        </button>
      </div>

      <div className="mb-2 last:mb-0">
        <button
          className="bg-blue-primary hover:bg-blue-secondary flex w-80 items-center rounded-lg p-4 text-lg font-medium leading-[31.32px] focus:outline-none focus:ring"
          data-sentry="wallet-settings-btn"
          onClick={() => navigator.clipboard.writeText(account)}
        >
          {t('copy-address')}
        </button>
      </div>
    </Modal>
  )
}

export default WalletSettingsModal
