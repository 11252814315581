import { useSessionStorage } from '../hooks/useSessionStorage'

import IconContainer from './icons/IconContainer'

const Ribbon = function ({
  className = '',
  id,
  message,
  children,
  closable = true,
  setShowRibbon = () => null
}) {
  const storageKey = `ribbon-${id}-closed`
  const [hideRibbon, setHideRibbon] = useSessionStorage(storageKey, false)

  if (hideRibbon) {
    return null
  }

  const center = message && !children && !closable

  const handleClose = function () {
    setHideRibbon(true)
    setShowRibbon(false)
  }

  return (
    <div
      className={`bg-blue-secondary fixed z-40 mb-2 h-10 w-full py-2 text-white ${className}`}
    >
      <div
        className={`max-w-screen-app flex items-center ${
          center ? 'justify-center' : 'justify-between'
        } mx-auto w-full px-6`}
      >
        {message ? <div>{message}</div> : null}
        {children}
        {closable && (
          <div>
            <button
              className="flex items-center hover:opacity-75 focus:outline-none"
              onClick={() => handleClose()}
            >
              <IconContainer
                className="h-6 w-6 fill-current text-white"
                name="close"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Ribbon
