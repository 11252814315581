const KoIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1309_135059)">
      <path
        d="M12 -1.90735e-06C18.6274 -1.90735e-06 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 -1.90735e-06 12 -1.90735e-06Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M3.69727 8.88306L4.24996 9.25585L6.48673 5.9397L5.93404 5.56691L3.69727 8.88306ZM4.47103 9.40497L5.02373 9.77777L7.2605 6.46162L6.70781 6.08882L4.47103 9.40497ZM5.2448 9.92688L5.79749 10.2997L8.03427 6.98353L7.48157 6.61073L5.2448 9.92688Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.93359 18.433L6.48629 18.0602L4.24951 14.744L3.69682 15.1168L5.93359 18.433ZM6.70736 17.9111L7.26005 17.5383L6.21623 15.9907L5.66354 16.3635L6.70736 17.9111ZM5.51442 16.1425L6.06711 15.7697L5.02328 14.2221L4.47059 14.5949L5.51442 16.1425ZM7.48113 17.3892L8.03382 17.0164L5.79705 13.7002L5.24436 14.073L7.48113 17.3892Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.2012 10.2997L18.7539 9.92689L17.71 8.37935L17.1573 8.75215L18.2012 10.2997ZM17.0082 8.53107L17.5609 8.15827L16.5171 6.61074L15.9644 6.98353L17.0082 8.53107ZM18.9749 9.77777L19.5276 9.40497L17.2909 6.08882L16.7382 6.46162L18.9749 9.77777ZM19.7487 9.25586L20.3014 8.88306L19.2576 7.33552L18.7049 7.70832L19.7487 9.25586ZM18.5558 7.48724L19.1085 7.11445L18.0646 5.56691L17.5119 5.93971L18.5558 7.48724Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.3008 15.1168L19.7481 14.744L18.7043 16.2916L19.257 16.6644L20.3008 15.1168ZM19.1078 16.8854L18.5551 16.5126L17.5113 18.0602L18.064 18.433L19.1078 16.8854ZM19.527 14.5949L18.9743 14.2221L17.9305 15.7696L18.4832 16.1424L19.527 14.5949ZM18.3341 16.3635L17.7814 15.9907L16.7376 17.5383L17.2902 17.9111L18.3341 16.3635ZM18.7532 14.073L18.2006 13.7002L17.1567 15.2477L17.7094 15.6205L18.7532 14.073ZM17.5603 15.8416L17.0076 15.4688L15.9638 17.0163L16.5165 17.3891L17.5603 15.8416Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        fill="#E94B35"
      />
      <path
        clipRule="evenodd"
        d="M15.3723 14.1486C14.2116 16.0061 11.752 16.5628 9.87852 15.3921C8.00507 14.2215 7.42723 11.7667 8.58789 9.90926C8.00756 10.838 8.29648 12.0654 9.23321 12.6507C10.1699 13.236 11.3998 12.9577 11.9801 12.0289C12.5604 11.1002 13.7902 10.8218 14.727 11.4072C15.6637 11.9925 15.9526 13.2199 15.3723 14.1486C15.3723 14.1486 15.9526 13.2199 15.3723 14.1486Z"
        fill="#227FBB"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1309_135059">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default KoIcon
