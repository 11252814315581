import type { Connector } from '@web3-react/types'

enum Actions {
  WALLET_CONNECTED = 'WALLET_CONNECTED',
  WALLET_DISCONNECTED = 'WALLET_DISCONNECTED',
  SET_USER_DATA = 'SET_USER_DATA'
}

export type ActionFunctions = {
  walletConnected: (
    chainId: number,
    active: boolean,
    account: string,
    connector: Connector,
    walletChainId: number
  ) => void
  walletDisconnected: () => void
  setUserData: (
    debtPosition,
    claimables,
    collateralBalances,
    depositTokenBalances,
    syntheticTokenBalances,
    debtTokenBalances
  ) => void
}

export const buildAppActions = (dispatch): ActionFunctions => ({
  setUserData(
    debtPosition,
    claimables,
    collateralBalances,
    depositTokenBalances,
    syntheticTokenBalances,
    debtTokenBalances
  ) {
    dispatch({
      payload: {
        claimables,
        collateralBalances,
        debtPosition,
        debtTokenBalances,
        depositTokenBalances,
        syntheticTokenBalances
      },
      type: Actions.SET_USER_DATA
    })
  },
  walletConnected(chainId, active, account, connector, walletChainId) {
    dispatch({
      payload: {
        account,
        active,
        chainId,
        connector,
        walletChainId
      },
      type: Actions.WALLET_CONNECTED
    })
  },
  walletDisconnected() {
    dispatch({ type: Actions.WALLET_DISCONNECTED })
  }
})

export default Actions
