import { findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { getChainPageRoute, getNavigationRoutes } from '../utils/routes'

import IconContainer from './icons/IconContainer.js'
import LanguageSelector from './LanguageSelector'
import LegalLinks from './LegalLinks.js'
import SocialLinks from './SocialLinks.js'
import VesperBanner from './VesperBanner'

const Sidebar = function ({ onItemClick, showRibbon }) {
  const { t } = useTranslation()
  const { asPath } = useRouter()
  const routes = getNavigationRoutes()
  const chainId = useChainId()

  return (
    <>
      <div className="h-screen w-64">
        <div
          className={`no-scrollbar fixed z-30 h-screen w-64 overflow-y-auto pl-4 ${
            showRibbon ? 'pt-10' : ''
          }`}
        >
          <div className="flex h-full flex-col">
            <div className="mb-16 mt-6 flex items-center pl-4">
              <div className="bg-logo mr-3 flex h-8 w-8 items-center justify-center rounded-full">
                <IconContainer name="m" />
              </div>
              <IconContainer name="metronome" />
            </div>
            <ul className="flex flex-col font-medium">
              {routes.map(function ({ key, route, supportedChainIds }) {
                const selected =
                  asPath.split('?')[0] ===
                  `/${findByChainId(chainId).shortName}${
                    route === '/' ? '' : route
                  }`

                return (
                  <li key={key}>
                    <Link
                      className={`active:text-gray-primary mb-4 flex items-center whitespace-nowrap p-3 hover:text-white ${
                        selected
                          ? 'bg-blue-4 rounded-lg text-white'
                          : 'text-gray-primary'
                      }`}
                      data-sentry="sidebar-item"
                      href={getChainPageRoute({ chainId, route }).href}
                      onClick={onItemClick(supportedChainIds, key)}
                    >
                      <IconContainer
                        className="mr-4"
                        fill={selected ? '#FFFFFF' : '#8F919B'}
                        name={key}
                      />
                      {t(`navigation.${key}`)}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <LanguageSelector />
            <VesperBanner className="mt-4" />
            <div className="flex grow flex-col justify-end">
              <div className="mb-6 space-y-5">
                <div className="mb-10 flex items-center space-x-4">
                  <SocialLinks />
                </div>
                <div className="text-gray-primary flex flex-col text-sm">
                  <LegalLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
