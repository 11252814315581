import DefaultIcon from './Default'

import icons from './'
const IconContainer = function ({ name, ...props }) {
  if (!name) {
    return null
  }
  const IconComponent =
    icons[name.toLowerCase().replace(/ /g, '').split('.e')[0]]
  return IconComponent ? (
    <IconComponent {...props} />
  ) : (
    <DefaultIcon {...props} />
  )
}

export default IconContainer
