import { useWeb3React } from '@web3-react/core'
import { chainList } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import { useContext, useEffect, useState } from 'react'

import WalletContext from '../components/WalletContext'

const useTransactionOptions = function () {
  const { wallet } = useContext(WalletContext)
  const chainId = useChainId()
  const [useEip1559, setUseEip1559] = useState(false)
  const [infiniteApprovalChecked, setInfiniteApprovalChecked] = useState(false)
  const [withdrawExact, setWithdrawExact] = useState(false)
  const [claimOnOperation, setClaimOnOperation] = useState(false)
  const [operateWithWrappedAsset, setOperateWithWrappedAsset] = useState(false)

  const [approvalChecked, setApprovalChecked] = useState()

  const { connector: currentConnector } = useWeb3React()

  useEffect(
    function () {
      setUseEip1559(function () {
        const supportedChains = chainList
          .filter(chain => chain.eip1559)
          .map(chain => chain.chainId)

        return supportedChains.includes(chainId) &&
          wallet?.connectorName === 'injected'
          ? true
          : useEip1559
      })
    },
    [chainId, currentConnector, useEip1559, wallet?.connectorName]
  )

  const resetTransactionOptionsValues = function () {
    setUseEip1559(false)
    setInfiniteApprovalChecked(false)
    setWithdrawExact(false)
    setClaimOnOperation(false)
    setOperateWithWrappedAsset(false)
  }

  return {
    options: {
      approvalChecked,
      claimOnOperation,
      infiniteApprovalChecked,
      onClaimOnOperationChange: setClaimOnOperation,
      onUseEip1559Change: setUseEip1559,
      onWithdrawExactChange: setWithdrawExact,
      operateWithWrappedAsset,
      setApprovalChecked,
      setInfiniteApprovalChecked,
      setOperateWithWrappedAsset,
      useEip1559,
      withdrawExact
    },
    resetTransactionOptionsValues
  }
}

export default useTransactionOptions
