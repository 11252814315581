const ArrowsIcon = ({ fillArrows, ...props }) => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        className="st0"
        d="M9.3,5.5C9.2,5.4,9.1,5.3,8.9,5.3C8.8,5.2,8.7,5.2,8.5,5.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0.1
  C8,5.3,7.9,5.4,7.8,5.5c0,0,0,0-0.1,0L4.4,8.8C4,9.3,4,9.9,4.4,10.4c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3L7.4,9v10.5
  c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1V9l1.4,1.4c0.4,0.4,1.1,0.4,1.6,0s0.4-1.1,0-1.6L9.3,5.5z"
      />
      <path
        className="st0"
        d="M23.6,17.6c-0.4-0.4-1.1-0.4-1.6,0L20.6,19V8.5c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1V19L17,17.6
  c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l3.3,3.3c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1
  c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l3.3-3.3C24,18.7,24,18.1,23.6,17.6z"
      />
    </g>
  </svg>
)

export default ArrowsIcon
