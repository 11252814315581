const BulletIcon = props => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" fill="white" r="8" />
    <path
      d="M7.92167 5.53066L6.195 7.25733C5.935 7.51733 5.935 7.93733 6.195 8.19733L7.92167 9.92399C8.34167 10.344 9.06167 10.044 9.06167 9.45066V5.99733C9.06167 5.40399 8.34167 5.11066 7.92167 5.53066Z"
      fill="#259DA8"
    />
  </svg>
)

export default BulletIcon
