import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { chainList } from 'chain-list'

const developmentChainIds = [31337, 1337]
const supportedChainIds = chainList
  .map(({ chainId }) => chainId)
  .concat(developmentChainIds)

const nodeUrls = {
  1: process.env.NEXT_PUBLIC_NODE_URL_ETH,
  10: process.env.NEXT_PUBLIC_NODE_URL_OPTIMISM,
  8453: process.env.NEXT_PUBLIC_NODE_URL_BASE
}

export const [injected, injectedHooks] = initializeConnector(
  actions => new MetaMask({ actions, options: { supportedChainIds } })
)

const [walletConnect, walletConnectHooks] = initializeConnector(
  actions =>
    new WalletConnect({
      actions,
      options: {
        chains: [1],
        projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID,
        rpcMap: nodeUrls,
        showQrModal: true
      }
    })
)

const [walletLink, walletLinkHooks] = initializeConnector(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        appName: 'Metronome App',
        url: nodeUrls[1]
      }
    })
)

const getSupportedChains = ({ connectorName }) =>
  chainList
    .filter(({ supportedConnectors }) =>
      supportedConnectors.includes(connectorName)
    )
    .map(({ chainId }) => chainId)

export const connectors = [
  {
    connector: injected,
    connectorName: 'injected',
    hooks: injectedHooks,
    name: 'Metamask'
  },
  {
    connector: walletConnect,
    connectorName: 'walletconnect',
    hooks: walletConnectHooks,
    name: 'WalletConnect'
  },
  {
    connector: walletLink,
    connectorName: 'walletlink',
    hooks: walletLinkHooks,
    name: 'Coinbase Wallet'
  }
].map(connector => ({
  ...connector,
  supportedChains: [...getSupportedChains(connector), ...developmentChainIds]
}))
