import { useWeb3React } from '@web3-react/core'

import { defaultChainId } from '../utils/chains'

// Bypass hardhat/ganache-core chainId issue by changing 31337/1337 back to mainnet.
// See: https://github.com/trufflesuite/ganache-core/issues/451
// Update to 137 if Polygon is required to change back to.
export const useWalletChainId = function (bypass = true) {
  const { chainId } = useWeb3React()
  return parseInt(
    bypass && [31337, 1337].includes(chainId) ? defaultChainId : chainId,
    10
  )
}
