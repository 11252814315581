const MediumIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M20.9632 11.8277C20.9632 15.377 19.4479 18.2554 17.5788 18.2554C15.7096 18.2554 14.1943 15.377 14.1943 11.8277C14.1943 8.27834 15.7094 5.3999 17.5785 5.3999C19.4477 5.3999 20.9629 8.27742 20.9629 11.8277"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M23.9995 11.8276C23.9995 15.0069 23.4667 17.5858 22.8092 17.5858C22.1517 17.5858 21.6191 15.0076 21.6191 11.8276C21.6191 8.64758 22.152 6.06934 22.8092 6.06934C23.4665 6.06934 23.9995 8.64735 23.9995 11.8276Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default MediumIcon
