const WarningSignIcon = ({ fill, ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.47012 21.0002H19.5301C21.0701 21.0002 22.0301 19.3302 21.2601 18.0002L13.7301 4.99018C12.9601 3.66018 11.0401 3.66018 10.2701 4.99018L2.74012 18.0002C1.97012 19.3302 2.93012 21.0002 4.47012 21.0002ZM12.0001 14.0002C11.4501 14.0002 11.0001 13.5502 11.0001 13.0002V11.0002C11.0001 10.4502 11.4501 10.0002 12.0001 10.0002C12.5501 10.0002 13.0001 10.4502 13.0001 11.0002V13.0002C13.0001 13.5502 12.5501 14.0002 12.0001 14.0002ZM13.0001 18.0002H11.0001V16.0002H13.0001V18.0002Z"
      fill={fill}
    />
  </svg>
)

export default WarningSignIcon
