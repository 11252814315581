const WalletIcon = props => (
  <svg
    height="61"
    viewBox="0 0 61 61"
    width="61"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="wallet">
      <path
        d="M33.5273 35.5361C33.5273 40.0111 37.1773 43.6611 41.6523 43.6611H52.9023V45.5361C52.9023 50.5361 50.4023 53.0361 45.4023 53.0361H15.4023C10.4023 53.0361 7.90234 50.5361 7.90234 45.5361V13.0361C7.90234 15.7861 10.1523 18.0361 12.9023 18.0361H45.4023C50.4023 18.0361 52.9023 20.5361 52.9023 25.5361V27.4111H41.6523C37.1773 27.4111 33.5273 31.0611 33.5273 35.5361ZM41.6523 31.1611C39.2523 31.1611 37.2773 33.1361 37.2773 35.5361C37.2773 37.9361 39.2523 39.9111 41.6523 39.9111H52.9023V31.1611H41.6523ZM42.9524 38.0361C41.5774 38.0361 40.4274 36.9111 40.4274 35.5361C40.4274 34.1611 41.5524 33.0361 42.9274 33.0361H42.9524C44.3274 33.0361 45.4524 34.1611 45.4524 35.5361C45.4524 36.9111 44.3274 38.0361 42.9524 38.0361ZM37.9023 8.03613H14.7773C13.0523 8.03613 11.6523 9.43613 11.6523 11.1611C11.6523 12.8861 13.0523 14.2861 14.7773 14.2861H45.3273C44.9523 10.1111 42.4773 8.03613 37.9023 8.03613Z"
        fill="white"
        id="wallet_2"
      />
    </g>
  </svg>
)

export default WalletIcon
