import { useWeb3React } from '@web3-react/core'
import { useChainId } from 'metronome-ui/hooks/chains'
import { useState } from 'react'

import { useWalletChainId } from '../hooks/useWalletChainId'

import ChainSelector from './ChainSelector'
import Footer from './Footer'
import CustomHead from './Head'
import MobileFixedFooter from './MobileFixedFooter'
import Navigation from './Navigation'
import OpInfoModal from './OpInfoModal'
import PlatformValues from './PlatformValues'
import UnavailableRibbon from './UnavailableRibbon'
import Wallet from './Wallet'
import WrongNetworkRibbon from './WrongNetworkRibbon'

const Layout = function ({ children, title, description }) {
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false)
  const [showUnavailableRibbon, setShowUnavailableRibbon] = useState(false)
  const { isActive: active } = useWeb3React()
  const walletChainId = useWalletChainId()
  const urlChainId = useChainId()
  const chainMismatch = urlChainId !== walletChainId
  const showWrongChainRibbon = active && chainMismatch
  const showRibbon = showWrongChainRibbon || showUnavailableRibbon
  return (
    <>
      <CustomHead description={description} pageTitle={title} />
      <div className="bg-main min-h-screen">
        <WrongNetworkRibbon showRibbon={showWrongChainRibbon} />
        <UnavailableRibbon
          setShowUnavailableRibbon={setShowUnavailableRibbon}
          showUnavailableRibbon={showUnavailableRibbon}
        />
        <div className="max-w-screen-1/2xl relative z-20 mx-auto lg:flex">
          <Navigation
            mobileNavbarOpen={mobileNavbarOpen}
            setMobileNavbarOpen={setMobileNavbarOpen}
            showRibbon={showRibbon}
          />
          <div
            className={`lg:w-main relative z-20 mx-auto w-full px-4 pb-6 ${
              mobileNavbarOpen ? 'hidden' : 'min-h-screen'
            } ${showRibbon ? 'mt-10' : ''}`}
          >
            <div
              className={`bg-blur-transparent sticky z-10 ${
                showRibbon ? 'top-10' : 'top-0'
              } mb-12 hidden w-full justify-end py-4 lg:flex`}
            >
              <div className="flex space-x-2">
                <PlatformValues />
                <ChainSelector mode="desktop" />
                <Wallet />
              </div>
            </div>
            <main className="m-auto">{children}</main>
            <div className="hidden pt-6 md:block lg:hidden">
              <div className="max-w-screen-app absolute inset-x-0 bottom-6 mx-auto w-full px-10" />
              <Footer />
            </div>
            <div className="md:hidden">
              <MobileFixedFooter />
            </div>
          </div>
        </div>
        <OpInfoModal />
        <div className="bg-radial-gradient fixed top-0 z-10 h-full w-full" />
      </div>
    </>
  )
}
export default Layout
