const CbethIcon = props => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M244.542 128c0 64.375-52.167 116.542-116.542 116.542S11.458 192.375 11.458 128 63.625 11.458 128 11.458 244.542 63.625 244.542 128Zm0 0"
      style={{
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1,
        strokeWidth: 22.946,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        stroke: '#0052ff',
        strokeOpacity: 1,
        strokeMiterlimit: 4
      }}
      transform="scale(.09375)"
    />
    <path
      d="M11.996 3v6.375l5.25 2.41Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#0052ff',
        fillOpacity: 1
      }}
    />
    <path
      d="m12 3-5.25 8.785L12 9.375Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#6697ff',
        fillOpacity: 1
      }}
    />
    <path
      d="M11.996 15.918v4.332l5.254-7.46Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#0052ff',
        fillOpacity: 1
      }}
    />
    <path
      d="M12 20.25v-4.332l-5.25-3.129ZM11.996 14.914l5.25-3.129-5.25-2.406Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#6697ff',
        fillOpacity: 1
      }}
    />
    <path
      d="m6.75 11.785 5.25 3.13V9.378Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#ccdcff',
        fillOpacity: 1
      }}
    />
  </svg>
)

export default CbethIcon
