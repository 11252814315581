import { useNProgress } from '@tanem/react-nprogress'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

function LoadBar() {
  const router = useRouter()
  const [isRouteChanging, setIsRouteChanging] = useState(false)
  const { animationDuration, progress, isFinished } = useNProgress({
    isAnimating: isRouteChanging
  })
  useEffect(
    function () {
      const handleRouteChangeStart = function () {
        setIsRouteChanging(true)
      }

      const handleRouteChangeEnd = function () {
        setIsRouteChanging(false)
      }

      router.events.on('routeChangeStart', handleRouteChangeStart)
      router.events.on('routeChangeComplete', handleRouteChangeEnd)
      router.events.on('routeChangeError', handleRouteChangeEnd)

      return function () {
        router.events.off('routeChangeStart', handleRouteChangeStart)
        router.events.off('routeChangeComplete', handleRouteChangeEnd)
        router.events.off('routeChangeError', handleRouteChangeEnd)
      }
    },
    [router.events]
  )

  return (
    <div
      className={`bg-teal-primary fixed left-0 top-0 z-50 h-[3px]`}
      style={{
        transition: `width ${animationDuration}ms ease-in-out`,
        width: `${isFinished ? '0' : progress * 100}%`
      }}
    />
  )
}

export default LoadBar
