import ExternalLink from './ExternalLink'
import IconContainer from './icons/IconContainer'

const Banner = ({ btn, className, link, name, text, title }) => (
  <div className={`bg-blur-1 flex w-full rounded-2xl p-3.5 ${className}`}>
    <div>
      <IconContainer className="mx-auto" name={name.toLowerCase()} />
    </div>
    <div className="ml-3.5">
      {title && <p className="text-md font-bold text-white">{title}</p>}
      {text && <p className="mb-3.5 text-xs text-gray-600">{text}</p>}
      <ExternalLink href={link}>
        <span className="bg-teal-primary-0.05 text-teal-primary inline-block rounded-lg px-6 py-3 text-sm font-bold">
          {btn}
        </span>
      </ExternalLink>
    </div>
  </div>
)

export default Banner
