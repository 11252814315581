const MetIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_865_4932)">
      <path
        d="M30 15C30 23.28 23.28 30 15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0C23.28 0 30 6.72 30 15Z"
        fill="url(#paint0_linear_865_4932)"
      />
      <path
        d="M20.6 9.31H15.75V11C15.26 10 14.23 9.32 13.04 9.32H6.38V20.69H8.51V11.45H13.17C13.59 11.45 13.93 11.79 13.93 12.21V20.69H16.06V12.34C16.06 12.03 16.01 11.73 15.93 11.45H20.73C21.15 11.45 21.49 11.79 21.49 12.21V20.69H23.62V12.34C23.62 10.67 22.27 9.31 20.6 9.31Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_865_4932"
        x1="4.3934"
        x2="25.6066"
        y1="4.3934"
        y2="25.6066"
      >
        <stop stopColor="#4BD5D8" />
        <stop offset="1" stopColor="#3C4257" />
      </linearGradient>
      <clipPath id="clip0_865_4932">
        <rect fill="white" height="30" width="30" />
      </clipPath>
    </defs>
  </svg>
)

export default MetIcon
