import { useWeb3React } from '@web3-react/core'
import { useChainId } from 'metronome-ui/hooks/chains'
import { useRouter } from 'next/router'
import { createContext, useEffect, useMemo, useReducer, useState } from 'react'
import useSWR from 'swr'

import { fetcher } from '../../utils'
import {
  prepareAssetLists,
  prepareFeesData
} from '../../utils/prepareMetronomeData'

import { ActionFunctions, buildAppActions } from './actions'
import buildInitialState from './initialState'
import appReducer from './reducer'

export type UserPosition = {
  claimable: string
  deposited: string
  generated: string
  health?: string
  healthFactor?: number
  healthFactorColor?: string
  percent?: number
  rawHealthFactor?: number
  issuableLimitInUsd: string
}

// This is just for displaying the list of collaterals in the dashboard for now
export type CollateralListItem = {
  address: string
  collateralFactor: number
  symbol: string
  balance?: string
  balanceInUsd?: string
  decimals?: number
  deposited?: string
  depositedInUsd?: string
  usdPrice?: string
  availableSupply?: number
  isActive: boolean
  apy?: string
}

// This is just for displaying the list of synthetics in the dashboard for now
export type SyntheticListItem = {
  address: string
  symbol: string
  balance?: string
  balanceInUsd?: string
  decimals?: number
  generated?: string
  generatedInUsd?: string
  usdPrice?: string
  availableSupply?: number
  debtAvailableSupply?: number
  maxTotalSupply?: number
  totalSupply?: number
  interestRate?: number
  isActive: boolean
}

export type AppState = {
  wallet: {
    balancesLoaded: boolean
    connected: boolean
    name?: string
    account?: string
  }
  userPosition: UserPosition
  balances: {
    collateral: {
      [k: string]: string
    }
    deposit: {
      [k: string]: string
    }
    synthetic: {
      [k: string]: string
    }
    debt: {
      [k: string]: string
    }
  }
}

export type AssetLists = {
  collaterals: CollateralListItem[]
  synthetics: SyntheticListItem[]
}

export type Fees = {
  defaultSwapFee: number
  depositFee: number
  issueFee: number
  liquidationFees: {
    liquidatorFee: number
    protocolFee: number
  }
  liquidationPenalty: number
  repayFee: number
  withdrawFee: number
}

export type AppContextState = Omit<AppState, 'balances'> & {
  assets?: AssetLists
  fees?: Fees
  status?: {
    everythingStopped: boolean
    isSwapActive: boolean
    paused: boolean
  }
  summary?: {
    tvl: string
    minted: string
  }
  swapRoutes?: {
    leverageRoutes: {
      tokenIn: string
      collateral: string
      synth: string
    }[]
    flashRepayRoutes: {
      collaterals: string[]
      synth: string
    }[]
  }
}

export type AppContextType = {
  actions: ActionFunctions
  error: any
  setError: (error: any) => void
  state: AppContextState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  legacyData: any
}

export const AppContext = createContext<AppContextType | undefined>(undefined)

function AppContextProvider({ children, initialData }) {
  const chainId = useChainId()
  const [state, dispatch] = useReducer(appReducer, buildInitialState())
  const {
    account,
    chainId: walletChainId,
    connector,
    isActive: active
  } = useWeb3React()
  const actionFunctions = useMemo(() => buildAppActions(dispatch), [dispatch])

  const [error, setError] = useState(false)
  const [shouldUpdateWallet, setShouldUpdateWallet] = useState(true)
  const [showLoader, setShowLoader] = useState(true)
  const {
    query: { shortName }
  } = useRouter()
  const { data: metronomeData } = useSWR(
    `/api/${shortName}/info?v=2`,
    fetcher,
    {
      fallbackData: initialData,
      onSuccess() {
        setShouldUpdateWallet(true)
        setShowLoader(false)
      },
      refreshInterval:
        parseInt(process.env.NEXT_PUBLIC_REFRESH_INTERVAL) * 1000,
      revalidateOnMount: true
    }
  )

  const selectedPoolData = metronomeData?.pools.find(pool => !!pool.mainPool)

  useEffect(
    function () {
      if (active) {
        actionFunctions.walletConnected(
          chainId,
          active,
          account,
          connector,
          walletChainId
        )
      } else {
        actionFunctions.walletDisconnected()
      }
    },
    [active, account, chainId, connector, walletChainId, actionFunctions]
  )

  return (
    <AppContext.Provider
      value={{
        actions: actionFunctions,
        /* @TODO This legacyData contains data and states has been moved up here temporarily.
         It should be removed when all components that depend on MetronomeContext
         start depending on AppContext data instead */
        error,
        legacyData: {
          selectedPoolData,
          setShouldUpdateWallet,
          setShowLoader,
          shouldUpdateWallet,
          showLoader
        },
        setError,
        state: {
          ...state,
          assets: prepareAssetLists(
            selectedPoolData,
            state.wallet.balancesLoaded,
            state.balances
          ),
          fees: prepareFeesData(selectedPoolData?.fees),
          status: selectedPoolData?.status,
          summary: metronomeData?.summary,
          swapRoutes: selectedPoolData?.swapRoutes
        }
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
