const TvlIcon = props => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" fill="#515563" r="8" />
    <path
      d="M4.53397 10.5689L6.88953 8.21334L8.33397 9.65778C8.51619 9.84001 8.80953 9.83112 8.97842 9.64001L12.1651 6.05334C12.3206 5.88001 12.3117 5.61334 12.1473 5.44445C11.9695 5.26667 11.6717 5.27112 11.5029 5.46223L8.66286 8.65334L7.20064 7.19112C7.0273 7.01778 6.7473 7.01778 6.57397 7.19112L3.8673 9.90223C3.69397 10.0756 3.69397 10.3556 3.8673 10.5289L3.9073 10.5689C4.08064 10.7422 4.36508 10.7422 4.53397 10.5689V10.5689Z"
      fill="white"
    />
  </svg>
)

export default TvlIcon
