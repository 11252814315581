const BackArrowIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 17 16"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.33594 8.48862H12.7677L7.77336 13.3102C7.37422 13.6955 7.37422 14.3278 7.77336 14.7132C8.1725 15.0985 8.81727 15.0985 9.21641 14.7132L15.9609 8.20209C16.36 7.81676 16.36 7.19431 15.9609 6.80898L9.22664 0.28802C8.8275 -0.0973089 8.18273 -0.0973089 7.78359 0.28802C7.38445 0.67335 7.38445 1.29581 7.78359 1.68113L12.7677 6.51257H1.33594C0.773047 6.51257 0.3125 6.95718 0.3125 7.5006C0.3125 8.04401 0.773047 8.48862 1.33594 8.48862Z" />
  </svg>
)

export default BackArrowIcon
