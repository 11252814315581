import ReactDOM from 'react-dom'

import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { useOnKeyUp } from '../hooks/useOnKeyUp'

import CloseIcon from './icons/Close'

const Modal = function ({
  onRequestClose,
  showClose = false,
  children,
  ...props
}) {
  const modalRef = useOnClickOutside(onRequestClose)

  useOnKeyUp(function (e) {
    if (e.key === 'Escape') onRequestClose()
  }, modalRef)
  return (
    <div ref={modalRef} {...props}>
      {showClose && (
        <CloseIcon
          className="absolute right-3 top-6 z-10 h-6 w-6 cursor-pointer"
          onClick={onRequestClose}
        />
      )}
      {children}
    </div>
  )
}

const ModalContainer = ({ className = '', modalIsOpen, ...props }) =>
  modalIsOpen
    ? ReactDOM.createPortal(
        <>
          <div
            className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none duration-[500ms]
          focus:outline-none ${
            modalIsOpen
              ? 'pointer-event-auto bg-blur'
              : 'bg-blur-0 pointer-events-none'
          }`}
            onTouchStart={e => e.stopPropagation()}
          >
            {modalIsOpen && (
              <div className={className}>
                <Modal {...props} />
              </div>
            )}
          </div>
        </>,
        document.body
      )
    : null

export default ModalContainer
