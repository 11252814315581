import { chainList, findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useContext, useState } from 'react'

import Dropdown from './Dropdown'
import IconContainer from './icons/IconContainer'
import { MetronomeContext } from './MetronomeContext'
import Modal from './Modal'
import WalletContext from './WalletContext'

const ChainSelectorModal = function ({
  modalIsOpen,
  onRequestClose,
  chains,
  currentChain,
  onChainChange
}) {
  const { t } = useTranslation()
  const { setSelectedToken } = useContext(MetronomeContext)
  const handleChainChange = function (shortName) {
    onChainChange(shortName)
    onRequestClose()
    setSelectedToken(undefined)
  }
  return chains ? (
    <Modal
      className="bg-main-opacity mx-4 w-full rounded-xl p-4 text-white"
      modalIsOpen={modalIsOpen}
      onRequestClose={onRequestClose}
    >
      <h4 className="mb-6 text-2xl">{t('change-network')}</h4>
      <ul>
        {chains.map(({ name, shortName }) => (
          <li className="mb-2 cursor-pointer last:mb-0" key={shortName}>
            <button
              className={`${
                name === currentChain.name
                  ? 'bg-teal-primary'
                  : 'bg-blue-primary'
              } flex w-full rounded-lg p-4 text-lg font-medium`}
              data-sentry={`chain-selector-${shortName}`}
              onClick={() => handleChainChange(shortName)}
              type="button"
            >
              <div className="flex items-center">
                <div className="mr-4 rounded-md p-2">
                  <IconContainer
                    height="24"
                    name={name.toLowerCase()}
                    width="24"
                  />
                </div>
                {name}
              </div>
            </button>
          </li>
        ))}
      </ul>
    </Modal>
  ) : null
}

const ChainSelectorMobile = function ({
  chains,
  currentChain,
  compact = false,
  onChainChange,
  showLoader
}) {
  const [showChainSelector, setShowChainSelector] = useState(false)

  return (
    <div className="lg:hidden">
      <ChainSelectorModal
        chains={chains}
        currentChain={currentChain}
        modalIsOpen={showChainSelector}
        onChainChange={onChainChange}
        onRequestClose={() => setShowChainSelector(false)}
      />
      {currentChain.name ? (
        <button
          className="bg-selector flex h-10 w-10 items-center rounded-lg p-2 text-sm font-medium text-white"
          data-sentry="chain-selector"
          onClick={() => setShowChainSelector(true)}
        >
          <IconContainer
            className="m-auto inline h-4 w-4"
            name={showLoader ? 'loading' : currentChain.name.toLowerCase()}
          />
          {compact ? '' : <span>{currentChain.name}</span>}
        </button>
      ) : null}
    </div>
  )
}

const ChainSelectorDesktop = ({
  chains,
  currentChain,
  showLoader,
  onChainChange,
  compact = false
}) =>
  chains && currentChain.name ? (
    <Dropdown
      className="relative z-30 inline-block"
      selector={
        <button
          className="bg-selector m-auto hidden items-center justify-between space-x-3.5 rounded-lg p-2 text-sm font-medium text-white focus:outline-none md:flex md:h-10 md:p-3"
          data-sentry="chain-selector"
        >
          <div className="flex items-center space-x-2">
            <IconContainer
              height="16"
              name={showLoader ? 'loading' : currentChain.name.toLowerCase()}
              width="16"
            />
            {compact ? '' : <span>{currentChain.name}</span>}
          </div>
          <div>
            <IconContainer
              className="text-navy-1000 fill-current"
              name="caret"
            />
          </div>
        </button>
      }
    >
      <div className="absolute right-0 top-12 z-10">
        <ul className="bg-blue-primary w-64 rounded-lg p-2 text-sm leading-6 text-white">
          {chains.map(({ name, shortName }) => (
            <li className="group mb-2 last:mb-0" key={shortName}>
              <button
                className={`${
                  name === currentChain.name
                    ? 'bg-blue-4 !text-white'
                    : 'group-hover:text-white'
                } text-gray-primary mb-2 flex w-full items-center rounded-lg px-4 py-2 focus:outline-none`}
                data-sentry={`chain-selector-${shortName}`}
                onClick={() => onChainChange(shortName)}
                type="button"
              >
                <div className="flex items-center">
                  <IconContainer
                    height="14"
                    name={name.toLowerCase()}
                    width="14"
                  />
                  <span className="px-2">{name}</span>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </Dropdown>
  ) : null

const ChainSelector = function ({ mode, ...props }) {
  const chainId = useChainId()
  const currentChain = findByChainId(chainId)
  const { setSelectedToken, setShowLoader, showLoader } =
    useContext(MetronomeContext)
  const { wallet } = useContext(WalletContext)
  const router = useRouter()

  // Prior to switching chains, it is required to disconnect the wallet (if any) to avoid a "glitch"
  // in which the "Switch Network" Ribbon is visible for a brief moment while loading the new network and while
  // the wallet is disconnecting. Because of that, we use js to disconnect and once that is completed, navigate.
  const handleChainChange = useCallback(
    function (destinationChainName) {
      setShowLoader(true)
      setSelectedToken(undefined)
      window.gtag?.(
        `event', 'Switch Network from ${currentChain.name} to ${destinationChainName}`
      )
      if (wallet) {
        const reconnectOnReload = wallet.supportedChains.includes(chainId)
        wallet.handleDisconnection(reconnectOnReload)
      }

      if (router.asPath === '/') {
        window.location = destinationChainName
      } else {
        window.location = router.asPath.replace(
          router.query.shortName,
          destinationChainName
        )
      }
    },
    [
      chainId,
      currentChain.name,
      router,
      setSelectedToken,
      setShowLoader,
      wallet
    ]
  )
  const ChainSelectorComponent = {
    desktop: ChainSelectorDesktop,
    mobile: ChainSelectorMobile
  }[mode]

  return chainList.length > 1 && ChainSelectorComponent ? (
    <ChainSelectorComponent
      chains={chainList}
      currentChain={currentChain}
      onChainChange={handleChainChange}
      showLoader={showLoader}
      {...props}
    />
  ) : null
}

export default ChainSelector
