/* eslint-disable @typescript-eslint/no-var-requires */
const { findByChainId } = require('chain-list')

const { defaultChainId } = require('./chains')

const getChainAlias = chainId => findByChainId(chainId).shortName

const getChainPageRoute = ({ route, chainId }) => ({
  href: `/${getChainAlias(chainId)}${route}`
})

const getNavigationRoutes = () => [
  { key: 'synth', route: '/' },
  { key: 'marketplace', route: '/market' },
  { key: 'liquiditypools', route: '/liquidity-pools' },
  { key: 'smartfarming', route: '/smart-farming' },
  ...(process.env.NEXT_PUBLIC_FLAGS_CROSS_CHAIN === 'true'
    ? [{ key: 'cctransactions', route: '/cross-chain-transactions' }]
    : []),
  { key: 'lockmet', route: '/lock-met', supportedChainIds: [1] }
]

const getPagesRedirects = () =>
  getNavigationRoutes().map(({ route }) => ({
    destination: `/${findByChainId(defaultChainId).shortName}${route}`,
    permanent: true,
    source: route
  }))

module.exports = {
  getChainPageRoute,
  getNavigationRoutes,
  getPagesRedirects
}
