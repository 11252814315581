const LockMetIcon = ({ fill, width = '33', height = '33', ...props }) => (
  <svg
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1483_17635)">
      <g clipPath="url(#clip1_1483_17635)">
        <path
          d="M15.9998 9.33317H15.3332V7.99984C15.3332 6.15984 13.8398 4.6665 11.9998 4.6665C10.1598 4.6665 8.6665 6.15984 8.6665 7.99984V9.33317H7.99984C7.2665 9.33317 6.6665 9.93317 6.6665 10.6665V17.3332C6.6665 18.0665 7.2665 18.6665 7.99984 18.6665H15.9998C16.7332 18.6665 17.3332 18.0665 17.3332 17.3332V10.6665C17.3332 9.93317 16.7332 9.33317 15.9998 9.33317ZM11.9998 15.3332C11.2665 15.3332 10.6665 14.7332 10.6665 13.9998C10.6665 13.2665 11.2665 12.6665 11.9998 12.6665C12.7332 12.6665 13.3332 13.2665 13.3332 13.9998C13.3332 14.7332 12.7332 15.3332 11.9998 15.3332ZM9.99984 9.33317V7.99984C9.99984 6.89317 10.8932 5.99984 11.9998 5.99984C13.1065 5.99984 13.9998 6.89317 13.9998 7.99984V9.33317H9.99984Z"
          fill={fill}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1483_17635">
        <rect fill={fill} height="16" transform="translate(4 4)" width="16" />
      </clipPath>
      <clipPath id="clip1_1483_17635">
        <rect fill={fill} height="16" transform="translate(4 4)" width="16" />
      </clipPath>
    </defs>
  </svg>
)

export default LockMetIcon
