import Big from 'big.js'
import {
  getMinimalRenderableValue,
  isLessThanMinimalRenderableValue
} from 'metronome-utils'

const fetcher = (...args) => fetch(...args).then(res => res.json())

const uiFiatDecimals = parseInt(process.env.NEXT_PUBLIC_UI_FIAT_DECIMALS)
const uiCryptoDecimals = parseInt(process.env.NEXT_PUBLIC_UI_CRYPTO_DECIMALS)

const bigToNumber = (number, roundDecimals, roundTo) =>
  roundDecimals >= 0
    ? Big(number).round(roundDecimals, roundTo).toNumber()
    : Big(number).toNumber()

const bigToFiat = (number, decimals) =>
  bigToNumber(number, decimals || uiFiatDecimals)

const bigToCrypto = number => bigToNumber(number, uiCryptoDecimals, 0)

const setCookie = function (name, value, days) {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = `${name}=${value};expires=${d.toGMTString()};path=/`
}

const numberFormat = (number, locale = 'en') =>
  new Intl.NumberFormat(locale).format(number)

const getValueOrMin = (amount, decimals = uiCryptoDecimals) =>
  isLessThanMinimalRenderableValue(amount, decimals)
    ? `< ${getMinimalRenderableValue(decimals)}`
    : Big(amount).round(decimals, 0).toFixed(decimals)

const timeDiffFromToday = function (_time, locale) {
  const relativeTimeFormat = new Intl.RelativeTimeFormat(locale)
  const today = new Date()
  const time = Math.round(_time - today.getTime() / 1000)
  const days = Math.floor(time / 86400)
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time / 60)

  if (days > 0) {
    return relativeTimeFormat.format(days, 'day')
  } else if (hours > 0) {
    return relativeTimeFormat.format(hours, 'hour')
  }
  return relativeTimeFormat.format(minutes, 'minutes')
}

const isTimeAfterNow = time => time > Date.now() / 1000

const formatDate = function (d, locale, options = {}) {
  const date = new Date(d)

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).toLocaleDateString(locale, options)
}

const formatTargetDateFromNow = function (date, locale) {
  const targetTime = new Date(Date.now() + date * 1000)

  return new Date(
    targetTime.getFullYear(),
    targetTime.getMonth(),
    targetTime.getDate()
  ).toLocaleDateString(locale)
}

export {
  bigToCrypto,
  bigToFiat,
  bigToNumber,
  fetcher,
  formatDate,
  formatTargetDateFromNow,
  getValueOrMin,
  isTimeAfterNow,
  numberFormat,
  timeDiffFromToday,
  setCookie,
  uiCryptoDecimals,
  uiFiatDecimals
}
