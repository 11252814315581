import dynamic from 'next/dynamic'
import { useCallback, useContext, useState } from 'react'

import TransactionContext from '../components/TransactionContext'

const TransactionDrawer = dynamic(
  () => import('../components/TransactionDrawer'),
  {
    ssr: false
  }
)

const useTransactionDrawer = function () {
  const [opened, setOpened] = useState(false)
  const [operation, setOperation] = useState('')

  const { currentTransactions, openedInternalTransactionId } =
    useContext(TransactionContext)

  const openedTransaction = currentTransactions.find(
    transaction =>
      transaction.internalTransactionId === openedInternalTransactionId &&
      transaction.operation === operation
  )

  const closeDrawer = useCallback(function () {
    setOpened(false)
  }, [])

  const openDrawer = useCallback(function (_operation) {
    setOpened(true)
    setOperation(_operation)
  }, [])

  return {
    closeTransactionDrawer: closeDrawer,
    openTransactionDrawer: openDrawer,
    opened,
    transactionDrawer: (
      <TransactionDrawer
        closeDrawer={closeDrawer}
        opened={opened}
        openedTransaction={openedTransaction}
      />
    )
  }
}

export default useTransactionDrawer
