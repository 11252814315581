import { createContext, useState } from 'react'

const WalletContext = createContext({})
const WalletContextProvider = function ({ children }) {
  const [wallet, setWallet] = useState(undefined)

  return (
    <WalletContext.Provider value={{ setWallet, wallet }}>
      {children}
    </WalletContext.Provider>
  )
}

export { WalletContextProvider }

export default WalletContext
