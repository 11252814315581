const FrxethIcon = props => (
  <svg
    height="24px"
    version="1.1"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="surface1">
      <path
        d="M 12 24 C 10.378906 24 8.808594 23.683594 7.328125 23.058594 C 5.898438 22.453125 4.617188 21.585938 3.515625 20.484375 C 2.414062 19.382812 1.546875 18.101562 0.941406 16.671875 C 0.316406 15.191406 0 13.621094 0 12 C 0 10.378906 0.316406 8.808594 0.941406 7.328125 C 1.546875 5.898438 2.414062 4.617188 3.515625 3.515625 C 4.617188 2.414062 5.898438 1.546875 7.328125 0.941406 C 8.808594 0.316406 10.378906 0 12 0 C 13.621094 0 15.191406 0.316406 16.671875 0.941406 C 18.101562 1.546875 19.382812 2.414062 20.484375 3.515625 C 21.585938 4.617188 22.453125 5.898438 23.058594 7.328125 C 23.683594 8.808594 24 10.378906 24 12 C 24 13.621094 23.683594 15.191406 23.058594 16.671875 C 22.453125 18.101562 21.585938 19.382812 20.484375 20.484375 C 19.382812 21.585938 18.101562 22.453125 16.671875 23.058594 C 15.191406 23.683594 13.621094 24 12 24 Z M 12 24 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 23.363281 12 C 23.363281 18.277344 18.277344 23.363281 12 23.363281 C 5.722656 23.363281 0.636719 18.277344 0.636719 12 C 0.636719 5.722656 5.722656 0.636719 12 0.636719 C 18.277344 0.636719 23.363281 5.722656 23.363281 12 Z M 23.363281 12 "
        style={{
          fill: 'rgb(0%,0%,0%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 12.28125 9.867188 L 12.28125 15.101562 L 17.40625 11.996094 Z M 12.28125 9.867188 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 12.28125 9.273438 L 17.359375 11.386719 L 16.144531 9.40625 L 18.726562 6.824219 L 17.183594 5.28125 L 14.972656 7.492188 L 12.28125 3.105469 Z M 12.28125 9.273438 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 11.734375 15.101562 L 11.734375 9.867188 L 6.609375 11.996094 Z M 11.734375 15.101562 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 6.65625 11.386719 L 11.734375 9.273438 L 11.734375 3.105469 L 9.042969 7.492188 L 6.832031 5.28125 L 5.289062 6.824219 L 7.867188 9.40625 Z M 6.65625 11.386719 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 17.347656 13.625 L 12.28125 16.695312 L 12.28125 20.765625 L 14.363281 17.824219 L 16.542969 20.003906 L 18.085938 18.460938 L 15.644531 16.023438 Z M 17.347656 13.625 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
      <path
        d="M 11.734375 16.695312 L 6.664062 13.625 L 8.367188 16.023438 L 5.929688 18.460938 L 7.472656 20.003906 L 9.648438 17.828125 L 11.734375 20.765625 Z M 11.734375 16.695312 "
        style={{
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none'
        }}
      />
    </g>
  </svg>
)
export default FrxethIcon
