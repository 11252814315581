const ExclamationmarkIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill-current"
      clipRule="evenodd"
      d="M0.333344 6.99967C0.333344 3.31967 3.32001 0.333008 7.00001 0.333008C10.68 0.333008 13.6667 3.31967 13.6667 6.99967C13.6667 10.6797 10.68 13.6663 7.00001 13.6663C3.32001 13.6663 0.333344 10.6797 0.333344 6.99967ZM1.66668 6.99967C1.66668 9.93967 4.06001 12.333 7.00001 12.333C9.94001 12.333 12.3333 9.93967 12.3333 6.99967C12.3333 4.05967 9.94001 1.66634 7.00001 1.66634C4.06001 1.66634 1.66668 4.05967 1.66668 6.99967ZM6.20004 4.33298C6.20004 3.89115 6.55822 3.53298 7.00004 3.53298C7.44187 3.53298 7.80004 3.89115 7.80004 4.33298C7.80004 4.77481 7.44187 5.13298 7.00004 5.13298C6.55822 5.13298 6.20004 4.77481 6.20004 4.33298ZM6.33334 6.99967C6.33334 6.63148 6.63182 6.33301 7.00001 6.33301C7.3682 6.33301 7.66668 6.63148 7.66668 6.99967V9.66634C7.66668 10.0345 7.3682 10.333 7.00001 10.333C6.63182 10.333 6.33334 10.0345 6.33334 9.66634V6.99967Z"
      fillRule="evenodd"
    />
  </svg>
)

export default ExclamationmarkIcon
