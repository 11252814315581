const RuIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1309_135079)">
      <path
        clipRule="evenodd"
        d="M23.3179 16C21.6706 20.6608 17.2256 24 12.0008 24C6.7759 24 2.33094 20.6608 0.683594 16H23.3179Z"
        fill="#E94B35"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.3172 16C23.7594 14.7489 24 13.4025 24 12C24 10.5975 23.7594 9.25112 23.3172 8H0.682822C0.240617 9.25112 0 10.5975 0 12C0 13.4025 0.240617 14.7489 0.682823 16H23.3172Z"
        fill="#227FBB"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.3179 8C21.6706 3.33923 17.2256 0 12.0008 0C6.7759 0 2.33094 3.33923 0.683594 8H23.3179Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1309_135079">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default RuIcon
