import { useContext, useEffect } from 'react'

import { useSessionStorage } from '../hooks/useSessionStorage'

import { MetronomeContext } from './MetronomeContext'
import Ribbon from './Ribbon'

const UnavailableRibbon = function ({
  setShowUnavailableRibbon,
  showUnavailableRibbon
}) {
  const {
    selectedPoolData: { status: { everythingStopped, paused } = {} } = {}
  } = useContext(MetronomeContext)

  const [hideRibbon] = useSessionStorage('ribbon-unavailable-closed')

  useEffect(
    function () {
      if (everythingStopped && paused) {
        if (hideRibbon) {
          setShowUnavailableRibbon(false)
        } else {
          setShowUnavailableRibbon(true)
        }
      } else {
        setShowUnavailableRibbon(false)
      }
    },
    [everythingStopped, hideRibbon, paused, setShowUnavailableRibbon]
  )

  if (!showUnavailableRibbon) {
    return null
  }

  return (
    <Ribbon
      className="top-0"
      closable={true}
      id="unavailable"
      message="All operations are unavailable. Sorry for the inconvenience."
      setShowRibbon={setShowUnavailableRibbon}
    />
  )
}

export default UnavailableRibbon
