const VaEthIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#627EEA"
    />
    <path
      d="M12.373 3V9.6525L17.9958 12.165L12.373 3Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path d="M12.3735 3L6.75 12.165L12.3735 9.6525V3Z" fill="white" />
    <path
      d="M12.373 16.4759V20.9962L17.9996 13.2119L12.373 16.4759Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path
      d="M12.3735 20.9962V16.4752L6.75 13.2119L12.3735 20.9962Z"
      fill="white"
    />
    <path
      d="M12.373 15.43L17.9958 12.1653L12.373 9.6543V15.43Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M6.75 12.1653L12.3735 15.43V9.6543L6.75 12.1653Z"
      fill="white"
      fillOpacity="0.602"
    />
    <circle
      cx="21"
      cy="19"
      fill="white"
      r="3.66146"
      stroke="#596AEC"
      strokeWidth="0.677083"
    />
    <path
      d="M21.0861 17.5225C21.0432 17.5225 21.0052 17.5501 20.992 17.591L20.3402 19.6042C20.3328 19.627 20.3005 19.627 20.2931 19.6042L19.6408 17.5909C19.6276 17.5501 19.5896 17.5225 19.5467 17.5225H18.437C18.3687 17.5225 18.3209 17.59 18.3436 17.6544L18.4795 18.0394C18.4934 18.079 18.5309 18.1054 18.5728 18.1054H19.098C19.1085 18.1054 19.1178 18.112 19.1213 18.1219L19.9765 20.5433C19.9905 20.5829 20.0279 20.6093 20.0699 20.6093H20.5634C20.6053 20.6093 20.6428 20.5829 20.6567 20.5433L21.6769 17.6544C21.6997 17.59 21.6519 17.5225 21.5836 17.5225H21.0861Z"
      fill="#596AEC"
    />
    <path
      d="M22.1323 20.6455C22.4889 20.6455 22.702 20.4783 22.7997 20.2874H22.8174V20.5996H23.3323V19.0785C23.3323 18.4778 22.8426 18.2973 22.409 18.2973C21.9311 18.2973 21.5642 18.5104 21.4458 18.9247L21.9459 18.9957C21.9992 18.8403 22.1501 18.7071 22.412 18.7071C22.6606 18.7071 22.7967 18.8344 22.7967 19.0578V19.0667C22.7967 19.2206 22.6354 19.228 22.2344 19.2709C21.7935 19.3182 21.3718 19.4499 21.3718 19.9619C21.3718 20.4087 21.6988 20.6455 22.1323 20.6455ZM22.2714 20.2519C22.048 20.2519 21.8882 20.1498 21.8882 19.953C21.8882 19.7473 22.0672 19.6615 22.3069 19.6275C22.4475 19.6083 22.7286 19.5727 22.7982 19.5165V19.7843C22.7982 20.0373 22.594 20.2519 22.2714 20.2519Z"
      fill="#596AEC"
    />
  </svg>
)

export default VaEthIcon
