import dynamic from 'next/dynamic'
import { useState } from 'react'

const OperationDrawer = dynamic(() => import('../components/OperationDrawer'), {
  ssr: false
})

export const DRAWER_ANIMATION_DURATION = 500

const useOperationDrawer = function (selectedPoolData) {
  const [animating, setAnimating] = useState(false)
  const [opened, setOpened] = useState(false)
  const [operation, setOperation] = useState('')
  const [selectedToken, setSelectedToken] = useState(null)
  const [hasSelectedToken, setHasSelectedToken] = useState(false)
  const [inputValue, setInputValue] = useState(0)
  const [disableOnOperation, setDisableOnOperation] = useState(false)

  const closeOperationDrawer = function () {
    setSelectedToken(undefined)
    setAnimating(false)
    // unmount the component after the animation finishes
    setTimeout(() => setOpened(false), DRAWER_ANIMATION_DURATION)
    // Unsets Background Scrolling to use when drawer is closed
    document.body.style.overflow = 'unset'
  }

  const openOperationDrawer = function (_operation, tokenAddress) {
    setOpened(true)
    setInputValue(0)
    setOperation(_operation)
    setDisableOnOperation(false)
    setHasSelectedToken(!!tokenAddress)
    setSelectedToken(
      [...selectedPoolData.collaterals, ...selectedPoolData.synthetics].find(
        t => t.address === tokenAddress
      )
    )
    // Disables Background Scrolling whilst the drawer is open
    document.body.style.overflow = 'hidden'

    // set timeout with 0 delay, so this executes in the next cycle
    // thus waiting till the component is mounted to trigger the animation
    setTimeout(function () {
      setAnimating(true)
    })
  }

  return {
    closeOperationDrawer,
    disableOnOperation,
    inputValue,
    selectedToken,
    setDisableOnOperation,
    setInputValue,
    setSelectedToken,
    openOperationDrawer,
    opened,
    operationDrawer: (
      <OperationDrawer
        animating={animating}
        animationDuration={DRAWER_ANIMATION_DURATION}
        closeDrawer={closeOperationDrawer}
        hasSelectedToken={hasSelectedToken}
        opened={opened}
        operation={operation}
        selectedToken={selectedToken}
        setOperation={setOperation}
        setShowOperationDrawer={setOpened}
      />
    )
  }
}

export default useOperationDrawer
