import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import InfoModal from './InfoModal'

const OpInfoModal = function () {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <InfoModal
      button={t('switch-to', { network: 'Optimism' })}
      chains={[1]}
      image="/icons/op-live.png"
      onClick={() => router.push('/op', undefined, { shallow: true })}
      storageKey="op-live-modal-hidden"
      text={t('modal-op-text')}
      title={t('is-live', { network: 'Optimism' })}
    />
  )
}
export default OpInfoModal
