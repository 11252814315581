const MintedIcon = props => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" fill="url(#paint0_linear_1959_38456)" r="8" />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1959_38456"
        x1="0"
        x2="16"
        y1="0"
        y2="16"
      >
        <stop stopColor="#596AEC" />
        <stop offset="1" stopColor="#60E2E9" />
      </linearGradient>
    </defs>
  </svg>
)

export default MintedIcon
