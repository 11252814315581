const WarningIcon = props => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(-105 -12)">
      <g
        fill="none"
        stroke="white"
        strokeWidth="1.5px"
        transform="translate(105 12)"
      >
        <circle cx="8" cy="8" r="8" stroke="none" />
        <circle cx="8" cy="8" fill="none" r="7.25" />
      </g>
      <g fill="#fff" stroke="white" transform="translate(112 16)">
        <rect height="5" stroke="none" width="2" />
        <rect fill="none" height="4" width="1" x="0.5" y="0.5" />
      </g>
      <g fill="#0d124d" stroke="white" transform="translate(112 22)">
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" fill="none" r="0.5" />
      </g>
    </g>
  </svg>
)

export default WarningIcon
