import dynamic from 'next/dynamic'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useState } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'

import Checkbox from './Checkbox'
import ExternalLink from './ExternalLink'
import IconContainer from './icons/IconContainer'
import WalletContext from './WalletContext'
const Modal = dynamic(() => import('./Modal'), {
  ssr: false
})

const hostUrl = process.env.NEXT_PUBLIC_HOST_URL

const WalletConnector = function ({ onRequestClose, modalIsOpen, wallets }) {
  const { t } = useTranslation()
  const { wallet, setWallet } = useContext(WalletContext)
  const [showCheckbox, setShowCheckbox] = useState(true)
  const [checkboxState, setCheckboxState] = useState(false)
  const [walletConnectIsLoading, setWalletConnectIsLoading] = useState(false)
  const [walletConnectionError, setWalletConnectionError] = useState(false)
  const [hideLegals, setHideLegals] = useLocalStorage(
    'terms-cond-checked',
    false
  )
  const handleClose = function () {
    onRequestClose()
    if (walletConnectionError) {
      setWalletConnectionError(false)
    }
  }

  useEffect(
    function () {
      if (!hideLegals) {
        setShowCheckbox(true)
        setCheckboxState(false)
      } else {
        setShowCheckbox(false)
        setCheckboxState(true)
      }
    },
    [hideLegals]
  )

  return (
    <Modal
      className="w-100 bg-main-opacity mx-4 rounded-xl p-4 text-white"
      modalIsOpen={modalIsOpen}
      onRequestClose={handleClose}
    >
      <h4 className="mb-6 text-2xl">{t('connect-your-wallet')}</h4>
      {showCheckbox ? (
        <div className="bg-blue-primary mb-2 flex rounded-lg p-4">
          <div className="mt-1">
            <Checkbox
              id="terms-cond"
              name="terms-cond"
              setCheckboxState={setCheckboxState}
            />
          </div>
          <label className="text-sm" htmlFor="terms-cond">
            <Trans
              components={{
                termsAndCondLink: (
                  <ExternalLink
                    className="text-teal-primary underline"
                    href="https://github.com/autonomoussoftware/metronome-synth-legal/blob/master/terms-and-conditions.md"
                  >
                    {t('terms-and-conditions')}
                  </ExternalLink>
                )
              }}
              i18nKey="wallet-connection-text"
            />
          </label>
        </div>
      ) : null}
      <div>
        {walletConnectionError && (
          <div className="bg-red-02 mb-4 px-6 py-3 text-center text-sm text-gray-300">
            {t('wallet-connection-error')}
          </div>
        )}
        {wallets.map(function (w) {
          const isWalletConnect = w.connectorName === 'walletconnect'
          return (
            <div className="mb-2 last:mb-0" key={w.name}>
              <button
                className={`flex w-full items-center justify-between rounded-lg p-4 text-lg font-medium focus:border-transparent focus:outline-none
              ${!checkboxState ? 'cursor-not-allowed opacity-50' : ''}
              ${
                wallet?.name === w.name
                  ? 'bg-teal-primary text-white'
                  : 'bg-blue-primary hover:bg-blue-secondary'
              }
              `}
                onClick={function () {
                  if (wallet?.name === w.name) return
                  const isMetamaskConnector = w.connectorName === 'injected'
                  const providerObjectInjected = window.ethereum
                  setHideLegals(checkboxState)
                  if (isMetamaskConnector && !providerObjectInjected) {
                    window.open(`https://metamask.app.link/dapp/${hostUrl}`)
                    return
                  }
                  if (isWalletConnect) {
                    setWalletConnectIsLoading(true)
                  }
                  // If there was an error web3react will log it
                  checkboxState
                    ? w
                        .handleConnection()
                        .then(function () {
                          setWallet(w)
                          if (isWalletConnect) {
                            setWalletConnectIsLoading(false)
                          }
                        })
                        .catch(function () {
                          setWalletConnectionError(true)
                          if (isWalletConnect) {
                            setWalletConnectIsLoading(false)
                          }
                        })
                    : null
                }}
              >
                <div className="flex items-center">
                  <div className="mr-4 rounded-md p-2">
                    <IconContainer
                      height="24"
                      name={w.name.replace(/\s/g, '')}
                      width="24"
                    />
                  </div>
                  {w.name}
                </div>
                {isWalletConnect && walletConnectIsLoading && (
                  <IconContainer className="h-5 w-5" name="loading" />
                )}
              </button>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default WalletConnector
