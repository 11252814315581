const BackArrowIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.7168 5L2.99972 12L9.7168 19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      strokeLinecap="round"
      strokeWidth="2"
      transform="matrix(1 0 0 -1 3.26758 11.0312)"
      x1="1"
      x2="16.7331"
      y1="-1"
      y2="-1"
    />
  </svg>
)

export default BackArrowIcon
