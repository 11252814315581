import { findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useState } from 'react'
import addChain from 'wallet-add-chain'
import switchChain from 'wallet-switch-chain'

import { ERR_CHAIN_NOT_ADDED } from '../utils/chains'
import { UnsupportedSwitchingOfChainError } from '../utils/customErrors'

import ErrorHandler from './ErrorHandler'
import Ribbon from './Ribbon'
import WalletContext from './WalletContext'

const WrongNetworkRibbon = function ({ showRibbon }) {
  const { wallet } = useContext(WalletContext)
  const urlChainId = useChainId()
  const [connectionError, setConnectionError] = useState(false)
  const { t } = useTranslation()

  if (!showRibbon) {
    return null
  }

  if (wallet?.connectorName !== 'injected') {
    return null
  }

  function getDestinyChain(chainId) {
    const destinyChain = findByChainId(chainId)
    return {
      switchToChainId: chainId,
      switchToChainName: destinyChain.name
    }
  }

  const { switchToChainId, switchToChainName } = getDestinyChain(urlChainId)

  const formatChainData = function (chainId) {
    const res = findByChainId(chainId)
    const formattedRes = {
      chainId: `0x${res.chainId.toString(16)}`,
      chainName: res.standardName,
      rpcUrls: res.rpcUrls,
      blockExplorerUrls: [res.explorer.url],
      nativeCurrency: {
        name: res.nativeTokenSymbol,
        symbol: res.nativeTokenSymbol,
        decimals: res.decimals
      }
    }
    return formattedRes
  }
  // force reconnection, if wallet is available, because if the user first connected to Metronome
  // with its wallet connected to an unsupported wallet, then after switching to a supported network
  // it won't automatically reconnect. If the user connected to Metronome with a supported network (but different
  // from the one they see in Metronome ui), it will automatically reconnect. However, reconnecting won't throw an error
  // so it is safe to call handleConnection() here.
  const forceReconnection = function () {
    if (wallet) {
      wallet.handleConnection()
    }
  }

  const handleSwitch = function () {
    const chainData = formatChainData(switchToChainId)
    const callbackAdd = function (err) {
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return
      }
      forceReconnection()
    }
    const callbackSwitch = function (err) {
      if (!err) {
        forceReconnection()
        return
      }
      if (err.code === ERR_CHAIN_NOT_ADDED) {
        addChain(chainData, callbackAdd)
      } else {
        setConnectionError(new UnsupportedSwitchingOfChainError())
      }
    }
    if (chainData) {
      switchChain(chainData.chainId, callbackSwitch)
    }
  }

  return (
    <>
      <Ribbon
        className="top-0"
        closable={false}
        id="wrong-network"
        message={t('wrong-network')}
      >
        <button
          className="hidden text-sm font-bold uppercase text-white focus:outline-none lg:block"
          onClick={handleSwitch}
        >
          {t('switch-to', { network: switchToChainName.replace('/', '') })}
        </button>
      </Ribbon>
      <ErrorHandler
        error={connectionError}
        errorArgs={{ network: switchToChainName }}
        modalIsOpen={connectionError !== false}
        onRequestClose={() => setConnectionError(false)}
      />
    </>
  )
}

export default WrongNetworkRibbon
