const StethIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="m5.23165,10.82773l-0.18472,0.2834c-2.08351,3.19617 -1.61816,7.38201 1.11878,10.06365c1.6101,1.57768 3.72036,2.36659 5.83069,2.36678c0,0 0,0 -6.76474,-12.71383l-0.00001,0z"
        fill="#00A3FF"
        id="svg_2"
        stroke="null"
      />
      <path
        d="m11.99446,14.69182l-6.76474,-3.86409c6.76474,12.71383 6.76474,12.71383 6.76474,12.71383c0,-2.76897 0,-5.94187 0,-8.84974z"
        fill="#00A3FF"
        id="svg_3"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m18.76835,10.82773l0.18472,0.2834c2.08351,3.19617 1.61816,7.38201 -1.11878,10.06365c-1.6101,1.57768 -3.72036,2.36659 -5.83069,2.36678c0,0 0,0 6.76474,-12.71383l0.00001,0z"
        fill="#00A3FF"
        id="svg_4"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m12.00284,14.69182l6.76474,-3.86409c-6.76468,12.71383 -6.76474,12.71383 -6.76474,12.71383c0,-2.76897 0,-5.94187 0,-8.84974z"
        fill="#00A3FF"
        id="svg_5"
        opacity="0.2"
        stroke="null"
      />
      <path
        d="m12.00535,6.05955l0,6.66445l5.82708,-3.33004l-5.82708,-3.33442l0,0.00001z"
        fill="#00A3FF"
        id="svg_6"
        opacity="0.2"
        stroke="null"
      />
      <path
        d="m12.00361,6.05955l-5.83127,3.33429l5.83127,3.33016l0,-6.66445z"
        fill="#00A3FF"
        id="svg_7"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m12.00361,0.45483l-5.83127,8.9412l5.83127,-3.34363l0,-5.59756l0,-0.00001z"
        fill="#00A3FF"
        id="svg_8"
        stroke="null"
      />
      <path
        d="m12.00535,6.05136l5.83153,3.34376l-5.83153,-8.94566l0,5.6019z"
        fill="#00A3FF"
        id="svg_9"
        opacity="0.6"
        stroke="null"
      />
    </g>
  </svg>
)

export default StethIcon
