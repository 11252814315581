const BackArrowIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="61"
    viewBox="0 0 59 61"
    width="59"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1405_2850)" id="access_time">
      <path
        d="M29.1783 5.53613C15.9303 5.53613 5.20233 16.7361 5.20233 30.5361C5.20233 44.3361 15.9303 55.5361 29.1783 55.5361C42.4503 55.5361 53.2023 44.3361 53.2023 30.5361C53.2023 16.7361 42.4503 5.53613 29.1783 5.53613ZM29.2023 50.5361C18.5943 50.5361 10.0023 41.5861 10.0023 30.5361C10.0023 19.4861 18.5943 10.5361 29.2023 10.5361C39.8103 10.5361 48.4023 19.4861 48.4023 30.5361C48.4023 41.5861 39.8103 50.5361 29.2023 50.5361ZM28.6743 18.0361H28.5303C27.5703 18.0361 26.8023 18.8361 26.8023 19.8361V31.6361C26.8023 32.5111 27.2343 33.3361 27.9783 33.7861L37.9383 40.0111C38.7543 40.5111 39.8103 40.2611 40.2903 39.4111C40.7943 38.5611 40.5303 37.4361 39.6903 36.9361L30.4023 31.1861V19.8361C30.4023 18.8361 29.6343 18.0361 28.6743 18.0361Z"
        fill="#F8F8F9"
        id="Vector"
      />
    </g>
    <defs>
      <clipPath id="clip0_1405_2850">
        <rect
          fill="white"
          height="60"
          transform="translate(0.402344 0.536133)"
          width="57.6"
        />
      </clipPath>
    </defs>
  </svg>
)

export default BackArrowIcon
