const MarketplaceIcon = ({ fill, ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.75 2C2.64543 2 1.75 2.89543 1.75 4V8C1.75 9.10457 2.64543 10 3.75 10H9.75C10.8546 10 11.75 9.10457 11.75 8V4C11.75 2.89543 10.8546 2 9.75 2H3.75ZM13.75 14C12.6454 14 11.75 14.8954 11.75 16V20C11.75 21.1046 12.6454 22 13.75 22H19.75C20.8546 22 21.75 21.1046 21.75 20V16C21.75 14.8954 20.8546 14 19.75 14H13.75ZM20.3536 6.75L19.6339 7.46967C19.341 7.76256 19.341 8.23744 19.6339 8.53033C19.9268 8.82322 20.4016 8.82322 20.6945 8.53033L21.9874 7.23744C22.6709 6.55402 22.6709 5.44598 21.9874 4.76256L20.6945 3.46967C20.4016 3.17678 19.9268 3.17678 19.6339 3.46967C19.341 3.76256 19.341 4.23744 19.6339 4.53033L20.3536 5.25H15.75C15.3358 5.25 15 5.58579 15 6C15 6.41421 15.3358 6.75 15.75 6.75H20.3536ZM3.86612 19.4697L3.14645 18.75H7.75C8.16421 18.75 8.5 18.4142 8.5 18C8.5 17.5858 8.16421 17.25 7.75 17.25H3.14645L3.86612 16.5303C4.15901 16.2374 4.15901 15.7626 3.86612 15.4697C3.57322 15.1768 3.09835 15.1768 2.80546 15.4697L1.51256 16.7626C0.829145 17.446 0.829147 18.554 1.51256 19.2374L2.80546 20.5303C3.09835 20.8232 3.57322 20.8232 3.86612 20.5303C4.15901 20.2374 4.15901 19.7626 3.86612 19.4697Z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
)

export default MarketplaceIcon
