import { useChainId } from 'metronome-ui/hooks/chains'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import useTransactionOptions from '../hooks/useTransactionOptions'
import { isTransactionFinished, txStatuses } from '../utils/transaction'

const TransactionContext = createContext()

const TransactionContextProvider = function ({ children }) {
  const [transactions, setTransactions] = useState([])
  const [openedInternalTransactionId, setOpenedInternalTransactionId] =
    useState(undefined)
  const chainId = useChainId()

  const { resetTransactionOptionsValues, options } = useTransactionOptions()

  const currentTransactions = useMemo(
    () =>
      Object.values(
        transactions.reduce(
          (result, transaction) => ({
            ...result,
            [transaction.internalTransactionId]: result[
              transaction.internalTransactionId
            ]
              ? { ...result[transaction.internalTransactionId], ...transaction }
              : transaction
          }),
          {}
        )
      ),
    [transactions]
  )

  const addTransactionStatus = useCallback(
    function (newTransaction) {
      setTransactions(previousTransactions => [
        ...previousTransactions,
        {
          chainId,
          ...newTransaction
        }
      ])
      // open the newly added transaction status
      setOpenedInternalTransactionId(newTransaction.internalTransactionId)
    },
    [chainId]
  )

  const removeTransaction = useCallback(function ({ internalTransactionId }) {
    setTransactions(prev =>
      prev.filter(t => t.internalTransactionId !== internalTransactionId)
    )
  }, [])

  const areNonFinalizedTransactions = currentTransactions.some(
    t =>
      !isTransactionFinished(t) ||
      t.transactionStatus === txStatuses.WalletTimeout
  )

  useEffect(
    function () {
      if (!areNonFinalizedTransactions) {
        return undefined
      }
      const handler = function (e) {
        // // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
      window.addEventListener('beforeunload', handler)
      return () => window.removeEventListener('beforeunload', handler)
    },
    [areNonFinalizedTransactions]
  )

  const removeFinishedTransaction = useCallback(
    function (openedTransaction) {
      if (openedTransaction && isTransactionFinished(openedTransaction)) {
        removeTransaction({
          internalTransactionId: openedInternalTransactionId
        })
        setOpenedInternalTransactionId(undefined)
      }
    },
    [openedInternalTransactionId, removeTransaction]
  )

  return (
    <TransactionContext.Provider
      value={{
        addTransactionStatus,
        currentTransactions,
        openedInternalTransactionId,
        options,
        removeFinishedTransaction,
        removeTransaction,
        resetOptions: resetTransactionOptionsValues,
        setOpenedInternalTransactionId,
        transactions
      }}
    >
      {children}
    </TransactionContext.Provider>
  )
}

export { TransactionContextProvider }

export default TransactionContext
