const MetamaskIcon = props => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1866 12.0807L15.214 15.2341L13.3047 14.7333L16.1866 12.0807Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M13.3047 14.7333L15.1462 12.3138L16.1866 12.0807L13.3047 14.7333Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M14.7559 9.7033L16.1868 12.0807L15.1465 12.3139L14.7559 9.7033ZM14.7559 9.7033L15.6766 9.09174L16.1868 12.0807L14.7559 9.7033Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M13.584 7.90678L16.2307 6.86713L16.0992 7.43664L13.584 7.90678ZM16.0155 8.12083L13.584 7.90678L16.0992 7.43664L16.0155 8.12083Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M16.0155 8.12083L15.6767 9.09168L13.584 7.90678L16.0155 8.12083ZM16.5456 7.10793L16.0992 7.43664L16.2307 6.86713L16.5456 7.10793ZM16.0155 8.12083L16.0992 7.43664L16.4539 7.71949L16.0155 8.12083Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M11.252 13.9651L11.9136 14.1677L13.3048 14.7334L11.252 13.9651Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeWidth="0.190386"
    />
    <path
      d="M15.6761 9.09176L16.0149 8.12091L16.3059 8.33495L15.6761 9.09176ZM15.6761 9.09176L12.9297 8.22411L13.5834 7.90686L15.6761 9.09176Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M14.2371 4.92926L13.5834 7.9068L12.9297 8.22405L14.2371 4.92926Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M16.2307 6.86715L13.584 7.9068L14.2377 4.92926L16.2307 6.86715Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M14.2373 4.92926L16.9996 4.62347L16.2303 6.86714L14.2373 4.92926Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M15.6761 9.09177L14.7553 9.70334L12.9297 8.22412L15.6761 9.09177Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M16.88 2.47925L16.9996 4.62354L14.2373 4.92932L16.88 2.47925Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M16.8805 2.47925L11.3598 6.32827L11.2881 3.71766L16.8805 2.47925Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeWidth="0.190386"
    />
    <path
      d="M7.18262 3.40417L11.2882 3.7176L11.36 6.32821L7.18262 3.40417Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M12.9309 8.22405L11.3604 6.32821L14.2383 4.92926L12.9309 8.22405Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M12.9298 8.22412L14.7554 9.70334L12.1924 9.97472L12.9298 8.22412Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M12.1934 9.97475L11.3604 6.32831L12.9308 8.22415L12.1934 9.97475Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M14.2383 4.92932L11.3604 6.32827L16.881 2.47925L14.2383 4.92932Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M7.2184 14.6722L8.52183 15.9756L6.74805 14.4276L7.2184 14.6722Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth="0.190386"
    />
    <path
      d="M13.3047 14.7334L13.9225 12.4821L15.1462 12.3139L13.3047 14.7334Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M1.93262 8.39986L4.18871 6.12561L2.2236 8.12848L1.93262 8.39986Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeWidth="0.190386"
    />
    <path
      d="M15.1467 12.3139L13.923 12.4821L14.7561 9.70327L15.1467 12.3139ZM11.36 6.32821L9.16368 6.25941L7.18262 3.40417L11.36 6.32821Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M14.7563 9.70331L13.9232 12.4821L13.8555 11.1252L14.7563 9.70331Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth="0.190386"
    />
    <path
      d="M12.1924 9.97469L14.7554 9.70331L13.8546 11.1252L12.1924 9.97469Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M9.16309 6.25946L11.3594 6.32826L12.1925 9.9747L9.16309 6.25946Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M9.1634 6.25944L3.23617 1L7.18234 3.4042L9.1634 6.25944ZM7.17835 15.4711L2.0284 17L1 13.2351L7.17835 15.4711Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M2.69824 9.34015L4.64343 7.87622L6.27372 8.23934L2.69824 9.34015Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M6.27384 8.23934L4.64355 7.87623L5.5165 4.4209L6.27384 8.23934Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M2.22363 8.12849L4.64316 7.87622L2.69797 9.34015L2.22363 8.12849Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M13.8546 11.1251L12.8501 10.6397L12.1924 9.97461L13.8546 11.1251Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M2.22413 8.12858L2.05273 7.26093L4.64366 7.87631L2.22413 8.12858Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M12.6436 11.7329L12.8508 10.6398L13.8553 11.1252L12.6436 11.7329Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.190386"
    />
    <path
      d="M13.9231 12.482L12.6436 11.7329L13.8553 11.1251L13.9231 12.482Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M4.643 7.87623L2.05208 7.26084L1.84082 6.53079L4.643 7.87623ZM5.51595 4.4209L4.643 7.87623L1.84082 6.53079L5.51595 4.4209ZM5.51595 4.4209L9.16317 6.25941L6.27329 8.23934L5.51595 4.4209Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M6.27344 8.23939L9.16331 6.25946L10.4508 10.032L6.27344 8.23939ZM10.4508 10.032L6.4568 9.95177L6.27344 8.23939L10.4508 10.032Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M2.69824 9.34021L6.27371 8.23939L6.45707 9.95177L2.69824 9.34021ZM12.193 9.9747L10.4511 10.032L9.16359 6.25946L12.193 9.9747Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M12.8501 10.6397L12.6428 11.7329L12.1924 9.97461L12.8501 10.6397Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M3.23633 1L9.16356 6.25944L5.51634 4.42093L3.23633 1Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M1 13.2351L5.99849 13.0364L7.17835 15.4712L1 13.2351Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M7.17889 15.4711L5.99902 13.0364L8.45442 12.9217L7.17889 15.4711Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M12.6432 11.733L13.9227 12.4822L14.6283 14.0761L12.6432 11.733ZM6.45688 9.95183L1 13.2352L2.69805 9.34027L6.45688 9.95183ZM5.99849 13.0364L1 13.2352L6.45688 9.95183L5.99849 13.0364ZM12.1928 9.97477L12.5196 11.1138L10.9412 11.2017L12.1928 9.97477ZM10.9412 11.2017L10.4509 10.0321L12.1928 9.97477L10.9412 11.2017Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M8.52103 15.9757L7.17773 15.4711L11.8494 16.2241L8.52103 15.9757Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth="0.190386"
    />
    <path
      d="M2.69794 9.34014L1.93262 8.39986L2.2236 8.12848L2.69794 9.34014Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M12.3795 15.6661L11.8494 16.2241L7.17773 15.4711L12.3795 15.6661Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeWidth="0.190386"
    />
    <path
      d="M12.6306 14.2977L7.17773 15.4711L8.45327 12.9217L12.6306 14.2977Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeWidth="0.190386"
    />
    <path
      d="M7.17773 15.4712L12.6306 14.2977L12.3795 15.6661L7.17773 15.4712Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeWidth="0.190386"
    />
    <path
      d="M1.8405 6.53075L1.66113 3.59143L5.51563 4.42086L1.8405 6.53075ZM2.22316 8.12846L1.69302 7.61245L2.05176 7.2608L2.22316 8.12846Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M9.55762 10.7889L10.4505 10.032L10.3229 11.882L9.55762 10.7889Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M10.4511 10.032L9.55824 10.7889L8.25879 11.4348L10.4511 10.032Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M14.6286 14.0759L14.3855 13.9039L12.6436 11.7328L14.6286 14.0759Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M8.25872 11.4349L6.45703 9.95184L10.451 10.0321L8.25872 11.4349Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeWidth="0.190386"
    />
    <path
      d="M10.3232 11.882L10.4508 10.032L10.9411 11.2017L10.3232 11.882Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth="0.190386"
    />
    <path
      d="M1.51855 6.82138L1.84142 6.53088L2.05268 7.26094L1.51855 6.82138Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M10.3236 11.8821L8.25879 11.4348L9.55824 10.7889L10.3236 11.8821Z"
      fill="#233447"
      stroke="#233447"
      strokeWidth="0.190386"
    />
    <path
      d="M5.51563 4.42093L1.66113 3.59149L3.23562 1L5.51563 4.42093Z"
      fill="#763D16"
      stroke="#763D16"
      strokeWidth="0.190386"
    />
    <path
      d="M11.8498 16.224L12.0691 16.7973L8.52148 15.9755L11.8498 16.224Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth="0.190386"
    />
    <path
      d="M8.4541 12.9215L8.25879 11.4347L10.3236 11.8819L8.4541 12.9215Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M6.45703 9.95184L8.25872 11.4349L8.45403 12.9217L6.45703 9.95184Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth="0.190386"
    />
    <path
      d="M10.9417 11.2017L12.5202 11.1138L14.3856 13.9041L10.9417 11.2017ZM6.45742 9.95184L8.45442 12.9217L5.99902 13.0364L6.45742 9.95184Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M10.9411 11.2015L12.9022 14.0644L10.3232 11.8819L10.9411 11.2015Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeWidth="0.190386"
    />
    <path
      d="M10.3232 11.8821L12.9022 14.0647L12.6312 14.2978L10.3232 11.8821Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M12.6315 14.2976L8.4541 12.9216L10.3236 11.8819L12.6315 14.2976ZM14.3853 13.9039L12.9025 14.0644L10.9414 11.2015L14.3853 13.9039Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeWidth="0.190386"
    />
    <path
      d="M14.7763 15.2495L14.1386 16.4764L12.0698 16.7975L14.7763 15.2495ZM12.0698 16.7975L11.8506 16.2241L12.3807 15.6661L12.0698 16.7975Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth="0.190386"
    />
    <path
      d="M12.3802 15.6661L12.7709 15.517L12.0693 16.7975L12.3802 15.6661ZM12.0693 16.7975L12.7709 15.517L14.7759 15.2495L12.0693 16.7975Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeWidth="0.190386"
    />
    <path
      d="M14.3853 13.9039L14.8755 14.1906L13.3848 14.3626L14.3853 13.9039Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.190386"
    />
    <path
      d="M13.3847 14.3626L12.9023 14.0645L14.3851 13.9039L13.3847 14.3626ZM13.2133 14.5881L15.0269 14.3779L14.7758 15.2494L13.2133 14.5881Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.190386"
    />
    <path
      d="M14.7765 15.2494L12.7715 15.5169L13.2139 14.5881L14.7765 15.2494ZM12.7715 15.5169L12.3809 15.666L12.632 14.2976L12.7715 15.5169ZM12.632 14.2976L12.903 14.0645L13.3853 14.3626L12.632 14.2976ZM14.8761 14.1906L15.0276 14.3779L13.2139 14.5881L14.8761 14.1906Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.190386"
    />
    <path
      d="M13.2128 14.5881L13.3842 14.3626L14.875 14.1906L13.2128 14.5881ZM12.6309 14.2976L13.2128 14.5881L12.7704 15.5169L12.6309 14.2976Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.190386"
    />
    <path
      d="M13.3842 14.3627L13.2128 14.5882L12.6309 14.2977L13.3842 14.3627Z"
      fill="#161616"
      stroke="#161616"
      strokeWidth="0.190386"
    />
  </svg>
)

export default MetamaskIcon
