const SmartFarmingIcon = ({ fill, ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.6442 13.2051C14.2649 13.9056 17.3857 14.7398 20.0187 12.1067L18.9066 10.9947C16.9437 12.9576 14.6989 12.3918 11.8685 11.6371L11.8134 11.6224C9.14422 10.9106 5.9432 10.0569 3.19832 12.8018L4.31042 13.9139C6.35644 11.8679 8.65594 12.4083 11.4633 13.1568C11.5233 13.1728 11.5837 13.1889 11.6442 13.2051Z"
      fill="url(#paint0_radial_617_2985)"
      fillRule="evenodd"
    />
    <path
      d="M10.7047 20.3085C15.3616 15.6516 7.70208 9.40997 12.5119 4.60016"
      stroke={fill}
      strokeWidth="1.57274"
    />
    <mask fill={fill} id="path-3-inside-1_617_2985">
      <path d="M20.0186 12.1067C21.1321 10.9933 21.7751 9.50077 21.8064 7.95744C21.8377 6.41412 21.2546 4.94643 20.1855 3.87726C19.1163 2.80808 17.6486 2.225 16.1053 2.25629C14.5619 2.28758 13.0694 2.93066 11.956 4.04408L13.0847 5.17285C13.8864 4.37119 14.961 3.90817 16.0722 3.88564C17.1834 3.86312 18.2402 4.28293 19.01 5.05274C19.7798 5.82254 20.1996 6.87928 20.1771 7.99047C20.1546 9.10167 19.6915 10.1763 18.8899 10.978L20.0186 12.1067Z" />
    </mask>
    <path
      d="M20.0186 12.1067C21.1321 10.9933 21.7751 9.50077 21.8064 7.95744C21.8377 6.41412 21.2546 4.94643 20.1855 3.87726C19.1163 2.80808 17.6486 2.225 16.1053 2.25629C14.5619 2.28758 13.0694 2.93066 11.956 4.04408L13.0847 5.17285C13.8864 4.37119 14.961 3.90817 16.0722 3.88564C17.1834 3.86312 18.2402 4.28293 19.01 5.05274C19.7798 5.82254 20.1996 6.87928 20.1771 7.99047C20.1546 9.10167 19.6915 10.1763 18.8899 10.978L20.0186 12.1067Z"
      mask="url(#path-3-inside-1_617_2985)"
      stroke={fill}
      strokeWidth="3.14547"
    />
    <mask fill={fill} id="path-4-inside-2_617_2985">
      <path d="M3.1984 12.8018C2.08498 13.9152 1.44189 15.4078 1.41061 16.9511C1.37932 18.4944 1.96241 19.9621 3.03158 21.0313C4.10076 22.1005 5.56845 22.6835 7.11178 22.6523C8.65511 22.621 10.1477 21.9779 11.2611 20.8645L10.1323 19.7357C9.33064 20.5374 8.25601 21.0004 7.14481 21.0229C6.03361 21.0454 4.97687 20.6256 4.20707 19.8558C3.43726 19.086 3.01744 18.0293 3.03996 16.9181C3.06249 15.8069 3.52551 14.7323 4.32717 13.9306L3.1984 12.8018Z" />
    </mask>
    <path
      d="M3.1984 12.8018C2.08498 13.9152 1.44189 15.4078 1.41061 16.9511C1.37932 18.4944 1.96241 19.9621 3.03158 21.0313C4.10076 22.1005 5.56845 22.6835 7.11178 22.6523C8.65511 22.621 10.1477 21.9779 11.2611 20.8645L10.1323 19.7357C9.33064 20.5374 8.25601 21.0004 7.14481 21.0229C6.03361 21.0454 4.97687 20.6256 4.20707 19.8558C3.43726 19.086 3.01744 18.0293 3.03996 16.9181C3.06249 15.8069 3.52551 14.7323 4.32717 13.9306L3.1984 12.8018Z"
      mask="url(#path-4-inside-2_617_2985)"
      stroke={fill}
      strokeWidth="3.14547"
    />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(11.5946 12.4682) rotate(-2.00088) scale(6.37062 6.91979)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_617_2985"
        r="1"
      >
        <stop stopColor={fill} stopOpacity="0" />
        <stop offset="1" stopColor={fill} stopOpacity="0.927083" />
      </radialGradient>
    </defs>
  </svg>
)

export default SmartFarmingIcon
