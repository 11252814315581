const EthIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#627EEA"
    />
    <path
      d="M12.373 3V9.6525L17.9958 12.165L12.373 3Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path d="M12.3735 3L6.75 12.165L12.3735 9.6525V3Z" fill="white" />
    <path
      d="M12.373 16.4759V20.9962L17.9996 13.2119L12.373 16.4759Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path
      d="M12.3735 20.9962V16.4752L6.75 13.2119L12.3735 20.9962Z"
      fill="white"
    />
    <path
      d="M12.373 15.43L17.9958 12.1653L12.373 9.6543V15.43Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M6.75 12.1653L12.3735 15.43V9.6543L6.75 12.1653Z"
      fill="white"
      fillOpacity="0.602"
    />
  </svg>
)

export default EthIcon
