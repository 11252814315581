import LanguageSelector from './LanguageSelector'
import LegalLinks from './LegalLinks'
import SocialLinks from './SocialLinks'

const Footer = () => (
  <div className="md:flex md:justify-between">
    <div className="border-gray-primary mt-4 border-t pt-6 md:mt-0 md:flex md:items-center md:space-x-10 md:border-0 md:pt-0">
      <div className="mb-10 flex space-x-6 md:mb-0">
        <SocialLinks />
      </div>
      <div className="text-gray-primary mb-10 space-y-4 text-sm md:mb-0 md:space-x-6 md:space-y-0">
        <LegalLinks />
      </div>
    </div>
    <LanguageSelector />
  </div>
)

export default Footer
