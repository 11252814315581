const EnIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1309_135029)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#227FBB"
      />
      <path
        clipRule="evenodd"
        d="M4.96258 2.27917L12.0001 9.3167L19.0376 2.27917C20.0675 3.02606 20.974 3.93259 21.7209 4.96246L14.6834 12L21.7209 19.0375C20.974 20.0674 20.0675 20.9739 19.0376 21.7208L12.0001 14.6833L4.96258 21.7208C3.93271 20.9739 3.02618 20.0674 2.2793 19.0375L9.31682 12L2.2793 4.96246C3.02618 3.93259 3.93272 3.02606 4.96258 2.27917Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.4858 19.3519L15.3237 13.0666H13.2411L20.5143 20.4571C20.86 20.1091 21.1845 19.74 21.4858 19.3519ZM4.63166 21.4713L12.8009 13.2H10.6676L3.49384 20.4634C3.85128 20.8226 4.23132 21.1594 4.63166 21.4713ZM20.5003 3.5288L13.6676 10.4166H11.7343L19.4763 2.61218C19.8349 2.89817 20.1769 3.20428 20.5003 3.5288ZM2.52734 4.63351L7.87321 9.99996H9.97063L3.51031 3.52001C3.16039 3.87037 2.83201 4.24226 2.52734 4.63351Z"
        fill="#E94B35"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.33333 0.297355V9.33333H0.297355C0.102749 10.191 0 11.0835 0 12C0 12.9165 0.102749 13.809 0.297355 14.6667H9.33333V23.7026C10.191 23.8973 11.0835 24 12 24C12.9165 24 13.809 23.8973 14.6667 23.7026V14.6667H23.7026C23.8973 13.809 24 12.9165 24 12C24 11.0835 23.8973 10.191 23.7026 9.33333H14.6667V0.297355C13.809 0.102749 12.9165 0 12 0C11.0835 0 10.191 0.102749 9.33333 0.297355Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10 0.165917V10H0.165917C0.0567976 10.6504 0 11.3186 0 12C0 12.6814 0.0567976 13.3496 0.165917 14H10V23.8341C10.6504 23.9432 11.3186 24 12 24C12.6814 24 13.3496 23.9432 14 23.8341V14H23.8341C23.9432 13.3496 24 12.6814 24 12C24 11.3186 23.9432 10.6504 23.8341 10H14V0.165917C13.3496 0.0567976 12.6814 0 12 0C11.3186 0 10.6504 0.0567976 10 0.165917Z"
        fill="#E94B35"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1309_135029">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default EnIcon
