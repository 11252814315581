const VaFraxIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx="12.00001"
        cy="12.03126"
        fill="#000000"
        id="svg_7"
        r="11.09376"
        stroke="null"
      />
      <path
        d="m16.69901,6.14058c-0.33302,0.34974 -0.75081,0.78693 -0.92606,0.96788l-0.31819,0.33226l-0.19236,-0.11823a7.04507,7.04507 0 0 0 -1.12831,-0.52766a5.80348,5.80348 0 0 0 -3.64381,-0.00228a5.64686,5.64686 0 0 0 -1.05798,0.47406a2.11177,2.11177 0 0 1 -0.20605,0.11823c-0.00608,0 -0.45125,-0.42083 -0.98537,-0.93443s-0.98461,-0.92834 -0.99867,-0.92226c-0.03155,0.0114 -1.63468,1.69854 -1.63468,1.71603c0,0.00836 0.35849,0.35545 0.79567,0.7702s0.86904,0.82342 0.95686,0.9082l0.16081,0.15586l-0.15206,0.25661a5.70236,5.70236 0 0 0 -0.22315,5.29901c0.08478,0.17829 0.19008,0.387 0.2395,0.46341l0.08478,0.14066l-0.09276,0.10188c-0.27942,0.29652 -1.37161,1.44194 -1.63658,1.71299l-0.30413,0.31287l0.25889,0.25395c0.61852,0.59799 1.44194,1.37769 1.46208,1.38567c0.01407,0.00608 0.46569,-0.45619 1.00476,-1.01882l0.98157,-1.02642l0.20376,0.12127a5.86469,5.86469 0 0 0 2.39499,0.80175a5.79284,5.79284 0 0 0 3.40849,-0.73979l0.22809,-0.13534l0.09808,0.09352c0.05702,0.05094 0.52272,0.49649 1.03897,0.98841s0.94469,0.89451 0.95914,0.89451c0.02547,0 1.6457,-1.67915 1.6457,-1.70463c0,-0.00532 -0.41475,-0.40867 -0.92302,-0.89451s-0.96788,-0.92226 -1.0211,-0.9732l-0.09656,-0.09048l0.10188,-0.16309a6.78657,6.78657 0 0 0 0.47976,-0.99943a5.8951,5.8951 0 0 0 0.17525,-3.32942a6.15855,6.15855 0 0 0 -0.60825,-1.502a0.7147,0.7147 0 0 1 -0.08478,-0.16309c0,-0.01407 0.42616,-0.47178 0.95039,-1.01578s0.94241,-1.00171 0.93671,-1.01578c-0.0114,-0.03155 -1.69588,-1.63468 -1.71603,-1.63468c-0.00684,0.00114 -0.28588,0.28816 -0.61623,0.64209zm-3.84871,2.66984a3.43662,3.43662 0 1 1 -3.92931,4.01256a4.99831,4.99831 0 0 1 -0.02851,-1.08421a3.47844,3.47844 0 0 1 0.98461,-1.96959a3.43244,3.43244 0 0 1 1.8373,-0.95686a4.14371,4.14371 0 0 1 1.13591,-0.00228l0,0.00038z"
        data-name="Path 10558"
        fill="#e1e1eb"
        id="Path_10558"
        stroke="null"
      />
    </g>
    <g>
      <circle
        cx="18.00003"
        cy="17.50002"
        fill="white"
        id="svg_3"
        r="3.66146"
        stroke="#596AEC"
        strokeWidth="0.67708"
      />
      <path
        d="m18.08613,16.02252c-0.0429,0 -0.0809,0.0276 -0.0941,0.0685l-0.6518,2.0132c-0.0074,0.0228 -0.0397,0.0228 -0.0471,0l-0.6523,-2.0133c-0.0132,-0.0408 -0.0512,-0.0684 -0.0941,-0.0684l-1.1097,0c-0.0683,0 -0.1161,0.0675 -0.0934,0.1319l0.1359,0.385c0.0139,0.0396 0.0514,0.066 0.0933,0.066l0.5252,0c0.0105,0 0.0198,0.0066 0.0233,0.0165l0.8552,2.4214c0.014,0.0396 0.0514,0.066 0.0934,0.066l0.4935,0c0.0419,0 0.0794,-0.0264 0.0933,-0.066l1.0202,-2.8889c0.0228,-0.0644 -0.025,-0.1319 -0.0933,-0.1319l-0.4975,0z"
        fill="#596AEC"
        id="svg_4"
      />
      <path
        d="m19.13233,19.14552c0.3566,0 0.5697,-0.1672 0.6674,-0.3581l0.0177,0l0,0.3122l0.5149,0l0,-1.5211c0,-0.6007 -0.4897,-0.7812 -0.9233,-0.7812c-0.4779,0 -0.8448,0.2131 -0.9632,0.6274l0.5001,0.071c0.0533,-0.1554 0.2042,-0.2886 0.4661,-0.2886c0.2486,0 0.3847,0.1273 0.3847,0.3507l0,0.0089c0,0.1539 -0.1613,0.1613 -0.5623,0.2042c-0.4409,0.0473 -0.8626,0.179 -0.8626,0.691c0,0.4468 0.327,0.6836 0.7605,0.6836zm0.1391,-0.3936c-0.2234,0 -0.3832,-0.1021 -0.3832,-0.2989c0,-0.2057 0.179,-0.2915 0.4187,-0.3255c0.1406,-0.0192 0.4217,-0.0548 0.4913,-0.111l0,0.2678c0,0.253 -0.2042,0.4676 -0.5268,0.4676z"
        fill="#596AEC"
        id="svg_5"
      />
    </g>
  </svg>
)

export default VaFraxIcon
