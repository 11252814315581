import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import Button from './Button'
import Layout from './Layout'

type ErrorComponentProps = {
  componentStack?: string
  resetError?: () => void
  statusCode?: number
  message?: string
}

const ErrorComponent = function ({
  componentStack,
  resetError,
  statusCode,
  message
}: ErrorComponentProps) {
  const { t } = useTranslation()
  const title = statusCode ? t(`error-${statusCode}`) : t('error-app')
  if (componentStack) {
    console.error(componentStack)
  }

  return (
    <Layout description={title} title={title}>
      <div className="w-full">
        <div className="text-center">
          <h2 className="text-display4 mb-8 font-semibold text-white">
            {title}
          </h2>
          {message && (
            <div className="w-105 m-auto text-lg text-white">
              <p>{message}</p>
            </div>
          )}
          {resetError && (
            <Button
              className="mx-auto my-6 block"
              onClick={() => resetError()}
              size="lg"
            >
              {t('reload')}
            </Button>
          )}
          <Image
            alt="Error"
            className="m-auto"
            height="378"
            src="/error.png"
            width="566"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ErrorComponent
