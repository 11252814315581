const MsusdIcon = ({ id = 'paint0_linear_5160_338600', ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5160_347742)">
      <path
        d="M12 24C14.3734 24 16.6935 23.2962 18.6669 21.9776C20.6403 20.6591 22.1783 18.7849 23.0866 16.5922C23.9948 14.3995 24.2325 11.9867 23.7695 9.65892C23.3064 7.33115 22.1636 5.19295 20.4853 3.51472C18.8071 1.83649 16.6689 0.693605 14.3411 0.230582C12.0133 -0.232441 9.60055 0.00519936 7.40784 0.913451C5.21512 1.8217 3.34098 3.35977 2.0224 5.33316C0.703824 7.30655 3.63305e-05 9.62663 3.63305e-05 12C-0.00384869 13.577 0.303889 15.1391 0.905569 16.5968C1.50725 18.0545 2.39101 19.3789 3.50609 20.4939C4.62117 21.609 5.94558 22.4928 7.40324 23.0945C8.8609 23.6961 10.4231 24.0039 12 24Z"
        fill={`url(#${id})`}
      />
      <path
        d="M15.3005 13.9001C15.3005 12.1546 14.2511 11.5492 12.1511 11.2993C10.65 11.0997 10.3511 10.6993 10.3511 10.0001C10.3511 9.30079 10.8507 8.84916 11.85 8.84916C12.7511 8.84916 13.2507 9.14916 13.5005 9.8997C13.5273 9.97201 13.5757 10.0343 13.6391 10.0782C13.7026 10.1221 13.7779 10.1455 13.8551 10.1452H14.6547C14.701 10.1464 14.7471 10.1381 14.7901 10.1209C14.8331 10.1038 14.8722 10.078 14.905 10.0453C14.9377 10.0125 14.9635 9.97342 14.9807 9.93039C14.9978 9.88736 15.0061 9.84129 15.0049 9.79497V9.74588C14.9083 9.20396 14.6354 8.70912 14.2286 8.33825C13.8218 7.96737 13.3039 7.74124 12.7554 7.69498V6.49498C12.7554 6.29534 12.6049 6.14588 12.3551 6.0957H11.6045C11.4049 6.0957 11.2554 6.24516 11.2052 6.49498V7.64916C9.70088 7.85425 8.7507 8.84916 8.7507 10.0993C8.7507 11.7499 9.75106 12.4001 11.8511 12.6499C13.2507 12.8997 13.7056 13.1953 13.7056 13.9993C13.7056 14.8033 13.0063 15.3499 12.0562 15.3499C10.7558 15.3499 10.3107 14.8044 10.1558 14.0495C10.1415 13.9652 10.0981 13.8885 10.0331 13.8329C9.96811 13.7773 9.88568 13.7463 9.80015 13.7452H8.94597C8.8997 13.7441 8.8537 13.7525 8.81074 13.7697C8.76778 13.7869 8.72876 13.8127 8.69603 13.8454C8.6633 13.8781 8.63755 13.9171 8.62032 13.9601C8.60309 14.0031 8.59475 14.0491 8.59579 14.0953V14.1455C8.79652 15.3957 9.59615 16.2957 11.2467 16.5455V17.7455C11.2467 17.9452 11.3962 18.0957 11.646 18.1459H12.3965C12.5962 18.1459 12.7467 17.9953 12.7958 17.7455V16.5455C14.2958 16.2957 15.2962 15.2452 15.2962 13.8957L15.3005 13.9001Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id={id}
        x1="0"
        x2="24"
        y1="0"
        y2="24"
      >
        <stop stopColor="#596AEC" />
        <stop offset="1" stopColor="#60E2E9" />
      </linearGradient>
      <clipPath id="clip0_5160_347742">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default MsusdIcon
