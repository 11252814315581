const LoadingIcon = ({ width = '33', height = '33', ...props }) => (
  <svg
    height={height}
    viewBox="0 0 100 100"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      r="33"
      stroke="currentColor"
      strokeDasharray="155.50883635269477 53.83627878423159"
      strokeWidth="5"
    >
      <animateTransform
        attributeName="transform"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
    </circle>
  </svg>
)

export default LoadingIcon
