import { useChainId } from 'metronome-ui/hooks/chains'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { useLocalStorage } from '../hooks/useLocalStorage'
import { useSessionStorage } from '../hooks/useSessionStorage'

import Button from './Button'
import IconContainer from './icons/IconContainer'

const Modal = dynamic(() => import('./Modal'), {
  ssr: false
})
const InfoModal = function ({
  button,
  chains = [],
  image,
  onClick = false,
  storageKey,
  text,
  title = ''
}) {
  const { t } = useTranslation()
  const chainId = useChainId()
  const [userHidModalLocal, setUserHidModalLocal] = useLocalStorage(
    storageKey,
    false
  )
  const [userHidModalSession, setUserHidModalSession] = useSessionStorage(
    storageKey,
    false
  )
  if (userHidModalLocal || userHidModalSession || !chains.includes(chainId)) {
    return null
  }
  const handleOnClick = function () {
    if (onClick) {
      onClick()
    }
    setUserHidModalLocal(true)
  }
  return (
    <Modal
      className="bg-modal relative mx-4 rounded-xl p-6 text-center text-white"
      modalIsOpen={!userHidModalLocal && !userHidModalSession}
      onRequestClose={() => setUserHidModalSession(true)}
    >
      <button
        className="absolute right-8 top-8 focus:outline-none"
        onClick={() => setUserHidModalSession(true)}
      >
        <IconContainer
          className={`h-5 w-5 fill-current text-white`}
          name="close"
        />
      </button>
      {image && (
        <Image
          alt={title}
          className="mb-8"
          height="261"
          src={image}
          width="516"
        />
      )}
      {title && <p className="mb-3 text-xl font-bold">{title}</p>}
      {text && <p className="m-auto mb-8 w-96 text-lg leading-8">{text}</p>}
      <div className="flex justify-between space-x-6">
        <Button
          className="flex w-1/2 items-center justify-center py-3.5"
          onClick={handleOnClick}
        >
          {button}
        </Button>
        <Button
          className="flex w-1/2 items-center justify-center py-3.5"
          onClick={() => setUserHidModalLocal(true)}
        >
          {t('dismiss')}
        </Button>
      </div>
    </Modal>
  )
}

export default InfoModal
