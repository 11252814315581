const ChevronLeft = props => (
  <svg
    className="h-6 w-6"
    fill="currentColor"
    height="15"
    viewBox="0 0 14 15"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1412_24278)">
      <path
        d="M8.5486 4.1774C8.32574 3.95454 7.96574 3.95454 7.74288 4.1774L5.12002 6.80025C4.89717 7.02311 4.89717 7.38311 5.12002 7.60597L7.74288 10.2288C7.96574 10.4517 8.32574 10.4517 8.5486 10.2288C8.77145 10.006 8.77145 9.64597 8.5486 9.42311L6.33145 7.20025L8.5486 4.98311C8.77145 4.76025 8.76574 4.39454 8.5486 4.1774Z"
        fill="#BABBC1"
      />
    </g>
    <defs>
      <clipPath id="clip0_1412_24278">
        <rect
          fill="currentColor"
          height="13.7143"
          transform="translate(0.142822 0.343262)"
          width="13.7143"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ChevronLeft
