const MsopIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6939_345732)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_6939_345732)"
      />
      <path
        d="M8.50001 15.1869C7.78481 15.1869 7.19921 15.0189 6.74321 14.6829C6.29201 14.3421 6.06641 13.8525 6.06641 13.2237C6.06641 13.0893 6.08081 12.9309 6.10961 12.7389C6.18641 12.3069 6.29681 11.7885 6.44081 11.1789C6.84881 9.52775 7.90481 8.70215 9.60401 8.70215C10.0648 8.70215 10.4824 8.77895 10.8472 8.93735C11.212 9.08615 11.5 9.31655 11.7112 9.62375C11.9224 9.92615 12.028 10.2861 12.028 10.7037C12.028 10.8285 12.0136 10.9869 11.9848 11.1789C11.8936 11.7117 11.788 12.2349 11.6584 12.7389C11.4472 13.5597 11.0872 14.1789 10.5688 14.5869C10.0552 14.9901 9.36401 15.1869 8.50001 15.1869ZM8.62961 13.8909C8.96561 13.8909 9.24881 13.7901 9.48401 13.5933C9.72401 13.3965 9.89681 13.0941 9.99761 12.6813C10.1368 12.1149 10.2424 11.6253 10.3144 11.2029C10.3384 11.0781 10.3528 10.9485 10.3528 10.8141C10.3528 10.2669 10.0696 9.99335 9.49841 9.99335C9.16241 9.99335 8.87441 10.0941 8.63441 10.2909C8.39921 10.4877 8.23121 10.7901 8.13041 11.2029C8.02001 11.6061 7.91441 12.0957 7.80401 12.6813C7.78001 12.8013 7.76561 12.9261 7.76561 13.0605C7.76081 13.6173 8.05361 13.8909 8.62961 13.8909Z"
        fill="white"
      />
      <path
        d="M12.4458 15.1011C12.3786 15.1011 12.3306 15.0819 12.2922 15.0387C12.2634 14.9907 12.2538 14.9379 12.2634 14.8755L13.5066 9.01955C13.5162 8.95235 13.5498 8.89955 13.6074 8.85635C13.6602 8.81315 13.7178 8.79395 13.7802 8.79395H16.1754C16.8426 8.79395 17.3754 8.93315 17.7786 9.20675C18.1866 9.48515 18.393 9.88355 18.393 10.4067C18.393 10.5555 18.3738 10.7139 18.3402 10.8771C18.1914 11.5683 17.889 12.0771 17.4282 12.4083C16.977 12.7395 16.3578 12.9027 15.5706 12.9027H14.3562L13.9434 14.8755C13.929 14.9427 13.9002 14.9955 13.8426 15.0387C13.7898 15.0819 13.7322 15.1011 13.6698 15.1011H12.4458ZM15.633 11.6595C15.8874 11.6595 16.1034 11.5923 16.2906 11.4531C16.4826 11.3139 16.6074 11.1171 16.6698 10.8579C16.689 10.7571 16.6986 10.6659 16.6986 10.5891C16.6986 10.4163 16.6458 10.2819 16.545 10.1907C16.4442 10.0947 16.2666 10.0467 16.0218 10.0467H14.9418L14.601 11.6595H15.633Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_6939_345732"
        x1="0"
        x2="24"
        y1="0"
        y2="24"
      >
        <stop stopColor="#596AEC" />
        <stop offset="1" stopColor="#60E2E9" />
      </linearGradient>
      <clipPath id="clip0_6939_345732">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default MsopIcon
