const GitbookIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 32 32"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_366_4222)">
      <path
        d="M14.3997 23.6999C14.8997 23.6999 15.3497 24.0999 15.3497 24.6499C15.3497 25.1499 14.9497 25.5999 14.3997 25.5999C13.8997 25.5999 13.4497 25.1999 13.4497 24.6499C13.4497 24.0999 13.8997 23.6999 14.3997 23.6999ZM29.0997 17.8999C28.5997 17.8999 28.1497 17.4999 28.1497 16.9499C28.1497 16.4499 28.5497 15.9999 29.0997 15.9999C29.5997 15.9999 30.0497 16.3999 30.0497 16.9499C30.0497 17.4499 29.5997 17.8999 29.0997 17.8999ZM29.0997 14.0499C27.4997 14.0499 26.1997 15.3499 26.1997 16.9499C26.1997 17.2499 26.2497 17.5499 26.3497 17.8499L16.7997 22.9499C16.2497 22.1499 15.3497 21.6999 14.3997 21.6999C13.2997 21.6999 12.2997 22.3499 11.7997 23.2999L3.19974 18.7999C2.29974 18.2999 1.59974 16.8499 1.69974 15.4499C1.74974 14.7499 1.99974 14.1999 2.34974 13.9999C2.59974 13.8499 2.84974 13.8999 3.19974 14.0499L3.24974 14.0999C5.54974 15.2999 12.9997 19.1999 13.2997 19.3499C13.7997 19.5499 14.0497 19.6499 14.8997 19.2499L30.2997 11.2499C30.5497 11.1499 30.7997 10.9499 30.7997 10.5999C30.7997 10.1499 30.3497 9.94992 30.3497 9.94992C29.4497 9.54992 28.0997 8.89992 26.7997 8.29992C23.9997 6.99992 20.7997 5.49992 19.3997 4.74992C18.1997 4.09992 17.1997 4.64992 17.0497 4.74992L16.6997 4.89992C10.3497 8.09992 1.94974 12.2499 1.44974 12.5499C0.599744 13.0499 0.0497445 14.0999 -0.000255493 15.3999C-0.100255 17.4499 0.949744 19.5999 2.44974 20.3499L11.5497 25.0499C11.7497 26.4499 12.9997 27.5499 14.3997 27.5499C15.9997 27.5499 17.2497 26.2999 17.2997 24.6999L27.2997 19.2999C27.7997 19.6999 28.4497 19.8999 29.0997 19.8999C30.6997 19.8999 31.9997 18.5999 31.9997 16.9999C31.9997 15.3499 30.6997 14.0499 29.0997 14.0499Z"
        fill="white"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_366_4222">
        <rect fill="white" height="32" width="32" />
      </clipPath>
    </defs>
  </svg>
)

export default GitbookIcon
