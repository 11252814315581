import arrow from './Arrow'
import arrows from './Arrows'
import avalanche from './Avalanche'
import backarrow from './BackArrow'
import base from './Base'
import bnb from './Bnb'
import boxview from './BoxView'
import bullet from './Bullet'
import caret from './Caret'
import cbeth from './Cbeth'
import checkcircle from './CheckCircle'
import checkmark from './Checkmark'
import chevronleft from './ChevronLeft'
import closeIcon from './Close'
import coinbasewallet from './CoinbaseWallet'
import cross from './Cross'
import cctransactions from './CrossArrows'
import curve from './Curve'
import dai from './Dai'
import discord from './Discord'
import en from './En'
import es from './Es'
import esmet from './EsMet'
import eth from './Eth'
import exclamationmark from './Exclamationmark'
import exclamationmarkalt from './ExclamationmarkAlt'
import eye from './Eye'
import fr from './Fr'
import frax from './Frax'
import fraxbp from './Fraxbp'
import frxeth from './Frxeth'
import gitbook from './Gitbook'
import github from './Github'
import hamburger from './Hamburger'
import ja from './Ja'
import ko from './Ko'
import layerzero from './LayerZero'
import liquiditypools from './LiquidityPools'
import listview from './ListView'
import loading from './Loading'
import lockmet from './LockMet'
import m from './M'
import marketplace from './Marketplace'
import medium from './Medium'
import met from './Met'
import metalt from './MetAlt'
import metamask from './Metamask'
import metronome from './Metronome'
import minted from './Minted'
import msbtc from './Msbtc'
import msdoge from './Msdoge'
import mseth from './Mseth'
import msop from './Msop'
import msusd from './Msusd'
import nl from './Nl'
import optimism from './Optimism'
import pointer from './Pointer'
import reth from './Reth'
import ru from './Ru'
import sfrxeth from './Sfrxeth'
import smartfarming from './SmartFarming'
import steth from './Steth'
import synth from './Synth'
import telegram from './Telegram'
import time from './Time'
import tr from './Tr'
import tvl from './Tvl'
import twitter from './Twitter'
import usdc from './Usdc'
import vacbeth from './VaCbeth'
import vadai from './VaDai'
import vaeth from './VaEth'
import vafrax from './VaFrax'
import valink from './VaLink'
import vaop from './VaOp'
import vareth from './VaReth'
import vasteth from './VaSteth'
import vausdc from './VaUsdc'
import vawsteth from './VaWsteth'
import velodrome from './Velodrome'
import vesper from './Vesper'
import vi from './Vi'
import vote from './Vote'
import wallet from './Wallet'
import walletconnect from './WalletConnect'
import warning from './Warning'
import warningsign from './WarningSign'
import wbtc from './Wbtc'
import weth from './Weth'
import zh from './Zh'

const icons = {
  arrow,
  arrows,
  avalanche,
  backarrow,
  base,
  bnb,
  boxview,
  bullet,
  caret,
  cbeth,
  cctransactions,
  checkcircle,
  checkmark,
  chevronleft,
  close: closeIcon,
  coinbasewallet,
  cross,
  curve,
  dai,
  discord,
  en,
  es,
  esmet,
  eth,
  ethereum: eth,
  exclamationmark,
  exclamationmarkalt,
  eye,
  fr,
  frax,
  fraxbp,
  frxeth,
  gitbook,
  github,
  hamburger,
  ja,
  ko,
  layerzero,
  liquiditypools,
  listview,
  loading,
  lockmet,
  m,
  marketplace,
  medium,
  met,
  metalt,
  metamask,
  metronome,
  minted,
  msbtc,
  msdoge,
  mseth,
  msop,
  msusd,
  nl,
  op: optimism,
  optimism,
  pointer,
  reth,
  ru,
  sfrxeth,
  smartfarming,
  steth,
  synth,
  telegram,
  time,
  tr,
  tvl,
  twitter,
  usdc,
  vacbeth,
  vadai,
  vaeth,
  vafrax,
  valink,
  vaop,
  vareth,
  vasteth,
  vausdc,
  vawsteth,
  velodrome,
  vesper,
  vi,
  vote,
  wallet,
  walletconnect,
  warning,
  warningsign,
  wbtc,
  weth,
  wsteth: steth,
  zh
}

export default icons
