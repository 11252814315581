const ZhIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1309_135089)">
      <path
        d="M11.9964 23.9844C18.61 23.9844 23.9714 18.6247 23.9714 12.0132C23.9714 5.40169 18.61 0.0419922 11.9964 0.0419922C5.38286 0.0419922 0.0214844 5.40169 0.0214844 12.0132C0.0214844 18.6247 5.38286 23.9844 11.9964 23.9844Z"
        fill="#E94B35"
      />
      <path
        clipRule="evenodd"
        d="M8.0058 9.23746L5.53974 10.4837L6.01071 7.84413L4.01562 5.97477L6.77277 5.58967L8.0058 3.18811L9.23884 5.58967L11.996 5.97478L10.0009 7.84413L10.4719 10.4837L8.0058 9.23746ZM12.0957 3.69378L11.5764 3.95618L11.6756 3.4004L11.2555 3.0068L11.8361 2.92571L12.0957 2.42004L12.3553 2.92571L12.9358 3.0068L12.5158 3.4004L12.6149 3.95618L12.0957 3.69378ZM14.3129 5.47584L13.7937 5.73824L13.8928 5.18246L13.4728 4.78885L14.0533 4.70777L14.3129 4.2021L14.5725 4.70777L15.1531 4.78885L14.733 5.18246L14.8322 5.73824L14.3129 5.47584ZM14.5706 8.15296L14.0514 8.41536L14.1505 7.85958L13.7305 7.46597L14.311 7.38489L14.5706 6.87922L14.8302 7.38489L15.4108 7.46597L14.9907 7.85958L15.0899 8.41536L14.5706 8.15296ZM12.5026 10.4812L11.9834 10.7436L12.0825 10.1878L11.6625 9.79423L12.243 9.71315L12.5026 9.20748L12.7623 9.71315L13.3428 9.79423L12.9227 10.1878L13.0219 10.7436L12.5026 10.4812Z"
        fill="#F2C500"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1309_135089">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default ZhIcon
