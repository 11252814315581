const TrIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1515_118949)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#E94B35"
      />
      <path
        clipRule="evenodd"
        d="M17.9675 13.1327L17.0405 14.0837L16.8495 12.7694L15.6585 12.1816L16.8495 11.5939L17.0405 10.2795L17.9675 11.2306L19.2765 11.0061L18.6585 12.1816L19.2765 13.3572L17.9675 13.1327ZM15.3809 14.9105C14.3568 16.7532 12.3904 18 10.1328 18C6.8191 18 4.13281 15.3137 4.13281 12C4.13281 8.68629 6.8191 6 10.1328 6C12.3904 6 14.3568 7.24684 15.3809 9.08946C14.5257 8.01908 13.2094 7.33333 11.7328 7.33333C9.15548 7.33333 7.06615 9.42267 7.06615 12C7.06615 14.5773 9.15548 16.6667 11.7328 16.6667C13.2094 16.6667 14.5257 15.9809 15.3809 14.9105C15.3809 14.9105 14.5257 15.9809 15.3809 14.9105Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1515_118949">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default TrIcon
