import { useChainId } from 'metronome-ui/hooks/chains'
import dynamic from 'next/dynamic'
import { useCallback, useState } from 'react'

const FeatureNotAvailableModal = dynamic(
  () => import('../components/FeatureNotAvailableModal'),
  {
    ssr: false
  }
)

const useFeatureNotAvailableModal = function () {
  const [showFeatureNotAvailableModal, setShowFeatureNotAvailableModal] =
    useState(false)
  const [feature, setFeature] = useState(false)
  const [supportedChainIds, setSupportedChainIds] = useState()
  const chainId = useChainId()

  const openFeatureNotAvailableModal = useCallback(
    function (_feature, _supportedChainIds) {
      if (!_supportedChainIds.includes(chainId)) {
        setShowFeatureNotAvailableModal(true)
        setFeature(_feature)
        setSupportedChainIds(_supportedChainIds)
      }
    },
    [chainId]
  )

  return {
    featureNotAvailableModal: (
      <FeatureNotAvailableModal
        feature={feature}
        modalIsOpen={showFeatureNotAvailableModal}
        onRequestClose={() => setShowFeatureNotAvailableModal(false)}
        supportedChainIds={supportedChainIds}
      />
    ),
    openFeatureNotAvailableModal
  }
}

export default useFeatureNotAvailableModal
