const VaRethIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M244.542 128c0 64.375-52.167 116.542-116.542 116.542S11.458 192.375 11.458 128 63.625 11.458 128 11.458 244.542 63.625 244.542 128Zm0 0"
      style={{
        fillRule: 'nonzero',
        fill: '#ff8a70',
        fillOpacity: 1,
        strokeWidth: 22.946,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        stroke: '#ffd48a',
        strokeOpacity: 1,
        strokeMiterlimit: 4
      }}
      transform="scale(.09375)"
    />
    <path
      d="M11.996 3v6.375l5.25 2.41Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
    <path
      d="m12 3-5.25 8.785L12 9.375Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
    <path
      d="M11.996 15.918v4.332l5.254-7.46Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
    <path
      d="M12 20.25v-4.332l-5.25-3.129ZM11.996 14.914l5.25-3.129-5.25-2.406Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
    <path
      d="m6.75 11.785 5.25 3.13V9.378Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1
      }}
    />
    <g id="svg_12">
      <circle
        cx="19.74687"
        cy="19"
        fill="white"
        id="svg_1"
        r="3.66146"
        stroke="#596AEC"
        strokeWidth="0.67708"
      />
      <path
        d="m19.83297,17.5225c-0.0429,0 -0.0809,0.0276 -0.0941,0.0685l-0.6518,2.0132c-0.0074,0.0228 -0.0397,0.0228 -0.0471,0l-0.6523,-2.0133c-0.0132,-0.0408 -0.0512,-0.0684 -0.0941,-0.0684l-1.1097,0c-0.0683,0 -0.1161,0.0675 -0.0934,0.1319l0.1359,0.385c0.0139,0.0396 0.0514,0.066 0.0933,0.066l0.5252,0c0.0105,0 0.0198,0.0066 0.0233,0.0165l0.8552,2.4214c0.014,0.0396 0.0514,0.066 0.0934,0.066l0.4935,0c0.0419,0 0.0794,-0.0264 0.0933,-0.066l1.0202,-2.8889c0.0228,-0.0644 -0.025,-0.1319 -0.0933,-0.1319l-0.4975,0z"
        fill="#596AEC"
        id="svg_10"
      />
      <path
        d="m20.87917,20.6455c0.3566,0 0.5697,-0.1672 0.6674,-0.3581l0.0177,0l0,0.3122l0.5149,0l0,-1.5211c0,-0.6007 -0.4897,-0.7812 -0.9233,-0.7812c-0.4779,0 -0.8448,0.2131 -0.9632,0.6274l0.5001,0.071c0.0533,-0.1554 0.2042,-0.2886 0.4661,-0.2886c0.2486,0 0.3847,0.1273 0.3847,0.3507l0,0.0089c0,0.1539 -0.1613,0.1613 -0.5623,0.2042c-0.4409,0.0473 -0.8626,0.179 -0.8626,0.691c0,0.4468 0.327,0.6836 0.7605,0.6836zm0.1391,-0.3936c-0.2234,0 -0.3832,-0.1021 -0.3832,-0.2989c0,-0.2057 0.179,-0.2915 0.4187,-0.3255c0.1406,-0.0192 0.4217,-0.0548 0.4913,-0.111l0,0.2678c0,0.253 -0.2042,0.4676 -0.5268,0.4676z"
        fill="#596AEC"
        id="svg_11"
      />
    </g>
  </svg>
)

export default VaRethIcon
