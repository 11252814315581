import { push } from '@socialgouv/matomo-next'
import { useChainId } from 'metronome-ui/hooks/chains'
import { shortHash } from 'metronome-ui/utils/hash'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useMemo, useState } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'
import { connectors } from '../utils/connectors'

import { AppContext } from './AppContext/AppContext'
import Button from './Button'
import Dropdown from './Dropdown'
import ErrorHandler from './ErrorHandler'
import IconContainer from './icons/IconContainer'
import WalletConnector from './WalletConnector'
import WalletContext from './WalletContext'
import WalletSettingsModal from './WalletSettingsModal'

export const WalletIcon = ({ connectorName }) =>
  connectorName ? (
    <IconContainer
      height="16"
      name={connectorName.replace(/\s/g, '')}
      width="16"
    />
  ) : null

const WalletSettingsButton = ({ onClick, children }) => (
  <button
    className="hover:bg-blue-4 mb-2 flex w-full items-center rounded-lg px-4 py-2 focus:outline-none"
    data-sentry="wallet-settings-btn"
    onClick={onClick}
  >
    {children}
  </button>
)

const Wallet = function () {
  const { t } = useTranslation()
  const chainId = useChainId()
  const [showWalletConnector, setShowWalletConnector] = useState(false)
  const [showWalletSettings, setShowWalletSettings] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const { setWallet } = useContext(WalletContext)
  const [lastConnector, setLastConnector, removeLastConnector] =
    useLocalStorage('lastConnector')

  const {
    error,
    setError,
    state: {
      wallet: { connected, account, name: currentConnectorName }
    }
  } = useContext(AppContext)

  useEffect(
    function () {
      if (error) {
        setErrorModalOpen(true)
      }
    },
    [error]
  )

  const wallets = useMemo(
    () =>
      connectors
        .filter(({ supportedChains }) => supportedChains.includes(chainId))
        .map(({ name, connector, hooks, connectorName, supportedChains }) => ({
          connector,
          connectorName,
          handleConnection: () =>
            new Promise((resolve, reject) =>
              connector
                .activate()
                .then(function () {
                  setLastConnector(connectorName)
                  setShowWalletConnector(false)
                  window.gtag?.('event', `Connected to ${name} in ${chainId}`)
                  push([
                    'trackEvent',
                    'wallet-connection',
                    `Connected to ${name} in ${chainId}`
                  ])
                  resolve()
                })
                .catch(function (err) {
                  setError(err)
                  reject(err)
                })
            ),
          handleDisconnection(allowReconnection) {
            if (connector?.deactivate) {
              connector.deactivate()
            } else {
              connector.resetState()
            }
            if (!allowReconnection) {
              removeLastConnector()
            }
            setWallet(undefined)
          },
          hooks,
          name,
          supportedChains
        })),
    [chainId, removeLastConnector, setError, setLastConnector, setWallet]
  )

  const metamaskConnector = useMemo(
    () =>
      connectors.find(({ connectorName }) => connectorName === 'injected')
        .connector,
    []
  )

  // Whenever the chainId in Metronome changes, if the wallet does not support the new chain
  // disconnect it.
  useEffect(
    function () {
      const wallet = wallets.find(
        ({ connectorName }) => connectorName === lastConnector
      )
      if (!wallet) {
        return
      }
      if (!wallet.supportedChains.includes(chainId)) {
        wallet.handleDisconnection()
      }
    },
    [chainId, wallets, lastConnector]
  )
  // this effect should try to reconnect the wallet after switching chains
  // if the wallet supports such chain (otherwise, getLastConnector() will return null)
  // @TODO: this useEffect is causing "TypeError: cyclic object value"
  useEffect(
    function () {
      const getLastConnector =
        connectors.find(
          ({ connectorName }) => connectorName === lastConnector
        ) || {}
      const { connector } = getLastConnector
      if (!connector) {
        return
      }

      const shouldTryToReconnect = Promise.resolve(
        getLastConnector.supportedChains.includes(chainId)
      )

      shouldTryToReconnect
        .then(function (reconnect) {
          if (reconnect) {
            setTimeout(
              () =>
                connector.activate().then(function () {
                  const w = wallets.find(
                    ({ connectorName }) => connectorName === lastConnector
                  )
                  setWallet(w)
                }),
              1
            )
          }
          return reconnect
        })
        // eslint-disable-next-line no-console
        .catch(console.warn)
    },
    [wallets, setWallet, chainId, lastConnector]
  )

  useEffect(
    function () {
      const { ethereum } = window
      if (ethereum && ethereum.on && !connected) {
        const handleChainChanged = function () {
          metamaskConnector.activate()
        }

        ethereum.on('chainChanged', handleChainChanged)

        return function () {
          if (ethereum.removeListener) {
            ethereum.removeListener('chainChanged', handleChainChanged)
          }
        }
      }

      return undefined
    },
    [metamaskConnector, connected]
  )

  const deactivateConnector = () =>
    wallets
      .find(({ connectorName }) => connectorName === lastConnector)
      .handleDisconnection()

  return (
    <>
      <WalletConnector
        modalIsOpen={showWalletConnector}
        onRequestClose={() => setShowWalletConnector(false)}
        wallets={wallets}
      />
      <WalletSettingsModal
        account={account}
        deactivateConnector={deactivateConnector}
        modalIsOpen={showWalletSettings}
        onRequestClose={() => setShowWalletSettings(false)}
        openWalletConnector={() => setShowWalletConnector(true)}
      />
      <ErrorHandler
        error={error}
        modalIsOpen={errorModalOpen}
        onRequestClose={() => setErrorModalOpen(false)}
      />
      {!connected ? (
        <Button
          className="grow-0 text-sm"
          data-sentry="connect-wallet"
          onClick={() => setShowWalletConnector(true)}
          size="lg"
        >
          {t('connect-your-wallet')}
        </Button>
      ) : (
        <>
          <button
            className="bg-selector w-42 flex h-10 items-center justify-between rounded-lg p-3 font-medium text-white focus:outline-none md:hidden"
            data-sentry="wallet-settings"
            onClick={() => setShowWalletSettings(true)}
          >
            <div className="flex w-full items-center space-x-2 text-sm leading-4">
              <WalletIcon connectorName={currentConnectorName} />
              <span className="grow md:hidden">{shortHash(account)}</span>
            </div>
          </button>
          <Dropdown
            className="relative z-30 inline-block"
            data-sentry="wallet-settings"
            selector={
              <button className="bg-selector w-30 md:w-42 m-auto hidden items-center justify-between rounded-lg p-2 text-sm font-medium text-white focus:outline-none md:flex md:h-10 md:p-3">
                <div className="flex items-center space-x-2">
                  <WalletIcon connectorName={currentConnectorName} />
                  <span>{shortHash(account)}</span>
                </div>
                <IconContainer
                  className="text-navy-1000 fill-current"
                  name="caret"
                />
              </button>
            }
          >
            <div className="absolute right-0 top-12 z-10">
              <ul className="bg-blue-primary w-64 rounded-lg p-2 text-sm leading-6 text-white">
                <li>
                  <WalletSettingsButton
                    onClick={() => setShowWalletConnector(true)}
                  >
                    {t('change-wallet')}
                  </WalletSettingsButton>
                </li>
                <li>
                  <WalletSettingsButton onClick={() => deactivateConnector()}>
                    {t('disconnect')}
                  </WalletSettingsButton>
                </li>
                <li>
                  <WalletSettingsButton
                    onClick={() => navigator.clipboard.writeText(account)}
                  >
                    {t('copy-address')}
                  </WalletSettingsButton>
                </li>
              </ul>
            </div>
          </Dropdown>
        </>
      )}
    </>
  )
}

export default Wallet
