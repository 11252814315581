import dynamic from 'next/dynamic'
import { useState } from 'react'

const ForbiddenOperationModal = dynamic(
  () => import('../components/ForbiddenOperationModal'),
  {
    ssr: false
  }
)

const useForbiddenOperationModal = function () {
  const [showForbiddenOperationModal, setShowForbiddenOperationModal] =
    useState(false)
  const [forbiddenOperation, setForbiddenOperation] = useState(null)
  const [connectedWallet, setConnectedWallet] = useState(null)
  const [tokenAddress, setTokenAddress] = useState(null)

  const closeForbiddenOperationModal = function () {
    setShowForbiddenOperationModal(false)
  }
  const openForbiddenOperationModal = function (
    operation,
    walletConnected = false,
    _tokenAddress = ''
  ) {
    setShowForbiddenOperationModal(true)
    setForbiddenOperation(operation)
    setConnectedWallet(walletConnected)
    setTokenAddress(_tokenAddress)
  }

  return {
    closeForbiddenOperationModal,
    forbiddenOperationModal: (
      <ForbiddenOperationModal
        connectedWallet={connectedWallet}
        modalIsOpen={showForbiddenOperationModal}
        onRequestClose={closeForbiddenOperationModal}
        operation={forbiddenOperation}
        tokenAddress={tokenAddress}
      />
    ),
    openForbiddenOperationModal
  }
}

export default useForbiddenOperationModal
