const PointerIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.83,15.54a1,1,0,0,1-1.66,0L7.64,9.42A1,1,0,0,1,8.47,8h7.06a1,1,0,0,1,.83,1.44Z" />
  </svg>
)

export default PointerIcon
