const UsdcIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M12 24C14.3734 24 16.6935 23.2962 18.6669 21.9776C20.6403 20.6591 22.1783 18.7849 23.0866 16.5922C23.9948 14.3995 24.2325 11.9867 23.7695 9.65892C23.3064 7.33115 22.1636 5.19295 20.4853 3.51472C18.8071 1.83649 16.6689 0.693605 14.3411 0.230582C12.0133 -0.232441 9.60055 0.00519936 7.40784 0.913451C5.21512 1.8217 3.34098 3.35977 2.0224 5.33316C0.703824 7.30655 3.63305e-05 9.62663 3.63305e-05 12C-0.00384869 13.577 0.303889 15.1391 0.905569 16.5968C1.50725 18.0545 2.39101 19.3789 3.50609 20.4939C4.62117 21.609 5.94558 22.4928 7.40324 23.0945C8.8609 23.6961 10.4231 24.0039 12 24Z"
        fill="#2775CA"
      />
      <path
        d="M15.2995 13.9001C15.2995 12.1546 14.2501 11.5492 12.1501 11.2993C10.649 11.0997 10.3501 10.6993 10.3501 10.0001C10.3501 9.30079 10.8497 8.84916 11.849 8.84916C12.7501 8.84916 13.2497 9.14916 13.4995 9.8997C13.5264 9.97201 13.5748 10.0343 13.6382 10.0782C13.7016 10.1221 13.777 10.1455 13.8541 10.1452H14.6537C14.7 10.1464 14.7461 10.1381 14.7891 10.1209C14.8322 10.1038 14.8712 10.078 14.904 10.0453C14.9368 10.0125 14.9625 9.97342 14.9797 9.93039C14.9969 9.88736 15.0051 9.84129 15.0039 9.79497V9.74588C14.9073 9.20396 14.6344 8.70912 14.2276 8.33825C13.8208 7.96737 13.303 7.74124 12.7544 7.69498V6.49498C12.7544 6.29534 12.6039 6.14588 12.3541 6.0957H11.6035C11.4039 6.0957 11.2544 6.24516 11.2043 6.49498V7.64916C9.6999 7.85425 8.74972 8.84916 8.74972 10.0993C8.74972 11.7499 9.75009 12.4001 11.8501 12.6499C13.2497 12.8997 13.7046 13.1953 13.7046 13.9993C13.7046 14.8033 13.0054 15.3499 12.0552 15.3499C10.7548 15.3499 10.3097 14.8044 10.1548 14.0495C10.1405 13.9652 10.0971 13.8885 10.0321 13.8329C9.96714 13.7773 9.8847 13.7463 9.79918 13.7452H8.945C8.89872 13.7441 8.85272 13.7525 8.80976 13.7697C8.7668 13.7869 8.72778 13.8127 8.69506 13.8454C8.66233 13.8781 8.63657 13.9171 8.61934 13.9601C8.60211 14.0031 8.59377 14.0491 8.59481 14.0953V14.1455C8.79554 15.3957 9.59518 16.2957 11.2457 16.5455V17.7455C11.2457 17.9452 11.3952 18.0957 11.645 18.1459H12.3955C12.5952 18.1459 12.7457 17.9953 12.7948 17.7455V16.5455C14.2948 16.2957 15.2952 15.2452 15.2952 13.8957L15.2995 13.9001Z"
        fill="white"
      />
      <path
        d="M9.44946 19.1499C8.01079 18.6222 6.76882 17.6655 5.89144 16.4091C5.01406 15.1528 4.54356 13.6573 4.54356 12.1249C4.54356 10.5925 5.01406 9.0971 5.89144 7.84075C6.76882 6.5844 8.01079 5.62772 9.44946 5.10003C9.54545 5.05851 9.62608 4.98804 9.68006 4.89846C9.73404 4.80887 9.75869 4.70467 9.75055 4.60039V3.90003C9.75728 3.80816 9.73049 3.71697 9.67512 3.64335C9.61976 3.56974 9.53958 3.51869 9.44946 3.49967C9.37908 3.49233 9.30837 3.5101 9.24982 3.54985C7.42954 4.12841 5.84059 5.27121 4.71297 6.81284C3.58534 8.35446 2.97754 10.2149 2.97754 12.1249C2.97754 14.035 3.58534 15.8954 4.71297 17.437C5.84059 18.9787 7.42954 20.1215 9.24982 20.7C9.29139 20.7242 9.3381 20.7381 9.3861 20.7408C9.43411 20.7434 9.48205 20.7346 9.52601 20.7151C9.56997 20.6957 9.60868 20.666 9.63899 20.6287C9.6693 20.5914 9.69033 20.5474 9.70037 20.5004C9.75055 20.4502 9.75055 20.4 9.75055 20.2997V19.6004C9.75055 19.4499 9.6 19.2502 9.44946 19.1499ZM14.7502 3.54985C14.7085 3.5257 14.6618 3.51177 14.6137 3.50921C14.5656 3.50665 14.5176 3.51553 14.4736 3.53512C14.4297 3.55472 14.391 3.58446 14.3607 3.62191C14.3305 3.65937 14.3095 3.70346 14.2996 3.75058C14.2505 3.79967 14.2505 3.84985 14.2505 3.95021V4.64948C14.259 4.75032 14.2904 4.84789 14.3424 4.93469C14.3944 5.02149 14.4656 5.09522 14.5505 5.15021C15.9892 5.6779 17.2312 6.63458 18.1086 7.89093C18.986 9.14728 19.4564 10.6427 19.4564 12.1751C19.4564 13.7075 18.986 15.203 18.1086 16.4593C17.2312 17.7157 15.9892 18.6723 14.5505 19.2C14.4547 19.2416 14.3742 19.3121 14.3204 19.4017C14.2666 19.4913 14.2422 19.5955 14.2505 19.6997V20.4C14.2438 20.4917 14.2704 20.5828 14.3256 20.6564C14.3807 20.73 14.4606 20.7811 14.5505 20.8004C14.6209 20.8077 14.6916 20.79 14.7502 20.7502C16.5698 20.1636 18.1564 19.0151 19.2819 17.4697C20.4074 15.9243 21.0138 14.0618 21.0138 12.15C21.0138 10.2382 20.4074 8.37572 19.2819 6.83035C18.1564 5.28498 16.5698 4.13642 14.7502 3.54985Z"
        fill="white"
      />
    </g>
    <defs></defs>
  </svg>
)

export default UsdcIcon
