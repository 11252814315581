const BoxViewIcon = ({ fill = '#656774', ...props }) => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.888 1.69657C15.793 1.77483 16.4977 2.4544 16.6209 3.35433C16.6916 3.87048 16.75 4.43112 16.75 4.875C16.75 5.31888 16.6916 5.87952 16.6209 6.39567C16.4977 7.2956 15.793 7.97517 14.888 8.05344C13.7702 8.15011 11.9596 8.25 9.25 8.25C6.54036 8.25 4.72976 8.15011 3.61197 8.05343C2.70702 7.97517 2.00232 7.2956 1.87907 6.39567C1.80838 5.87952 1.75 5.31888 1.75 4.875C1.75 4.43112 1.80838 3.87048 1.87907 3.35433C2.00232 2.4544 2.70702 1.77483 3.61197 1.69656C4.72976 1.59989 6.54036 1.5 9.25 1.5C11.9596 1.5 13.7702 1.59989 14.888 1.69657Z"
      fill={fill}
    />
    <path
      d="M14.888 9.94657C15.793 10.0248 16.4977 10.7044 16.6209 11.6043C16.6916 12.1205 16.75 12.6811 16.75 13.125C16.75 13.5689 16.6916 14.1295 16.6209 14.6457C16.4977 15.5456 15.793 16.2252 14.888 16.3034C13.7702 16.4001 11.9596 16.5 9.25 16.5C6.54036 16.5 4.72976 16.4001 3.61197 16.3034C2.70702 16.2252 2.00232 15.5456 1.87907 14.6457C1.80838 14.1295 1.75 13.5689 1.75 13.125C1.75 12.6811 1.80838 12.1205 1.87907 11.6043C2.00232 10.7044 2.70702 10.0248 3.61197 9.94656C4.72976 9.84989 6.54036 9.75 9.25 9.75C11.9596 9.75 13.7702 9.84989 14.888 9.94657Z"
      fill={fill}
    />
  </svg>
)

export default BoxViewIcon
