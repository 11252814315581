import { useWeb3React } from '@web3-react/core'
import Big from 'big.js'
import createMetronomeSynthUser from 'metronome-synth-user-lib'
import { useChainId } from 'metronome-ui/hooks/chains'
import { fromUnit } from 'metronome-utils'
import dynamic from 'next/dynamic'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import useForbiddenOperationModal from '../hooks/useForbiddenOperationModal'
import useOperationDrawer from '../hooks/useOperationDrawer'
import useRefreshedUsdRate from '../hooks/useRefreshedUsdRate'
import useTransactionDrawer from '../hooks/useTransactionDrawer'
import { useWalletChainId } from '../hooks/useWalletChainId'

import { AppContext } from './AppContext/AppContext'

const TransactionToasts = dynamic(
  () => import('../components/TransactionToasts'),
  { ssr: false }
)

export const MetronomeContext = createContext({})

const MetronomeContextProvider = function ({ children, getLibrary }) {
  const {
    actions: { setUserData },
    legacyData: {
      selectedPoolData,
      shouldUpdateWallet,
      setShouldUpdateWallet,
      showLoader,
      setShowLoader
    },
    setError,
    state: {
      assets: { collaterals }
    }
  } = useContext(AppContext)

  const {
    closeOperationDrawer,
    disableOnOperation,
    inputValue,
    openOperationDrawer,
    opened: operationDrawerIsOpened,
    operationDrawer,
    selectedToken,
    setDisableOnOperation,
    setInputValue,
    setSelectedToken
  } = useOperationDrawer(selectedPoolData)

  const {
    opened: transactionDrawerIsOpened,
    openTransactionDrawer,
    transactionDrawer
  } = useTransactionDrawer()

  const [metronome, setMetronome] = useState(false)
  const [debtBalances, setDebtBalances] = useState({})
  const [depositBalances, setDepositBalances] = useState({})
  const [syntheticBalances, setSyntheticBalances] = useState({})
  const [debtPosition, setDebtPosition] = useState({})
  const [collateralBalances, setCollateralBalances] = useState({})
  const [synthBalancesLoaded, setSynthBalancesLoaded] = useState(false)
  const [walletBalancesLoaded, setWalletBalancesLoaded] = useState(false)
  const [operationExecuted, setOperationExecuted] = useState(false)
  const [claimables, setClaimables] = useState([])
  const { account, isActive: active, provider } = useWeb3React()
  const walletChainId = useWalletChainId()
  const chainId = useChainId()
  const [selectedTokenUsdRate] = useRefreshedUsdRate({
    metronome,
    token: selectedToken
  })

  // We take all "tokenIn"s and pass them to createMetronomeSynthUser as
  // "additionalCollaterals" so that they are taken into account when
  // requesting their balances
  const additionalCollaterals = useMemo(
    () => selectedPoolData.swapRoutes.leverageRoutes.map(r => r.tokenIn),
    [selectedPoolData?.swapRoutes?.leverageRoutes]
  )

  useEffect(
    function () {
      if (active && walletChainId === chainId) {
        const library = getLibrary(provider.provider)
        createMetronomeSynthUser(library, {
          additionalCollaterals,
          from: account,
          initialData: selectedPoolData,
          poolAddress: selectedPoolData.address,
          ...(process.env.NEXT_PUBLIC_PROOFS_FILE_PATH
            ? { proofsFilePath: process.env.NEXT_PUBLIC_PROOFS_FILE_PATH }
            : {})
        })
          .then(function (_metronome) {
            setMetronome(_metronome)
            setSynthBalancesLoaded(true)
            setShowLoader(false)
          })
          .catch(function (err) {
            setError(err)
            // eslint-disable-next-line no-console
            console.error(err)
            setSynthBalancesLoaded(true)
          })
      } else {
        setMetronome(false)
        setSynthBalancesLoaded(false)
      }
    },
    [
      account,
      active,
      getLibrary,
      selectedPoolData,
      provider?.provider,
      setError,
      setShowLoader,
      additionalCollaterals,
      walletChainId,
      chainId
    ]
  )

  const updateBalances = useCallback(
    function () {
      if (metronome) {
        Promise.all([
          metronome.getDebtPosition(),
          metronome.getDebtTokenBalances(),
          metronome.getDepositTokenBalances(),
          metronome.getSyntheticTokenBalances(),
          metronome.getCollateralBalances(),
          metronome.claimable()
        ])
          .then(function ([
            _debtPosition,
            debtTokenBalances,
            depositTokenBalances,
            syntheticTokenBalances,
            _collateralBalances,
            _claimables
          ]) {
            const addUsdBalances = (balances, tokens) =>
              Object.fromEntries(
                tokens.map(({ address, usdPrice }) => [
                  address,
                  {
                    ...balances[address],
                    usdBalance: balances[address]?.balance
                      ? Big(
                          fromUnit(
                            balances[address].balance,
                            balances[address].decimals
                          )
                        )
                          .times(usdPrice)
                          .toFixed()
                      : '0'
                  }
                ])
              )

            setDebtPosition(_debtPosition)
            setDebtBalances(
              addUsdBalances(debtTokenBalances, selectedPoolData.synthetics)
            )
            setDepositBalances(
              addUsdBalances(depositTokenBalances, selectedPoolData.collaterals)
            )
            setSyntheticBalances(
              addUsdBalances(
                syntheticTokenBalances,
                selectedPoolData.synthetics
              )
            )
            setCollateralBalances(
              addUsdBalances(_collateralBalances, [
                ...collaterals,
                ...additionalCollaterals
              ])
            )
            setClaimables(_claimables)

            setUserData(
              _debtPosition,
              _claimables,
              collateralBalances,
              depositTokenBalances,
              syntheticTokenBalances,
              debtTokenBalances
            )
          })
          .then(() => setWalletBalancesLoaded(true))
          // eslint-disable-next-line no-console
          .catch(console.error)
      }
    },
    [
      additionalCollaterals,
      collateralBalances,
      collaterals,
      metronome,
      selectedPoolData.collaterals,
      selectedPoolData.synthetics,
      setUserData
    ]
  )

  useEffect(
    function () {
      if (!active) {
        setWalletBalancesLoaded(false)
      }
    },
    [active]
  )

  useEffect(
    function () {
      if (active && shouldUpdateWallet) {
        updateBalances()
        setShouldUpdateWallet(false)
      }
    },
    [active, setShouldUpdateWallet, shouldUpdateWallet, updateBalances]
  )

  const {
    closeForbiddenOperationModal,
    openForbiddenOperationModal,
    forbiddenOperationModal
  } = useForbiddenOperationModal()

  return (
    <MetronomeContext.Provider
      value={{
        claimables,
        closeForbiddenOperationModal,
        closeOperationDrawer,
        collateralBalances,
        debtBalances,
        debtPosition,
        depositBalances,
        disableOnOperation,
        getLibrary,
        inputValue,
        metronome,
        openForbiddenOperationModal,
        openOperationDrawer,
        openTransactionDrawer,
        operationDrawerIsOpened,
        operationExecuted,
        selectedPoolData,
        selectedToken,
        selectedTokenUsdRate,
        setDisableOnOperation,
        setInputValue,
        setOperationExecuted,
        setSelectedToken,
        setShowLoader,
        showLoader,
        synthBalancesLoaded,
        syntheticBalances,
        updateBalances,
        walletBalancesLoaded
      }}
    >
      {children}
      {operationDrawer}
      {transactionDrawer}
      <TransactionToasts
        isOperationOpened={operationDrawerIsOpened}
        isTransactionOpened={transactionDrawerIsOpened}
        openOperationDrawer={openOperationDrawer}
        openTransactionDrawer={openTransactionDrawer}
      />
      {forbiddenOperationModal}
    </MetronomeContext.Provider>
  )
}

export default MetronomeContextProvider
