const LayerZeroIcon = ({ ...props }) => (
  <svg
    height="196"
    viewBox="0 0 196 196"
    width="728"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M56.3409 1C49.0364 1.03643 41.8104 2.51911 35.0759 5.36363C28.3414 8.20814 22.2302 12.359 17.0912 17.5785C11.9523 22.7979 7.88627 28.9841 5.12543 35.7836C2.36459 42.5832 0.96305 49.8629 1.00074 57.2072V75.7728H45.965V48.875C45.9645 47.6118 46.2115 46.3609 46.692 45.1937C47.1725 44.0265 47.877 42.966 48.7654 42.0727C49.6537 41.1794 50.7084 40.4711 51.8691 39.9877C53.0299 39.5044 54.274 39.2558 55.5304 39.256H57.1514C58.4079 39.2558 59.6521 39.5044 60.813 39.9877C61.9738 40.471 63.0286 41.1794 63.9171 42.0727C64.8056 42.966 65.5103 44.0265 65.991 45.1937C66.4717 46.3609 66.719 47.6117 66.7188 48.875V120.982C72.6236 120.982 78.4707 119.812 83.9261 117.541C89.3816 115.269 94.3385 111.939 98.5139 107.741C102.689 103.543 106.001 98.559 108.261 93.0739C110.52 87.5888 111.683 81.7097 111.683 75.7728V57.2072C111.715 42.3268 105.887 28.0386 95.473 17.4654C90.363 12.2479 84.2714 8.105 77.553 5.27816C70.8347 2.45132 63.6239 0.996939 56.3409 1V1Z"
      fill="white"
    />
    <path
      d="M57.1536 157.489H55.5326C54.2764 157.49 53.0324 157.241 51.8718 156.758C50.7111 156.274 49.6565 155.566 48.7682 154.673C47.8799 153.78 47.1754 152.72 46.6948 151.553C46.2142 150.386 45.9669 149.135 45.9671 147.872V75.7656C40.0622 75.7651 34.215 76.934 28.7594 79.2057C23.3039 81.4775 18.347 84.8075 14.1715 89.0056C9.99612 93.2037 6.684 98.1877 4.42452 103.673C2.16503 109.158 1.00239 115.037 1.0029 120.974V139.281C0.927992 146.636 2.30411 153.933 5.05167 160.75C7.79923 167.567 11.8637 173.768 17.01 178.996C22.1564 184.224 28.2824 188.373 35.0339 191.206C41.7855 194.038 49.0285 195.496 56.344 195.496C63.6596 195.496 70.9026 194.038 77.6541 191.206C84.4056 188.373 90.5317 184.224 95.678 178.996C100.824 173.768 104.889 167.567 107.636 160.75C110.384 153.933 111.76 146.636 111.685 139.281V120.974H66.7209V147.872C66.7209 149.135 66.4734 150.386 65.9926 151.553C65.5118 152.72 64.807 153.78 63.9186 154.673C63.0301 155.566 61.9754 156.274 60.8146 156.758C59.6539 157.241 58.4099 157.49 57.1536 157.489V157.489Z"
      fill="white"
    />
  </svg>
)

export default LayerZeroIcon
