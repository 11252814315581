const Weth = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.72841 23.8035C12.7609 23.8035 12.7609 23.7136 13.7837 23.41C15.1204 23.0132 10.0097 19.4756 6.72841 19.4756C3.44712 19.4756 0.787109 20.4444 0.787109 21.6395C0.787109 22.8346 3.44725 23.8035 6.72841 23.8035Z"
      fill="black"
    />
    <path
      d="M20.4259 5.40155L20.7034 5.70056L20.4608 6.02852C12.5934 16.6659 8.06125 21.4822 6.13831 20.8362C4.96704 20.4428 3.79243 19.2594 2.61779 17.5412C2.20491 16.9372 1.8172 16.3013 1.46458 15.6684C1.22976 15.2469 1.03101 14.8626 0.927818 14.6461C0.796181 14.3699 0.740604 14.1998 0.696511 13.9763C0.693821 13.9626 0.693821 13.9626 0.691265 13.9495C0.672861 13.8546 0.666494 13.8246 0.652738 13.7728C0.416003 12.8806 0.294922 11.9562 0.294922 11.0162C0.294922 5.09502 5.09502 0.294922 11.0162 0.294922C12.8713 0.294922 14.6589 0.767036 16.2432 1.65328L16.2841 1.67887C17.5812 2.58193 18.9609 3.8228 20.4259 5.40155ZM15.742 2.49998C14.3087 1.70286 12.6938 1.27853 11.0162 1.27853C5.63825 1.27853 1.27853 5.63825 1.27853 11.0162C1.27853 11.8708 1.3885 12.7104 1.60345 13.5205C1.6247 13.6007 1.63432 13.6459 1.65686 13.7621C1.65923 13.7743 1.65923 13.7743 1.66151 13.7859C1.68996 13.9301 1.71999 14.022 1.81572 14.2228C1.90986 14.4203 2.09904 14.7862 2.32381 15.1896C2.66273 15.7979 3.03517 16.4088 3.42979 16.9861C4.49106 18.5385 5.54357 19.5988 6.45153 19.9038C7.66251 20.3106 12.2214 15.4847 19.426 5.77309C18.118 4.39246 16.8892 3.30141 15.742 2.49998Z"
      fill="black"
    />
    <path
      d="M15.7418 2.49977C14.3085 1.70266 12.6936 1.27832 11.016 1.27832C5.63804 1.27832 1.27832 5.63804 1.27832 11.016C1.27832 11.8706 1.3883 12.7102 1.60324 13.5203C1.6245 13.6004 1.63411 13.6457 1.65666 13.7619C1.65902 13.7741 1.65902 13.7741 1.6613 13.7857C1.68976 13.9299 1.71978 14.0218 1.81551 14.2226C1.90965 14.4201 2.09883 14.786 2.32361 15.1894C2.66252 15.7977 3.03496 16.4086 3.42958 16.9859C4.49085 18.5383 5.54336 19.5986 6.45132 19.9036C7.66231 20.3104 12.2212 15.4845 19.4258 5.77288C18.1178 4.39225 16.889 3.3012 15.7418 2.49977Z"
      fill="#EC1C79"
    />
    <path
      d="M12.983 23.7043C7.06181 23.7043 2.26172 18.9042 2.26172 12.983C2.26172 7.06181 7.06181 2.26172 12.983 2.26172C18.9042 2.26172 23.7043 7.06181 23.7043 12.983C23.7043 18.9042 18.9042 23.7043 12.983 23.7043ZM12.983 22.7207C18.361 22.7207 22.7207 18.361 22.7207 12.983C22.7207 7.60504 18.361 3.24533 12.983 3.24533C7.60504 3.24533 3.24533 7.60504 3.24533 12.983C3.24533 18.361 7.60504 22.7207 12.983 22.7207Z"
      fill="black"
    />
    <path
      d="M12.9828 22.7205C18.3608 22.7205 22.7205 18.3608 22.7205 12.9828C22.7205 7.60484 18.3608 3.24512 12.9828 3.24512C7.60484 3.24512 3.24512 7.60484 3.24512 12.9828C3.24512 18.3608 7.60484 22.7205 12.9828 22.7205Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M5.69907 14.9783L4.41016 11.095H5.53934L6.21134 13.3258L6.95494 11.084H7.87481L8.61842 13.3258L9.29042 11.095H10.3976L9.10865 14.9783H8.17776L7.40111 12.7585L6.62996 14.9783H5.69907ZM10.8217 14.9507V11.095H13.9228V12.0039H11.8793V12.5877H13.73V13.4305H11.8793V14.0419H13.9504V14.9507H10.8217ZM15.4376 14.9507V12.0314H14.2808V11.095H17.6629V12.0314H16.5062V14.9507H15.4376ZM18.1311 14.9507V11.095H19.1997V12.5381H20.5712V11.095H21.6398V14.9507H20.5712V13.4856H19.1997V14.9507H18.1311Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      d="M0.933513 9.93521C0.661419 9.79916 0.551132 9.4683 0.687178 9.19621C0.823225 8.92411 1.15409 8.81383 1.42618 8.94987L2.99995 9.73676C3.27204 9.87281 3.38233 10.2037 3.24628 10.4758C3.11024 10.7479 2.77938 10.8581 2.50728 10.7221L0.933513 9.93521Z"
      fill="black"
    />
    <path
      d="M0.752755 12.5442C0.476543 12.4168 0.355967 12.0895 0.483443 11.8133C0.610918 11.5371 0.938172 11.4165 1.21438 11.544L2.78815 12.2703C3.06437 12.3978 3.18494 12.725 3.05747 13.0012C2.92999 13.2775 2.60274 13.398 2.32653 13.2706L0.752755 12.5442Z"
      fill="black"
    />
  </svg>
)

export default Weth
