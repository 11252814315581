const BoxViewIcon = ({ fill = '#656774', ...props }) => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6291 11.6043C10.7523 10.7044 11.4544 10.0023 12.3543 9.87907C12.8705 9.80838 13.4311 9.75 13.875 9.75C14.3189 9.75 14.8795 9.80838 15.3957 9.87907C16.2956 10.0023 16.9977 10.7044 17.1209 11.6043C17.1916 12.1205 17.25 12.6811 17.25 13.125C17.25 13.5689 17.1916 14.1295 17.1209 14.6457C16.9977 15.5456 16.2956 16.2477 15.3957 16.3709C14.8795 16.4416 14.3189 16.5 13.875 16.5C13.4311 16.5 12.8705 16.4416 12.3543 16.3709C11.4544 16.2477 10.7523 15.5456 10.6291 14.6457C10.5584 14.1295 10.5 13.5689 10.5 13.125C10.5 12.6811 10.5584 12.1205 10.6291 11.6043Z"
      fill={fill}
    />
    <path
      d="M2.37907 11.6043C2.50232 10.7044 3.2044 10.0023 4.10433 9.87907C4.62048 9.80838 5.18112 9.75 5.625 9.75C6.06888 9.75 6.62952 9.80838 7.14567 9.87907C8.0456 10.0023 8.74768 10.7044 8.87093 11.6043C8.94162 12.1205 9 12.6811 9 13.125C9 13.5689 8.94162 14.1295 8.87093 14.6457C8.74768 15.5456 8.0456 16.2477 7.14567 16.3709C6.62952 16.4416 6.06888 16.5 5.625 16.5C5.18112 16.5 4.62048 16.4416 4.10433 16.3709C3.2044 16.2477 2.50232 15.5456 2.37907 14.6457C2.30838 14.1295 2.25 13.5689 2.25 13.125C2.25 12.6811 2.30838 12.1205 2.37907 11.6043Z"
      fill={fill}
    />
    <path
      d="M10.6291 3.35433C10.7523 2.4544 11.4544 1.75232 12.3543 1.62907C12.8705 1.55838 13.4311 1.5 13.875 1.5C14.3189 1.5 14.8795 1.55838 15.3957 1.62907C16.2956 1.75232 16.9977 2.4544 17.1209 3.35433C17.1916 3.87048 17.25 4.43112 17.25 4.875C17.25 5.31888 17.1916 5.87952 17.1209 6.39567C16.9977 7.2956 16.2956 7.99768 15.3957 8.12093C14.8795 8.19162 14.3189 8.25 13.875 8.25C13.4311 8.25 12.8705 8.19162 12.3543 8.12093C11.4544 7.99768 10.7523 7.2956 10.6291 6.39567C10.5584 5.87952 10.5 5.31888 10.5 4.875C10.5 4.43112 10.5584 3.87048 10.6291 3.35433Z"
      fill={fill}
    />
    <path
      d="M2.37907 3.35433C2.50232 2.4544 3.2044 1.75232 4.10433 1.62907C4.62048 1.55838 5.18112 1.5 5.625 1.5C6.06888 1.5 6.62952 1.55838 7.14567 1.62907C8.0456 1.75232 8.74768 2.4544 8.87093 3.35433C8.94162 3.87048 9 4.43112 9 4.875C9 5.31888 8.94162 5.87952 8.87093 6.39567C8.74768 7.2956 8.0456 7.99768 7.14567 8.12093C6.62952 8.19162 6.06888 8.25 5.625 8.25C5.18112 8.25 4.62048 8.19162 4.10433 8.12093C3.2044 7.99768 2.50232 7.2956 2.37907 6.39567C2.30838 5.87952 2.25 5.31888 2.25 4.875C2.25 4.43112 2.30838 3.87048 2.37907 3.35433Z"
      fill={fill}
    />
  </svg>
)

export default BoxViewIcon
