const MIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 18 12"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5999 0.310059H9.74988V2.00006C9.25988 1.00006 8.22988 0.320058 7.03988 0.320058H0.379883V11.6901H2.50988V2.45006H7.16988C7.58988 2.45006 7.92988 2.79006 7.92988 3.21006V11.6901H10.0599V3.34006C10.0599 3.03006 10.0099 2.73006 9.92988 2.45006H14.7299C15.1499 2.45006 15.4899 2.79006 15.4899 3.21006V11.6901H17.6199V3.34006C17.6199 1.67006 16.2699 0.310059 14.5999 0.310059Z"
      fill="white"
    />
  </svg>
)

export default MIcon
