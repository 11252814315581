import Big from 'big.js'
import chroma from 'chroma-js'

const defaultOptions = { percentMax: 75, percentMin: 9 }
export const synthPositionFactors = {
  40: 'safe',
  94: 'risky',
  100: 'liquidation'
}
const domain = [0, 39.81, 46.18, 67.07, 71.45, 100]
export const healthFactorGradient = chroma
  .scale(['#68B94A', '#AFAC36', '#CBA72E', '#E89621', '#CD5815', '#B01508'])
  .domain(domain)

healthFactorGradient.toCSS = () =>
  `linear-gradient(90deg, ${domain
    .map(i => `${healthFactorGradient(i).hex()} ${i}%`)
    .join(',')})`

export const getColor = function (position, options = defaultOptions) {
  if (position && position > 0) {
    const _position =
      options?.percentMin && options?.percentMax
        ? Math.max(options.percentMin, Math.min(position, options.percentMax))
        : position
    return healthFactorGradient(Big(_position).toNumber()).hex()
  }
  return 'transparent'
}

const getHealth = function (percent) {
  let _health
  if (percent) {
    Object.keys(synthPositionFactors)
      .reverse()
      .forEach(function (key) {
        if (Big(percent).lte(key)) {
          _health = synthPositionFactors[key]
        }
      })
  }
  return _health
}

export const getHealthFactor = rawHealthFactor =>
  rawHealthFactor &&
  Big(Math.max(1, Math.min(rawHealthFactor, 10)))
    .round(2, Big.roundUp)
    .toNumber()

export const getUserPosition = function ({
  debtInUsd,
  issuableLimitInUsd,
  options = defaultOptions
}) {
  let rawHealthFactor
  let percent

  if (debtInUsd && issuableLimitInUsd) {
    percent = Big(issuableLimitInUsd).lte(0)
      ? 100
      : Math.min(
          Big(debtInUsd)
            .div(issuableLimitInUsd)
            .times(100)
            .round(1, Big.roundUp)
            .toNumber(),
          100
        )
    rawHealthFactor = Big(issuableLimitInUsd).lte(0)
      ? undefined
      : Big(issuableLimitInUsd).div(Math.max(debtInUsd, 1)).toNumber()
  }

  return {
    health: getHealth(percent),
    healthFactor: getHealthFactor(rawHealthFactor),
    healthFactorColor: getColor(percent, options),
    percent,
    rawHealthFactor
  }
}
