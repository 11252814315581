import useTranslation from 'next-translate/useTranslation'

import Banner from './Banner'

function VesperBanner({ className }) {
  const { t } = useTranslation()
  return (
    <Banner
      btn={t('vesper-banner.button')}
      className={`mb-16 ${className ? className : ''}`}
      link="https://app.vesper.finance"
      name="Vesper"
      text={t('vesper-banner.text')}
    />
  )
}

export default VesperBanner
