import useTranslation from 'next-translate/useTranslation'

import { UnsupportedSwitchingOfChainError } from '../utils/customErrors'

import Modal from './Modal'

const getErrorKey = function (error) {
  if (error.name === 'ChainNotSupportedError') {
    return 'error-unsupported-network'
  } else if (error.code === 4001) {
    return 'error-rejected-wallet-connection'
  } else if (error instanceof UnsupportedSwitchingOfChainError) {
    return 'error-wallet-rejected-switching-chain'
  }
  // eslint-disable-next-line no-console
  console.error(error)
  return 'error-unknown'
}

const ErrorHandler = function ({
  error,
  modalIsOpen,
  onRequestClose,
  errorArgs = {}
}) {
  const { t } = useTranslation()
  return error ? (
    <Modal
      className="w-100 bg-main-opacity mx-4 rounded-xl p-4 text-white"
      modalIsOpen={modalIsOpen}
      onRequestClose={onRequestClose}
    >
      <p className="mb-6 text-2xl">{t('error')}</p>
      <p>{t(`${getErrorKey(error)}`, { ...errorArgs })}</p>
    </Modal>
  ) : null
}

export default ErrorHandler
