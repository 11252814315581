const VaUsdcIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="prefix__a">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
    </defs>
    <g clipPath="url(#prefix__a)">
      <path d="M12 24A12 12 0 100 12a11.97 11.97 0 0012 12z" fill="#2775CA" />
      <path
        d="M15.3 13.9c0-1.745-1.05-2.35-3.15-2.6-1.501-.2-1.8-.6-1.8-1.3 0-.7.5-1.15 1.499-1.15.901 0 1.4.3 1.65 1.05a.377.377 0 00.355.245h.8a.34.34 0 00.35-.35v-.05a2.498 2.498 0 00-2.25-2.05v-1.2c0-.2-.15-.35-.4-.4h-.75c-.2 0-.35.15-.4.4v1.154C9.7 7.854 8.75 8.85 8.75 10.1c0 1.65 1 2.301 3.1 2.55 1.4.25 1.855.546 1.855 1.35s-.7 1.35-1.65 1.35c-1.3 0-1.745-.545-1.9-1.3a.365.365 0 00-.356-.304h-.854a.342.342 0 00-.35.35v.05c.2 1.25 1 2.15 2.65 2.4v1.2c0 .2.15.35.4.4h.75c.2 0 .35-.15.4-.4v-1.2c1.5-.25 2.5-1.3 2.5-2.65l.005.005z"
        fill="#fff"
      />
      <path
        d="M9.45 19.15a7.482 7.482 0 010-14.05.502.502 0 00.3-.5v-.7a.38.38 0 00-.3-.4.3.3 0 00-.2.05 8.998 8.998 0 000 17.15.305.305 0 00.45-.2c.05-.05.05-.1.05-.2v-.7c0-.15-.15-.35-.3-.45zm5.3-15.6a.304.304 0 00-.45.2c-.05.05-.05.1-.05.2v.7a.663.663 0 00.3.5 7.483 7.483 0 010 14.05.501.501 0 00-.3.5v.7a.381.381 0 00.3.4.3.3 0 00.2-.05 9.036 9.036 0 000-17.2z"
        fill="#fff"
      />
    </g>
    <g>
      <circle
        cx="18.125"
        cy="17.75"
        fill="#fff"
        r="3.661"
        stroke="#596AEC"
        strokeWidth=".677"
      />
      <path
        d="M18.211 16.273a.099.099 0 00-.094.068l-.652 2.013a.025.025 0 01-.047 0l-.652-2.013a.099.099 0 00-.094-.068h-1.11a.099.099 0 00-.093.131l.136.385c.013.04.05.066.093.066h.525c.01 0 .02.007.023.017l.856 2.421c.014.04.05.066.093.066h.493c.042 0 .08-.026.094-.066l1.02-2.889a.099.099 0 00-.093-.131h-.498zM19.257 19.396c.357 0 .57-.168.668-.359h.017v.313h.515v-1.521c0-.601-.49-.782-.923-.782-.478 0-.845.213-.963.628l.5.07c.053-.155.204-.288.466-.288.249 0 .385.127.385.35v.01c0 .154-.162.161-.563.204-.44.047-.862.179-.862.69 0 .448.327.685.76.685zm.14-.394c-.224 0-.384-.102-.384-.299 0-.206.18-.291.419-.325.14-.02.422-.055.491-.111v.267c0 .253-.204.468-.527.468z"
        fill="#596AEC"
      />
    </g>
  </svg>
)

export default VaUsdcIcon
