const EyeIcon = ({ fillEye = '#259DA8', ...props }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.89128 8.8377C1.59353 8.31909 1.59353 7.68164 1.89128 7.16304C3.45794 4.43431 5.96382 2.66699 8.78783 2.66699C11.6118 2.66699 14.1177 4.43428 15.6844 7.16298C15.9821 7.68158 15.9821 8.31903 15.6844 8.83764C14.1177 11.5664 11.6118 13.3337 8.7878 13.3337C5.96382 13.3337 3.45795 11.5664 1.89128 8.8377Z"
      stroke={fillEye}
      strokeWidth="1"
    />
    <ellipse
      cx="8.78516"
      cy="8"
      rx="2"
      ry="2"
      stroke={fillEye}
      strokeWidth="2"
    />
  </svg>
)
export default EyeIcon
