import IconContainer from './icons/IconContainer'

const Checkbox = ({ setCheckboxState, name, id }) => (
  <>
    <input
      className="absolute h-8 w-8 opacity-0"
      id={id}
      name={name}
      onChange={e => setCheckboxState(e.target.checked)}
      type="checkbox"
    />
    <div className="mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-md border-2 border-gray-600">
      <IconContainer className="hidden h-4 w-4 text-white" name="checkmark" />
    </div>
  </>
)

export default Checkbox
