export const txStatuses = Object.freeze({
  Created: 'created',
  InProgress: 'in-progress',
  Confirmed: 'confirmed',
  Canceled: 'canceled',
  Error: 'error',
  WalletTimeout: 'wallet-timeout',
  PendingConfirmation: 'pending-confirmation'
})

export const isTransactionFinished = transaction =>
  [txStatuses.Confirmed, txStatuses.Canceled, txStatuses.Error].includes(
    transaction.transactionStatus
  )
