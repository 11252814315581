const WbtcIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M19.5691 5.03686L18.9102 5.69584C20.4826 7.4154 21.3546 9.66117 21.3546 11.9913C21.3546 14.3214 20.4826 16.5672 18.9102 18.2867L19.5691 18.9457C21.3178 17.0489 22.2887 14.5635 22.2887 11.9836C22.2887 9.4037 21.3178 6.91829 19.5691 5.02148V5.03686Z"
        fill="#5A5564"
      />
      <path
        d="M5.71146 5.09438C7.43103 3.52194 9.67679 2.64995 12.0069 2.64995C14.337 2.64995 16.5828 3.52194 18.3023 5.09438L18.9613 4.4354C17.0645 2.68669 14.5791 1.71582 11.9992 1.71582C9.41933 1.71582 6.93391 2.68669 5.03711 4.4354L5.71146 5.09438Z"
        fill="#5A5564"
      />
      <path
        d="M5.09437 18.2931C3.52381 16.5739 2.65296 14.3295 2.65296 12.0009C2.65296 9.67235 3.52381 7.42796 5.09437 5.70878L4.4354 5.0498C2.68669 6.94661 1.71582 9.43202 1.71582 12.0119C1.71582 14.5918 2.68669 17.0772 4.4354 18.974L5.09437 18.2931Z"
        fill="#5A5564"
      />
      <path
        d="M18.2928 18.9014C16.5733 20.4738 14.3275 21.3458 11.9974 21.3458C9.66727 21.3458 7.42151 20.4738 5.70195 18.9014L5.04297 19.5603C6.93977 21.3091 9.42519 22.2799 12.0051 22.2799C14.585 22.2799 17.0704 21.3091 18.9672 19.5603L18.2928 18.9014Z"
        fill="#5A5564"
      />
      <path
        d="M16.1605 9.80106C16.0287 8.426 14.8426 7.96471 13.3423 7.82413V5.93066H12.1825V7.78898C11.8772 7.78898 11.5652 7.78898 11.2555 7.78898V5.93066H10.1045V7.83731H7.75195V9.07839C7.75195 9.07839 8.60863 9.06301 8.59545 9.07839C8.74991 9.06139 8.90494 9.10503 9.02787 9.20009C9.15079 9.29515 9.23202 9.43422 9.25443 9.588V14.8071C9.25112 14.8613 9.23698 14.9144 9.21284 14.963C9.18869 15.0117 9.15503 15.055 9.11384 15.0905C9.07343 15.1266 9.02613 15.1541 8.9748 15.1715C8.92346 15.1889 8.86914 15.1957 8.81511 15.1915C8.83048 15.2047 7.97161 15.1915 7.97161 15.1915L7.75195 16.5776H10.0825V18.515H11.2423V16.6061H12.1693V18.5062H13.3313V16.5907C15.2907 16.4721 16.657 15.9889 16.8283 14.1547C16.9667 12.6786 16.2725 12.0196 15.1633 11.7538C15.8376 11.4222 16.255 10.8071 16.1605 9.80106ZM14.535 13.9263C14.535 15.3672 12.0661 15.2025 11.2797 15.2025V12.6457C12.0661 12.6479 14.535 12.4216 14.535 13.9263ZM13.9969 10.3239C13.9969 11.6418 11.9365 11.4815 11.2819 11.4815V9.15746C11.9365 9.15746 13.9969 8.95098 13.9969 10.3239Z"
        fill="#F09242"
      />
      <path
        d="M11.9978 24C9.62462 23.9996 7.30485 23.2955 5.33179 21.9767C3.35874 20.658 1.82101 18.7838 0.913031 16.5912C0.00505138 14.3986 -0.232403 11.986 0.230689 9.65838C0.693782 7.33082 1.83663 5.19282 3.51472 3.51472C5.19282 1.83663 7.33082 0.693782 9.65838 0.230689C11.986 -0.232403 14.3986 0.00505138 16.5912 0.913031C18.7838 1.82101 20.658 3.35874 21.9767 5.33179C23.2955 7.30485 23.9996 9.62462 24 11.9978C24.0003 13.574 23.69 15.1349 23.087 16.5912C22.4839 18.0475 21.5999 19.3707 20.4853 20.4853C19.3707 21.5999 18.0475 22.4839 16.5912 23.087C15.1349 23.69 13.574 24.0003 11.9978 24ZM11.9978 0.935755C9.81137 0.937492 7.67453 1.58738 5.85735 2.80327C4.04018 4.01916 2.62426 5.74648 1.78855 7.7669C0.952839 9.78732 0.734858 12.0101 1.16216 14.1544C1.58945 16.2987 2.64285 18.2682 4.1892 19.8139C5.73555 21.3596 7.70544 22.4123 9.84989 22.8387C11.9943 23.2652 14.2171 23.0463 16.2372 22.2098C18.2572 21.3733 19.984 19.9567 21.1992 18.139C22.4143 16.3214 23.0634 14.1842 23.0643 11.9978C23.0648 10.5446 22.779 9.1055 22.223 7.76285C21.667 6.42019 20.8518 5.20029 19.824 4.17291C18.7962 3.14553 17.576 2.33082 16.2331 1.77537C14.8902 1.21991 13.451 0.9346 11.9978 0.935755Z"
        fill="#282138"
      />
    </g>
  </svg>
)

export default WbtcIcon
