const VesperIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_776_11875)">
      <path
        d="M18 0.5C8.33556 0.5 0.5 8.33556 0.5 18C0.5 27.6644 8.33556 35.5 18 35.5C27.6615 35.4883 35.4883 27.6586 35.5 18C35.5 8.33556 27.6644 0.5 18 0.5Z"
        fill="#4138AC"
      />
      <path
        d="M18 35.5C27.665 35.5 35.5 27.665 35.5 18C35.5 8.33502 27.665 0.5 18 0.5C8.33502 0.5 0.5 8.33502 0.5 18C0.5 27.665 8.33502 35.5 18 35.5Z"
        fill="#596AEC"
      />
      <path
        d="M23.3372 10.3018L19.2266 22.9987L15.113 10.3018H7.32422L8.53666 13.7375H12.1477L17.3597 28.4941H21.0934L27.5179 10.3018H23.3372Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_776_11875">
        <rect
          fill="white"
          height="35"
          transform="translate(0.5 0.5)"
          width="35"
        />
      </clipPath>
    </defs>
  </svg>
)

export default VesperIcon
