// eslint-disable-next-line @typescript-eslint/no-var-requires
const tailwindForms = require('@tailwindcss/forms')({
  strategy: 'class'
})

const MAX_BLINK_DOTS = 3
const getBlinkDelay = i => (i === 0 ? '' : `${i * 200}`)
const extendBlinkAnimation = () =>
  Object.fromEntries(
    [...Array(MAX_BLINK_DOTS).keys()].map(i => [
      `blink${getBlinkDelay(i)}`,
      `blink 1s infinite ${i > 0 ? `${getBlinkDelay(i)}ms` : ''}`
    ])
  )

module.exports = {
  content: [
    './components/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}'
  ],
  safelist: [
    'w-1/2',
    'w-1/3',
    'w-1/4',
    {
      pattern: new RegExp(
        `animate-blink(${[...Array(MAX_BLINK_DOTS).keys()]
          .filter(i => i > 0)
          .map(getBlinkDelay)
          .join('|')})`
      ),
      variants: ['motion-safe']
    }
  ],
  theme: {
    fontFamily: {
      sans: ['Inter'],
      mono: ['SF Mono']
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    fontSize: {
      'display1': [
        '80px',
        {
          lineHeight: '100px'
        }
      ],
      'display2': [
        '72px',
        {
          lineHeight: '92px'
        }
      ],
      'display3': [
        '64px',
        {
          lineHeight: '77.45px'
        }
      ],
      'display4': [
        '56px',
        {
          lineHeight: '67.77px'
        }
      ],
      'display5': [
        '48px',
        {
          lineHeight: '58.09px'
        }
      ],
      'display6': [
        '40px',
        {
          lineHeight: '48.41px'
        }
      ],
      'heading1': [
        '36px',
        {
          lineHeight: '48.97px'
        }
      ],
      'heading2': [
        '28px',
        {
          lineHeight: '38.09px'
        }
      ],
      'heading3': [
        '20px',
        {
          lineHeight: '27.3px'
        }
      ],
      'heading4': [
        '20px',
        {
          lineHeight: '24.2px'
        }
      ],
      'heading5': [
        '16px',
        {
          lineHeight: '25px'
        }
      ],
      'heading6': [
        '14px',
        {
          lineHeight: '16.94px'
        }
      ],
      'tiny': '10px',
      'xs': '12px',
      'sm': '14px',
      'base': '16px',
      'lg': '18px',
      'xl': '20px',
      '2xl': '24px',
      '3xl': '32px',
      '4xl': '40px',
      '5xl': '50px'
    },
    extend: {
      animation: { ...extendBlinkAnimation() },
      boxShadow: {
        'dropdown': '0px 64px 64px -48px rgba(31, 47, 70, 0.12)',
        'info-box':
          '4px 12px 11px -2px rgba(237, 242, 247, 0.2), 4px -12px 11px -2px rgba(237, 242, 247, 0.12), 0px -8px 0px -2px rgba(237, 242, 247, 0.12)',
        'card': '0px 4px 4px #F0F3F4',
        'nav-btn':
          '0px 4px 8px rgba(89, 106, 236, 0.18), 0px 0px 2px rgba(89, 106, 236, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        'primary-action': '0px 3px 8px rgba(89, 106, 236, 0.45)',
        'all-round': '0px 0px 5px 5px rgba(0,0,0,0.75)'
      },
      keyframes: {
        blink: {
          '50%': { color: 'transparent' }
        },
        shimmer: {
          '100%': {
            transform: 'translateX(100%)'
          }
        }
      },
      maxWidth: {
        'screen-1/2xl': '1440px'
      },
      padding: {
        7.5: '1.875rem' // 30px
      },
      spacing: {
        0.75: '0.188rem',
        10.5: '2.625rem',
        13: '3.25rem',
        17: '4.25rem',
        30: '7.5rem',
        42: '10.5rem',
        50: '12.5rem',
        68: '17rem',
        100: '25rem',
        105: '26.25rem',
        130: '32.25rem',
        main: '1100px'
      },
      screens: {
        app: '1440px',
        main: '1100px',
        xs: '370px'
      },
      colors: {
        'black': '#1A202C',
        'blue': {
          primary: '#23262F',
          secondary: '#292D3F',
          tertiary: '#303445',
          4: '#3A405A',
          5: '#343849',
          6: '#333749'
        },
        'coral': {
          primary: '#C659EC',
          secondary: '#F9EEFD'
        },
        'purple': {
          primary: '#596AEC',
          secondary: '#DEE1FB',
          tertiary: '#EEF0FD',
          quaternary: '#F4F5FC'
        },
        'gray': {
          100: '#FAFAFA',
          150: '#F8FCFF',
          200: '#F7FAFC',
          300: '#EDF2F7',
          400: '#E2E8F0',
          500: '#CBD5E0',
          600: '#A0AEC0',
          700: '#718096',
          800: '#4A5568',
          900: '#2D3748',
          primary: '#8F919B',
          secondary: '#999BA3',
          tertiary: '#515563',
          4: '#D7D8DB',
          5: '#8B959E',
          6: '#656774'
        },
        'state': {
          error: '#BE3409',
          success: '#09BE67',
          warning: '#F7936F'
        },
        'state-bg': {
          error: 'rgba(190, 52, 9, 0.1)',
          success: 'rgba(9, 190, 103, 0.1)',
          warning: 'rgba(247, 147, 111, 0.1)'
        },
        'teal': {
          'primary': '#259DA8',
          'primary-0.05': 'rgba(37, 157, 168, 0.05)',
          'primary-0.5': 'rgba(37, 157, 168, 0.5)',
          'primary-0.2': 'rgba(37, 157, 168, 0.2)'
        },
        'main': '#393D51',
        'current': 'currentColor'
      },
      transitionProperty: {
        width: 'width',
        toast: 'position opacity'
      },
      transitionTimingFunction: {
        'bounce': 'cubic-bezier(.47,1.64,.41,.8)',
        'bounce-2': 'cubic-bezier(.51,.84,.32,1.14)'
      },
      minHeight: {
        100: '25rem',
        120: '30rem',
        122.5: '30.625rem',
        132: '33rem',
        146.252: '36.563rem'
      }
    }
  },
  plugins: [tailwindForms],
  variants: {
    animation: ['motion-safe']
  }
}
