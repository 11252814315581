const LiquidityPoolsIcon = ({ fill, ...props }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15.602 5.25H8.39801C7.23556 3.67982 8.80169 1.48227 10.7471 2.10985L11.6749 2.40914C11.8861 2.47728 12.1139 2.47728 12.3252 2.40914L13.253 2.10985C15.1984 1.48227 16.7645 3.67982 15.602 5.25ZM8.50618 6.75H15.4939C16.8131 7.48048 17.8438 8.70013 18.3158 10.2104L19.5658 14.2104C20.7731 18.0739 17.8867 22 13.8389 22H10.1612C6.11332 22 3.22692 18.0739 4.43429 14.2104L5.68429 10.2103C6.15623 8.70013 7.18691 7.48048 8.50618 6.75ZM15.364 17.6558C15.7261 17.4547 15.8568 16.9982 15.6558 16.636C15.4547 16.2739 14.9982 16.1432 14.636 16.3442C13.4669 16.9932 12.6655 17.2501 11.9401 17.2472C11.2185 17.2443 10.4607 16.9836 9.37783 16.3521C9.02002 16.1435 8.56079 16.2644 8.35212 16.6222C8.14345 16.98 8.26436 17.4392 8.62217 17.6479C9.79371 18.3311 10.8248 18.7427 11.934 18.7472C13.0395 18.7517 14.1109 18.3513 15.364 17.6558Z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
)

export default LiquidityPoolsIcon
