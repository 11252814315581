import { WithTooltip } from 'metronome-ui/components/WithTooltip'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { AppContext } from './AppContext/AppContext'
import IconContainer from './icons/IconContainer'

const PlatformValueBox = function ({ value, type }) {
  const { t } = useTranslation()
  const fixedValue = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact'
  }).format(value)
  return (
    <div>
      <WithTooltip
        id={`tip-${type}`}
        overlay={
          <div>
            <p>{t(`${type.toLowerCase()}-description`)}</p>
          </div>
        }
        placement="bottom"
      >
        <div className="bg-selector inline-flex h-10 items-center rounded-lg p-2 text-sm font-medium text-white md:flex">
          <IconContainer className="mr-2 inline" name={type} />
          <span>
            {t(type.toLowerCase())}: ${fixedValue}
          </span>
        </div>
      </WithTooltip>
    </div>
  )
}

const PlatformValues = function () {
  const {
    state: { summary: { tvl = 0, minted = 0 } = {} }
  } = useContext(AppContext)

  return (
    <div className="mb-2 block space-y-2 md:m-0 md:flex md:space-x-2 md:space-y-0">
      <PlatformValueBox type="TVL" value={tvl} />
      <PlatformValueBox type="Minted" value={minted} />
    </div>
  )
}

export default PlatformValues
