const VoteIcon = props => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 41 41"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1497_918)">
      <path
        d="M18.9 25.3764C19.55 26.0264 20.6 26.0264 21.25 25.3764L31.85 14.7764C32.5 14.1264 32.5 13.0764 31.85 12.4264L23.6 4.17642C22.9667 3.50975 21.9167 3.50975 21.2667 4.15975L10.65 14.7764C10 15.4264 10 16.4764 10.65 17.1264L18.9 25.3764ZM22.4333 7.69309L28.3333 13.5931L20.0833 21.8431L14.1833 15.9431L22.4333 7.69309ZM34.0167 26.0264L30.4833 22.4931C30.1833 22.1931 29.75 22.0098 29.3167 22.0098H28.8667L25.5333 25.3431H28.7167L31.6667 28.6764H8.33333L11.3 25.3431H14.7167L11.3833 22.0098H10.6833C10.2333 22.0098 9.81667 22.1931 9.5 22.4931L5.96667 26.0264C5.35 26.6598 5 27.5098 5 28.3931V33.6764C5 35.5098 6.5 37.0098 8.33333 37.0098H31.6667C33.5 37.0098 35 35.5264 35 33.6764V28.3931C35 27.5098 34.65 26.6598 34.0167 26.0264Z"
        fill="#F8F8F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_1497_918">
        <rect
          fill="white"
          height="40"
          transform="translate(0 0.343262)"
          width="40"
        />
      </clipPath>
    </defs>
  </svg>
)

export default VoteIcon
