const MsdogeIcon = ({ id = 'paint0_linear_513_23571', ...props }) => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_513_23571)">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill={`url(#${id})`}
      />
      <path
        clipRule="evenodd"
        d="M16.56 18.2625H21.9525V21.12H16.56V27.1425H19.9612C21.3075 27.1425 22.4088 26.9613 23.2663 26.5963C24.1238 26.2325 24.7963 25.7287 25.285 25.0837C25.7849 24.413 26.1252 23.6371 26.28 22.815C26.4606 21.8877 26.5485 20.9447 26.5425 20C26.5485 19.0553 26.4606 18.1123 26.28 17.185C26.1254 16.3629 25.785 15.5869 25.285 14.9162C24.7963 14.2712 24.1225 13.7675 23.2663 13.4037C22.4088 13.0387 21.3075 12.8575 19.9612 12.8575H16.56V18.2638V18.2625ZM13.1025 21.12H11.25V18.2638H13.1025V10H21.2888C22.8013 10 24.11 10.2625 25.2163 10.7837C26.3225 11.3075 27.225 12.0212 27.9262 12.9275C28.6262 13.8325 29.1475 14.8925 29.4887 16.1063C29.83 17.32 30 18.6187 30 20C30.0086 21.3153 29.8361 22.6255 29.4875 23.8937C29.1475 25.1062 28.6263 26.1675 27.925 27.0725C27.225 27.9788 26.3225 28.6925 25.2163 29.2162C24.11 29.7387 22.8 30 21.2888 30H13.1025V21.12V21.12Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id={id}
        x1="0"
        x2="40"
        y1="0"
        y2="40"
      >
        <stop stopColor="#596AEC" />
        <stop offset="1" stopColor="#60E2E9" />
      </linearGradient>
      <clipPath id="clip0_513_23571">
        <rect fill="white" height="40" width="40" />
      </clipPath>
    </defs>
  </svg>
)

export default MsdogeIcon
