const CaretIcon = props => (
  <svg
    fill="currentColor"
    height="8"
    viewBox="0 0 12 8"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.90561 0.326951C1.46967 -0.108984 0.762885 -0.108984 0.326951 0.326951C-0.108984 0.762885 -0.108984 1.46967 0.326951 1.90561L5.21067 6.78933C5.64661 7.22526 6.35339 7.22526 6.78933 6.78933L11.673 1.90561C12.109 1.46967 12.109 0.762885 11.673 0.326951C11.2371 -0.108984 10.5303 -0.108984 10.0944 0.326951L6 4.42134L1.90561 0.326951Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export default CaretIcon
