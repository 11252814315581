import { findByChainId } from 'chain-list'
import { useChainId } from 'metronome-ui/hooks/chains'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'

const trackingId = process.env.NEXT_PUBLIC_ANALYTICS_ID
const hostUrl = process.env.NEXT_PUBLIC_HOST_URL

const CustomHead = function ({ pageTitle, description }) {
  const { t } = useTranslation('common')
  const chainId = useChainId()
  const { defaultLocale, locale, asPath } = useRouter()
  const { shortName: chainShortName } = findByChainId(chainId)

  const _asPath = asPath.split('?')[0]
  const pageUrl = `${hostUrl}${
    defaultLocale !== locale ? `/${locale}` : ''
  }${_asPath}`

  const previewImageUrl = useMemo(
    function () {
      let imageFile = 'metronome'

      if (_asPath.startsWith(`/${chainShortName}/smart-farming`)) {
        imageFile = `smart-farming-${chainShortName}`
      }
      return `${hostUrl}/preview/${imageFile}.jpg`
    },
    [_asPath, chainShortName]
  )

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta
          content={description ? description : t('app-description')}
          name="description"
        />
        <meta content="max-image-preview:large" name="robots" />
        <meta content="website" property="og:type" />
        <meta content={locale} property="og:locale" />
        <meta content="Metronome App" property="og:site_name" />
        <link href={pageUrl} rel="canonical" />
        <meta content={pageUrl} property="og:url" />
        <meta content={pageTitle} property="og:title" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="@MetronomeDAO" name="twitter:site" />
        <meta content={t('app-description')} property="og:description" />
        <meta content={previewImageUrl} property="og:image" />
        <meta content={previewImageUrl} property="twitter:image" />
        <link href="/favicon.ico" rel="icon" />
        <link href="/icons/icon_144x144.png" rel="apple-touch-icon" />
        <link
          href="/icons/icon_192x192.png"
          rel="apple-touch-icon"
          sizes="192x192"
        />
        <link
          href="/icons/icon_240x240.png"
          rel="apple-touch-icon"
          sizes="240x240"
        />
        <link
          href="/icons/icon_256x256.png"
          rel="apple-touch-icon"
          sizes="256x256"
        />
        <meta content="Metronome Synth" name="apple-mobile-web-app-title" />
      </Head>
      {process.env.NODE_ENV === 'production' && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}');
          `
            }}
            id="gtag-init"
          />
        </>
      )}
    </>
  )
}

export default CustomHead
