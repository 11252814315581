import Big from 'big.js'
import { fromUnit } from 'metronome-utils'

import { connectors } from '../../utils/connectors'
import { getUserPosition } from '../../utils/getUserPosition'

import actions from './actions'
import { AppState } from './AppContext'

const buildAssetBalances = assetBalances =>
  Object.fromEntries(
    Object.keys(assetBalances).map(function (assetAddress) {
      const { balance, decimals } = assetBalances[assetAddress]
      return [assetAddress, Big(fromUnit(balance, decimals)).toFixed()]
    })
  )

function appReducer(state: AppState, action): AppState {
  switch (action.type) {
    case actions.WALLET_CONNECTED: {
      const {
        payload: { account, active, connector }
      } = action

      const currentConnectorName =
        connector && connectors.find(c => c.connector === connector)?.name

      return {
        ...state,
        wallet: {
          ...state.wallet,
          account,
          connected: active,
          name: currentConnectorName
        }
      }
    }
    case actions.WALLET_DISCONNECTED:
      return {
        ...state,
        balances: {
          collateral: {},
          debt: {},
          deposit: {},
          synthetic: {}
        },
        userPosition: {
          claimable: '0',
          deposited: '0',
          generated: '0',
          issuableLimitInUsd: '0'
        },
        wallet: {
          balancesLoaded: false,
          connected: false
        }
      }
    case actions.SET_USER_DATA: {
      const debtInUsd = action.payload.debtPosition?.debtInUsd ?? 0
      const issuableLimitInUsd =
        action.payload.debtPosition?.issuableLimitInUsd ?? 0

      const claimableTotalInUsd = action.payload.claimables.reduce(
        (acc, { amount, rewardToken: { decimals }, usdPrice }) =>
          usdPrice
            ? Big(acc).plus(Big(fromUnit(amount, decimals)).times(usdPrice))
            : acc,
        Big(0)
      )

      const {
        collateralBalances,
        depositTokenBalances,
        syntheticTokenBalances,
        debtTokenBalances
      } = action.payload

      return {
        ...state,
        balances: {
          collateral: buildAssetBalances(collateralBalances),
          debt: buildAssetBalances(debtTokenBalances),
          deposit: buildAssetBalances(depositTokenBalances),
          synthetic: buildAssetBalances(syntheticTokenBalances)
        },
        userPosition: {
          ...state.userPosition,
          claimable: claimableTotalInUsd,
          deposited: action.payload.debtPosition?.depositInUsd ?? 0,
          generated: debtInUsd,
          issuableLimitInUsd,
          ...getUserPosition({
            debtInUsd,
            issuableLimitInUsd
          })
        },
        wallet: {
          ...state.wallet,
          balancesLoaded: true
        }
      }
    }
  }
  return state
}

export default appReducer
