import useTranslation from 'next-translate/useTranslation'

import ExternalLink from './ExternalLink'

const LegalLinks = function () {
  const { t } = useTranslation()
  return (
    <>
      <ExternalLink
        className="block cursor-pointer md:inline"
        href="https://github.com/autonomoussoftware/metronome-synth-legal/blob/master/terms-and-conditions.md"
      >
        {t('terms-and-conditions')}
      </ExternalLink>
      <ExternalLink
        className="block md:inline"
        href="https://github.com/autonomoussoftware/metronome-synth-legal/blob/master/privacy-policy.md"
      >
        {t('privacy-policy')}
      </ExternalLink>
    </>
  )
}

export default LegalLinks
