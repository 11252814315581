const VaStethIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="m5.23165,10.82773l-0.18472,0.2834c-2.08351,3.19617 -1.61816,7.38201 1.11878,10.06365c1.6101,1.57768 3.72036,2.36659 5.83069,2.36678c0,0 0,0 -6.76474,-12.71383l-0.00001,0z"
        fill="#00A3FF"
        id="svg_2"
        stroke="null"
      />
      <path
        d="m11.99446,14.69182l-6.76474,-3.86409c6.76474,12.71383 6.76474,12.71383 6.76474,12.71383c0,-2.76897 0,-5.94187 0,-8.84974z"
        fill="#00A3FF"
        id="svg_3"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m18.76835,10.82773l0.18472,0.2834c2.08351,3.19617 1.61816,7.38201 -1.11878,10.06365c-1.6101,1.57768 -3.72036,2.36659 -5.83069,2.36678c0,0 0,0 6.76474,-12.71383l0.00001,0z"
        fill="#00A3FF"
        id="svg_4"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m12.00284,14.69182l6.76474,-3.86409c-6.76468,12.71383 -6.76474,12.71383 -6.76474,12.71383c0,-2.76897 0,-5.94187 0,-8.84974z"
        fill="#00A3FF"
        id="svg_5"
        opacity="0.2"
        stroke="null"
      />
      <path
        d="m12.00535,6.05955l0,6.66445l5.82708,-3.33004l-5.82708,-3.33442l0,0.00001z"
        fill="#00A3FF"
        id="svg_6"
        opacity="0.2"
        stroke="null"
      />
      <path
        d="m12.00361,6.05955l-5.83127,3.33429l5.83127,3.33016l0,-6.66445z"
        fill="#00A3FF"
        id="svg_7"
        opacity="0.6"
        stroke="null"
      />
      <path
        d="m12.00361,0.45483l-5.83127,8.9412l5.83127,-3.34363l0,-5.59756l0,-0.00001z"
        fill="#00A3FF"
        id="svg_8"
        stroke="null"
      />
      <path
        d="m12.00535,6.05136l5.83153,3.34376l-5.83153,-8.94566l0,5.6019z"
        fill="#00A3FF"
        id="svg_9"
        opacity="0.6"
        stroke="null"
      />
      <g id="svg_12">
        <circle
          cx="19.74687"
          cy="19"
          fill="white"
          id="svg_1"
          r="3.66146"
          stroke="#596AEC"
          strokeWidth="0.67708"
        />
        <path
          d="m19.83297,17.5225c-0.0429,0 -0.0809,0.0276 -0.0941,0.0685l-0.6518,2.0132c-0.0074,0.0228 -0.0397,0.0228 -0.0471,0l-0.6523,-2.0133c-0.0132,-0.0408 -0.0512,-0.0684 -0.0941,-0.0684l-1.1097,0c-0.0683,0 -0.1161,0.0675 -0.0934,0.1319l0.1359,0.385c0.0139,0.0396 0.0514,0.066 0.0933,0.066l0.5252,0c0.0105,0 0.0198,0.0066 0.0233,0.0165l0.8552,2.4214c0.014,0.0396 0.0514,0.066 0.0934,0.066l0.4935,0c0.0419,0 0.0794,-0.0264 0.0933,-0.066l1.0202,-2.8889c0.0228,-0.0644 -0.025,-0.1319 -0.0933,-0.1319l-0.4975,0z"
          fill="#596AEC"
          id="svg_10"
        />
        <path
          d="m20.87917,20.6455c0.3566,0 0.5697,-0.1672 0.6674,-0.3581l0.0177,0l0,0.3122l0.5149,0l0,-1.5211c0,-0.6007 -0.4897,-0.7812 -0.9233,-0.7812c-0.4779,0 -0.8448,0.2131 -0.9632,0.6274l0.5001,0.071c0.0533,-0.1554 0.2042,-0.2886 0.4661,-0.2886c0.2486,0 0.3847,0.1273 0.3847,0.3507l0,0.0089c0,0.1539 -0.1613,0.1613 -0.5623,0.2042c-0.4409,0.0473 -0.8626,0.179 -0.8626,0.691c0,0.4468 0.327,0.6836 0.7605,0.6836zm0.1391,-0.3936c-0.2234,0 -0.3832,-0.1021 -0.3832,-0.2989c0,-0.2057 0.179,-0.2915 0.4187,-0.3255c0.1406,-0.0192 0.4217,-0.0548 0.4913,-0.111l0,0.2678c0,0.253 -0.2042,0.4676 -0.5268,0.4676z"
          fill="#596AEC"
          id="svg_11"
        />
      </g>
    </g>
  </svg>
)

export default VaStethIcon
