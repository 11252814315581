import { useChainId } from 'metronome-ui/hooks/chains'

import useFeatureNotAvailableModal from '../hooks/useFeatureNotAvailableModal'

import NavigationMenu from './NavigationMenu.js'
import Sidebar from './Sidebar.js'

function Navigation({ mobileNavbarOpen, setMobileNavbarOpen, showRibbon }) {
  const chainId = useChainId()
  const { featureNotAvailableModal, openFeatureNotAvailableModal } =
    useFeatureNotAvailableModal()

  const handleItemClick = (supportedChainIds, featureKey) =>
    function (e) {
      if (supportedChainIds && !supportedChainIds.includes(chainId)) {
        e.preventDefault()
        openFeatureNotAvailableModal(featureKey, supportedChainIds)
      }
    }

  return (
    <>
      <div className="lg:hidden">
        <NavigationMenu
          mobileNavbarOpen={mobileNavbarOpen}
          onItemClick={handleItemClick}
          setMobileNavbarOpen={setMobileNavbarOpen}
          showRibbon={showRibbon}
        />
      </div>
      <div className="hidden lg:block">
        <Sidebar onItemClick={handleItemClick} showRibbon={showRibbon} />
      </div>
      {featureNotAvailableModal}
    </>
  )
}

export default Navigation
