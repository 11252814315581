import Big from 'big.js'
import { fromUnit } from 'metronome-utils'

import { AssetLists, Fees } from '../components/AppContext/AppContext'

export const prepareAssetLists = function (
  selectedPoolData,
  balancesLoaded,
  balances
): AssetLists {
  if (!selectedPoolData) {
    return {
      collaterals: [],
      synthetics: []
    }
  }
  return {
    collaterals: selectedPoolData.collaterals.map(function ({
      address,
      apy,
      availableSupply,
      collateralFactor,
      decimals,
      isActive,
      usdPrice,
      symbol
    }) {
      let balance
      let deposited
      if (balancesLoaded) {
        balance = balances.collateral[address]
        deposited = balances.deposit[address]
      }

      return {
        address,
        apy,
        availableSupply: fromUnit(availableSupply, decimals),
        balance,
        balanceInUsd: balance
          ? Big(balance).times(usdPrice).toFixed()
          : undefined,
        collateralFactor: Big(collateralFactor).toNumber(),
        decimals,
        deposited,
        depositedInUsd: deposited
          ? Big(deposited).times(usdPrice).toFixed()
          : undefined,
        isActive,
        symbol,
        usdPrice
      }
    }),
    synthetics: selectedPoolData.synthetics.map(function ({
      address,
      availableSupply,
      debtAvailableSupply,
      decimals,
      interestRate,
      isActive,
      maxTotalSupply,
      symbol,
      totalSupply,
      usdPrice
    }) {
      let balance
      let generated
      if (balancesLoaded) {
        balance = balances.synthetic[address]
        generated = balances.debt[address]
      }
      return {
        address,
        availableSupply: fromUnit(availableSupply, decimals),
        balance,
        balanceInUsd: balance
          ? Big(balance).times(usdPrice).toFixed()
          : undefined,
        debtAvailableSupply: fromUnit(debtAvailableSupply, decimals),
        decimals,
        generated,
        generatedInUsd: generated
          ? Big(generated).times(usdPrice).toFixed()
          : undefined,
        interestRate: Big(interestRate).toNumber(),
        isActive,
        maxTotalSupply: fromUnit(maxTotalSupply, decimals),
        symbol,
        totalSupply: fromUnit(totalSupply, decimals),
        usdPrice
      }
    })
  }
}

export const prepareFeesData = (fees): Fees =>
  fees && {
    defaultSwapFee: Big(fees.defaultSwapFee).toNumber(),
    depositFee: Big(fees.depositFee).toNumber(),
    issueFee: Big(fees.issueFee).toNumber(),
    liquidationFees: {
      liquidatorFee: Big(fees.liquidationFees.liquidatorFee).toNumber(),
      protocolFee: Big(fees.liquidationFees.protocolFee).toNumber()
    },
    liquidationPenalty: Big(fees.liquidationFees.liquidatorFee)
      .plus(fees.liquidationFees.protocolFee)
      .mul(100)
      .toNumber(),
    repayFee: Big(fees.repayFee).toNumber(),
    withdrawFee: Big(fees.withdrawFee).toNumber()
  }
