const CheckCircleIcon = props => (
  <svg
    className="h-6 w-6"
    fill="none"
    height="24"
    viewBox="0 0 24 25"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 12.3433C22 17.8633 17.52 22.3433 12 22.3433C6.48 22.3433 2 17.8633 2 12.3433C2 6.82326 6.48 2.34326 12 2.34326C17.52 2.34326 22 6.82326 22 12.3433ZM6.79289 11.6362C6.40237 12.0267 6.40237 12.6598 6.79289 13.0504L9.79289 16.0504C10.1834 16.4409 10.8166 16.4409 11.2071 16.0504L17.2071 10.0504C17.5976 9.65984 17.5976 9.02668 17.2071 8.63616C16.8166 8.24563 16.1834 8.24563 15.7929 8.63616L10.5 13.929L8.20711 11.6362C7.81658 11.2456 7.18342 11.2456 6.79289 11.6362Z"
      fill="#259DA8"
      fillOpacity="0.5"
    />
  </svg>
)

export default CheckCircleIcon
