import ChainSelector from './ChainSelector'
import Wallet from './Wallet'

const MobileFixedFooter = () => (
  <div className="bg-main-opacity fixed bottom-0 left-0 z-20 flex h-20 w-full items-center justify-center space-x-2.5">
    <ChainSelector compact={true} mode="mobile" />
    <Wallet />
  </div>
)

export default MobileFixedFooter
