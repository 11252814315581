const GithubIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1581_22256)">
      <path
        clipRule="evenodd"
        d="M12.0006 0.5C9.15186 0.501478 6.3965 1.51046 4.22724 3.34652C2.05797 5.18259 0.61627 7.72601 0.159911 10.522C-0.296448 13.318 0.262293 16.1842 1.73624 18.6082C3.21018 21.0321 5.50321 22.8558 8.2053 23.753C8.80142 23.8636 9.02596 23.4941 9.02596 23.18C9.02596 22.8658 9.01404 21.955 9.01006 20.9592C5.67176 21.6804 4.96635 19.5505 4.96635 19.5505C4.42189 18.1674 3.635 17.8039 3.635 17.8039C2.54608 17.065 3.71648 17.0788 3.71648 17.0788C4.92264 17.1637 5.55652 18.3097 5.55652 18.3097C6.62557 20.1333 8.36426 19.6058 9.04782 19.2976C9.15512 18.5251 9.4671 17.9995 9.81086 17.7012C7.1442 17.4008 4.3424 16.3774 4.3424 11.8054C4.32588 10.6197 4.76839 9.47305 5.57838 8.60268C5.45518 8.30236 5.04384 7.08923 5.69561 5.44143C5.69561 5.44143 6.70307 5.12136 8.99616 6.66444C10.963 6.12962 13.0382 6.12962 15.0051 6.66444C17.2962 5.12136 18.3017 5.44143 18.3017 5.44143C18.9554 7.08528 18.5441 8.29841 18.4209 8.60268C19.2334 9.47319 19.6769 10.6218 19.6588 11.8094C19.6588 16.3912 16.8511 17.4008 14.1805 17.6952C14.6097 18.0667 14.9932 18.7918 14.9932 19.9061C14.9932 21.5026 14.9793 22.7868 14.9793 23.18C14.9793 23.4981 15.1959 23.8695 15.8039 23.753C18.5063 22.8557 20.7996 21.0317 22.2735 18.6073C23.7474 16.183 24.3059 13.3163 23.849 10.5201C23.3921 7.7238 21.9497 5.18035 19.7797 3.34461C17.6097 1.50886 14.8537 0.500541 12.0046 0.5H12.0006Z"
        fill="white"
        fillOpacity="0.5"
        fillRule="evenodd"
      />
      <path
        d="M4.54456 17.6321C4.51873 17.6914 4.42335 17.7092 4.34585 17.6677C4.26835 17.6262 4.21073 17.5491 4.23855 17.4879C4.26637 17.4266 4.35976 17.4108 4.43726 17.4523C4.51476 17.4938 4.57436 17.5729 4.54456 17.6321Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M5.03123 18.1714C4.99008 18.192 4.943 18.1978 4.89805 18.1877C4.8531 18.1776 4.81308 18.1523 4.78483 18.1161C4.70734 18.0331 4.69143 17.9185 4.75104 17.8671C4.81066 17.8157 4.91797 17.8395 4.99546 17.9224C5.07296 18.0054 5.09084 18.12 5.03123 18.1714Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M5.50388 18.857C5.43036 18.9084 5.30516 18.857 5.23562 18.7543C5.21638 18.7359 5.20109 18.7138 5.19064 18.6893C5.1802 18.6649 5.1748 18.6386 5.1748 18.612C5.1748 18.5855 5.1802 18.5592 5.19064 18.5347C5.20109 18.5103 5.21638 18.4882 5.23562 18.4698C5.30914 18.4204 5.43433 18.4698 5.50388 18.5705C5.57343 18.6713 5.57541 18.8057 5.50388 18.857Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M6.14563 19.5207C6.08005 19.5939 5.94692 19.5741 5.83763 19.4753C5.72834 19.3765 5.7025 19.2422 5.76808 19.171C5.83365 19.0999 5.96679 19.1197 6.08005 19.2165C6.19331 19.3133 6.21518 19.4496 6.14563 19.5207Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M7.04617 19.9081C7.01637 20.001 6.88124 20.0425 6.74612 20.003C6.611 19.9635 6.52158 19.8528 6.54741 19.758C6.57325 19.6631 6.71036 19.6197 6.84747 19.6631C6.98457 19.7066 7.07201 19.8113 7.04617 19.9081Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M8.02832 19.9752C8.02832 20.072 7.91705 20.155 7.77398 20.1569C7.63091 20.1589 7.51367 20.0799 7.51367 19.9831C7.51367 19.8863 7.62494 19.8033 7.76801 19.8013C7.91108 19.7993 8.02832 19.8764 8.02832 19.9752Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M8.94238 19.8232C8.96027 19.92 8.86091 20.0207 8.71784 20.0445C8.57477 20.0682 8.44959 20.0109 8.4317 19.916C8.41382 19.8212 8.51715 19.7185 8.65624 19.6928C8.79534 19.6671 8.9245 19.7264 8.94238 19.8232Z"
        fill="white"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1581_22256">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default GithubIcon
