const MetAltIcon = ({ ...props }) => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 40 41"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 20.3433C40 31.3833 31.04 40.3433 20 40.3433C8.96 40.3433 0 31.3833 0 20.3433C0 9.30326 8.96 0.343262 20 0.343262C31.04 0.343262 40 9.30326 40 20.3433Z"
      fill="#F8F8F9"
      fillOpacity="0.05"
    />
    <path
      d="M27.4668 12.7568H21.0002V15.0102C20.3468 13.6768 18.9735 12.7702 17.3868 12.7702H8.50684V27.9302H11.3468V15.6102H17.5602C18.1202 15.6102 18.5735 16.0635 18.5735 16.6235V27.9302H21.4135V16.7968C21.4135 16.3835 21.3468 15.9835 21.2402 15.6102H27.6402C28.2002 15.6102 28.6535 16.0635 28.6535 16.6235V27.9302H31.4935V16.7968C31.4935 14.5702 29.6935 12.7568 27.4668 12.7568Z"
      fill="#F8F8F9"
    />
  </svg>
)

export default MetAltIcon
