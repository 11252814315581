const BnbIcon = props => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
      fill="#f0b90b"
      fillRule="evenodd"
      transform="translate(0)"
    />
    <path
      d="M6.62,12v3.22L9.31,16.8v1.85l-4.25-2.4V11.14Zm0-3.14v1.82L5.06,9.77V8l1.56-.91L8.18,8,6.62,8.9Zm3.82-.94L12,7.06,13.56,8,12,8.9,10.44,8Z"
      fill="#fff"
      transform="translate(0)"
    />
    <path
      d="M7.75,14.57V12.72l1.56.91v1.85Zm2.69,2.88,1.56.93,1.56-.93V19.2l-1.56,1-1.56-1ZM15.82,8l1.56-.91L18.94,8V9.82l-1.56.91V8.9Zm1.56,7.2V12l1.56-.91v5.06l-4.25,2.4V16.8l2.69-1.58Z"
      fill="#fff"
      transform="translate(0)"
    />
    <polygon
      fill="#fff"
      points="16.25 14.54 14.69 15.46 14.69 13.63 16.25 12.7 16.25 14.54"
    />
    <path
      d="M16.25,9.6v1.85L13.56,13v3.12L12,17.06l-1.56-.91V13L7.75,11.4V9.6l1.56-.94L12,10.2l2.69-1.58ZM7.75,6.38,12,3.89l4.25,2.4-1.56.93L12,5.74,9.31,7.32Z"
      fill="#fff"
      transform="translate(0)"
    />
  </svg>
)

export default BnbIcon
